import styled from '@emotion/styled';

import { NewTable as Table } from '@jane/shared/reefer-table';

export const FixedContainer = styled.div<{ height?: number }>(({ height }) => ({
  height: `calc(100vh - 80px - ${height}px)`,
  overflow: 'hidden',
  display: 'flex',
}));

export const ScrollableContainer = styled.div({
  scrollbarWidth: 'none',
  overflow: 'scroll',
  paddingBottom: '24px',
});

export const StyledTable = styled(Table)({
  tableLayout: 'fixed',
  width: '100%',
  height: 'fit-content',
});

export const HeaderCell = styled(Table.HeaderCell)({
  width: '100%',
  '> div': {
    width: '100%',
  },
});

export const SpecialsTableRow = styled.tr({
  verticalAlign: 'middle',
});
