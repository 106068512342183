import { useQueryClient } from '@tanstack/react-query';
import type {
  ColumnDef,
  ColumnFiltersState,
  RowSelectionState,
  SortingState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo, useState } from 'react';

import {
  useBrandProductFilters,
  useBrandProducts,
  useInfiniteBrandProducts,
} from '@jane/gold-manager/data-access';
import type { BrandProduct } from '@jane/gold-manager/data-access';
import {
  ProductSelectModal,
  columnFiltersToFilters,
  getSelectedProductIds,
  getSelectedProducts,
  useProductSelectColumns,
} from '@jane/shared-b2b/components';
import { Button, Flex, Modal, Typography } from '@jane/shared/reefer';
import { useWatch } from '@jane/shared/reefer-hook-form';

import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import { getExclusionRule } from '../utils/specialForm.util';
import type { BrandSpecialRulesVariant } from './productsSection';
import { SelectedProducts } from './selectedProducts';

interface ProductSelectionProps {
  variant: BrandSpecialRulesVariant;
}

export const ProductSelection = ({ variant }: ProductSelectionProps) => {
  const queryClient = useQueryClient();
  const { rules, setRulesValue } = useSpecialForm();
  const brandId = useWatch({ name: 'productBrandId' });
  const [open, setOpen] = useState(false);
  const [searchIds, setSearchIds] = useState<string[]>([]);

  const isExclusion = variant === 'exclusion';
  const formKey = isExclusion ? 'excludes.product_ids' : 'includes.product_ids';
  const selectedProductIds = isExclusion
    ? getExclusionRule(rules, 'product_ids')
    : rules.includes?.[0].product_ids ?? [];

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const activeFilters = columnFiltersToFilters(columnFilters);
  const { category, subcategory, lineage, name } = activeFilters || {};

  const {
    data: selectedProducts = [],
    isLoading: isLoadingSelectedProducts,
    isFetching,
  } = useBrandProducts({
    brandId,
    ids: selectedProductIds,
    enabled: !!selectedProductIds.length,
  });

  const selectedProductsToRows =
    getSelectedProducts<BrandProduct>(selectedProducts);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>(
    selectedProductsToRows
  );

  const {
    data: productsData,
    isFetchingNextPage,
    isLoading,
    fetchNextPage,
  } = useInfiniteBrandProducts({
    brandId,
    category: Array.isArray(category) ? category : [],
    enabled: !!brandId,
    ids: searchIds.map((id) => Number(id)),
    lineage: Array.isArray(lineage) ? lineage : [],
    perPage: 100,
    query: typeof name === 'string' ? name : '',
    subcategory: Array.isArray(subcategory) ? subcategory : [],
  });

  const {
    data: filtersData,
    isFetching: isFetchingFilters,
    isLoading: isLoadingFilters,
  } = useBrandProductFilters({
    brandId,
    category: Array.isArray(category) ? category : [],
  });

  const products = useMemo(
    () => productsData?.pages.flatMap((page) => page.products) || [],
    [productsData]
  );

  const filters = useMemo(() => filtersData || {}, [filtersData]);

  const columnVisibility = {
    name: true,
    brand: false,
    id: true,
    category: true,
    subcategory: true,
    lineage: true,
    weight: false,
    outOfStock: false,
  };

  // casting is required ¯\_(ツ)_/¯ https://github.com/TanStack/table/issues/4382
  const productTableColumns = useProductSelectColumns({
    filters,
    productCount: products?.length || 0,
  }) as ColumnDef<BrandProduct>[];

  const productTable = useReactTable({
    columns: productTableColumns,
    data: products,
    state: { columnFilters, columnVisibility, sorting, rowSelection },
    onColumnFiltersChange: setColumnFilters,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id.toString(),
    enableRowSelection: true,
    enableGlobalFilter: false,
  });

  const selectedTableProducts = productTable.getSelectedRowModel().rows;
  const hasSelectedProducts = selectedProductIds.length > 0;

  const onSaveClick = () => {
    const selectedProducts = productTable
      .getSelectedRowModel()
      .rows.map((row) => row.original);

    const formKey =
      variant === 'exclusion' ? 'excludes.product_ids' : 'includes.product_ids';

    if (selectedTableProducts) {
      setRulesValue(
        formKey,
        selectedProducts.map((product) => Number(product.id))
      );
    } else {
      setRulesValue(formKey, []);
    }

    setOpen(false);
    setSearchIds([]);
  };

  const onRemoveSelectedProduct = (productId: string) => {
    const updatedSelectedProducts = selectedProducts.filter(
      (product) => product.id !== productId
    );

    const newProps = {
      brandId,
      ids: selectedProductIds.filter((id: number) => id !== Number(productId)),
    };

    queryClient.setQueryData(
      ['brand', 'products', newProps],
      updatedSelectedProducts
    );

    setRulesValue(
      formKey,
      updatedSelectedProducts.map((product) => Number(product.id))
    );
    productTable.setRowSelection(getSelectedProducts(updatedSelectedProducts));
    setSearchIds(updatedSelectedProducts.map((product) => product.id));
  };

  const updateSearchIds: Dispatch<SetStateAction<string[]>> = (value) => {
    if (typeof value !== 'function' && value.length > 0) {
      productTable.setRowSelection(getSelectedProductIds(value));
    }

    setSearchIds(value);
  };

  return (
    <Flex flexDirection="column" gap={16} my={16} key={variant}>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Typography>
          Products{hasSelectedProducts ? ` (${selectedProductIds.length})` : ''}
        </Typography>
        <Button
          variant="tertiary"
          label="Select"
          aria-label={`Select ${variant} products`}
          disabled={!brandId}
          onClick={() => setOpen(true)}
        />
        <Modal
          open={open}
          onRequestClose={() => setOpen(false)}
          appId="parent"
          variant="flex"
        >
          <ProductSelectModal<BrandProduct>
            dualSearchMode
            isFetchingNextPage={isFetchingNextPage}
            isLoadingFilters={isLoadingFilters || isFetchingFilters}
            isLoadingProducts={isLoading}
            onNextPage={fetchNextPage}
            onSaveClick={onSaveClick}
            productTable={productTable}
            setSearchIds={updateSearchIds}
          />
        </Modal>
      </Flex>

      {hasSelectedProducts && (
        <SelectedProducts
          isLoading={isFetching || isLoadingSelectedProducts}
          numberOfProducts={selectedProductIds.length}
          products={selectedProducts}
          onRemove={onRemoveSelectedProduct}
          variant={variant}
        />
      )}
    </Flex>
  );
};
