import type * as ReactTable from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import { useInView } from 'react-intersection-observer';

import type { Product } from '@jane/ad-manager/data-access';
import { Flex } from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { StyledLoading } from './StyledLoading';

export type ProductTableWithMeta = ReactTable.Table<Product>;

const ProductsFetching = ({ fetchMore }: { fetchMore: () => void }) => {
  const { ref: fetchMoreRef } = useInView({
    onChange: (inView) => {
      if (inView) fetchMore();
    },
  });
  return (
    <Table.Row>
      <th colSpan={100} ref={fetchMoreRef}>
        <Flex height={72} justifyContent="center" alignItems="center">
          <StyledLoading data-testid="product-table-fetching" size="sm" />
        </Flex>
      </th>
    </Table.Row>
  );
};

const TableBody = ({
  table,
  hasNextPage,
  fetchMore,
}: {
  fetchMore: () => void;
  hasNextPage?: boolean;
  table: ProductTableWithMeta;
}) => {
  return (
    <Table.Body>
      <>
        {table.getRowModel().rows.map((row) => (
          <Table.Row
            key={row.id}
            data-testid={`select-products-table-row-${row.id}`}
          >
            {row.getVisibleCells().map((cell) => (
              <Table.Cell key={cell.id}>
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </Table.Cell>
            ))}
          </Table.Row>
        ))}
        {hasNextPage && <ProductsFetching fetchMore={fetchMore} />}
      </>
    </Table.Body>
  );
};

export const ProductTable = ({
  fetchMore,
  table,
  hasNextPage,
}: {
  fetchMore: () => void;
  hasNextPage?: boolean;
  table: ProductTableWithMeta;
}) => {
  return (
    <Table scrollable={false} style={{ tableLayout: 'auto' }}>
      <Table.Head style={{ top: '-24px' }}>
        {table.getHeaderGroups().map((headerGroup) => (
          <Table.Row key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <Table.HeaderCell key={header.id} width={`${header.getSize()}px`}>
                {!header.isPlaceholder &&
                  flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
              </Table.HeaderCell>
            ))}
          </Table.Row>
        ))}
      </Table.Head>
      <TableBody
        table={table}
        fetchMore={fetchMore}
        hasNextPage={hasNextPage}
      />
    </Table>
  );
};
