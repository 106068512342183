import type {
  BrandCategory,
  BrandSpecialRules,
  BrandSpecialSchedule,
} from '@jane/gold-manager/data-access';
import {
  API_SCHEDULE_DATETIME_FORMAT,
  API_SCHEDULE_TIME_FORMAT,
  CLIENT_SCHEDULE_DATETIME_FORMAT,
  CLIENT_SCHEDULE_DATE_FORMAT,
  CLIENT_SCHEDULE_TIME_FORMAT,
} from '@jane/gold-manager/util';
import {
  type CategoryOption,
  type DailySchedule,
  type ManualScheduleForDays,
  WEEKDAY_TO_WEEKDAY_MIN,
} from '@jane/shared-b2b/components';
import type { KindCondition, ProductWeight } from '@jane/shared/models';
import type { ProductKind } from '@jane/shared/types';
import {
  PRICE_ID_TO_GRAMS,
  formatTime,
  formatUtcTime,
  sentenceCase,
} from '@jane/shared/util';

import { ORDERED_DAYS } from '../scheduleSection/scheduleSection';

type WeightToPriceIdType = { [k: string]: ProductWeight };

const GRAMS_TO_PRICE_ID: WeightToPriceIdType = {
  '0.5g': 'half_gram',
  '1g': 'gram',
  '2g': 'two_gram',
  '3.5g': 'eighth_ounce',
  '7g': 'quarter_ounce',
  '14g': 'half_ounce',
  '28g': 'ounce',
};

export const DEFAULT_START_TIME = '12:00 AM';
export const DEFAULT_END_TIME = '11:59 PM';

// Transforms API response kinds into form options
// [{ kind: "grow" }] -> [{ label: "grow", value: "grow" }]
// [{ kind: "edible", root_subtype: "Chocolate" }] -> [{ label: "Chocolate", value: "edible:Chocolate" }]
export const transformKinds = (kinds: KindCondition[]): CategoryOption[] => {
  return kinds.map((item) => {
    if (!item.root_subtype) {
      return {
        label: item.kind,
        value: item.kind,
      };
    }

    return {
      label: item.root_subtype,
      value: `${item.kind}:${item.root_subtype}`,
    };
  });
};

// Transforms form values back into API format for saving
// [{ label: "grow", value: "grow" }] -> [{ kind: "grow" }]
// [{ label: "Chocolate", value: "edible:Chocolate" }] -> [{ kind: "edible", root_subtype: "Chocolate" }]
export const untransformKinds = (
  categories: CategoryOption[]
): KindCondition[] => {
  const kinds = categories.map((category) => {
    const [kind, root_subtype] = category.value.split(':');
    return root_subtype
      ? { kind: kind as ProductKind, root_subtype }
      : { kind: category.value as ProductKind };
  });

  return kinds;
};

// Converts price IDs to display format (e.g. "half_gram" -> "0.5g")
export const transformWeights = (priceIds: ProductWeight[]) =>
  priceIds.map((priceId) => `${PRICE_ID_TO_GRAMS[priceId]}g`);

// Converts display format back to price IDs (e.g. "0.5g" -> "half_gram")
export const untransformWeights = (weights: string[]) =>
  weights.map((weight) => GRAMS_TO_PRICE_ID[weight]);

// Converts API datetime (2025-04-20T23:40:26+00:00) into separate date and time values for form inputs
export const transformDate = (dateString?: string | null) => {
  if (!dateString) return { date: undefined, time: undefined };

  return {
    date: formatUtcTime(
      dateString,
      CLIENT_SCHEDULE_DATE_FORMAT,
      API_SCHEDULE_DATETIME_FORMAT
    ),
    time: formatUtcTime(
      dateString,
      CLIENT_SCHEDULE_TIME_FORMAT,
      API_SCHEDULE_DATETIME_FORMAT
    ),
  };
};

// Combines date and time form values into API format (2025-04-20 23:40:00.000)
export const untransformDate = (date?: string, time?: string, end = false) => {
  if (!date) return undefined;

  const dateTime = `${date} ${
    time ? time : end ? DEFAULT_END_TIME : DEFAULT_START_TIME
  }`;
  const formattedUtcTime = formatUtcTime(
    dateTime,
    API_SCHEDULE_DATETIME_FORMAT,
    CLIENT_SCHEDULE_DATETIME_FORMAT
  );

  return formattedUtcTime;
};

// This extracts a specific rule from the excludes array
export const getExclusionRule = (
  rules: BrandSpecialRules | undefined,
  property: string
): any[] => {
  const matchingRule = rules?.excludes?.find((rule) => property in rule);
  return matchingRule?.[property as keyof typeof matchingRule] ?? [];
};

// This checks if a product based inclusion rule exists,
// which determines if we check the "specific" radio to expand the rules form.
export const hasProductInclusionRules = (
  rules?: BrandSpecialRules['includes']
) =>
  !!rules?.[0].product_ids?.length ||
  !!rules?.[0].kinds?.length ||
  !!rules?.[0].weights?.length;

// This checks if a product based exclusion rule exists,
// which determines if we check the "specific" radio to expand the rules form.
export const hasProductExclusionRules = (rules?: BrandSpecialRules) =>
  !!getExclusionRule(rules, 'product_ids').length ||
  !!getExclusionRule(rules, 'kinds').length ||
  !!getExclusionRule(rules, 'weights').length;

// If "include all products" or "exclude no products" is selected;
// We skip adding those inclusions/exclusions to the rules
export const formatInclusionProductRules = (
  rules: BrandSpecialRules['includes'] = [],
  removeRules: boolean
) => {
  if (!removeRules) return rules;

  const { kinds, product_ids, weights, ...rest } = rules[0] ?? {};
  return [rest];
};

interface Exclusions {
  excludeCustomers: boolean;
  excludeStates: boolean;
  excludeStores: boolean;
  productExclusionType: 'none' | string;
}

// If a user fills out some exclusions, but then unchecks "Exclude x",
// this removes that exclusion from the payload.
export const formatExclusionRules = (
  rules: BrandSpecialRules['excludes'] = [],
  exclusions: Exclusions
) => {
  const {
    excludeCustomers,
    excludeStates,
    excludeStores,
    productExclusionType,
  } = exclusions;

  return rules.filter((rule) => {
    const key = Object.keys(rule)[0];

    // Remove product-related rules if productExclusionType is 'none'
    if (
      productExclusionType === 'none' &&
      ['kinds', 'product_ids', 'weights'].includes(key)
    ) {
      return false;
    }

    if (!excludeCustomers && key === 'user_segment_ids') {
      return false;
    }

    if (!excludeStates && key === 'states') {
      return false;
    }

    if (!excludeStores && key === 'store_ids') {
      return false;
    }

    return true;
  });
};

// Transforms the API categories response array into nested structure with subItems
// [{category: "edible"}, {category: "edible", subcategory: "Chocolate"}] ->
// [{label: "Edible", value: "edible", subItems: [{label: "Chocolate", value: "edible:Chocolate"}]}]
export const transformCategories = (
  items: BrandCategory[]
): CategoryOption[] => {
  const grouped = items.reduce((acc, item) => {
    if (!acc[item.category]) {
      acc[item.category] = {
        label: sentenceCase(item.category),
        value: item.category,
        subItems: [],
      };
    }

    if (item.subcategory) {
      acc[item.category].subItems?.push({
        label: sentenceCase(item.subcategory),
        value: `${item.category}:${item.subcategory}`,
      });
    }

    return acc;
  }, {} as Record<string, CategoryOption>);

  return Object.values(grouped);
};

// Accepts an id and scrolls to the element with that id
export const handleScroll = (scrollId: string) =>
  document.getElementById(scrollId)?.scrollIntoView({ behavior: 'smooth' });

// Transforms the form's DailySchedule into the BrandSpecialSchedule
// If no schedule is provided, it returns a schedule with all days enabled
// If day is included in schedule and has the default start/end times, times are set to null
// If day is included in schedule and has custom times, times are set to the custom values
// If day is not included in schedule, day is disabled and times are set to null
export const untransformSchedule = (
  schedule: DailySchedule | Record<string, never> = {}
): BrandSpecialSchedule => {
  return ORDERED_DAYS.reduce<any>((acc, day) => {
    const emptySchedule = Object.keys(schedule).length === 0;
    const dayWithSchedule = schedule[day];
    const scheduleDay = day.toLowerCase();
    const enabledDayKey = `enabled_${scheduleDay}` as keyof typeof schedule;
    const startTimeKey = `start_time_${scheduleDay}` as keyof typeof schedule;
    const endTimeKey = `end_time_${scheduleDay}` as keyof typeof schedule;

    if (emptySchedule) {
      acc[`enabled_${scheduleDay}`] = true;
      acc[`start_time_${scheduleDay}`] = null;
      acc[`end_time_${scheduleDay}`] = null;
    }

    if (!emptySchedule && dayWithSchedule) {
      if (
        dayWithSchedule.startTime === DEFAULT_START_TIME &&
        dayWithSchedule.endTime === DEFAULT_END_TIME
      ) {
        acc[enabledDayKey] = true;
        acc[startTimeKey] = null;
        acc[endTimeKey] = null;
      } else {
        acc[enabledDayKey] = true;
        acc[startTimeKey] = dayWithSchedule.startTime;
        acc[endTimeKey] = dayWithSchedule.endTime;
      }
    }

    if (!emptySchedule && !dayWithSchedule) {
      acc[enabledDayKey] = false;
      acc[startTimeKey] = null;
      acc[endTimeKey] = null;
    }

    return acc;
  }, {});
};

// Returns object of daily schedules with start and end times
export const getDailySchedule = (
  schedule: BrandSpecialSchedule | Record<string, never> = {}
): DailySchedule => {
  return Object.entries(schedule).reduce<any>((acc, [key, value]) => {
    const day = key.split('_').pop() as string;
    const startTimeKey = `start_time_${day}` as keyof typeof schedule;
    const endTimeKey = `end_time_${day}` as keyof typeof schedule;
    const dailyStartTime =
      typeof schedule[startTimeKey] === 'string'
        ? formatTime(
            schedule[startTimeKey] as string,
            CLIENT_SCHEDULE_TIME_FORMAT,
            API_SCHEDULE_TIME_FORMAT
          )
        : DEFAULT_START_TIME;
    const dailyEndTime =
      typeof schedule[endTimeKey] === 'string'
        ? formatTime(
            schedule[endTimeKey] as string,
            CLIENT_SCHEDULE_TIME_FORMAT,
            API_SCHEDULE_TIME_FORMAT
          )
        : DEFAULT_END_TIME;
    const dayIsEnabled = schedule[`enabled_${day}` as keyof typeof schedule];

    // Use existing time or set to default for the component which uses these days to check if day is enabled
    if (dayIsEnabled) {
      acc[sentenceCase(day)] = {
        endTime: dailyEndTime,
        startTime: dailyStartTime,
      };
    }

    return acc;
  }, {});
};

// Compares daily start and end times
export const hasCustomTimes = (
  dayEnd: string | undefined,
  dayStart: string | undefined
) => {
  if (!dayEnd || !dayStart) return false;

  return dayEnd !== DEFAULT_END_TIME || dayStart !== DEFAULT_START_TIME;
};

// Returns object of scheduled days, returning true if the day has a custom start/end time
export const getScheduledDays = (
  schedule: BrandSpecialSchedule | Record<string, never> = {}
): ManualScheduleForDays => {
  return ORDERED_DAYS.reduce((acc, day) => {
    const startTimeKey =
      `start_time_${day.toLowerCase()}` as keyof typeof schedule;
    const endTimeKey = `end_time_${day.toLowerCase()}` as keyof typeof schedule;

    const dailyStartTime =
      typeof schedule[startTimeKey] === 'string'
        ? formatTime(
            schedule[startTimeKey] as string,
            CLIENT_SCHEDULE_TIME_FORMAT,
            API_SCHEDULE_TIME_FORMAT
          )
        : undefined;
    const dailyEndTime =
      typeof schedule[endTimeKey] === 'string'
        ? formatTime(
            schedule[endTimeKey] as string,
            CLIENT_SCHEDULE_TIME_FORMAT,
            API_SCHEDULE_TIME_FORMAT
          )
        : undefined;

    return {
      ...acc,
      [WEEKDAY_TO_WEEKDAY_MIN[day]]: hasCustomTimes(
        dailyEndTime,
        dailyStartTime
      ),
    };
  }, {});
};

export const isScheduleCustom = (
  dailySchedule: DailySchedule,
  scheduledDays: ManualScheduleForDays
): boolean => {
  return (
    Object.values(scheduledDays).some((day) => day === true) ||
    Object.keys(dailySchedule).length < 7
  );
};

// Transforms API schedule into
// 1. dailySchedule: Object with day of week as key and start/end times as values
// 2. scheduledDays: Object with day of week as key and boolean. Truthy value signals the day has a custom schedule
// 3. hasCustomSchedule: Boolean indicating if any day has a custom schedule, used to determine if default schedule option should be 'custom'
export const transformSchedule = (
  schedule: BrandSpecialSchedule | Record<string, never> = {}
): {
  dailySchedule: DailySchedule;
  hasCustomSchedule: boolean;
  scheduledDays: ManualScheduleForDays;
} => {
  // determines which days show as enabled in custom schedule
  const dailySchedule = getDailySchedule(schedule);

  // determines which days have custom schedules
  const scheduledDays = getScheduledDays(schedule);

  // determines if any day is custom, or if not all days are enabled
  const hasCustomSchedule = isScheduleCustom(dailySchedule, scheduledDays);

  return { dailySchedule, scheduledDays, hasCustomSchedule };
};
