import { Campaigns } from '@jane/ad-manager/components';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Flex, Loading } from '@jane/shared/reefer';

export const CampaignsPage = () => {
  const {
    isLoading: isSelectedBrandLoading,
    isSuccess: isSelectedBrandSuccess,
  } = useSelectedBrandContext();

  return (
    <Flex flexDirection="column">
      {isSelectedBrandLoading && <Loading />}
      {isSelectedBrandSuccess && <Campaigns />}
    </Flex>
  );
};
