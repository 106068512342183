import { WEIGHTS } from '@jane/gold-manager/util';
import { Button, DismissIcon, Flex, Typography } from '@jane/shared/reefer';
import { useWatch } from '@jane/shared/reefer-hook-form';

import type { MultiSelectOption } from '../../multiSelectFilter';
import { MultiSelectFilter } from '../../multiSelectFilter';
import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import {
  getExclusionRule,
  transformWeights,
  untransformWeights,
} from '../utils/specialForm.util';
import type { BrandSpecialRulesVariant } from './productsSection';

export const WeightSelection = ({
  variant,
}: {
  variant: BrandSpecialRulesVariant;
}) => {
  const weights = WEIGHTS;
  const brandId = useWatch({ name: 'productBrandId' });
  const { rules, setRulesValue } = useSpecialForm();
  const isExclusion = variant === 'exclusion';
  const formKey = isExclusion ? 'excludes.weights' : 'includes.weights';

  const priceIds = isExclusion
    ? getExclusionRule(rules, 'weights')
    : rules.includes?.[0].weights ?? [];

  const selectedWeights = transformWeights(priceIds);

  const setSelectedWeights = (weights: (string | number)[]) => {
    return setRulesValue(formKey, untransformWeights(weights as string[]));
  };

  const weightOptions: MultiSelectOption[] = weights.map((weight) => {
    return { label: weight, value: weight };
  });

  return (
    <Flex flexDirection="column" gap={16}>
      <Flex flexDirection="row" width="100%" alignItems="center" mt={16}>
        <Typography>Weight</Typography>
        <MultiSelectFilter
          currentValue={selectedWeights}
          disabled={!brandId}
          handleChange={setSelectedWeights}
          label={selectedWeights.length > 0 ? 'Weight' : 'All weights'}
          options={weightOptions}
          alignment={{ horizontal: 'right', vertical: 'bottom' }}
          ml="auto"
        />
      </Flex>
      {selectedWeights && selectedWeights.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap">
          {selectedWeights.map((weight) => (
            <Button
              key={weight}
              label={
                <Typography width="48px" color="inherit" variant="body-bold">
                  {weight}
                </Typography>
              }
              aria-label={`Remove ${weight}`}
              startIcon={<DismissIcon size="sm" />}
              variant={isExclusion ? 'destructive-secondary' : 'secondary'}
              onClick={() =>
                setSelectedWeights(
                  selectedWeights.filter(
                    (selectedWeight) => selectedWeight !== weight
                  )
                )
              }
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
