import type { CSSProperties } from 'react';

import type { TypographyProps } from '../components/typography/typography';
import type { Spacing } from '../utils/spacing.types';
import type { BorderRadiusKey } from './theme.types';
import type { ColorKey } from './themeColors.types';

/** components.Icon Types */
export type IconSize = 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl';

export type IconSizeConfig = {
  [key in IconSize]: string;
};

export interface IconConfig {
  sizes: IconSizeConfig;
}

/** components.Typography Types */

export type TypographyComponent =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'div'
  | 'span'
  | 'label'
  | 'legend';

export const BASE_TYPOGRAPHY_VARIANTS = [
  'title',
  'header',
  'body',
  'mini',
  'caps',
] as const;

const BOLD_TYPOGRAPHY_VARIANTS = [
  'title-bold',
  'header-bold',
  'body-bold',
  'mini-bold',
  'caps-bold',
] as const;

export const TYPOGRAPHY_VARIANTS = [
  ...BASE_TYPOGRAPHY_VARIANTS,
  ...BOLD_TYPOGRAPHY_VARIANTS,
] as const;

export interface Font {
  fontFamily?: CSSProperties['fontFamily'];
  url?: string;
}
export interface TypographyFonts {
  branded: Font;
  default: Font;
}

type InnerTypographyVariantConfigOptions = Pick<
  CSSProperties,
  'fontSize' | 'fontWeight' | 'lineHeight' | 'textTransform' | 'letterSpacing'
>;

type SharedTypographyVariantConfig =
  Partial<InnerTypographyVariantConfigOptions>;

type TypographyVariantConfigOptions = Pick<TypographyProps, 'branded'> &
  Required<SharedTypographyVariantConfig> & {
    desktopStyles?: SharedTypographyVariantConfig;
    mobileStyles?: SharedTypographyVariantConfig;
  };

export type TypographyVariantConfig<T extends string = TypographyVariant> = {
  [key in T]: TypographyVariantConfigOptions;
};

export type BaseTypographyVariantConfig =
  TypographyVariantConfig<BaseTypographyVariant>;

export type BoldTypographyVariantConfig =
  TypographyVariantConfig<BoldTypographyVariant>;

export type BoldTypographyVariant = typeof BOLD_TYPOGRAPHY_VARIANTS[number];
export type BaseTypographyVariant = typeof BASE_TYPOGRAPHY_VARIANTS[number];

export type TypographyVariant = typeof TYPOGRAPHY_VARIANTS[number];

export type TypographyVariantComponentMap<
  T extends string = TypographyVariant
> = {
  [variant in T]: TypographyComponent;
};

export type TypographyConfig<
  Variants extends string = TypographyVariant,
  Map extends string = TypographyVariant
> = {
  componentMapping: TypographyVariantComponentMap<Map>;
  fonts: TypographyFonts;
  variants: TypographyVariantConfig<Variants>;
};

/** components.Button Types */

export const BUTTON_VARIANTS = [
  'primary',
  'primary-inverse',
  'secondary',
  'tertiary',
  'tertiary-selected',
  'destructive',
  'destructive-secondary',
  'minimal',
  'minimal-inverse',
] as const;

export const BUTTON_SIZES = ['small', 'default', 'large', 'jumbo'] as const;

export type ButtonSize = typeof BUTTON_SIZES[number];

export type ButtonVariant = typeof BUTTON_VARIANTS[number];

interface ButtonSharedConfig {
  borderRadius?: BorderRadiusKey;
  borderWidth?: string;
  typographyVariant?: TypographyVariant;
}

export interface ButtonSizeConfig {
  borderRadius?: BorderRadiusKey;
  height?: number | string;
  paddingX?: Spacing;
  typographyVariant?: TypographyVariant;
}

export interface ButtonVariantConfig {
  backgroundColor?: ColorKey;
  borderColor?: ColorKey;
  borderWidth?: string;
  typographyColor?: ColorKey;
  typographyVariant?: TypographyVariant;
}

/** components types */
export interface ComponentsConfig<
  Variant extends string = string,
  ComponentMap extends string = string
> {
  Button: {
    icons: {
      sizes: {
        [key in ButtonSize]: ButtonSizeConfig;
      };
    };
    shared: ButtonSharedConfig;
    sizes: {
      [key in ButtonSize]: ButtonSizeConfig;
    };
    variants: {
      [key in ButtonVariant]: ButtonVariantConfig;
    };
  };

  Card: {
    styles: {
      borderRadius: BorderRadiusKey;
    };
  };

  Icon: IconConfig;

  Typography: TypographyConfig<Variant, ComponentMap>;
}
