import { useCallback } from 'react';
import type { NavigateOptions } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

import { brandsPaths } from '@jane/brands/util';

interface BrandsNavigateOpts extends NavigateOptions {
  /** By default search params are maintained between routes, setting this
   * option to false will clear all search params when navigating to the new
   * path */
  preserveSearchParams?: boolean;
}

export const useBrandsNavigate = () => {
  const navigate = useNavigate();
  const location = useLocation();

  /**
   * Helper function for navigating to the product list page. The opts are
   * passed to React Router's `navigate` function. Defaults to preserving the
   * URL search params but they can be cleared by passing the
   * `preserveSearchParams: false` option.
   */
  const productList = useCallback(
    ({ preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}) => {
      const path = preserveSearchParams
        ? brandsPaths.products() + location.search
        : brandsPaths.products();
      navigate(path, opts);
    },
    [navigate, location.search]
  );

  /**
   * Helper function for navigating a specific product's edit page. The opts are
   * passed to React Router's `navigate` function. Defaults to preserving the
   * URL search params but they can be cleared by passing the
   * `preserveSearchParams: false` option.
   */
  const editProduct = useCallback(
    (
      uuid: string,
      { preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}
    ) => {
      const path = preserveSearchParams
        ? brandsPaths.editProduct(uuid) + location.search
        : brandsPaths.editProduct(uuid);

      navigate(path, opts);
    },
    [navigate, location.search]
  );

  /**
   * Helper function for navigating a specific product localization's edit page.
   * The opts are passed to React Routers `navigate` function. Defaults to
   * preserving the URL search params but they can be cleared by passing the
   * `preserveSearchParams: false` options.
   */
  const editLocalization = useCallback(
    (
      productId: string,
      localizationId: string,
      { preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}
    ) => {
      const path = preserveSearchParams
        ? brandsPaths.editLocalization(productId, localizationId) +
          location.search
        : brandsPaths.editLocalization(productId, localizationId);
      navigate(path, opts);
    },
    [navigate, location.search]
  );

  /**
   * Helper function for navigating to the create localization page.
   * The opts are passed to React Routers `navigate` function. Defaults to
   * preserving the URL search params but they can be cleared by passing the
   * `preserveSearchParams: false` options.
   */
  const createLocalization = useCallback(
    (
      uuid: string,
      { preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}
    ) => {
      const path = preserveSearchParams
        ? brandsPaths.createLocalization(uuid) + location.search
        : brandsPaths.createLocalization(uuid);

      navigate(path, opts);
    },
    [navigate, location.search]
  );

  /**
   * Helper function for navigating to the product notice page.
   * The opts are passed to React Routers `navigate` function. Defaults to
   * preserving the URL search params but they can be cleared by passing the
   * `preserveSearchParams: false` options.
   */
  const productNotice = useCallback(
    (
      uuid: string,
      { preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}
    ) => {
      const path = preserveSearchParams
        ? brandsPaths.productNotice(uuid) + location.search
        : brandsPaths.productNotice(uuid);

      navigate(path, opts);
    },
    [navigate, location.search]
  );

  /**
   * Helper function for navigating to the specials list page. The opts are
   * passed to React Router's `navigate` function. Defaults to preserving the
   * URL search params but they can be cleared by passing the
   * `preserveSearchParams: false` option.
   */
  const specialsList = useCallback(
    ({ preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}) => {
      const path =
        preserveSearchParams && location.search
          ? brandsPaths.specials() + location.search
          : brandsPaths.specials();
      navigate(path, opts);
    },
    [navigate, location.search]
  );

  const duplicateSpecial = useCallback(
    (
      id: string,
      { preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}
    ) => {
      const path =
        preserveSearchParams && location.search
          ? brandsPaths.duplicateSpecial(id) + location.search
          : brandsPaths.duplicateSpecial(id);
      navigate(path, opts);
    },
    [navigate, location.search]
  );

  const editSpecial = useCallback(
    (
      id: string,
      { preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}
    ) => {
      const path =
        preserveSearchParams && location.search
          ? brandsPaths.editSpecial(id) + location.search
          : brandsPaths.editSpecial(id);
      navigate(path, opts);
    },
    [navigate, location.search]
  );

  const newSpecial = useCallback(
    ({ preserveSearchParams = true, ...opts }: BrandsNavigateOpts = {}) => {
      const path =
        preserveSearchParams && location.search
          ? brandsPaths.newSpecial() + location.search
          : brandsPaths.newSpecial();
      navigate(path, opts);
    },
    [navigate, location.search]
  );

  return {
    productList,
    editProduct,
    editLocalization,
    createLocalization,
    productNotice,
    specialsList,
    duplicateSpecial,
    editSpecial,
    newSpecial,
  };
};
