import { useCallback } from 'react';

import { SrOnly } from '@jane/brands/components';
import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { LINEAGE_OPTIONS } from '@jane/catalog-cms/util';
import { Box, Flex, Grid, Link } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

type ProductKeys = 'name' | 'lineage' | 'categoryLabel' | 'description';

export interface LocalizationInputsProps {
  product: Partial<Pick<EditFormProduct, ProductKeys>>;
}

export const LocalizationInputs = ({ product }: LocalizationInputsProps) => {
  const { setValue, watch } = useFormContext();

  const RevertToDefault = useCallback(
    ({ fieldKey, label }: { fieldKey: ProductKeys; label: string }) => {
      const productValue = product[fieldKey];
      const formValue = watch(fieldKey);

      const shouldShow =
        (productValue === null && formValue) ||
        (productValue && formValue !== productValue);

      if (shouldShow) {
        return (
          <Flex mt={12} justifyContent="end">
            <Link
              onClick={() => {
                setValue(fieldKey, product[fieldKey] ?? '', {
                  shouldValidate: true,
                  shouldDirty: true,
                  shouldTouch: true,
                });
              }}
            >
              Revert<SrOnly as="span">{` ${label} field`}</SrOnly>
            </Link>
          </Flex>
        );
      }

      return null;
    },
    [product, setValue, watch]
  );

  const trimOnBlur = (fieldName: string) => {
    const value = watch(fieldName) as string;
    setValue(fieldName, value.trim(), {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
  };

  return (
    <Box>
      <Grid.Container>
        <Grid.Item xs={12}>
          <Form.TextField
            name="name"
            label="Name"
            maxLength={255}
            onBlur={() => trimOnBlur('name')}
          />
          <RevertToDefault fieldKey="name" label="name" />
        </Grid.Item>
        <Grid.Item xs={12} md={6}>
          <Form.SelectField
            name="lineage"
            label="Lineage"
            options={LINEAGE_OPTIONS}
          />
          <RevertToDefault fieldKey="lineage" label="lineage" />
        </Grid.Item>
        <Grid.Item xs={12} md={6}>
          <Form.TextField
            name="categoryLabel"
            label="Brand Category"
            maxLength={255}
            onBlur={() => trimOnBlur('categoryLabel')}
          />
          <RevertToDefault fieldKey="categoryLabel" label="brand category" />
        </Grid.Item>
        <Grid.Item xs={12}>
          <Form.TextAreaField
            name="description"
            label="Description"
            resize={true}
            rows={8}
            onBlur={() => trimOnBlur('description')}
          />
          <RevertToDefault fieldKey="description" label="description" />
        </Grid.Item>
      </Grid.Container>
    </Box>
  );
};
