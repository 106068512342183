import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useContext, useEffect, useMemo } from 'react';

import '../../../../styles/base.css';
import { BASE_CONFIG } from '../../../theme/baseConfig';
import { generateThemeCSS } from '../../../theme/cssVariables/generateThemeCSS';
import { generateThemeCSSObject } from '../../../theme/cssVariables/generateThemeCSSObject';
import { generateTheme } from '../../../theme/generateTheme';
import { loadCustomFonts } from '../../../theme/loadCustomFonts';
import type {
  CustomThemeConfig,
  ReeferTheme,
} from '../../../theme/theme.types';
import type { TypographyConfig } from '../../../theme/themeComponents.types';

export type ReeferThemeContextProps = {
  theme: ReeferTheme;
};

const ReeferThemeContext = createContext<ReeferThemeContextProps>({
  theme: generateThemeCSSObject(generateTheme()),
});

/**
 * This returns the applicable CSS Variable for a given
 * theme property. This is typically for use inside Reefer
 * component implementations.
 */
export function useReeferTheme(): ReeferTheme {
  const { theme } = useContext(ReeferThemeContext);
  return theme;
}

export type RenderProviderProp = (
  theme: ReeferTheme,
  children: ReactNode
) => any;

export interface ReeferThemeProviderProps extends PropsWithChildren {
  /** TODO(sarah): eliminate the need for this prop.
   * This prop was added due to an issue with test runtimes caused by certain sections of ReeferThemeProvider (see below).
   * Ths prop is passed in ReeferThemeProvider's own tests, allowing them to pass. This prop should not be used elsewhere.
   * */
  bypassTestDisabledSection?: boolean;

  /** Function for loading fonts or disable font loading */
  loadFonts?:
    | false
    | ((customTypography: TypographyConfig, element?: HTMLElement) => void);

  /** The `ReeferThemeProvider` has two modes. `mode` only affects whether or not the theme CSS is pre-injected into the document head. */
  mode?: 'client' | 'server';

  /** Render with another provider eg. `emotion` `ThemeProvider` */
  renderProvider?: RenderProviderProp;

  /** Custom theme configuration */
  theme?: CustomThemeConfig;

  /**
   * Id to scope the theme to. When no `themeId` is provided, the theme will be scoped to `:root`. When `themeId` is provided, it will be scoped to `#themeId`.
   * Add an element with `id` `themeId` to your code when `themeId` is set. The theme will be scoped to that element.
   */
  themeId?: string;
}
export function ReeferThemeProvider({
  bypassTestDisabledSection = false,
  children,
  loadFonts = loadCustomFonts,
  mode = 'client',
  renderProvider,
  theme: customTheme,
  themeId,
}: ReeferThemeProviderProps) {
  const baseTheme = generateTheme(customTheme);
  const theme = generateThemeCSSObject(baseTheme);
  const contextProps = useMemo(
    () => ({
      theme,
    }),
    [theme]
  );

  useEffect(() => {
    // TODO(sarah): find a better method to disable this section in test environments
    // eg. perhaps figure out a way to mock ReeferThemerovider globally in tests.
    // These lines proved extremely costly in test contexts, causing test runtimes to slow down > 2x.
    // This is because the ReeferThemeProvider is wraps components in most of our component tests,
    // which means that it is mounted 1000s of times across our test suites.
    if (
      mode === 'client' &&
      (process.env['NODE_ENV'] !== 'test' || bypassTestDisabledSection)
    ) {
      const STYLE_TAG_ID = themeId
        ? `${themeId}-reefer-theme-css`
        : 'reefer-theme-css';

      let styleTag = document.getElementById(STYLE_TAG_ID);

      // Don't add the theme CSS more than once
      if (!styleTag) {
        styleTag = document.createElement('style');
        document.head.appendChild(styleTag);
        styleTag.id = STYLE_TAG_ID;
      }

      const themeCSS = generateThemeCSS(baseTheme, themeId);
      styleTag.innerHTML = themeCSS;
    }
  }, [baseTheme, bypassTestDisabledSection, mode, theme, themeId]);

  useEffect(() => {
    // TODO(sarah): find a better method to disable this section in test environments
    // eg. perhaps figure out a way to mock ReeferThemerovider globally in tests.
    // These lines proved costly in test contexts, causing test runtimes to slow down > 2x
    // (though not as much as the insertion of the style tag above).
    // This is because the ReeferThemeProvider is wraps components in most of our component tests,
    // which means that it is mounted 1000s of times across our test suites.
    if (
      theme &&
      loadFonts &&
      (process.env.NODE_ENV !== 'test' || bypassTestDisabledSection)
    ) {
      const loadThemeFonts = async () => {
        if (theme.components?.Typography) {
          const themedElement = themeId && document.getElementById(themeId);
          try {
            await loadFonts(
              baseTheme.components.Typography,
              themedElement || undefined
            );
          } catch (e: unknown) {
            // If we get an error while trying to load fonts, fall back
            // to default Reefer typography
            // eslint-disable-next-line no-console
            console.log((e as Error).message);
            baseTheme.components.Typography = BASE_CONFIG.components.Typography;
          }
        } else {
          baseTheme.components.Typography = BASE_CONFIG.components.Typography;
        }
      };
      loadThemeFonts();
    }
  }, [baseTheme, bypassTestDisabledSection, loadFonts, theme, themeId]);

  const themeProvider = (
    <ReeferThemeContext.Provider value={contextProps}>
      {children}
    </ReeferThemeContext.Provider>
  );

  if (renderProvider) {
    // Send the `baseTheme` to the provider so it uses actual values
    // - such as hex colors - instead of CSS variables
    return renderProvider(baseTheme, themeProvider);
  }

  return themeProvider;
}
