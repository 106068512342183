import styled from '@emotion/styled';
import compact from 'lodash/compact';
import uniq from 'lodash/uniq';
import { useCallback, useEffect, useMemo, useState } from 'react';

import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  ChevronDownIcon,
  Flex,
  Link,
  Popover,
  RadioFieldGroup,
  SearchField,
  TextAreaField,
  TextField,
} from '@jane/shared/reefer';

import type { Store, StoreSearchMode } from './StoreSelectModal';

const StoreSearch = ({
  searchFilter,
  setSearchFilter,
  searchFilterPlaceholder,
}: {
  searchFilter: string;
  searchFilterPlaceholder?: string;
  setSearchFilter: (searchTerm: string) => void;
}) => {
  useEffect(() => {
    if (!searchFilter) {
      setSearchFilter('');
    }
  }, [searchFilter]);

  return (
    <Flex pr={12}>
      <SearchField
        label="Store search"
        name="search_input"
        autocomplete="off"
        placeholder={searchFilterPlaceholder}
        defaultValue={searchFilter}
        autoFocus
        onChange={(val) => setSearchFilter(val.toLowerCase())}
        isDebounced={true}
        debounceDelay={300}
        width={300}
      />
    </Flex>
  );
};

const StoreTypeFilter = ({
  onClose,
  setTypeFilter,
  typeFilter,
}: {
  onClose?: () => void;
  setTypeFilter: (type: string) => void;
  typeFilter: string;
}) => {
  const changeTypeFilter = useCallback(
    (type: string, closePopover: () => void) => {
      setTypeFilter(type);
      closePopover();
    },
    [typeFilter]
  );

  return (
    <Popover
      onClose={onClose}
      target={
        <Flex>
          <Button
            full
            variant="tertiary"
            label={typeFilter || 'All types'}
            endIcon={<ChevronDownIcon size="sm" />}
          ></Button>
        </Flex>
      }
      mx={16}
      label="All types"
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label="Store type filter" padding={false}>
          <RadioFieldGroup
            name="type-list"
            defaultChecked={typeFilter || 'All types'}
            onChange={(type: string) => changeTypeFilter(type, closePopover)}
            options={[
              {
                id: 'All types',
                label: 'All types',
                value: 'All types',
                wrapper: (children: React.ReactNode) => (
                  <Flex
                    mx={16}
                    mt={16}
                    ariaLabel={`all-label`}
                    onClick={() => changeTypeFilter('', closePopover)}
                  >
                    {children}
                  </Flex>
                ),
              },
              {
                id: 'Recreational',
                label: 'Recreational',
                value: 'Recreational',
                wrapper: (children: React.ReactNode) => (
                  <Flex
                    mx={16}
                    mt={16}
                    ariaLabel={`rec-label`}
                    onClick={() =>
                      changeTypeFilter('Recreational', closePopover)
                    }
                  >
                    {children}
                  </Flex>
                ),
              },
              {
                id: 'Medical',
                label: 'Medical',
                value: 'Medical',
                wrapper: (children: React.ReactNode) => (
                  <Flex
                    mx={16}
                    mt={16}
                    mb={16}
                    ariaLabel={`med-label`}
                    onClick={() => changeTypeFilter('Medical', closePopover)}
                  >
                    {children}
                  </Flex>
                ),
              },
            ]}
          />
        </Popover.Content>
      )}
    </Popover>
  );
};

const StoreStateFilter = ({
  filteredStores,
  isFetchingStores,
  setStateFilter,
  stateFilter,
  setStateSearch,
  stateSearch,
}: {
  filteredStores: Store[];
  isFetchingStores: boolean;
  setStateFilter: (state: string) => void;
  setStateSearch: (state: string) => void;
  stateFilter: string;
  stateSearch: string;
}) => {
  const changeStateFilter = useCallback(
    (state: string, closePopover: () => void) => {
      setStateFilter(state);
      closePopover();
    },
    [stateFilter]
  );

  const initialStores = useMemo(() => {
    if (!isFetchingStores) {
      return filteredStores;
    } else {
      return [];
    }
  }, [isFetchingStores]);

  const states: string[] = useMemo(() => {
    const states = initialStores
      .map((store: Store) => store.state)
      .filter((state) => state && state.toLowerCase().includes(stateSearch));
    return compact(uniq(states)).sort();
  }, [initialStores, stateSearch]);

  return (
    <Popover
      target={
        <Flex>
          <Button
            full
            variant="tertiary"
            label={stateFilter || 'All states'}
            endIcon={<ChevronDownIcon size="sm" />}
          ></Button>
        </Flex>
      }
      ml={16}
      mr={24}
      label="All states"
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content label="Store state filter" padding={false}>
          <TextField
            autoFocus={true}
            labelHidden={true}
            placeholder="Search"
            label="Search"
            name="state-search"
            type="search"
            onChange={setStateSearch}
            mx={16}
            mt={16}
          />
          <RadioFieldGroup
            name="state-list"
            defaultChecked={stateFilter}
            onChange={(state: string) => changeStateFilter(state, closePopover)}
            options={states.map((state, i) => ({
              id: state,
              label: state,
              value: state,
              wrapper: (children: React.ReactNode) => (
                <Flex
                  mx={16}
                  mt={16}
                  mb={i === states.length - 1 ? 16 : undefined}
                  ariaLabel={`${state}-label`}
                  onClick={() => changeStateFilter(state || '', closePopover)}
                >
                  {children}
                </Flex>
              ),
            }))}
          />
        </Popover.Content>
      )}
    </Popover>
  );
};

const StoreIdsSearch = ({
  setSearchIds,
}: {
  setSearchIds: (ids: string) => void;
}) => {
  const [ids, setIds] = useState('');

  const handleSubmit = () => {
    setSearchIds(ids);
  };

  return (
    <Flex gap={16} alignItems="center">
      <TextAreaField
        label="Store IDs"
        name="search_ids_input"
        autocomplete="off"
        placeholder="Enter Store IDs"
        defaultValue={ids}
        autoFocus
        labelHidden
        onChange={(val) => setIds(val)}
        enterKeyHint="search"
        width={416}
      />
      <Button variant="secondary" label="Search" onClick={handleSubmit} />
    </Flex>
  );
};

const HeaderWrapper = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
}));

export const StoreSelectModalFilters = ({
  disablePadding,
  filteredStores,
  isFetchingStores,
  onIdsChange,
  searchFilter,
  searchFilterPlaceholder,
  setSearchFilter,
  setStateFilter,
  setTypeFilter,
  stateFilter,
  typeFilter,
  searchMode,
}: {
  disablePadding?: boolean;
  filteredStores: Store[];
  isFetchingStores: boolean;
  onIdsChange: (ids: string) => void;
  searchFilter: string;
  searchFilterPlaceholder?: string;
  searchMode: StoreSearchMode;
  setSearchFilter: (searchTerm: string) => void;
  setStateFilter: (state: string) => void;
  setTypeFilter: (type: string) => void;
  stateFilter: string;
  typeFilter: string;
}) => {
  const anyFilterSet = useMemo(() => {
    return !!(searchFilter || typeFilter || stateFilter);
  }, [searchFilter, typeFilter, stateFilter]);

  const [stateSearch, setStateSearch] = useState('');

  const resetFilters = () => {
    setSearchFilter('');
    setTypeFilter('');
    setStateFilter('');
    setStateSearch('');
  };

  return (
    <HeaderWrapper>
      {searchMode === 'storeName' && (
        <Flex px={disablePadding ? 0 : 64} pt={disablePadding ? 0 : 40}>
          <StoreSearch
            searchFilter={searchFilter}
            searchFilterPlaceholder={searchFilterPlaceholder}
            setSearchFilter={setSearchFilter}
          />
          <StoreTypeFilter
            typeFilter={typeFilter}
            setTypeFilter={setTypeFilter}
          />
          <StoreStateFilter
            stateFilter={stateFilter}
            setStateFilter={setStateFilter}
            filteredStores={filteredStores}
            isFetchingStores={isFetchingStores}
            setStateSearch={setStateSearch}
            stateSearch={stateSearch}
          />
          {anyFilterSet ? (
            <Flex p={12} ml={8}>
              <Link onClick={resetFilters}>Clear filters</Link>
            </Flex>
          ) : null}
        </Flex>
      )}
      {searchMode === 'storeId' && (
        <StoreIdsSearch setSearchIds={onIdsChange} />
      )}
    </HeaderWrapper>
  );
};
