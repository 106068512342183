import { useCallback, useContext } from 'react';

import { ProductsPageParamsContext } from '@jane/catalog-cms/providers';

export const useSelectedSubdivision = () => {
  const context = useContext(ProductsPageParamsContext);

  if (context === null) {
    throw new Error(
      'useSelectedSubdivision must be a child of ProductsPageParamsProvider.'
    );
  }

  const {
    productsPageParams,
    updateProductsPageParams,
    clearProductsPageParams,
  } = context;

  const selectedSubdivision = productsPageParams.subdivision || '';

  const updateSelectedSubdivision = useCallback(
    (subdivision: string) => {
      if (subdivision) {
        updateProductsPageParams('subdivision', subdivision);
      } else {
        clearProductsPageParams(['subdivision']);
      }
    },
    [clearProductsPageParams, updateProductsPageParams]
  );

  return {
    selectedSubdivision,
    updateSelectedSubdivision,
  };
};
