import { useQuery } from '@tanstack/react-query';
import React from 'react';

import { fetchSegments } from '@jane/ad-manager/data-access';
import { Box, Button, Flex, TrashIcon, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import type { AdSubmissionFormState } from '../../formState/types';

export const CustomerSegmentsTargetForm = ({
  setCustomerSegmentsFormOpen,
}: {
  setCustomerSegmentsFormOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { data } = useQuery({ queryFn: fetchSegments, queryKey: ['segments'] });
  const { resetField, getValues } = useFormContext<AdSubmissionFormState>();
  const resetCustomerSegments = () => resetField('customerSegments');
  const selectedCustomerSegments = getValues('customerSegments');

  const segmentsAsSelectOptions =
    data?.segments.map((segment) => ({
      label: segment.description,
      value: segment.id,
    })) ?? [];

  return (
    <Box width="100%">
      <Box mb={24}>
        <Typography variant="body-bold">Customer segment to target</Typography>
      </Box>
      <Flex>
        <Flex basis="50%">
          <Typography mb={12} variant="body-bold">
            Customer behavior
          </Typography>
        </Flex>
      </Flex>
      <Box mb={16}>
        <Flex>
          <Flex pr={24} basis="50%">
            <Form.SelectField
              options={segmentsAsSelectOptions}
              label="Select customer segment"
              labelHidden
              placeholder="Select"
              name="customerSegments"
              width="100%"
              data-testid="customer-segments-selection"
            />
          </Flex>
          {selectedCustomerSegments && (
            <Flex basis="50%">
              <Button.Icon
                variant="minimal"
                icon={<TrashIcon></TrashIcon>}
                onClick={() => {
                  resetCustomerSegments();
                  setCustomerSegmentsFormOpen(false);
                }}
                data-testid="delete-segment"
              />
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
