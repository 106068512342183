import clsx from 'clsx';
import type { CSSProperties, ReactNode } from 'react';

import { Box } from '../../box/box';
import { Flex } from '../../flex/flex';
import hideStyles from '../../hide/hide.module.css';
import { Typography } from '../../typography/typography';
import type { FieldProps } from '../field.types';
import { HelperText } from '../helperText/helperText';
import styles from './fieldWrapper.module.css';

export interface RenderProps {
  'aria-describedby'?: string;
  'aria-labelledby': string;
}

export interface FieldWrapperProps extends Omit<FieldProps, 'autoFocus'> {
  /** Disables mobile text input styling */
  disableMobileInputStyling?: boolean;

  /** Customise helper text id */
  helperTextId?: string;

  /** If the input it is wrapping is a text input (for mobile styling) */
  isWrappingTextInput?: boolean;

  /** Where to position the field label, 't' for top, or 'r' for right. */
  labelPosition?: 't' | 'r';

  /** Render function */
  render: (renderProps: RenderProps) => ReactNode;

  /** Whether to reposition the helper text beside the label on mobile */
  repositionMobileHelperText?: boolean;
}

/**
 * This is a wrapper component for common functionality of fields.
 */
export const FieldWrapper = ({
  children,
  className,
  'data-testid': testId,
  disabled = false,
  disableMobileInputStyling = false,
  helperText,
  helperTextId: helperTextIdProp,
  id,
  isWrappingTextInput = false,
  label,
  labelHidden = false,
  labelPosition = 't',
  name,
  render,
  repositionMobileHelperText = false,
  style,
  typography,
  width = 'auto',
  ...marginProps
}: FieldWrapperProps) => {
  const labelId = `${name}-label`;
  let helperTextId;
  let describedBy;
  if (helperText || helperTextIdProp) {
    helperTextId = helperTextIdProp || name;
    describedBy = `${helperTextId}-helper-text`;
    if (repositionMobileHelperText) {
      describedBy = `${describedBy} ${helperTextId}-mobile-helper-text`;
    }
  }
  const labelVariant = labelPosition === 't' ? 'body-bold' : 'body';

  return (
    <Box
      className={clsx(className, styles.fieldWrapper, {
        [styles.fieldWrapper__disabled]: disabled,
        [styles.fieldWrapper__isWrappingText]:
          isWrappingTextInput && !disableMobileInputStyling,
      })}
      data-testid={testId}
      id={id}
      style={
        {
          '--fieldWrapper-margin-bottom':
            typeof marginProps.mb === 'number'
              ? `${marginProps.mb - 1}px`
              : '-1px',
          ...style,
        } as CSSProperties
      }
      width={width}
      {...marginProps}
    >
      <Flex
        alignItems={labelPosition === 't' ? undefined : 'center'}
        className="reefer_fieldWrapper_labelContainer"
        flexDirection={labelPosition === 't' ? 'column' : 'row-reverse'}
        justifyContent="flex-end"
      >
        <Flex alignItems="center" justifyContent="space-between">
          <Typography
            as="label"
            className={clsx(
              styles.fieldWrapper_label,
              styles[`fieldWrapper_label__position_${labelPosition}`],
              {
                [styles.fieldWrapper_label__mobileStyled]:
                  !disableMobileInputStyling,
                [styles[
                  `fieldWrapper_label__position_${labelPosition}__mobileStyled`
                ]]: !disableMobileInputStyling,
                [hideStyles.isHidden]: labelHidden,
              }
            )}
            color="inherit"
            htmlFor={name}
            id={labelId}
            mb={labelPosition === 't' ? 12 : 0}
            variant={typography || labelVariant}
          >
            {label}
          </Typography>
          {repositionMobileHelperText && !labelHidden && (
            <HelperText
              className={clsx(styles.fieldWrapper_helperText__mobile)}
              disabled={disabled}
              id={`${helperTextId}-mobile`}
              mb={12}
            >
              {helperText}
            </HelperText>
          )}
        </Flex>

        {render({
          'aria-describedby': describedBy,
          'aria-labelledby': labelId,
        })}
      </Flex>
      {children}
      <HelperText
        className={clsx({
          [hideStyles.isHidden__tabletMax]:
            !labelHidden && repositionMobileHelperText,
        })}
        disabled={disabled}
        id={helperTextId}
        mt={!labelHidden && !repositionMobileHelperText ? 12 : 0}
      >
        {helperText}
      </HelperText>
    </Box>
  );
};
