import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import ReactModal from 'react-modal';

import { useVisualViewportHeight } from '../../hooks/useVisualViewportHeight/useVisualViewportHeight';
import {
  getColorCSSVar,
  getColorForBackgroundCSSVar,
} from '../../utils/colors';
import styles from './modal.module.css';
import type {
  ModalContentDividerProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
} from './modal.types';
import { ModalContent } from './modalContent/modalContent';
import { ModalContentDivider } from './modalContent/modalContentDivider';
import { ModalFooter } from './modalFooter/modalFooter';
import { ModalHeader } from './modalHeader/modalHeader';

function shadowAppSelector(): HTMLElement {
  const shadowHost = document.getElementById('shadow-host')?.shadowRoot;
  let shadowApp = shadowHost?.getElementById('shadow-app-sibling');
  shadowApp = shadowApp || document.body;
  return shadowApp;
}

/**
 * Modal Context to pass "variant" and "onRequestClose" to the subcomponents
 * when necessary
 */
export const ModalContext = createContext<
  Required<Pick<ModalProps, 'variant' | 'onRequestClose'>>
>({
  onRequestClose: () => {
    return;
  },
  variant: 'standard',
});

/**
 * `Modal` component that allows for simple dialogue modals, complex modals
 * with headers and footers, or full-screen modals.
 */
function BaseModal({
  appId = 'app',
  background,
  centerByVisualViewport = false,
  children,
  className,
  contentLabel,
  'data-testid': testId,
  isLayered,
  onAfterClose,
  onAfterOpen,
  onRequestClose,
  open,
  overflow = 'hidden',
  overlayClassName,
  overlayClose,
  parentId = 'parent',
  parentSelector,
  portalClassName,
  style,
  shouldReturnFocusAfterClose = true,
  topOverride,
  variant = 'standard',
}: ModalProps) {
  if (variant === 'full-screen' && !background) {
    background = 'grays-ultralight';
  } else if (!background) {
    background = 'grays-white';
  }

  const [appElementId, setAppElementId] = useState<HTMLElement | null>(null);

  const { keyboardHeight } = useVisualViewportHeight();
  /** Only offset by keyboard height when centerByVisualViewport=true */
  const onScreenKeyboardHeight = centerByVisualViewport ? keyboardHeight : 0;

  const contextValue = useMemo(
    () => ({ onRequestClose, variant }),
    [onRequestClose, variant]
  );

  useEffect(() => {
    setAppElementId(document.getElementById(appId));
  }, [appId]);

  return (
    <ModalContext.Provider value={contextValue}>
      <ReactModal
        appElement={appElementId as HTMLElement}
        className={clsx(className, styles[variant], [
          styles.ReactModal__Content,
        ])}
        closeTimeoutMS={200}
        contentLabel={contentLabel}
        data-testid={testId}
        id={parentId}
        isOpen={open}
        onAfterOpen={() => {
          document.body.style.overflow = 'hidden';
          onAfterOpen?.();
        }}
        onAfterClose={() => {
          if (!isLayered) {
            document.body.style.overflow = 'unset';
          }
          onAfterClose?.();
        }}
        onRequestClose={onRequestClose}
        overlayClassName={clsx(
          styles.ReactModal__Overlay,
          {
            [styles['ReactModal__Overlay--isOpen']]: open,
            [styles['ReactModal__Overlay--isClosed']]: !open,
          },
          overlayClassName
        )}
        parentSelector={parentSelector || shadowAppSelector}
        portalClassName={portalClassName}
        shouldCloseOnOverlayClick={overlayClose ? true : false}
        shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
        style={{
          content: {
            '--keyboard-height': `${onScreenKeyboardHeight}px`,
            '--modal-background-color': getColorCSSVar(background),
            '--modal-color': getColorForBackgroundCSSVar(background),
            '--modal-overflow': overflow,
            '--modal-top': topOverride
              ? topOverride
              : `calc(50% - ${onScreenKeyboardHeight / 2}px)`,
            '--modal-transform': topOverride
              ? 'translateX(-50%)'
              : 'translate(-50%, -50%)',
            ...style,
          } as CSSProperties,
        }}
      >
        {children}
      </ReactModal>
    </ModalContext.Provider>
  );
}

export type {
  ModalContentDividerProps,
  ModalContentProps,
  ModalFooterProps,
  ModalHeaderProps,
  ModalProps,
};

export const Modal = Object.assign(BaseModal, {
  Header: ModalHeader,
  Content: ModalContent,
  ContentDivider: ModalContentDivider,
  Footer: ModalFooter,
});
