import type { QueryFunctionContext } from '@tanstack/react-query';
import { z } from 'zod';

import { brandServerPaths, brandsApi } from '@jane/brands/data-access';
import type { ProductFilters } from '@jane/catalog-cms/types';
import { parseData } from '@jane/shared/util';

export const STATUS_ENUM = [
  'Active',
  'Duplicate',
  'Incorrect Brand',
  'Discontinued',
] as const;

const SelectSubdivisionSchema = z
  .object({
    id: z.string().nullable(),
    name: z.string().nullable(),
    categoryLabel: z.string().nullable(),
    imageUrl: z.string().nullable(),
    storeCount: z.number().nullable(),
  })
  .nullable();

const ProductListItemSchema = z.object({
  id: z.string().uuid(),
  productId: z.number().int().positive(),
  name: z.string(),
  category: z.string().nullable(),
  categoryLabel: z.string().nullable(),
  subcategory: z.string().nullable(),
  imageUrl: z.string().url().nullable(),
  brand: z.string().nullable(),
  storeCount: z.number().nonnegative(),
  status: z.enum(STATUS_ENUM),
  createdTime: z.string().datetime(),
  updatedTime: z.string().datetime(),
  selectedSubdivision: SelectSubdivisionSchema,
});

const ProductListResponseSchema = z.object({
  count: z.number().nonnegative(),
  totalCount: z.number().nonnegative(),
  final: z.boolean(),
  nextPosition: z.string(),
  data: z.array(ProductListItemSchema),
});

export type ProductListItem = z.infer<typeof ProductListItemSchema>;
export type ProductListResponseBody = z.infer<typeof ProductListResponseSchema>;
type URLParamsObject = {
  [key: string | number]: (string | number)[] | string | number;
};

export type FetchProductsParams = ProductFilters & {
  brand?: string[];
  subdivision?: string;
};

/** Fetch function for the infinite products query. Designed to be used with
 * React Query's useInfiniteQuery hook */
export const fetchInfiniteProducts = async ({
  pageParam,
  queryKey,
}: QueryFunctionContext<
  [string, string, FetchProductsParams],
  string | undefined
>) => {
  const params = { ...queryKey[2] } as URLParamsObject;
  if (pageParam) params['cursor'] = pageParam;

  const searchString = toProductsSearchString(params);

  const { data } = await brandsApi.get(
    `${brandServerPaths.products()}?${searchString}`
  );

  const parsedData = parseData(ProductListResponseSchema, data);
  return parsedData;
};

/**
 * Fetch function to the products endpoint
 */
export const fetchProducts = async ({
  queryKey,
}: QueryFunctionContext<[string, string, FetchProductsParams]>) => {
  const params = queryKey[2] as URLParamsObject;
  const searchString = toProductsSearchString(params);

  const path = searchString
    ? `${brandServerPaths.products()}?${searchString}`
    : brandServerPaths.products();

  const { data } = await brandsApi.get(path);
  const parsedData = parseData(ProductListResponseSchema, data);

  return parsedData.data;
};

/**
 * Takes a params object and produces a URL search string with all valid params
 * present in the params object.
 */
export const toProductsSearchString = (params: URLParamsObject) => {
  const searchParams = new URLSearchParams();

  if (params['brand'] && Array.isArray(params['brand'])) {
    appendArrayValues(searchParams, 'brandId', params['brand']);
  }

  if (params['category'] && Array.isArray(params['category'])) {
    appendArrayValues(searchParams, 'category', params['category']);
  }

  if (params['categoryLabel'] && Array.isArray(params['categoryLabel'])) {
    appendArrayValues(searchParams, 'categoryLabel', params['categoryLabel']);
  }

  if (params['status'] && Array.isArray(params['status'])) {
    appendArrayValues(searchParams, 'status', params['status']);
  }

  if (params['createdTime'] && Array.isArray(params['createdTime'])) {
    appendArrayValues(searchParams, 'createdTime', params['createdTime']);
  }

  if (params['updatedTime'] && Array.isArray(params['updatedTime'])) {
    appendArrayValues(searchParams, 'updatedTime', params['updatedTime']);
  }

  if (params['cursor'] && !Array.isArray(params['cursor'])) {
    appendValue(searchParams, 'cursor', params['cursor']);
  }

  if (params['searchTerm'] && !Array.isArray(params['searchTerm'])) {
    appendValue(searchParams, 'searchTerm', params['searchTerm']);
  }

  if (params['sortField'] && !Array.isArray(params['sortField'])) {
    appendValue(searchParams, 'sortField', params['sortField']);
  }

  if (params['reverse'] && !Array.isArray(params['reverse'])) {
    appendValue(searchParams, 'reverse', params['reverse']);
  }

  if (params['subdivision'] && !Array.isArray(params['subdivision'])) {
    appendValue(searchParams, 'subdivision', params['subdivision']);
  }

  return searchParams.toString();
};

const appendValue = (
  searchParams: URLSearchParams,
  key: string,
  value: string | number
) => {
  const stringVal = typeof value === 'string' ? value : value.toString();
  searchParams.append(key, stringVal);
  return searchParams;
};

const appendArrayValues = (
  searchParams: URLSearchParams,
  key: string,
  value: (string | number)[]
) => {
  const arrayKey = `${key}[]`;
  value.forEach((term) => appendValue(searchParams, arrayKey, term));
};
