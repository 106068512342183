import styled from '@emotion/styled';
import { useCallback } from 'react';

import type { ManualScheduleForDays } from '@jane/ad-manager/types';
import {
  DailyScheduleSelect,
  WeeklyScheduleSelect,
} from '@jane/shared-b2b/components';
import { Accordion } from '@jane/shared/components';
import { Flex, Typography } from '@jane/shared/reefer';
import {
  Form,
  useController,
  useFormContext,
  useWatch,
} from '@jane/shared/reefer-hook-form';

import type { AdSubmissionFormState } from '../formState/types';
import { SpendAllocationOption } from '../useAdBuilderForm';
import {
  ALL_DAY_SCHEDULE,
  ORDERED_DAYS,
  WEEKDAY_TO_WEEKDAY_MIN,
} from './constants';

const AccordionHeading = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
}));

export const SPEND_ALLOCATION_LABELS = {
  consistent: 'Consistent schedule throughout week',
  manual: 'Manually select days',
  weekly: 'Set start and end times for entire week',
};

const spendAllocationOptions = [
  {
    label: SPEND_ALLOCATION_LABELS.consistent,
    id: SpendAllocationOption.Consistent,
    value: SpendAllocationOption.Consistent,
  },
  {
    label: SPEND_ALLOCATION_LABELS.weekly,
    id: SpendAllocationOption.Weekly,
    value: SpendAllocationOption.Weekly,
  },
  {
    label: SPEND_ALLOCATION_LABELS.manual,
    id: SpendAllocationOption.Manual,
    value: SpendAllocationOption.Manual,
  },
];

export const SpendAllocation = () => {
  const { getValues, setError, clearErrors } =
    useFormContext<AdSubmissionFormState>();
  const selectedOption = useWatch<
    AdSubmissionFormState,
    '_spendAllocationOption'
  >({
    name: '_spendAllocationOption',
    defaultValue: getValues('_spendAllocationOption'),
  });
  const {
    field: { onChange: onChangeDayParting, value: dayParting },
  } = useController<AdSubmissionFormState, 'dayParting'>({
    name: 'dayParting',
  });
  const {
    field: { onChange: onChangeManualDays, value: manualDays },
  } = useController<AdSubmissionFormState, '_manualScheduleForDays'>({
    name: '_manualScheduleForDays',
  });

  const setDayPartError = (errorMessage: string) =>
    setError('dayParting', {
      type: 'custom',
      message: errorMessage,
    });
  const clearDayPartError = () => clearErrors('dayParting');

  const getSelectedAllocationText = () => {
    switch (selectedOption) {
      case SpendAllocationOption.Manual:
        return SPEND_ALLOCATION_LABELS.manual;
      case SpendAllocationOption.Weekly:
        return SPEND_ALLOCATION_LABELS.weekly;
      default:
        return SPEND_ALLOCATION_LABELS.consistent;
    }
  };

  const onOptionChange = useCallback(
    (newOption: SpendAllocationOption) => {
      // reset the times when changing to consistent or weekly schedule
      if (
        newOption === SpendAllocationOption.Consistent ||
        newOption === SpendAllocationOption.Weekly
      ) {
        onChangeDayParting({});
      }

      if (newOption === SpendAllocationOption.Manual) {
        const manualDays: ManualScheduleForDays = {};
        for (let i = 0; i < ORDERED_DAYS.length; i++) {
          const dayName = ORDERED_DAYS[i];
          const dayNameMin = WEEKDAY_TO_WEEKDAY_MIN[dayName];

          manualDays[dayNameMin] =
            dayParting[dayName] !== undefined &&
            (dayParting[dayName].startTime !== ALL_DAY_SCHEDULE.startTime ||
              dayParting[dayName].endTime !== ALL_DAY_SCHEDULE.endTime);
        }

        onChangeManualDays(manualDays);
      }
    },
    [dayParting, onChangeDayParting, onChangeManualDays]
  );

  return (
    <Accordion>
      <Accordion.Item id="ad-spend-allocation">
        <Accordion.Heading>
          <AccordionHeading>
            <Flex justifyContent="space-between">
              <Typography variant="body-bold">
                How do you want to allocate your spend?
              </Typography>
              <Accordion.Indicator size={18} />
            </Flex>
            <Typography variant="body">
              {getSelectedAllocationText()}
            </Typography>
          </AccordionHeading>
        </Accordion.Heading>
        <Accordion.Content>
          <Form.RadioFieldGroup
            name="_spendAllocationOption"
            options={spendAllocationOptions}
            mt={32}
            defaultChecked={selectedOption}
            onChange={onOptionChange}
          />
          {selectedOption === SpendAllocationOption.Manual && (
            <DailyScheduleSelect
              schedule={dayParting}
              onChangeSchedule={onChangeDayParting}
              daysWithScheduling={manualDays}
              setDaysWithScheduling={onChangeManualDays}
              setError={setDayPartError}
              clearError={clearDayPartError}
              timeSlotInfo="Configure specific times on specific days for this flight to
              serve. Times display in Pacific Time Zone."
            />
          )}
          {selectedOption === SpendAllocationOption.Weekly && (
            <WeeklyScheduleSelect
              onChangeSchedule={onChangeDayParting}
              setError={setDayPartError}
              clearError={clearDayPartError}
            />
          )}
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
};
