import { RadioFieldGroup } from '@jane/shared/reefer';
import type {
  RadioFieldGroupProps,
  RadioFieldOption,
} from '@jane/shared/reefer';

import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormRadioFieldGroupProps
  extends Omit<
      RadioFieldGroupProps,
      'children' | 'allowUndefinedExternalValue'
    >,
    Pick<FormFieldProps, 'required' | 'shouldUnregister' | 'validate'> {}

export type FormRadioFieldGroupOption = RadioFieldOption;

/**
 * Radio fields allow users to choose one option from a set of options.
 *
 * Use this component *inside forms* for inputs of `type`:
 * `radio`
 *
 * `Form.RadioFieldGroup` options support a `wrapper` attribute for displaying individual inputs in a more complex UI if needed.
 * An example illustrating the usage of `options` with `wrappers` can be seen [here](/story/components-table-examples-radio-column--default).
 *
 * When using `RadioFieldGroup`'s option `wrapper` attribute, please keep in mind the following:
 *
 * - Using `helperText` may disrupt a `wrapper`'s layout. Use the two together with caution.
 *
 * - When `option wrappers` are not in use, the `RadioFieldGroup` is wrapped in a `fieldset` tag. The component's `margin` props are applied to this `fieldset`.
 * To avoid layout and semantic issues, this `fieldset` tag does not wrap the group when `wrappers` are in use, and so `margin` props will have no affect.
 *
 * - To avoid layout issues, validation error messages are not displayed when `wrappers` are in use. You may display the error in question with an `ErrorBanner` in these circumstances.
 * Due to the mechanics of radio inputs, error messages do not generally display for `RadioFieldGroups` so this would really only be relevant if a custom `validate` function was applied.
 * When `wrappers` are not in use, the field will still be validated according to applied rules.
 *
 * _Field validations supported:_ `required`, `validate`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`RadioFieldGroup`](/story/components-forms-fields-radiofieldgroup--default).*
 */
export function FormRadioFieldGroup({
  autoFocus = false,
  defaultChecked,
  disabled = false,
  helperText,
  labelHidden = false,
  name,
  onBlur,
  onChange,
  required = false,
  shouldUnregister,
  'data-testid': testId,
  validate,
  options,
  optionWidth = 'auto',
  row = false,
  ...props
}: FormRadioFieldGroupProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultChecked}
      name={name}
      render={({ children, field }) => {
        return (
          <RadioFieldGroup
            allowUndefinedExternalValue
            options={options}
            autoFocus={autoFocus}
            defaultChecked={defaultChecked}
            disabled={disabled}
            helperText={helperText}
            labelHidden={labelHidden}
            name={field.name}
            onBlur={(fieldValue) => {
              onBlur && onBlur(fieldValue);
              field.onBlur();
            }}
            onChange={(fieldValue) => {
              onChange && onChange(fieldValue);
              field.onChange(fieldValue);
            }}
            onUpdate={(fieldValue) => {
              onChange && onChange(fieldValue);
            }}
            ref={field.ref}
            data-testid={testId}
            optionWidth={optionWidth}
            externalValue={field.value}
            row={row}
            {...props}
          >
            {children}
          </RadioFieldGroup>
        );
      }}
      requiredAllowFalse={required}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
