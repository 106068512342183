import { z } from 'zod';

import { PLACEMENTS_TO_ZONES_MAP } from '@jane/ad-manager/util';

export const AdSubmissionSchema = z.object({
  details: z.object({
    adPlacement: z.array(z.string()),
    adType: z.string(),
    menuRowTitle: z.string(),
    displayPlatforms: z.string(),
  }),
  pricing: z.object({
    isPriority: z.boolean(),
    budget: z.number(),
    cpmBid: z.number(),
    cpcBid: z.number(),
  }),
  products: z.object({
    categories: z.optional(z.array(z.string())),
    productIds: z.optional(z.array(z.string())),
  }),
  schedule: z.object({
    startDate: z.string(),
    endDate: z.string(),
    dayParting: z.optional(
      z.record(
        z.object({
          endTime: z.string(),
          startTime: z.string(),
        })
      )
    ),
  }),
  targeting: z.object({
    customerSegments: z.optional(z.array(z.string())),
    states: z.optional(z.array(z.string())),
    storeIds: z.optional(z.array(z.string())),
    excludedStoreIds: z.optional(z.array(z.string())),
  }),
});

export type AdSubmission = z.infer<typeof AdSubmissionSchema>;

export const JamApiBidModifierSchema = z.object({
  match: z.object({
    zone: z.nativeEnum(PLACEMENTS_TO_ZONES_MAP),
  }),
  multiplier: z.number().optional(),
  price: z.number().optional(),
});

export type JamApiBidModifier = z.infer<typeof JamApiBidModifierSchema>;

export const JamApiAdSchema = z.object({
  adModel: z.enum(['flat', 'cpc', 'cpm']),
  adType: z.enum(['priority', 'programmatic']),
  bid: z.number().nullable(),
  bidModifiers: z.array(JamApiBidModifierSchema),
  budget: z.number().nullable(),
  categories: z.array(z.string()).nullable(),
  creditPercent: z.number(),
  days: z
    .array(
      z.object({
        endTime: z.string().nullable(),
        startTime: z.string().nullable(),
        weekdays: z.array(z.string()).nullable(),
      })
    )
    .nullable(),
  endDate: z.string().datetime(),
  excludedStores: z.array(z.number()).nullable(),
  frequencyCapDenominator: z.number().nullable(),
  frequencyCapNumerator: z.number().nullable(),
  frequencyCapUnit: z.enum(['disabled', 'hours', 'days', 'minutes']).nullable(),
  goalAmount: z.number(),
  goalType: z.enum(['percentage', 'monthly_revenue', 'revenue']),
  platforms: z.array(z.string()).nullable(),
  products: z.array(z.number()).nullable(),
  segmentId: z.number().nullable(),
  startDate: z.string().datetime(),
  states: z.array(z.string()).nullable(),
  stores: z.array(z.number()).nullable(),
  title: z.string().nullable(),
  zones: z.array(z.string()).nullable(),
});

export type JamApiAd = z.infer<typeof JamApiAdSchema>;

export interface JamApiError {
  message: string;
}
