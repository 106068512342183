import { useState } from 'react';

import {
  Banner,
  Card,
  ChevronDownIcon,
  ChevronUpIcon,
  ErrorIcon,
  Flex,
  InfoIcon,
  Link,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import { Form, useWatch } from '@jane/shared/reefer-hook-form';
import { sentenceCase } from '@jane/shared/util';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import { handleScroll } from '../utils/specialForm.util';
import { CategorySelection } from './categorySelection';
import { ProductSelection } from './productSelection';
import { WeightSelection } from './weightSelection';

export type BrandSpecialRulesVariant = 'inclusion' | 'exclusion';

const BrandSpecialRules = ({
  variant,
}: {
  variant: BrandSpecialRulesVariant;
}) => {
  const isInclusion = variant === 'inclusion';
  const defaultRadioValue = isInclusion ? 'all' : 'none';
  const defaultLabel = isInclusion ? 'All products' : 'No exclusions';
  const bannerLabel = isInclusion
    ? 'Discounted products must satisfy all inclusion rules set'
    : 'Excluded products must satisfy all exclusion rules set';

  const [open, setOpen] = useState(false);

  const formSelectName = isInclusion
    ? 'productInclusionType'
    : 'productExclusionType';

  const brandId = useWatch({ name: 'productBrandId' });
  const selected = useWatch({ name: formSelectName });

  return (
    <>
      <SpecialFormBlock first={isInclusion}>
        {isInclusion && (
          <Typography variant="header-bold" branded mb={32}>
            Products
          </Typography>
        )}
        <Flex
          flexDirection="row"
          width="100%"
          alignItems="center"
          mb={open ? 24 : 0}
          onClick={() => setOpen((prev) => !prev)}
          aria-label={`${open ? 'Close' : 'Open'} ${variant}s section`}
        >
          <Flex flexDirection="column" mb={open ? 24 : 0}>
            <Typography variant="body-bold">
              {`${sentenceCase(variant)}s`}
            </Typography>
            {!open && (
              <Typography>
                {selected === 'specific'
                  ? 'Specific products, category and weight'
                  : defaultLabel}
              </Typography>
            )}
          </Flex>
          {open ? <ChevronUpIcon ml="auto" /> : <ChevronDownIcon ml="auto" />}
        </Flex>
        {open && (
          <>
            <Form.RadioFieldGroup
              name={formSelectName}
              shouldUnregister={false}
              options={[
                {
                  id: `${variant}-${defaultRadioValue}`,
                  label: defaultLabel,
                  value: `${defaultRadioValue}`,
                },
                {
                  id: `${variant}-specific`,
                  label: 'Specific products, category and weight',
                  value: 'specific',
                },
              ]}
              defaultChecked={selected}
            />
            <Banner
              full
              icon={<InfoIcon />}
              label={<Typography>{bannerLabel}</Typography>}
            />
          </>
        )}
      </SpecialFormBlock>

      {open && selected === 'specific' && (
        <SpecialFormBlock>
          {!brandId && (
            <Banner
              full
              icon={<ErrorIcon />}
              variant="error"
              actions={
                <Link ml="auto" onClick={() => handleScroll('details-card')}>
                  Select brand
                </Link>
              }
              label="Select a brand before including/excluding specific products, categories, or weights"
            />
          )}
          <ProductSelection variant={variant} />

          <Modal.ContentDivider color="grays-light" padding={false} />

          <CategorySelection variant={variant} />

          <Modal.ContentDivider color="grays-light" padding={false} />

          <WeightSelection variant={variant} />
        </SpecialFormBlock>
      )}
    </>
  );
};

export const ProductsSection = () => {
  return (
    <Card width="100%" style={{ overflow: 'visible' }}>
      <StyledCardGroup width="100%">
        <BrandSpecialRules variant="inclusion" />
        <BrandSpecialRules variant="exclusion" />
      </StyledCardGroup>
    </Card>
  );
};
