import pickBy from 'lodash/pickBy';

import { getCSSVar } from '../../internal/utils/css';
import { getColorCSSVar } from '../../utils/colors';
import type { BorderRadiusKey, ReeferTheme } from '../theme.types';
import type { BaseColorKeys, ColorKey } from '../themeColors.types';
import type {
  ButtonSizeConfig,
  ButtonVariantConfig,
} from '../themeComponents.types';

function getColorVariations(color: BaseColorKeys) {
  return {
    dark: getColorCSSVar(`${color}-dark` as ColorKey),
    light: getColorCSSVar(`${color}-light` as ColorKey),
    main: getColorCSSVar(`${color}` as ColorKey),
  };
}

function getButtonSizeCSS({
  borderRadius,
  height,
  paddingX,
  typographyVariant,
}: ButtonSizeConfig): ButtonSizeConfig {
  return pickBy(
    {
      borderRadius: borderRadius
        ? (getCSSVar(`--border-radius-${borderRadius}`) as BorderRadiusKey)
        : undefined,
      height,
      paddingX,
      typographyVariant,
    },
    (v) => v !== undefined
  );
}

function getButtonVariantCSS({
  backgroundColor,
  borderColor,
  borderWidth,
  typographyColor,
  typographyVariant,
}: ButtonVariantConfig): ButtonVariantConfig {
  return pickBy(
    {
      backgroundColor: backgroundColor
        ? (getColorCSSVar(backgroundColor) as ColorKey)
        : undefined,
      borderColor: borderColor
        ? (getColorCSSVar(borderColor) as ColorKey)
        : undefined,
      borderWidth,
      typographyColor: typographyColor
        ? (getColorCSSVar(typographyColor) as ColorKey)
        : undefined,
      typographyVariant,
    },
    (v) => v !== undefined
  );
}

export function generateThemeCSSObject(theme: ReeferTheme): ReeferTheme {
  const buttonConfig = theme.components.Button;
  return {
    borderRadius: {
      xs: getCSSVar('--border-radius-xs'),
      sm: getCSSVar('--border-radius-sm'),
      lg: getCSSVar('--border-radius-lg'),
      xl: getCSSVar('--border-radius-xl'),
    },
    colors: {
      primary: getColorVariations('primary'),
      secondary: getColorVariations('secondary'),
      info: getColorVariations('info'),
      tertiary: {
        emerald: getColorVariations('emerald'),
        pacific: getColorVariations('pacific'),
        palm: getColorVariations('palm'),
        seafoam: getColorVariations('seafoam'),
      },
      brand: {
        gold: getColorVariations('gold'),
        ember: getColorVariations('ember'),
        grape: getColorVariations('grape'),
        purple: getColorVariations('purple'),
        sunset: getColorVariations('sunset'),
        tangerine: getColorVariations('tangerine'),
      },
      success: getColorVariations('success'),
      error: getColorVariations('error'),
      text: {
        info: getColorCSSVar('text-info'),
        inverse: getColorCSSVar('text-inverse'),
        main: getColorCSSVar('text-main'),
        light: getColorCSSVar('text-light'),
      },
      system: {
        positive: {
          light: getColorCSSVar('system-positive-light'),
          dark: getColorCSSVar('system-positive-dark'),
          main: getColorCSSVar('system-positive-main'),
        },
        negative: {
          light: getColorCSSVar('system-negative-light'),
          dark: getColorCSSVar('system-negative-dark'),
          main: getColorCSSVar('system-negative-main'),
        },
      },
      grays: {
        white: getColorCSSVar('grays-white'),
        dark: getColorCSSVar('grays-dark'),
        light: getColorCSSVar('grays-light'),
        mid: getColorCSSVar('grays-mid'),
        black: getColorCSSVar('grays-black'),
        ultralight: getColorCSSVar('grays-ultralight'),
        hover: getColorCSSVar('grays-hover'),
      },
      background: getColorCSSVar('background'),
      transparent: {
        black: {
          five: getColorCSSVar('transparent-black-five'),
          ten: getColorCSSVar('transparent-black-ten'),
          twenty: getColorCSSVar('transparent-black-twenty'),
          fifty: getColorCSSVar('transparent-black-fifty'),
        },
        white: {
          ten: getColorCSSVar('transparent-white-ten'),
        },
      },
    },
    components: {
      Button: {
        icons: {
          sizes: {
            small: getButtonSizeCSS(buttonConfig.icons.sizes['small']),
            default: getButtonSizeCSS(buttonConfig.icons.sizes['default']),
            large: getButtonSizeCSS(buttonConfig.icons.sizes['large']),
            jumbo: getButtonSizeCSS(buttonConfig.icons.sizes['jumbo']),
          },
        },
        shared: {
          borderRadius: getCSSVar(
            `--border-radius-${buttonConfig.shared.borderRadius}`
          ) as BorderRadiusKey,
          borderWidth: buttonConfig.shared.borderWidth,
          typographyVariant: buttonConfig.shared.typographyVariant,
        },
        sizes: {
          small: getButtonSizeCSS(buttonConfig.sizes['small']),
          default: getButtonSizeCSS(buttonConfig.sizes['default']),
          large: getButtonSizeCSS(buttonConfig.sizes['large']),
          jumbo: getButtonSizeCSS(buttonConfig.sizes['jumbo']),
        },
        variants: {
          destructive: getButtonVariantCSS(
            buttonConfig.variants['destructive']
          ),
          'destructive-secondary': getButtonVariantCSS(
            buttonConfig.variants['destructive-secondary']
          ),
          minimal: getButtonVariantCSS(buttonConfig.variants['minimal']),
          'minimal-inverse': getButtonVariantCSS(
            buttonConfig.variants['minimal-inverse']
          ),
          primary: getButtonVariantCSS(buttonConfig.variants['primary']),
          'primary-inverse': getButtonVariantCSS(
            buttonConfig.variants['primary-inverse']
          ),
          secondary: getButtonVariantCSS(buttonConfig.variants['secondary']),
          tertiary: getButtonVariantCSS(buttonConfig.variants['tertiary']),
          'tertiary-selected': getButtonVariantCSS(
            buttonConfig.variants['tertiary-selected']
          ),
        },
      },
      Card: {
        styles: {
          borderRadius: getCSSVar(
            `--border-radius-${theme.components.Card.styles.borderRadius}`
          ) as BorderRadiusKey,
        },
      },
      Icon: {
        sizes: {
          sm: getCSSVar('--icon-sizes-sm'),
          md: getCSSVar('--icon-sizes-md'),
          lg: getCSSVar('--icon-sizes-lg'),
          xl: getCSSVar('--icon-sizes-xl'),
          xxl: getCSSVar('--icon-sizes-xxl'),
          xxxl: getCSSVar('--icon-sizes-xxxl'),
        },
      },
      Typography: {
        ...theme.components.Typography,
        fonts: {
          branded: {
            fontFamily: getCSSVar('--font-family-branded'),
          },
          default: {
            fontFamily: getCSSVar('--font-family-default'),
          },
        },
      },
    },
  };
}
