import type * as ReactTable from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import {
  type ApiFlightSummary,
  useGetBrandFlights,
} from '@jane/ad-manager/data-access';
import { DEFAULT_START_DATE, useFilterParams } from '@jane/ad-manager/hooks';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import {
  formatCurrency,
  formatPacificTimeToUtc,
  formatPacificTimeToUtcEod,
} from '@jane/ad-manager/util';
import { Box, Flex } from '@jane/shared/reefer';

import { BrandSelectHeader } from '../Header/BrandSelectHeader';
import { AdActions } from './AdActions';
import { useNavigateToAdBuilder } from './AdBuilder/AdBuilder';
import { ColumnVisibilityPopover } from './ColumnVisibilityPopover';
import { EmptyTableState } from './EmptyTableState';
import { useFlightsTable } from './FlightsTable/useFlightsTable';
import { NoBrandSelectedState } from './NoBrandSelectedState';
import { StatisticsDisplay } from './StatisticsDisplay';
import { StatisticsFilters } from './StatisticsFilters';
import { Table } from './Table';

const FLIGHT_COLUMN_VISIBILITY_OPTIONS = [
  'states',
  'stores',
  'dateRange',
  'model',
  'products',
  'placement',
  'budget',
  'spend',
  'bid',
  'frequencyCap',
  'impressions',
  'displaysOn',
];

export const DigitalMerchandising = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { selectedBrand, setSelectedBrand } = useSelectedBrandContext();

  const { filterParams, onChangeFilterParams } = useFilterParams();

  const {
    data: brandFlightsData,
    isLoading: isFlightsLoading,
    refetch: refetchBrandFlights,
    error: fetchBrandFlightsError,
  } = useGetBrandFlights({
    brandId: selectedBrand?.id,
    startDate:
      filterParams.startDatePT &&
      formatPacificTimeToUtc(filterParams.startDatePT),
    endDate:
      filterParams.endDatePT &&
      formatPacificTimeToUtcEod(filterParams.endDatePT),
    models: filterParams.types,
    states: filterParams.locations,
  });

  const navigateToAdBuilder = useNavigateToAdBuilder();
  const handleOpenAdBuilder = () => {
    navigateToAdBuilder();
  };

  useEffect(() => {
    // If no brand is selected, open the search modal (initial load)
    if (!selectedBrand) setIsSearchOpen(true);
  }, [selectedBrand]);

  const onDuplicateRow = ({ original }: ReactTable.Row<ApiFlightSummary>) => {
    navigateToAdBuilder({ adBuilderPrefill: original.id });
  };

  const flightsTable = useFlightsTable({
    data: brandFlightsData?.flights || [],
    onDuplicateRow,
    onFlightUpdated: () => refetchBrandFlights(),
  });

  const brandFlightsStatistics = brandFlightsData?.statistics
    ? [
        {
          label: 'ROAS',
          valueDisplay: formatCurrency(brandFlightsData.statistics.roas),
        },
        {
          label: 'Ads live',
          valueDisplay: brandFlightsData.statistics.adsLive.toLocaleString(),
        },
        {
          label: 'Customers impressed',
          valueDisplay:
            brandFlightsData.statistics.customersImpressed.toLocaleString(),
        },
        {
          label: 'Total billable',
          valueDisplay: formatCurrency(
            brandFlightsData.statistics.totalBillable
          ),
        },
      ]
    : [
        { label: 'ROAS', valueDisplay: 0 },
        { label: 'Ads live', valueDisplay: 0 },
        {
          label: 'Customers impressed',
          valueDisplay: 0,
        },
        {
          label: 'Total billable',
          valueDisplay: 0,
        },
      ];

  const isEmpty = !isFlightsLoading && !brandFlightsData?.flights.length; // No results or have issues loading flights
  const onEmptyFlightClick = (isError: boolean) => {
    if (isError) {
      // Re-trigger query
      refetchBrandFlights();
    }

    // Reset filters and get default view results
    onChangeFilterParams({
      locations: [],
      types: [],
      startDatePT: DEFAULT_START_DATE,
      endDatePT: null,
      pacing: [],
    });
  };

  return (
    <Box>
      {selectedBrand ? (
        <Box>
          <BrandSelectHeader
            hasBoxShadow={false}
            brand={selectedBrand}
            setBrand={setSelectedBrand}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <Flex justifyContent="space-between" px={24} pt={24}>
            <StatisticsFilters
              filterParams={filterParams}
              onChangeFilterParams={onChangeFilterParams}
              hasAdTypeFilter
            />
            <Flex gap={12}>
              <ColumnVisibilityPopover<ApiFlightSummary>
                table={flightsTable}
                columnOptions={FLIGHT_COLUMN_VISIBILITY_OPTIONS}
              />
              <AdActions openAdBuilder={handleOpenAdBuilder} />
            </Flex>
          </Flex>
          {isEmpty ? (
            <EmptyTableState
              isError={!!fetchBrandFlightsError}
              onClick={onEmptyFlightClick}
              errorTitle="Couldn't load flights"
              description="No flights match the filters you have selected"
              buttonLabel="View all flights"
            />
          ) : (
            <>
              <Flex mt={24} px={24}>
                <StatisticsDisplay entries={brandFlightsStatistics} />
              </Flex>
              <Flex>
                <Table<ApiFlightSummary>
                  table={flightsTable}
                  isLoading={isFlightsLoading}
                />
              </Flex>
            </>
          )}
        </Box>
      ) : (
        <Box>
          <BrandSelectHeader
            hasBoxShadow={false}
            brand={selectedBrand}
            setBrand={setSelectedBrand}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <NoBrandSelectedState onClick={() => setIsSearchOpen(true)} />
        </Box>
      )}
    </Box>
  );
};
