import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

export const UTC_TIMEZONE = 'UTC';
export const API_SCHEDULE_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss[Z]'; // ISO 8601/RFC3339 format ex: 2025-03-26T06:00:00+00:00
export const API_SCHEDULE_TIME_FORMAT = 'H:mm';
export const CLIENT_SCHEDULE_DATE_FORMAT = 'YYYY-MM-DD';
export const CLIENT_SCHEDULE_TIME_FORMAT = 'hh:mm A';
export const CLIENT_SCHEDULE_DATETIME_FORMAT = 'YYYY-MM-DD hh:mm A';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export const nowInUTC = () => dayjs().tz(UTC_TIMEZONE);

export const isFutureDate = (dateString: string): boolean => {
  const date = dayjs(dateString);
  const now = nowInUTC();
  return date.isAfter(now);
};

export const isPastDate = (dateString: string): boolean => {
  const date = dayjs(dateString);
  const now = nowInUTC();
  return date.isBefore(now);
};

export const getPastDate = (daysAgo: number): string =>
  dayjs().subtract(daysAgo, 'days').format('YYYY-MM-DD');

export const getToday = (): string => dayjs().format('YYYY-MM-DD');
