import {
  SliderField,
  calculateDoubleSliderFieldDefaultValue,
  calculateSingleSliderFieldDefaultValue,
  numberRegex,
} from '@jane/shared/reefer';
import type { SliderFieldProps } from '@jane/shared/reefer';

import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormSliderFieldProps
  extends SliderFieldProps,
    Pick<FormFieldProps, 'shouldUnregister' | 'validate'> {}

/**
 * Slider fields use a `range` slider to allow users let the user specify numeric values
 * which must be no less than a given value, and no more than another given value.
 *
 * Two types of sliders are supported:
 * - `single` - set 1 one numeric value, has a single handle.
 * - `double` - set 2 numberic values, has 2 handles.
 *
 * _Field validations supported:_ none.
 *
 * Use this component *inside* forms*.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms, see [`SliderField`](/story/components-forms-fields-sliderfield--default).*
 */
export function FormSliderField({
  autoFocus = false,
  defaultValue: defaultValueProp,
  disabled = false,
  label,
  labelHidden = false,
  max,
  min,
  startUnit,
  endUnit,
  name,
  onAfterChange,
  onBlur,
  onChange,
  step = 1,
  isDouble = true,
  shouldUnregister,
  validate,
  width = 'auto',
  'data-testid': testId,
  ...props
}: FormSliderFieldProps) {
  const defaultValue = isDouble
    ? calculateDoubleSliderFieldDefaultValue(
        min,
        max,
        step,
        typeof defaultValueProp === 'object' ? defaultValueProp : undefined
      )
    : calculateSingleSliderFieldDefaultValue(
        min,
        max,
        step,
        typeof defaultValueProp === 'number' ? defaultValueProp : undefined
      );

  return (
    <FormFieldWrapper
      defaultValue={defaultValue}
      errorMessage={'Please enter a numeric value.'}
      name={name}
      pattern={numberRegex}
      render={({ children, field }) => (
        <SliderField
          autoFocus={autoFocus}
          defaultValue={defaultValue}
          disabled={disabled}
          label={label}
          labelHidden={labelHidden}
          max={max}
          min={min}
          name={field.name}
          onAfterChange={(value?: number) => {
            onAfterChange && onAfterChange(value);
          }}
          onBlur={(value?: number) => {
            onBlur && onBlur(value);
            field.onBlur();
          }}
          onChange={(value?: number) => {
            onChange && onChange(value);
            field.onChange(value);
          }}
          onUpdate={onChange}
          ref={field.ref}
          isDouble={isDouble}
          step={step}
          startUnit={startUnit}
          endUnit={endUnit}
          data-testid={testId}
          value={field.value}
          width={width}
          {...props}
        >
          {children}
        </SliderField>
      )}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
