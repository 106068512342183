import type { Brand } from '@jane/gold-manager/types';
import { getSpecialStatus } from '@jane/gold-manager/util';
import { Flex, PartnerLogo, Tag, Typography } from '@jane/shared/reefer';
import type { ColorKey } from '@jane/shared/reefer';
import { formatTime } from '@jane/shared/util';

import type { SpecialsTableRow } from './specialsTable.types';

export const getScheduledTime = (time?: string | null) =>
  time ? formatTime(time, 'MM/DD/YYYY h:mm A', undefined, 'UTC') : '';

export const bodyContent = (value?: string) => (
  <Typography whiteSpace="break-spaces" maxLines={2}>
    {value}
  </Typography>
);

export const headerContent = (value: string) => (
  <Typography variant="caps" truncateAt="100%">
    {value}
  </Typography>
);

export const brandContent = ({ brand }: { brand: Brand }) => (
  <Flex alignItems="center" gap={8}>
    <PartnerLogo
      size="xxs"
      name={brand.name}
      image={brand.logoUrl}
      variant="brand"
    />
    <Typography whiteSpace="break-spaces" maxLines={2}>
      {brand.name}
    </Typography>
  </Flex>
);

interface StatusTagColorProps {
  tag: ColorKey;
  text: ColorKey;
}

export const StatusTag = ({ row }: { row: SpecialsTableRow }) => {
  const status = getSpecialStatus({
    enabled: row.original.enabled,
    endTime: row.original.end_time,
    startTime: row.original.start_time,
  });

  const STATUS_COLOR_MAP: { [key: string]: StatusTagColorProps } = {
    Live: {
      text: 'palm-dark',
      tag: 'palm-light',
    },
    Upcoming: {
      text: 'purple-dark',
      tag: 'purple-light',
    },
    Expired: {
      text: 'ember-dark',
      tag: 'ember-light',
    },
    Disabled: {
      text: 'error-dark',
      tag: 'error-light',
    },
  };

  return (
    <Tag
      label={status}
      color={STATUS_COLOR_MAP[status].text}
      background={STATUS_COLOR_MAP[status].tag}
    />
  );
};
