import type { FormState } from 'final-form';

import type { FilterSortId } from '@jane/search/types';
import type {
  CalloutVariants,
  MobileFilterTab,
  ShareNetwork,
} from '@jane/shared-ecomm/types';
import type {
  AppMode,
  Coordinates,
  CrmProviderType,
  Id,
  Location,
  Preferences,
  PriceId,
  ProductKind,
  ProductLineage,
  Zone,
} from '@jane/shared/models';
import type { AlmostGone } from '@jane/shared/types';

export enum EventNames {
  AbandonedCheckoutPage = 'Abandoned Checkout Page',
  AddedProductToCart = 'Added Product to Cart',
  AddedStrongholdPaymentSource = 'Added Stronghold Payment Source',
  AllSpecialsTap = 'All Specials Tap',
  BrandDetailPageTap = 'Brand Detail Page Tap',
  BrandPageLoaded = 'Brand Page Loaded',
  BrandPageOpenedVideo = 'Brand Page Opened Video',
  CashBackDetailPageTap = 'Cash Back Detail Page Tap',
  ChangeBankAccountSuccess = 'Change Bank Account Success',
  ChangeBankAccountTap = 'Change Bank Account Tap',
  CheckoutSuccess = 'Checkout Success',
  ClickedAd = 'Clicked Ad',
  ClickedBrandLink = 'Clicked Brand Link',
  ClickedBundleEnticementFilter = 'Clicked Bundle Enticement Filter',
  ClickedCart = 'Clicked Cart',
  ClickedCartProductCard = 'Clicked Cart Product Card',
  ClickedCheckout = 'Clicked Checkout',
  ClickedContinueShopping = 'Clicked Continue Shopping',
  ClickedHomePageProductCard = 'Clicked HomePage Product Card',
  ClickedMarketplaceProductCard = 'Clicked Marketplace Product Card',
  ClickedMenuProductCard = 'Clicked Menu Product Card',
  ClickedPayWithJanePay = 'Clicked Pay With Jane Pay',
  ClickedPayWithStronghold = 'Clicked Pay With Stronghold',
  ClickedProceedWithAeropay = 'Clicked Proceed With Aeropay',
  ClickedSeeAll = 'Clicked See All',
  ClickedSegmentedMobileFilterTab = 'Clicked Segmented Store Menu Filter Tab',
  ClickedSegmentedMobileFilterToggle = 'Clicked Segmented Mobile Filter Toggle',
  ClickedShowMore = 'Clicked Show More',
  ClickedStoreInfo = 'Clicked Store Info',
  ClickedStoreNearYou = 'Clicked HomePage Store Near You',
  ConvertedAdSale = 'Converted Ad Sale',
  DispensaryTap = 'Dispensary Tap',
  FetchedAdCandidatesError = 'Fetched Ad Candidates Error',
  HomeTap = 'Home Tap',
  InteractedWithProductReviews = 'Interacted With Product Reviews',
  LinkBankAccountFailed = 'Link Bank Account Failed',
  LinkBankAccountTap = 'Link Bank Account Tap',
  LinkedAeropayBankAccount = 'Linked Aeropay Bank Account',
  LinkedFirstBankAccount = 'Linked First Bank Account',
  LinkedNewBankAccount = 'Linked New Bank Account',
  LoadedCartPage = 'Loaded Cart Page',
  LoadedCheckoutPage = 'Loaded Checkout Page',
  LoadedGuestCheckoutPage = 'Loaded Guest Checkout Page',
  LoadedHomePage = 'Loaded Homepage',
  LoadedLoginRegistrationPage = 'Loaded Login/Registration Page',
  LoadedLoyaltyPoints = 'Loaded Loyalty Points',
  LoadedStoresPage = 'Loaded Stores Page',
  LoadedWriteProductReviewForm = 'Loaded Write Product Review Form',
  LoggedIn = 'Logged In',
  MenuTabTap = 'Tab Tap',
  NavBarTap = 'Nav Bar Tap',
  OpenedAccordionItem = 'Opened Accordion Item',
  ProductListingTap = 'Product Listing Tap',
  Registered = 'Registered',
  RemovedProductFromCart = 'Removed Product from Cart',
  ReviewedProduct = 'Reviewed Product',
  SearchedDispensaries = 'Searched Dispensaries',
  SearchedMenuProducts = 'Searched Menu Products',
  SearchedProducts = 'Searched Products',
  SetLocation = 'Set Location',
  SharedProduct = 'Shared Product',
  StartedKioskOrder = 'Started Kiosk Order',
  StoreCommunicationBannerLoaded = 'Store Communication Banner Loaded',
  StoreSpecialTap = 'Store Special Tap',
  SubmittedCurbsideForm = 'Submitted Curbside Form',
  TimedGetJaneDeviceId = 'Timed Get Jane Device ID',
  TimedKioskInactivity = 'Timed Kiosk Inactivity',
  TimedKioskRefreshToLanding = 'Timed Kiosk Refresh To Landing',
  TimedMenuLoad = 'Timed Menu Load',
  UnlinkedLastBankAccount = 'Unlinked Last Bank Account',
  UpdatedProductInCart = 'Updated Product in Cart',
  ViewedBundleEnticement = 'Viewed Bundle Enticement',
  ViewedClickableBundleEnticement = 'Viewed Clickable Bundle Enticement',
  ViewedExperiment = 'Viewed Experiment',
  ViewedProduct = 'Viewed Product',
  ViewedProductReviews = 'Viewed Product Reviews',
  ViewedSpecial = 'Viewed Product Special',
}

export type UserPreferencesType = Pick<Location, 'cityState' | 'zipcode'> &
  Pick<Preferences, 'storeFulfillmentType' | 'storeSearchRadius' | 'storeType'>;

export interface AbandonedCheckoutPage extends Partial<FormState<any>> {
  appMode: AppMode;
  checkoutError: string | null;
  checkoutTotal: number;
  event: EventNames.AbandonedCheckoutPage;
  formState: any;
  guestCheckout: boolean;
  imageUploadFieldsValid: boolean;
  invalid: boolean;
  minimumMet: boolean;
  pathname: string;
  step?: string;
  storeId: Id;
}

interface AddedStrongholdPaymentSource {
  event: EventNames.AddedStrongholdPaymentSource;
}

export interface AllSpecialsTap {
  event: EventNames.AllSpecialsTap;
  storeId: Id;
  storeName: string;
}

export interface BrandDetailPageTap extends UserPreferencesType {
  brandDiscount: boolean;
  brandId: Id;
  brandName: string;
  brandRankInList: number;
  event: EventNames.BrandDetailPageTap;
  source: '/' | '/brands' | '/brands/all';
}

export interface BrandPageLoaded {
  brandId: Id;
  brandName: string;
  event: EventNames.BrandPageLoaded;
  productReferrerId?: Id;
}

export interface BrandPageOpenedVideo {
  brandId: Id;
  event: EventNames.BrandPageOpenedVideo;
}

export interface CashBackDetailPageTap extends UserPreferencesType {
  brandDiscountDetails: number;
  brandDiscountId: Id;
  brandDiscountRankInList: number;
  brandDiscountType: string;
  brandId: Id;
  brandName: string;
  discountBrandName: string;
  event: EventNames.CashBackDetailPageTap;
  source: string;
}

export interface ClickedAd {
  event: EventNames.ClickedAd;
  janeDeviceId: string;
}

export interface ClickedBrandLink {
  appMode: AppMode;
  brandName: string;
  event: EventNames.ClickedBrandLink;
  page: string;
  storeId: Id | null;
}

export interface ClickedBundleEnticementFilter {
  event: EventNames.ClickedBundleEnticementFilter;
  specialId: Id;
  storeId: Id;
}

interface ClickedCart {
  event: EventNames.ClickedCart;
}

interface ClickedCheckout {
  event: EventNames.ClickedCheckout;
}

export interface ChangeBankAccountSuccess {
  event: EventNames.ChangeBankAccountSuccess;
  source: 'account' | 'checkout';
}

interface ChangeBankAccountTap {
  event: EventNames.ChangeBankAccountTap;
}

export interface CheckoutSuccess
  extends AlgoliaFilterProperties,
    AlgoliaObjectProperties {
  cartId: Id;
  event: EventNames.CheckoutSuccess;
  paymentMethod: string;
  paymentOptions: string[];
  priceTotal: number;
  products: any[];
  storeCurrency?: string;
}

interface ClickedContinueShopping {
  event: EventNames.ClickedContinueShopping;
}

interface ClickedProceedWithAeropay {
  event: EventNames.ClickedProceedWithAeropay;
}

interface ClickedPayWithJanePay {
  event: EventNames.ClickedPayWithJanePay;
}

interface ClickedPayWithStronghold {
  event: EventNames.ClickedPayWithStronghold;
}

export interface ClickedSeeAll {
  category: string;
  event: EventNames.ClickedSeeAll;
  linkLocation:
    | 'carousel card'
    | 'bucket title'
    | 'bottom of bucket button'
    | 'see all link'
    | 'ad banner';
  numberOfItems: number;
  placementIndex: number;
  storeId?: string;
  zone?: Zone;
}

interface ClickedShowMore {
  category: string;
  event: EventNames.ClickedShowMore;
}

export type StoreInfoAttribute =
  | 'description'
  | 'map'
  | 'phone'
  | 'ratings'
  | 'schedule';

interface ClickedStoreInfo {
  attribute: StoreInfoAttribute;
  event: EventNames.ClickedStoreInfo;
}

export type CardType = 'Order Again' | 'Trending' | 'Best Selling';

export interface ClickedHomePageProductCard {
  cardType: CardType;
  event: EventNames.ClickedHomePageProductCard;
}

export interface ClickedMarketplaceProductCard
  extends ProductProperties,
    AlgoliaObjectProperties {
  event: EventNames.ClickedMarketplaceProductCard;
}

interface SegmentedMobileFilterEvent {
  filterTab: MobileFilterTab;
}

export interface ClickedSegmentedMobileFilterTab
  extends SegmentedMobileFilterEvent {
  event: EventNames.ClickedSegmentedMobileFilterTab;
}

export interface ClickedSegmentedMobileFilterToggle
  extends SegmentedMobileFilterEvent {
  event: EventNames.ClickedSegmentedMobileFilterToggle;
}

export type CardLocation = 'grid carousel' | 'grid table' | 'list';

export interface AlgoliaObjectProperties {
  event: string;
  indexName: string;
  objectIds: string[];
}

export interface AlgoliaFilterProperties {
  event: string;
  filters: string[];
  indexName: string;
}

export interface ClickedMenuProductCard
  extends ProductProperties,
    AlgoliaObjectProperties {
  bestSelling: boolean;
  bucketName?: string;
  cardLocation: CardLocation;
  category: string;
  columnPosition?: number | null;
  event: EventNames.ClickedMenuProductCard;
  index?: number;
  rowPosition?: number | null;
}

export interface ClickedCartProductCard
  extends ProductProperties,
    AlgoliaObjectProperties {
  event: EventNames.ClickedCartProductCard;
}

export interface ClickedStoreNearYou {
  event: EventNames.ClickedStoreNearYou;
}

export interface DispensaryTap extends UserPreferencesType {
  currentUrl: string;
  event: EventNames.DispensaryTap;
  source: 'home' | 'search' | 'pdp' | 'dispensaries';
  storeCity: string;
  storeId: Id;
  storeName: string;
  storeState: string;
}

export interface FetchedAdCandidatesError {
  error: object | string;
  event: EventNames.FetchedAdCandidatesError;
}

export interface StoreCommunicationBannerLoaded {
  bannerEnabled: boolean;
  bannerMessageCharacterCount: number;
  event: EventNames.StoreCommunicationBannerLoaded;
  isExpanded: boolean;
  storeId: Id;
}

export interface ConvertedAdSale {
  event: EventNames.ConvertedAdSale;
}

interface TimedKioskInactivity {
  event: EventNames.TimedKioskInactivity;
}

interface TimedKioskRefreshToLanding {
  event: EventNames.TimedKioskRefreshToLanding;
}

export interface HomeTap extends UserPreferencesType {
  event: EventNames.HomeTap;
  section: string;
  tile?: string;
}

type AddSuffix<T extends string, Suffix extends string> = `${T}${Suffix}`;
export interface LinkBankAccountEvent {
  email: string;
  event:
    | EventNames.LinkBankAccountFailed
    | EventNames.LinkBankAccountTap
    | EventNames.LinkedFirstBankAccount
    | EventNames.LinkedNewBankAccount
    | EventNames.UnlinkedLastBankAccount;
  isJanePay?: boolean;
  source?:
    | CalloutVariants
    | AddSuffix<CalloutVariants, ' (Pay)'>
    | AddSuffix<CalloutVariants, ' (Gold)'>;
  username: string;
}

interface LinkedAeropayBankAccount {
  event: EventNames.LinkedAeropayBankAccount;
}

export interface LoadedCheckoutPage {
  event: EventNames.LoadedCheckoutPage;
}

export interface LoadedGuestCheckoutPage {
  event: EventNames.LoadedGuestCheckoutPage;
}

export interface LoadedCartPage {
  event: EventNames.LoadedCartPage;
  isEmpty: boolean;
}

export interface LoadedHomePage {
  event: EventNames.LoadedHomePage;
}

export interface LoadedStoresPage {
  event: EventNames.LoadedStoresPage;
  map: boolean;
}

export interface LoadedLoginRegistrationPage {
  event: EventNames.LoadedLoginRegistrationPage;
}

export interface LoadedLoyaltyPoints {
  crmProvider: CrmProviderType | null;
  crmRewards: { point_value: number; reward_name: string }[] | null;
  event: EventNames.LoadedLoyaltyPoints;
  memberPoints: number | null;
  storeHasProvider: boolean;
}

export interface LoadedWriteProductReviewForm {
  event: EventNames.LoadedWriteProductReviewForm;
}

export enum NavBarTapSection {
  Account = 'Account',
  Bag = 'Bag',
  Brands = 'Brands',
  BusinessAnalytics = 'Analytics (Business)',
  BusinessBrands = 'Brands (Business)',
  BusinessCatalog = 'Catalog (Business)',
  BusinessDispensaries = 'Dispensaries (Business)',
  BusinessEcomm = 'Ecommerce (Business)',
  BusinessIntegrations = 'Integrations (Business)',
  BusinessKiosk = 'Kiosk (Business)',
  BusinessMedia = 'Media (Business)',
  BusinessOverview = 'Overview (Business)',
  BusinessPayments = 'Payments (Business)',
  BusinessPos = 'Point of Sale (Business)',
  DownloadApp = 'Download App',
  JaneGold = 'Jane Gold',
  Login = 'Login',
  Logo = 'Logo',
  Products = 'Products',
  Stores = 'Stores',
  Strains = 'Strains',
}

export interface NavBarTap extends UserPreferencesType {
  event: EventNames.NavBarTap;
  navBarTap: NavBarTapSection;
  source: string;
}

export interface OpenedAccordionItem {
  event: EventNames.OpenedAccordionItem;
  itemName: string;
}

export type ProductReview = { comment?: string; rating: number };

export interface ReviewedProduct {
  activities: string[];
  event: EventNames.ReviewedProduct;
  feelings: string[];
  review: ProductReview;
}

export type SSOChannel = 'google' | 'facebook' | 'cognito' | 'apple';

interface Registered {
  event: EventNames.Registered;
  partnerChannel: AppMode;
  partnerId?: Id;
  partnerName?: string;
  ssoChannel?: SSOChannel;
}

export interface LoggedIn {
  event: EventNames.LoggedIn;
  source: string;
  ssoChannel?: SSOChannel;
}

export interface SharedSearchProperties
  extends Omit<AlgoliaFilterProperties, 'event'> {
  aggregateRatingMin?: number;
  filterCount: number;
  filters: string[];
  hits: number;
  menuUrl?: string;
  priceRange?: string;
  query?: string;
  sort?: FilterSortId;
  typeahead?: boolean;
}

export interface SearchedProducts
  extends SharedSearchProperties,
    AlgoliaFilterProperties {
  cityState?: string;
  event: EventNames.SearchedProducts;
}

export interface SearchedMenuProducts
  extends SharedSearchProperties,
    AlgoliaFilterProperties {
  event: EventNames.SearchedMenuProducts;
  storeId: string;
  storeName: string;
  view: 'grid' | 'list';
}

export interface SearchedDispensaries
  extends SharedSearchProperties,
    AlgoliaFilterProperties {
  cityState?: string;
  event: EventNames.SearchedDispensaries;
}

interface StartedKioskOrder {
  event: EventNames.StartedKioskOrder;
}

interface StoreSpecialTap {
  event: EventNames.StoreSpecialTap;
  source: string;
  specialId: Id;
  specialRankOnList: number;
  specialType: string;
  storeId: Id;
  storeName: string;
}

interface SubmittedCurbsideForm {
  cartId?: Id;
  customerName: string;
  event: EventNames.SubmittedCurbsideForm;
  formOrigin: string;
  orderNotFound: boolean;
  storeId: Id;
}

export interface BrandSpecialsType {
  brandDiscountDetails?: number;
  brandDiscountId?: Id | null;
  brandDiscountType?: string;
  brandId?: Id | null;
  brandName?: string | null;
  hasBrandDiscount?: boolean | null;
}

export interface StoreSpecialsType {
  hasSpecial?: boolean;
  specialIds?: Id[] | number[] | null;
}

export interface ProductProperties {
  hasMultipleWeights?: boolean;
  productAggregateRating?: number | null;
  productBrand?: string | null;
  productBrandSubtype?: string | null;
  productId: Id | null;
  productKind: ProductKind;
  productLineage?: ProductLineage | null;
  productName: string;
  productPercentCBD?: number | string | null;
  productPercentTHC?: number | string | null;
  productReviewCount?: number | null;
  productRootSubtype?: string | null;
  storeCity?: string;
  storeState?: string;
}

export interface ProductListingTap
  extends BrandSpecialsType,
    ProductProperties,
    StoreSpecialsType,
    UserPreferencesType {
  almostGone?: AlmostGone;
  columnPosition?: number | null; // the column number within a row (or null)
  event: EventNames.ProductListingTap;
  pricePerUnit?: number | null;
  productLocation: string;
  rowPosition?: number | null; // the row number on the menu
  source?: string;
  storeCurrency?: string;
  storeId?: string;
  storeName?: string;
}

export type MenuTabTapSources =
  | null
  | '/featured'
  | '/specials'
  | '/flower'
  | '/edible'
  | '/pre-roll'
  | '/extract'
  | '/tincture'
  | '/topical'
  | '/vape'
  | '/merch'
  | '/gear'
  | '/grow'
  | '/all';

export interface MenuTabTap
  extends Pick<
    ProductListingTap,
    | 'productLocation'
    | 'storeId'
    | 'storeCurrency'
    | 'storeName'
    | 'storeCity'
    | 'storeState'
  > {
  appMode: AppMode;
  event: EventNames.MenuTabTap;
  source: MenuTabTapSources;
}

export interface AddedOrUpdatedProductInCart
  extends BrandSpecialsType,
    ProductProperties,
    AlgoliaObjectProperties,
    AlgoliaFilterProperties,
    StoreSpecialsType,
    UserPreferencesType {
  almostGone?: AlmostGone;
  cartId?: Id;
  columnPosition?: number | null;
  event: EventNames.AddedProductToCart | EventNames.UpdatedProductInCart;
  location: 'menu' | 'productDetailPage' | 'pendingCart' | 'marketplaceProduct';
  menuRowName?: string;
  pricePerUnit: number;
  priceTotal: number;
  priceUnit: PriceId;
  productLocation?: string;
  quantity: number;
  recommended?: boolean;
  rowPosition?: number | null;
  source?: string;
  specialDiscountPerUnit?: number;
  specialDiscountTotal?: number;
  specialId?: string;
  storeCurrency: string;
  storeId: string;
  storeName: string;
}

export interface ViewedProduct
  extends BrandSpecialsType,
    ProductProperties,
    StoreSpecialsType,
    UserPreferencesType {
  event: EventNames.ViewedProduct;
  pricePerUnit?: number;
  priceUnit?: string;
  productReviewsVisible?: boolean;
  recommended?: boolean;
  storeCurrency?: string;
  storeId?: string;
  storeName?: string;
}

export interface ViewedProductReviews extends ProductProperties {
  aggregateRating?: number | null;
  event: EventNames.ViewedProductReviews;
  reviewCount?: number | null;
}

export type ProductReviewInteractionTypes =
  | 'Clicked Pagination'
  | 'Filter Rating'
  | 'Filter Tags'
  | 'Sort Ratings'
  | 'Clicked Helpful'
  | 'Report';

export interface InteractedWithProductReviews extends ProductProperties {
  aggregateRating?: number | null;
  event: EventNames.InteractedWithProductReviews;
  interactionType: ProductReviewInteractionTypes;
  interactionValue: any;
  reviewCount?: number | null;
}

export interface RemovedProductFromCart {
  event: EventNames.RemovedProductFromCart;
  productBrand?: string | null;
  productCategory?: string | null;
  productId: string;
  productKind?: string | null;
  productName?: string | null;
  productPrice?: number | null;
  quantity?: number | null;
  storeCurrency?: string | null;
  variant?: string | null;
}

export interface TimedMenuLoad {
  event: EventNames.TimedMenuLoad;
  seconds: number;
  storeId: Id;
}

export interface TimedGetJaneDeviceId {
  event: EventNames.TimedGetJaneDeviceId;
}

export interface SetLocation {
  cityState?: string;
  coordinates?: Coordinates;
  event: EventNames.SetLocation;
  zipcode?: string;
}

export interface SharedProduct {
  event: EventNames.SharedProduct;
  productId: string;
  storeId?: string;
  via: ShareNetwork;
}

export interface ViewedBundleEnticement {
  event: EventNames.ViewedBundleEnticement;
  specialId: Id;
  storeId: Id;
}

export interface ViewedClickableBundleEnticement {
  event: EventNames.ViewedClickableBundleEnticement;
  specialId: Id;
  storeId: Id;
}

export interface ViewedExperiment {
  event: EventNames.ViewedExperiment;
  experimentName: string;
  variantName: string;
}

export interface ViewedSpecial {
  event: EventNames.ViewedSpecial;
  isGold: boolean;
}

export type TrackedEvent =
  | AbandonedCheckoutPage
  | AddedOrUpdatedProductInCart
  | AddedStrongholdPaymentSource
  | AllSpecialsTap
  | BrandDetailPageTap
  | BrandPageLoaded
  | BrandPageOpenedVideo
  | CashBackDetailPageTap
  | ChangeBankAccountSuccess
  | ChangeBankAccountTap
  | CheckoutSuccess
  | ClickedAd
  | ClickedBrandLink
  | ClickedBundleEnticementFilter
  | ClickedCart
  | ClickedCartProductCard
  | ClickedCheckout
  | ClickedContinueShopping
  | ClickedHomePageProductCard
  | ClickedMarketplaceProductCard
  | ClickedMenuProductCard
  | ClickedPayWithJanePay
  | ClickedPayWithStronghold
  | ClickedProceedWithAeropay
  | ClickedSeeAll
  | ClickedSegmentedMobileFilterTab
  | ClickedSegmentedMobileFilterToggle
  | ClickedShowMore
  | ClickedStoreInfo
  | ClickedStoreNearYou
  | ConvertedAdSale
  | DispensaryTap
  | FetchedAdCandidatesError
  | HomeTap
  | InteractedWithProductReviews
  | LinkBankAccountEvent
  | LinkedAeropayBankAccount
  | LoadedCartPage
  | LoadedCheckoutPage
  | LoadedGuestCheckoutPage
  | LoadedHomePage
  | LoadedLoginRegistrationPage
  | LoadedLoyaltyPoints
  | LoadedStoresPage
  | LoadedWriteProductReviewForm
  | LoggedIn
  | MenuTabTap
  | NavBarTap
  | OpenedAccordionItem
  | ProductListingTap
  | Registered
  | RemovedProductFromCart
  | ReviewedProduct
  | SearchedMenuProducts
  | SearchedProducts
  | SearchedDispensaries
  | SetLocation
  | SharedProduct
  | StartedKioskOrder
  | StoreCommunicationBannerLoaded
  | StoreSpecialTap
  | SubmittedCurbsideForm
  | TimedGetJaneDeviceId
  | TimedKioskInactivity
  | TimedKioskRefreshToLanding
  | TimedMenuLoad
  | ViewedBundleEnticement
  | ViewedClickableBundleEnticement
  | ViewedExperiment
  | ViewedProduct
  | ViewedProductReviews
  | ViewedSpecial;
