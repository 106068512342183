import styled from '@emotion/styled';
import omit from 'lodash/omit';

import {
  Button,
  CheckboxField,
  Flex,
  Modal,
  SwitchField,
  Typography,
} from '@jane/shared/reefer';
import type { ButtonVariant } from '@jane/shared/reefer';

import { TimeSlotSelector } from '../timeSlotSelector/timeSlotSelector';
import {
  ALL_DAY_SCHEDULE,
  ORDERED_DAYS,
  WEEKDAY_TO_WEEKDAY_MIN,
} from '../util/constants';
import type {
  DailySchedule,
  ManualScheduleForDays,
  Weekday,
} from '../util/schedulers.types';

const TimeSlotSelectorWrapper = styled.div(({ theme }) => ({
  background: theme.colors.grays.ultralight,
  padding: '24px',
}));

const FULL_SCHEDULE = ORDERED_DAYS.reduce(
  (pV, cV) => ({ ...pV, [cV]: ALL_DAY_SCHEDULE }),
  {} as Record<Weekday, { endTime: string; startTime: string }>
);

export interface DailyScheduleSelectProps {
  /** Optionally sets the color variant for buttons */
  buttonColor?: ButtonVariant;

  /** Optional method to clear errors in the TimeSlotSelector */
  clearError?: () => void;

  /** Days of the week for which manual scheduling is enabled */
  daysWithScheduling: ManualScheduleForDays;

  /** Optionally pass a default end time, defaults to 11:59PM */
  defaultEndTime?: string;

  /** Optionally pass a default start time, defaults to 12:00AM */
  defaultStartTime?: string;

  /** Method called to update the schedule */
  onChangeSchedule: (...event: any[]) => void;

  /** Start and end times for days of the week in which manual scheduling is enabled */
  schedule: DailySchedule;

  /** Method called to update the daysWithScheduling prop */
  setDaysWithScheduling: (...event: any[]) => void;

  /** Optional method to set errors in the TimeSlotSelector */
  setError?: (message: string) => void;

  /** Text description to accompany time slots */
  timeSlotInfo?: string;
}

export const DailyScheduleSelect = ({
  buttonColor = 'tertiary',
  clearError,
  daysWithScheduling,
  defaultEndTime = '11:59 PM',
  defaultStartTime = '12:00 AM',
  onChangeSchedule,
  schedule,
  setError,
  setDaysWithScheduling,
  timeSlotInfo,
}: DailyScheduleSelectProps) => {
  return (
    <>
      <Flex gap={16} pb={24} pt={16}>
        <Button
          data-testid="select-all"
          variant={buttonColor}
          label="Select all"
          onClick={() => {
            const updatedSchedule = { ...FULL_SCHEDULE, ...schedule };
            onChangeSchedule(updatedSchedule);
          }}
        />
        <Button
          data-testid="select-none"
          variant={buttonColor}
          label="Deselect all"
          onClick={() => onChangeSchedule({})}
        />
      </Flex>
      {ORDERED_DAYS.map((day, idx) => (
        <div key={day}>
          <ScheduleDayRow
            day={day}
            daysWithScheduling={daysWithScheduling}
            defaultEndTime={defaultEndTime}
            defaultStartTime={defaultStartTime}
            onChangeSchedule={onChangeSchedule}
            schedule={schedule}
            setDaysWithScheduling={setDaysWithScheduling}
            setError={setError}
            clearError={clearError}
            timeSlotInfo={timeSlotInfo}
          />
          {idx < ORDERED_DAYS.length - 1 && (
            <Modal.ContentDivider color="grays-light" padding={false} />
          )}
        </div>
      ))}
    </>
  );
};

const ScheduleDayRow = ({
  day,
  daysWithScheduling,
  defaultEndTime,
  defaultStartTime,
  onChangeSchedule,
  schedule,
  setDaysWithScheduling,
  setError,
  clearError,
  timeSlotInfo,
}: { day: Weekday } & DailyScheduleSelectProps) => {
  const isDaySelected = day in schedule;

  const dayMin = WEEKDAY_TO_WEEKDAY_MIN[day];
  const enableDailySchedule = daysWithScheduling[dayMin];
  const setDayWithSchedule = (enabled: boolean) =>
    setDaysWithScheduling({ ...daysWithScheduling, [dayMin]: enabled });

  const onChangeDaySchedule = (newSchedule: {
    endTime: string;
    startTime: string;
  }) => {
    onChangeSchedule({ ...schedule, [day]: newSchedule });
  };

  return (
    <>
      <Flex justifyContent="space-between" mt={24}>
        <CheckboxField
          label={day}
          name={day}
          mb={20}
          checked={isDaySelected}
          onChange={(checked) => {
            if (checked) {
              onChangeSchedule({
                ...schedule,
                [day]: { endTime: defaultEndTime, startTime: defaultStartTime },
              });
            } else {
              onChangeSchedule(omit(schedule, day));
            }
          }}
        />
        {isDaySelected && (
          <SwitchField
            key={day}
            checked={enableDailySchedule}
            data-testid={`enableDailySchedule-${day}`}
            label="Enable daily schedule"
            name={`enableDailySchedule-${day}`}
            onChange={setDayWithSchedule}
          />
        )}
      </Flex>
      {enableDailySchedule && isDaySelected && (
        <TimeSlotSelectorWrapper>
          <Flex flexDirection="column" gap={24}>
            <TimeSlotSelector
              timeframe={day}
              startTime={schedule[day].startTime}
              endTime={schedule[day].endTime}
              onChangeTimeframeSchedule={onChangeDaySchedule}
              setError={setError}
              clearError={clearError}
            />
            {!!timeSlotInfo && (
              <Typography color="grays-mid">{timeSlotInfo}</Typography>
            )}
          </Flex>
        </TimeSlotSelectorWrapper>
      )}
    </>
  );
};
