import type { BooleanFieldProps } from '@jane/shared/reefer';
import { SwitchField } from '@jane/shared/reefer';

import type { FormFieldProps } from '../formField.types';
import { FormFieldWrapper } from '../formFieldWrapper/formFieldWrapper';

export interface FormSwitchFieldProps
  extends Omit<BooleanFieldProps, 'children'>,
    Pick<FormFieldProps, 'required' | 'shouldUnregister' | 'validate'> {}

/**
 * Similar to checkbox fields, switch fields allow users to choose an option, or not.
 *
 * Use this component *within forms* for inputs of `type`:
 * `checkbox` displayed as a switch.
 *
 * _Field validations supported:_ `required`, `validate`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT OUTSIDE FORMS**
 *
 * *For a similar component for use outside forms,
 * see [`SwitchField`](/story/components-forms-fields-swtichfield--default).*
 */
export function FormSwitchField({
  autoFocus = false,
  defaultChecked = false,
  disabled = false,
  helperText,
  label,
  labelHidden = false,
  name,
  onBlur,
  onChange,
  onClick,
  required = false,
  shouldUnregister,
  'data-testid': testId,
  validate,
  width = 'auto',
  ...props
}: FormSwitchFieldProps) {
  return (
    <FormFieldWrapper
      defaultValue={defaultChecked}
      name={name}
      render={({ children, field }) => (
        <SwitchField
          autoFocus={autoFocus}
          checked={field.value}
          defaultChecked={defaultChecked}
          disabled={disabled}
          helperText={helperText}
          label={label}
          labelHidden={labelHidden}
          name={field.name}
          onBlur={(value) => {
            onBlur && onBlur(value);
            field.onBlur();
          }}
          onChange={(value) => {
            onChange && onChange(value);
            field.onChange(value);
          }}
          onClick={onClick}
          onUpdate={onChange}
          ref={field.ref}
          data-testid={testId}
          width={width}
          {...props}
        >
          {children}
        </SwitchField>
      )}
      required={required}
      shouldUnregister={shouldUnregister}
      validate={validate}
    />
  );
}
