import { useState } from 'react';

export const useValidateEmails = () => {
  const [error, setError] = useState('');

  const validateEmails = (emails: string[]) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isEmail = emails.every((email) => emailRegex.test(email));
    const isJaneEmail = emails.every((email) =>
      email.toLowerCase().endsWith('@iheartjane.com')
    );

    if (!isEmail) {
      setError('Input must be a valid email.');
      return false;
    }

    if (!isJaneEmail) {
      setError('Email must be an iheartjane address.');
      return false;
    }

    return true;
  };

  return {
    error,
    setError,
    validateEmails,
  };
};
