import type {
  SortingState,
  TableOptions,
  VisibilityState,
} from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';

import { useColumnVisibilityInStorage } from '@jane/ad-manager/hooks';

import type { ApiCampaign } from './getColumns';
import { getColumns } from './getColumns';

const DEFAULT_COLUMN_VISIBILITY_STATE: VisibilityState = {
  endDate: true,
  flightsBudget: true,
  pacing: false,
  startDate: true,
  states: true,
  targetBudget: true,
};

type Props = Omit<TableOptions<ApiCampaign>, 'getCoreRowModel' | 'columns'> & {
  data: ApiCampaign[];
};

export const useCampaignsTable = ({ data, ...props }: Props) => {
  const [sorting, setSorting] = useState<SortingState>([
    { desc: true, id: 'id' },
  ]);

  const [columnVisibility, setColumnVisibility] = useColumnVisibilityInStorage(
    'campaigns',
    DEFAULT_COLUMN_VISIBILITY_STATE
  );

  const columns = getColumns();

  return useReactTable({
    columns,
    onColumnVisibilityChange: setColumnVisibility,
    state: { columnVisibility, sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    data,
    ...props,
  });
};
