import { createContext, useState } from 'react';

import { Lightbox } from '../lightbox/lightbox';
import type { CarouselProps } from './carousel.types';
import { CarouselSwiper } from './carouselSwiper/carouselSwiper';

/**
 * CarouselContext to pass props to Carousel subcomponents
 */

export const CarouselContext = createContext<
  Pick<
    CarouselProps,
    | 'desktopHeight'
    | 'desktopWidth'
    | 'mobileHeight'
    | 'mobileWidth'
    | 'onVideoClick'
    | 'responsive'
    | 'stockPhoto'
  >
>({
  desktopHeight: '',
  desktopWidth: '',
  mobileHeight: '',
  mobileWidth: '',
  onVideoClick: () => {
    /* */
  },
  responsive: false,
  stockPhoto: false,
});

export const Carousel = ({
  appId,
  desktopHeight = '566px',
  desktopWidth = '566px',
  responsive = false,
  items,
  mobileHeight = '375px',
  mobileWidth = '100vw',
  onVideoClick,
  forceMobile,
  showThumbnails = true,
  stockPhoto = false,
  variant = 'product',
  withLightbox = true,
}: CarouselProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const updateIndex = (newIndex: number) => {
    setActiveIndex(newIndex);
  };

  const openLightboxHandler = () => {
    if (withLightbox) {
      setIsOpen(true);
    }
  };

  return (
    <CarouselContext.Provider
      value={{
        desktopHeight,
        desktopWidth,
        mobileHeight,
        mobileWidth,
        onVideoClick,
        responsive,
        stockPhoto,
      }}
    >
      {!isOpen && (
        <CarouselSwiper
          items={items}
          activeIndex={activeIndex}
          updateIndex={updateIndex}
          onClick={openLightboxHandler}
          showThumbnails={showThumbnails}
          forceMobile={forceMobile}
          variant={variant}
          withLightbox={withLightbox}
        />
      )}
      {withLightbox && (
        <Lightbox
          isOpen={isOpen}
          onRequestClose={() => setIsOpen(false)}
          appId={appId}
        >
          {isOpen && (
            <CarouselSwiper
              items={items}
              activeIndex={activeIndex}
              updateIndex={updateIndex}
              isLightbox={true}
              showThumbnails={showThumbnails}
              variant={variant}
              withLightbox={withLightbox}
            />
          )}
        </Lightbox>
      )}
    </CarouselContext.Provider>
  );
};
