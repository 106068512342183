import pick from 'lodash/pick';
import { useCallback, useContext } from 'react';

import { trackBrandEvent } from '@jane/brands/util';
import { ProductsPageParamsContext } from '@jane/catalog-cms/providers';
import type { ProductFilters } from '@jane/catalog-cms/types';

const defaultFilters = {
  status: ['active'] as string[],
} as const;

const ARRAY_KEYS = ['category', 'categoryLabel', 'status'] as const;
const STRING_KEYS = ['searchTerm', 'sortField', 'reverse'] as const;
const DATETIME_KEYS = ['createdTime', 'updatedTime'] as const;
const FILTER_KEYS = [...ARRAY_KEYS, ...STRING_KEYS, ...DATETIME_KEYS];

/**
 * Returns an object with the product filters and some useful functions for
 * modifying the filters. The `filters` property is a ProductFilters object with
 * current filter values parsed from the URL search string parameters. The
 * `updateFilters` function should be used to modify the value of an individual
 * filter. The `clearFilters` function should be used to clear all product
 * filters.
 */

export const useProductFilters = () => {
  const context = useContext(ProductsPageParamsContext);

  if (context === null) {
    throw new Error(
      'useProductFilters must be a child of ProductsPageParamsProvider.'
    );
  }

  const {
    productsPageParams,
    updateProductsPageParams,
    clearProductsPageParams,
  } = context;

  const updateFilters = useCallback(
    (filterKey: keyof ProductFilters, filterValue: string[] | string) => {
      // Mixpanel tracking of filter updates
      if (filterKey === 'status') {
        trackBrandEvent({
          eventName: 'Filter Products by Status',
          filter_value: filterValue as string[],
          is_multi_value: filterValue.length > 1,
        });
      }

      if (filterKey === 'updatedTime') {
        trackBrandEvent({
          eventName: 'Filter Products by Updated Time',
          filter_value: filterValue as string[],
        });
      }

      updateProductsPageParams(filterKey, filterValue);
    },
    [updateProductsPageParams]
  );

  const clearFilters = useCallback(() => {
    clearProductsPageParams(FILTER_KEYS);
  }, [clearProductsPageParams]);

  const sortBy = useCallback(
    (field: string) => {
      const sortField = productsPageParams['sortField'];
      const reverse = productsPageParams['reverse'];

      // Mixpanel tracking of updates to sort order
      if (field === 'notice_kind') {
        const sortOrder = reverse === 'true' ? 'desc' : 'asc';

        trackBrandEvent({
          eventName: 'Sort Products by Status',
          sort_order: sortOrder,
        });
      }

      if (sortField === field && !reverse) {
        updateProductsPageParams('sortField', field);
        updateProductsPageParams('reverse', 'true');
      } else if (sortField === field && reverse === 'true') {
        clearProductsPageParams(['reverse']);
      } else if (sortField !== field) {
        clearProductsPageParams(['reverse']);
        updateProductsPageParams('sortField', field);
      }
    },
    [clearProductsPageParams, productsPageParams, updateProductsPageParams]
  );

  const filters = pick(productsPageParams, FILTER_KEYS);

  if (filters.status === undefined) {
    filters.status = [...defaultFilters.status];
  }

  for (const key of FILTER_KEYS) {
    if (filters[key]?.length === 0) {
      delete filters[key];
    }
  }

  return { filters, updateFilters, clearFilters, sortBy };
};
