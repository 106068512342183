import { z } from 'zod';

export const BrandSchema = z
  .object({
    id: z.number(),
    logo_url: z.string().nullable(),
    name: z.string(),
    normalized_name: z.string().nullable(),
  })
  .transform((data) => ({
    id: z.coerce.string().parse(data.id),
    logoUrl: data.logo_url,
    name: data.name,
  }));

export const BrandsResponseSchema = z.object({
  product_brands: z.array(BrandSchema),
});

export type Brand = z.infer<typeof BrandSchema>;

export interface FetchedBrand {
  id: number;
  logo_url: string | null;
  name: string;
  normalized_name: string;
}

export interface FetchBrandsParams {
  limit?: number;
  offset?: number;
  productBrandIds?: string[];
  query?: string;
}

export interface UseInfiniteBrandsParams extends FetchBrandsParams {
  enabled: boolean;
  perPage: number;
}
