import styled from '@emotion/styled';
import difference from 'lodash/difference';
import pluralise from 'pluralise';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import {
  Button,
  ButtonToggle,
  Flex,
  Loading,
  Modal,
} from '@jane/shared/reefer';

import { idConversions } from '../util/idConversions';
import { NoStoresFound } from './NoStoresFound';
import { SelectHeader } from './SelectHeader';
import { StoreRow } from './StoreRow';
import { StoreSelectModalFilters } from './StoreSelectModalFilters';
import { useFilteredStores } from './useFilteredStores';

const ButtonToggleStylesOverrideWrapper = styled.div(({ theme }) => ({
  '& > div': {
    backgroundColor: theme.colors.grays.ultralight,
    borderRadius: theme.borderRadius.sm,

    '> button': {
      borderRadius: theme.borderRadius.sm,
      paddingLeft: '24px',
      paddingRight: '24px',
      marginRight: 0,
    },
  },
}));

export type ExtraColumnOptions = {
  label: string;
  render: (store: Store) => string | null;
};

export const enum SUBMIT_BUTTON_VARIANTS {
  save,
  saveTo,
  select,
}

const SUBMIT_BUTTON_MAPS: { [k in SUBMIT_BUTTON_VARIANTS]: string } = {
  [SUBMIT_BUTTON_VARIANTS.save]: 'Save',
  [SUBMIT_BUTTON_VARIANTS.saveTo]: 'Save to',
  [SUBMIT_BUTTON_VARIANTS.select]: 'Select',
};

export interface Store {
  address: string;
  address2?: string | null;
  cart_limit_policy?: { id: string | number; name: string | null } | null;
  city?: string | null;
  id: string | number;
  name: string;
  recreational: boolean;
  state?: string | null;
  zip?: string | undefined;
}

enum FilterNames {
  StoreState = 'Store State',
  StoreType = 'Store Type',
}

export type StoreSearchMode = 'storeId' | 'storeName';
export interface StoreSelectModalProps {
  closeModal: () => void;
  disableSubmit?: boolean;
  extraColumn?: ExtraColumnOptions;
  fetchNextPage?: () => void;
  hasNextPage?: boolean;
  initialSearchFilter?: string;
  isFetchingStores: boolean;
  isLoadingStores: boolean;
  onFilterChange?: (filterName: FilterNames, value?: string) => void;
  onSearchCallback?: (query: string, successful?: boolean) => void;
  onSearchedStoreIdsChange?: (ids: string[]) => void;
  onSubmit: (formDataEnabledIds: number[], isDirty: boolean) => void;
  onToggleAll?: (selected: boolean) => void;
  onToggleStore?: (storeId: string, selected: boolean) => void;
  searchFilterPlaceholder?: string;
  searchedStoreIds?: string[];
  selectedStoreIds?: string[];
  showId?: boolean;
  storesData: Store[];
  submitButtonType?: SUBMIT_BUTTON_VARIANTS;
  subtitle?: string;
}

interface TableProps {
  allSelected: boolean;
  extraColumn?: ExtraColumnOptions;
  fetchMoreRef: (node?: Element | null | undefined) => void;
  handleToggleAll: (selected: boolean) => void;
  hasNextPage?: boolean;
  isSelectAllIndeterminate: boolean;
  renderStoreRow: (store: Store) => JSX.Element;
  stores?: Store[];
}

const StoreTable = ({
  allSelected,
  isSelectAllIndeterminate,
  handleToggleAll,
  extraColumn,
  stores,
  renderStoreRow,
  fetchMoreRef,
  hasNextPage,
}: TableProps) => {
  return (
    <Flex flexDirection="column">
      <Flex mb={24}>
        <SelectHeader
          selectAllChecked={allSelected}
          selectAllIndeterminate={isSelectAllIndeterminate}
          onChangeSelectAll={handleToggleAll}
          extraColumn={extraColumn}
        />
      </Flex>
      <Modal.ContentDivider padding={false} />
      {stores?.map((store) => renderStoreRow(store))}
      {hasNextPage && <TableFetching fetchMoreRef={fetchMoreRef} />}
    </Flex>
  );
};

const StyledLoading = styled(Loading)({
  position: 'inherit',
  transform: 'inherit',
  margin: 'auto',
});

const StyledLoadingWrapper = styled.div(() => ({
  height: '74px',
  display: 'flex',
}));

const TableFetching = ({
  fetchMoreRef,
}: {
  fetchMoreRef: (node?: Element | null | undefined) => void;
}) => (
  <StyledLoadingWrapper ref={fetchMoreRef}>
    <StyledLoading />
  </StyledLoadingWrapper>
);

export const StoreSelectModal = ({
  closeModal,
  disableSubmit = false,
  extraColumn,
  fetchNextPage,
  hasNextPage,
  onFilterChange,
  onSubmit,
  onSearchCallback,
  onToggleAll,
  onToggleStore,
  searchFilterPlaceholder = 'Search name, city or id',
  selectedStoreIds = [],
  showId = false,
  submitButtonType = SUBMIT_BUTTON_VARIANTS.saveTo,
  subtitle,
  storesData,
  initialSearchFilter = '',
  isFetchingStores,
  isLoadingStores,
  onSearchedStoreIdsChange,
  searchedStoreIds,
}: StoreSelectModalProps) => {
  const [searchFilter, setSearchFilter] = useState(initialSearchFilter);
  const [typeFilter, setTypeFilter] = useState('');
  const [stateFilter, setStateFilter] = useState('');
  const [searchMode, setSearchMode] = useState<StoreSearchMode>(
    searchedStoreIds && searchedStoreIds?.length > 0 ? 'storeId' : 'storeName'
  );
  const enableSearchByStoreId = !!onSearchedStoreIdsChange;
  const prevSearchFilter = useRef('');
  const handleSearchChange = (value: string) => {
    setSearchFilter((prev) => {
      prevSearchFilter.current = prev;
      return value;
    });
  };

  const { ref: fetchMoreRef } = useInView({
    onChange: (inView) => {
      if (inView && fetchNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const handleStateChange = (value: string) => {
    setStateFilter(value);
    onFilterChange && onFilterChange(FilterNames.StoreState, value);
  };

  const handleTypeChange = (value: string) => {
    setTypeFilter(value);
    onFilterChange && onFilterChange(FilterNames.StoreType, value);
  };

  type StoreSelectionsRecord = Record<number | string, boolean>;

  const [storeSelections, setStoreSelections] = useState<StoreSelectionsRecord>(
    selectedStoreIds.reduce<Record<string, boolean>>((acc, curr) => {
      acc[`${curr}`] = true;
      return acc;
    }, {})
  );

  const stores = useMemo(
    () =>
      storesData?.map(({ id, ...restStore }) => ({
        id: `${id}`,
        ...restStore,
      })),
    [storesData]
  );

  const isStoreSelected = useCallback(
    (store: Store) =>
      storeSelections[idConversions.asString(store.id)] === true,
    [storeSelections]
  );

  const numStoresSelected = useMemo(
    () => Object.values(storeSelections).filter((val) => val).length,
    [storeSelections]
  );

  const submitButtonTypeLabel = SUBMIT_BUTTON_MAPS[submitButtonType];

  const submitLabel =
    submitButtonTypeLabel === 'Save'
      ? 'Save'
      : `${submitButtonTypeLabel} ${numStoresSelected} ${pluralise(
          numStoresSelected,
          'store'
        )}`;

  const filteredStores = useFilteredStores({
    stores,
    searchFilter,
    typeFilter,
    stateFilter,
  });

  const lastFilteredStoreId = filteredStores[filteredStores.length - 1]?.id;

  const formDataEnabledIds = useMemo(
    () =>
      Object.entries(storeSelections)
        .filter(([_, selected]) => selected)
        .map(([id]) => idConversions.asNumber(id)),
    [storeSelections]
  );

  const isDirty = useMemo(() => {
    const hasDifferentFormEnabledIds =
      difference(
        formDataEnabledIds,
        selectedStoreIds.map((id) => idConversions.asNumber(id))
      ).length > 0;

    const hasDifferentSelectedStoreIds =
      difference(
        selectedStoreIds.map((id) => idConversions.asNumber(id)),
        formDataEnabledIds
      ).length > 0;

    return hasDifferentFormEnabledIds || hasDifferentSelectedStoreIds;
  }, [selectedStoreIds, formDataEnabledIds]);

  const allSelected = useMemo(
    () =>
      filteredStores.every(
        ({ id }) => !!storeSelections[idConversions.asNumber(id)]
      ),
    [filteredStores, storeSelections]
  );

  const someSelected =
    !allSelected && Object.values(storeSelections).filter(Boolean).length !== 0;

  const isSelectAllIndeterminate = filteredStores.length === 0 || someSelected;

  const handleSubmit = () => {
    onSubmit(formDataEnabledIds, isDirty);
  };

  const isTogglingAll = useRef(false);

  const handleToggleAll = (selected: boolean) => {
    const update: { [key: string]: boolean } = { ...storeSelections };
    filteredStores.forEach((store) => {
      update[store.id] = selected;
    });

    isTogglingAll.current = true;
    setStoreSelections(update);
    onToggleAll && onToggleAll(selected);
  };

  useEffect(() => {
    isTogglingAll.current = false;
  }, [storeSelections]);

  useEffect(() => {
    const shouldPreSelectStoresById =
      enableSearchByStoreId && searchedStoreIds && searchedStoreIds.length > 0;
    if (shouldPreSelectStoresById) {
      // Pre-select stores when using By Store IDs search
      setStoreSelections(
        searchedStoreIds.reduce<Record<string, boolean>>((acc, curr) => {
          acc[curr] = true;
          return acc;
        }, {})
      );
    }
  }, [enableSearchByStoreId, searchedStoreIds]);

  useEffect(() => {
    if (searchFilter !== prevSearchFilter.current && onSearchCallback) {
      onSearchCallback(searchFilter, filteredStores.length > 0);
    }
  }, [searchFilter, filteredStores, onSearchCallback]);

  const handleSearchModeChange = (searchMode: StoreSearchMode) => {
    // clear previous search filters
    setSearchFilter('');
    setTypeFilter('');
    setStateFilter('');
    onSearchedStoreIdsChange && onSearchedStoreIdsChange([]);
    setStoreSelections({});
    setSearchMode(searchMode);
  };

  const handleIdsChange = useCallback(
    (ids: string) => {
      if (onSearchedStoreIdsChange) {
        const parsedIds = idConversions.fromStrings(ids);
        onSearchedStoreIdsChange(parsedIds.filter(Boolean));
      }
    },
    [onSearchedStoreIdsChange]
  );

  const renderStoreRow = (store: Store) => {
    const storeId = idConversions.asNumber(store.id);
    const isChecked = isStoreSelected(store);

    const isHidden = !filteredStores.find(({ id }) => id === store.id);

    const changeHandler = (checked: boolean) => {
      if (isTogglingAll.current) return;

      setStoreSelections((currentSelections) => ({
        ...currentSelections,
        [storeId]: checked,
      }));

      onToggleStore && onToggleStore(storeId.toString(), checked);
    };

    const isLast = store.id === lastFilteredStoreId;

    return (
      <span key={`${store.name}-${store.id}`}>
        <StoreRow
          isHidden={isHidden}
          isChecked={isChecked}
          store={store}
          showId={showId}
          extraColumn={extraColumn}
          handleChange={changeHandler}
        />
        {!isHidden && !isLast && <Modal.ContentDivider padding={false} />}
      </span>
    );
  };

  const renderTableContent = () => {
    if (isLoadingStores) {
      return <StyledLoading />;
    }
    if (filteredStores.length === 0) {
      return <NoStoresFound searchMode={searchMode} />;
    }
    return (
      <StoreTable
        allSelected={allSelected}
        isSelectAllIndeterminate={isSelectAllIndeterminate}
        extraColumn={extraColumn}
        stores={filteredStores}
        renderStoreRow={renderStoreRow}
        fetchMoreRef={fetchMoreRef}
        handleToggleAll={handleToggleAll}
        hasNextPage={hasNextPage}
      />
    );
  };

  return (
    <Modal
      appId="root"
      onRequestClose={closeModal}
      contentLabel="select stores"
      open
    >
      <>
        <Modal.Header
          title="Select stores"
          subtitle={subtitle}
          actions={
            <Button
              variant="primary"
              data-testid="store-select-submit"
              label={submitLabel}
              ml={16}
              onClick={handleSubmit}
              disabled={disableSubmit}
            />
          }
        />
        <Modal.Content>
          <Flex flexDirection="column" gap={24} height={'100%'}>
            {enableSearchByStoreId && (
              <Flex gap={16} alignItems="center">
                <ButtonToggleStylesOverrideWrapper>
                  <ButtonToggle
                    value={searchMode}
                    onChange={(value) => {
                      handleSearchModeChange(value as StoreSearchMode);
                    }}
                    full={false}
                  >
                    <ButtonToggle.Button
                      label="Select stores"
                      value={'storeName'}
                    />
                    <ButtonToggle.Button
                      label="By Store IDs"
                      value={'storeId'}
                    />
                  </ButtonToggle>
                </ButtonToggleStylesOverrideWrapper>
              </Flex>
            )}
            <StoreSelectModalFilters
              searchMode={enableSearchByStoreId ? searchMode : 'storeName'}
              onIdsChange={handleIdsChange}
              typeFilter={typeFilter}
              stateFilter={stateFilter}
              searchFilter={searchFilter}
              searchFilterPlaceholder={searchFilterPlaceholder}
              setTypeFilter={handleTypeChange}
              setStateFilter={handleStateChange}
              setSearchFilter={handleSearchChange}
              filteredStores={filteredStores}
              isFetchingStores={isFetchingStores}
              disablePadding
            />
            {renderTableContent()}
          </Flex>
        </Modal.Content>
      </>
    </Modal>
  );
};
