import styled from '@emotion/styled';
import type { SmartSortProduct } from '@iheartjane/dm-sdk';
import { useEffect, useRef, useState } from 'react';

import type { AlgoliaProduct, JaneSearchState } from '@jane/search/types';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import type {
  AppMode,
  Brand,
  DeepReadonly,
  Id,
  MenuProduct,
  PendingCartProduct,
  PriceId,
  Product,
  ReservationCartProduct,
  RouteAppMode,
  Store,
  StoreSpecial,
  UserLocation,
  _DeepReadonlyArray,
} from '@jane/shared/models';
import { Typography } from '@jane/shared/reefer';
import type {
  Breadcrumb,
  EcommPaths,
  MenuProduct as ZMenuProduct,
} from '@jane/shared/types';
import {
  type PartnerHostedConfig,
  getMaxCartQuantity,
} from '@jane/shared/util';

import type {
  AddData,
  DeleteData,
  ProductCardContextProps,
} from '../productCardProvider';
import { ProductCardProvider } from '../productCardProvider';
import { SponsoredTag } from '../sponsoredTag/sponsoredTag';
import { ListViewProductCard } from './listView/listViewProductCard';
import { MenuProductCardContainer } from './menuProductCardContainer';
import { ItemContainer, sortByPriceId } from './utils/productCardHelper';

export const findCartProductForMenuProduct = (
  cartProducts: PendingCartProduct[],
  menuProductId: Id,
  storeId: Id,
  cartStoreId?: Id
): ReservationCartProduct[] => {
  const cartProductForMenuProduct = cartProducts.filter(
    (cp) => cp.id === menuProductId && cartStoreId === storeId
  ) as unknown as ReservationCartProduct[];

  return cartProductForMenuProduct;
};

const MenuItemContainer = styled.div<{ disabledState?: boolean }>(
  {
    flex: '0 0 auto',
    position: 'relative',
    width: '100%',
  },
  ({ disabledState }) => {
    return [
      {
        cursor: 'default',
        opacity: disabledState ? 0.5 : 1,
      },
    ];
  }
);

export interface LegacyMenuProductCardProps {
  appMode: AppMode;
  appliedWeightFilter: PriceId | '';
  brand?: Brand;
  breadcrumbs?: Breadcrumb[];
  carouselView?: boolean;
  cartIsOpen: boolean;
  cartProducts?: _DeepReadonlyArray<PendingCartProduct>;
  cartStoreId?: Id;
  currentSpecial?: StoreSpecial;
  customerId?: number | null;
  /** Disables any click actions (and tracking events) on the card and any sub-components */
  disableInteraction?: boolean;
  /** Makes the component transparent, to indicate a product or row is toggled off */
  disabledState?: boolean;
  fromAllSpecials?: boolean | null;
  fromSpecialId?: Id | null;
  hideActions?: boolean;
  isMenuTopRow?: boolean;
  /**
   * Boolean that determines if all the products in the smart instance
   * are sponsored
   */
  isRowSponsored?: boolean;
  itemWidth?: number | null;
  /** Determined in ProductCardWithTracking so we can track Jane Gold deals */
  janeGoldLabel?: string | null;
  listView: boolean;
  maxWeightOption?: number | null;
  onAddToCart?: (addData: AddData) => void;
  onClick?: (selectedWeight: PriceId | null) => void;
  onCloseCart?: () => void;
  onDeleteFromCart?: (deleteData: DeleteData) => void;
  onSetBreadcrumbs?: (searchState: JaneSearchState<AlgoliaProduct>) => void;
  pdpPath?: EcommPaths['menuProduct'];
  product: MenuProduct | ZMenuProduct;
  productInstance?:
    | SmartSortProduct<AlgoliaProduct>
    | SmartSortProduct<MenuProduct>;
  routeAppMode?: RouteAppMode;
  routePartnerHostedConfig: PartnerHostedConfig;
  searchState?: JaneSearchState<AlgoliaProduct>;
  setProductCardWidth?: (arg: number) => void;
  showOnlyWeights?: string[] | null;
  store: DeepReadonly<Store>;
  userLocation: UserLocation;
  weightFilter?: (
    weight: string,
    product: ZMenuProduct | AlgoliaProduct
  ) => boolean;
}

export const LegacyMenuProductCard = ({
  appMode,
  appliedWeightFilter,
  brand,
  breadcrumbs,
  carouselView,
  cartIsOpen,
  cartProducts,
  cartStoreId,
  currentSpecial,
  customerId,
  disabledState,
  disableInteraction,
  fromAllSpecials,
  hideActions,
  itemWidth,
  janeGoldLabel,
  listView,
  onClick,
  onCloseCart,
  onAddToCart,
  onDeleteFromCart,
  onSetBreadcrumbs,
  pdpPath,
  product,
  routeAppMode,
  routePartnerHostedConfig,
  searchState,
  showOnlyWeights,
  setProductCardWidth,
  store,
  fromSpecialId,
  maxWeightOption,
  userLocation,
  productInstance,
  isRowSponsored = false,
  isMenuTopRow = false,
  weightFilter,
}: LegacyMenuProductCardProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [selectedWeight, setSelectedWeight] = useState<PriceId>('each');
  const showAlmostGone = useFlag(FLAGS.almostGone);

  const inventoryCount =
    showAlmostGone && !!product && !!selectedWeight
      ? getMaxCartQuantity(product as MenuProduct, selectedWeight)
      : undefined;

  const showInventoryCount = !!inventoryCount && inventoryCount < 4;

  useEffect(() => {
    setProductCardWidth &&
      containerRef.current &&
      setProductCardWidth(
        itemWidth || containerRef.current.getBoundingClientRect().width
      );
  }, []);

  const cartProduct = sortByPriceId(
    findCartProductForMenuProduct(
      cartProducts as PendingCartProduct[],
      product.id,
      store.id,
      cartStoreId
    ),
    product
  ) as ReservationCartProduct[];

  const productCardContext: Partial<ProductCardContextProps> = {
    appMode,
    appliedWeightFilter,
    brand,
    breadcrumbs,
    carouselView,
    cartIsOpen,
    cartProduct,
    currentSpecial,
    customerId,
    disableInteraction,
    hideActions,
    janeGoldLabel,
    listView,
    onAddToCart,
    onCloseCart,
    onDeleteFromCart,
    onSetBreadcrumbs,
    pdpPath,
    product: product as Product,
    menuProduct: product as MenuProduct,
    routeAppMode,
    routePartnerHostedConfig,
    showOnlyWeights,
    searchState,
    selectedWeight,
    setSelectedWeight,
    store,
    trackListViewClick: onClick
      ? () => {
          onClick(selectedWeight);
        }
      : undefined,
    fromSpecialId,
    fromAllSpecials,
    maxWeightOption,
    userLocation,
    weightFilter,
  };

  /**
   * @todo Remove this when legacy code is deleted since product
   * instance will always be available
   */
  const shouldDisplaySponsoredTag = productInstance
    ? isMenuTopRow || isRowSponsored
      ? false
      : productInstance.isSponsored
    : false;

  if (listView) {
    return (
      <ProductCardProvider value={productCardContext}>
        <ListViewProductCard
          data-product-id={product.id}
          isAd={shouldDisplaySponsoredTag}
        />
      </ProductCardProvider>
    );
  }

  return (
    <ProductCardProvider value={productCardContext}>
      <ItemContainer
        data-testid={`product-card-container-${product.id}`}
        ref={containerRef}
      >
        <MenuItemContainer
          data-testid="product-card"
          data-product-id={product.id}
          disabledState={disabledState}
          onClick={
            onClick
              ? () => {
                  onClick(selectedWeight);
                }
              : undefined
          }
        >
          <MenuProductCardContainer />
        </MenuItemContainer>
        {showInventoryCount && (
          <Typography textAlign="center" variant="mini" mt={8}>
            Only {inventoryCount} left
          </Typography>
        )}
        <SponsoredTag hideText={!shouldDisplaySponsoredTag} mt={8} />
      </ItemContainer>
    </ProductCardProvider>
  );
};
