import debounce from 'lodash/debounce';

type MessageType =
  | 'analyticsEvent'
  | 'authEvent'
  | 'closeModal'
  | 'disableShrinkEveryResize'
  | 'enableShrinkEveryResize'
  | 'loadingEvent'
  | 'openModal'
  | 'resizeIFrame'
  | 'resizeIFrameForModal'
  | 'restoreScrollPosition'
  | 'saveScrollPosition'
  | 'scrollTo'
  | 'scrollToTop'
  | 'shrinkNextResize';

interface Args {
  messageType: MessageType;
  pathname?: string;
  payload?: any;
  setOptions?: {
    preventContainerResizing?: boolean;
    preventResizing?: boolean;
  };
}

export const postMessageToIframeParent = ({
  messageType,
  pathname,
  payload,
  setOptions,
}: Args) => {
  const path = pathname || window.location.href;
  window.parent.postMessage(
    { messageType, payload, pathname: path, setOptions },
    '*'
  );
};

export const debouncedPostMessageToIframeParent = debounce(
  ({ messageType, payload, setOptions }: Args) =>
    postMessageToIframeParent({
      messageType,
      payload,
      setOptions,
    }),
  275
);
