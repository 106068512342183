import clsx from 'clsx';

import { spacingCSSVars } from '../../utils/spacing';
import styles from './list.module.css';
import type { ListItemProps, ListProps } from './list.types';
import { ListItem } from './listItem/listItem';

/**
 * Lists are a continuous group of text or other elements. They are composed of items containing primary and supplemental actions, which are represented by icons and text.
 */

function BaseList({
  children,
  className,
  'data-testid': testId,
  id,
  label,
  style,
  ...spacingProps
}: ListProps) {
  return (
    <ul
      aria-label={label}
      className={clsx(styles.list, className)}
      id={id}
      style={{
        ...spacingCSSVars(spacingProps),
        ...style,
      }}
      data-testid={testId}
    >
      {children}
    </ul>
  );
}

export type { ListItemProps, ListProps };

export const List = Object.assign(BaseList, {
  Item: ListItem,
});
