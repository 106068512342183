import { ConfirmChangeModal } from '@jane/shared/components';

interface ConfirmDiscardWrapperProps {
  children: React.ReactNode;
  onCancel: () => void;
  onConfirm: () => void;
  open: boolean;
}

export const ConfirmDiscardWrapper = ({
  children,
  ...modalProps
}: ConfirmDiscardWrapperProps) => {
  return (
    <>
      {children}
      <ConfirmChangeModal
        {...modalProps}
        appId="parent"
        title="Discard Changes?"
        subtitle="You will lose any unsaved changes."
        confirmLabel="Discard"
        confirmButtonVariant="destructive"
      />
    </>
  );
};
