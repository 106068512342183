import { useQuery } from '@tanstack/react-query';

import { fetchEditFormProduct } from '@jane/catalog-cms/data-access';

export const editFormProductQueryKey = (
  uuid: string
): [string, string, { uuid: string }] => {
  return ['products', 'editForm', { uuid }];
};

/**
 * Hook for fetching a product with attributes tailored to the product edit form.
 */
export const useEditFormProduct = (uuid: string) => {
  return useQuery({
    queryKey: editFormProductQueryKey(uuid),
    queryFn: fetchEditFormProduct,
    enabled: !!uuid,
  });
};
