// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KWw6aBiYk3g5pPzAWfRi {\n  max-height: var(--baseForm-max-height);\n  max-width: var(--baseForm-max-width);\n  height: var(--baseForm-height);\n  width: var(--baseForm-width);\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer-hook-form/src/lib/baseForm/baseForm.module.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,oCAAoC;EACpC,8BAA8B;EAC9B,4BAA4B;AAC9B","sourcesContent":[".baseForm {\n  max-height: var(--baseForm-max-height);\n  max-width: var(--baseForm-max-width);\n  height: var(--baseForm-height);\n  width: var(--baseForm-width);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"baseForm": "KWw6aBiYk3g5pPzAWfRi"
};
export default ___CSS_LOADER_EXPORT___;
