import noop from 'lodash/noop';
import { useController } from 'react-hook-form';

import type { ActiveFilters } from '@jane/search/types';
import {
  Box,
  Button,
  DismissIcon,
  Flex,
  Typography,
} from '@jane/shared/reefer';
import { us_states } from '@jane/shared/util';

import { MultiSelectFilter } from '../../../Filters/MultiSelectFilter';
import type { AdSubmissionFormState } from '../../formState/types';

const locationOptions = us_states.map((value) => ({
  label: value.name,
  value: value.abbreviation || value.name,
}));

interface StateCapsuleButtonProps {
  label: string;
  onClick: () => unknown;
}

const StateCapsuleButton = ({ onClick, label }: StateCapsuleButtonProps) => (
  <Button
    endIcon={<DismissIcon color="inherit" />}
    label={label}
    key={label}
    onClick={onClick}
    data-testid={`remove-state-button-${label}`}
    type="button"
    variant="secondary"
  />
);

export const StateTargetsForm = () => {
  const {
    field: { onChange: onStatesChange, onBlur: onBlurStates, value: states },
  } = useController<AdSubmissionFormState, 'states'>({
    name: 'states',
  });

  const handleOnStatesChange = (val: AdSubmissionFormState['states']) => {
    // since there is no underlying states input to trigger a blur event, we need to do it
    // manually to set the field touched and trigger validation
    onBlurStates();
    onStatesChange(val);
  };

  const onClickRemoveState = (stateToRemove: string) => {
    const newStates = states.filter((state) => state !== stateToRemove);
    handleOnStatesChange(newStates);
  };

  const stateNameToActiveFilterEntry = (stateName: string): ActiveFilters => ({
    key: 'locations',
    label: stateName,
    value: stateName,
  });

  return (
    <Box>
      <Typography variant="body-bold" mb={24}>
        States to target
      </Typography>
      <MultiSelectFilter
        label="Add states"
        filterType="locations"
        options={locationOptions}
        setMultiSelectParams={(multiSelectParams) => {
          handleOnStatesChange(multiSelectParams.locations);
        }}
        typeahead={true}
        filterParams={{
          endDatePT: null,
          locations: states,
          startDatePT: null,
          types: [],
          pacing: [],
        }}
        activeFilters={states.map(stateNameToActiveFilterEntry)}
        setActiveFilters={noop}
      />
      <Flex flexWrap="wrap">
        {states.map((stateTargetValue) => (
          <Box mr={24} mt={24} key={stateTargetValue}>
            <StateCapsuleButton
              label={stateTargetValue}
              onClick={() => onClickRemoveState(stateTargetValue)}
            ></StateCapsuleButton>
          </Box>
        ))}
      </Flex>
    </Box>
  );
};
