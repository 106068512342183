import styled from '@emotion/styled';
import type * as ReactTable from '@tanstack/react-table';
import { flexRender } from '@tanstack/react-table';
import type { PropsWithChildren } from 'react';

import { Box, Loading } from '@jane/shared/reefer';
import { Table as ReeferTable } from '@jane/shared/reefer-table';

interface TableProps<T extends { id: string | number }> {
  isLoading: boolean;
  table: ReactTable.Table<T>;
}

const ColumnHeaderCell = ({ children }: PropsWithChildren) => {
  return (
    <ReeferTable.HeaderCell>
      <Box pl={20}>{children}</Box>
    </ReeferTable.HeaderCell>
  );
};

const TableRow = <T extends { id: string | number }>({
  row,
}: {
  row: ReactTable.Row<T>;
}) => (
  <ReeferTable.Row>
    {row.getVisibleCells().map((cell: ReactTable.Cell<T, unknown>) => (
      <ReeferTable.Cell
        key={cell.id}
        data-testid={`${row.original.id}-${cell.column.id}`}
      >
        <Box pl={20}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </Box>
      </ReeferTable.Cell>
    ))}
  </ReeferTable.Row>
);

const StyledBox = styled(Box)({
  scrollbarWidth: 'none',
});

export const Table = <T extends { id: string | number }>({
  table,
  isLoading,
}: TableProps<T>) => {
  const headersToRender = table
    .getFlatHeaders()
    .filter((header) => header.column.getIsVisible());

  return (
    <StyledBox overflow="scroll">
      <ReeferTable
        minWidth="100%"
        style={{ paddingBottom: 240 }}
        data-testid="reefer-table"
      >
        <ReeferTable.Head>
          <ReeferTable.Row>
            {headersToRender.map((header) => (
              <ColumnHeaderCell key={header.id}>
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </ColumnHeaderCell>
            ))}
          </ReeferTable.Row>
        </ReeferTable.Head>
        <ReeferTable.Body>
          {isLoading ? (
            <ReeferTable.Row>
              <ReeferTable.Cell>
                <Box mt={128}>
                  <Loading />
                </Box>
              </ReeferTable.Cell>
            </ReeferTable.Row>
          ) : (
            table
              .getSortedRowModel()
              .rows.map((row) => <TableRow key={row.id} row={row} />)
          )}
        </ReeferTable.Body>
      </ReeferTable>
    </StyledBox>
  );
};
