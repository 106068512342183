import { Card, Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext, useWatch } from '@jane/shared/reefer-hook-form';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import type { SpecialFormData } from '../utils/useSpecialFormData';

export const BudgetSection = () => {
  const { brandSpecial, duplicate } = useSpecialForm();
  const {
    clearErrors,
    setValue,
    formState: { errors },
  } = useFormContext<SpecialFormData>();
  const discountType = useWatch({ name: 'discountType' });
  const currentAvailable = useWatch({ name: 'availableBudget' });
  const editMode = brandSpecial && !duplicate;
  const createMode = !brandSpecial && !duplicate;

  const startingAvailable = parseFloat(brandSpecial?.available_budget ?? '0');
  const startingTotal = parseFloat(brandSpecial?.budget_total ?? '0');

  const handleBlurTotal = (value = 0) => {
    const change = startingTotal - value;
    const newAvailable = startingAvailable - change;

    setValue('availableBudget', Math.max(newAvailable, 0));

    if (value === 0) {
      setValue('budgetTotal', 0);
    }
  };

  const handleBlurRemaining = (value = 0) => {
    // If we reduce the budget_total by more than the available_budget, we manually set available_budget to zero (not negative).
    // This check handles the edge case where this occurs, but the user blurs that 0 available_budget field again.
    // Otherwise, the calculation would be re-run as if the user set available_budget to zero, which would increase budget_total.
    if (currentAvailable === 0 && value === 0) return;

    const change = startingAvailable - value;
    const newTotal = startingTotal - change;

    setValue('budgetTotal', Math.max(newTotal, 0));

    if (value === 0) {
      setValue('availableBudget', 0);
    }
  };

  return (
    <Card width="100%">
      <StyledCardGroup width="100%">
        <SpecialFormBlock first>
          <Typography variant="header-bold" branded mb={32}>
            Budget
          </Typography>
          <Flex flexDirection="column" gap={24}>
            <Typography variant="body-bold">Discount type</Typography>
            <Form.RadioFieldGroup
              name="discountType"
              onChange={(value: string) => {
                setValue('discountType', value);
                clearErrors('discountType');
              }}
              shouldUnregister={false}
              required
              options={[
                {
                  id: 'discount-type-dollar',
                  label: 'Dollar',
                  value: 'dollar_amount',
                  wrapper: (children) => (
                    <>
                      {children}
                      {discountType === 'dollar_amount' && (
                        <Flex flexDirection="column" gap={12} mx={24} px={12}>
                          <Form.NumberField
                            width="calc(50% - 12px)"
                            name="discountDollarAmount"
                            label="Amount"
                            disableMobileInputStyling
                            disableOnWheel
                            startUnit="$"
                            allowedDecimalPlaces={2}
                            shouldUnregister={false}
                            min={0.01}
                            max={20}
                            required
                            autoFocus={createMode}
                          />
                          <Typography color="grays-mid">
                            Cannot exceed $20.00
                          </Typography>
                        </Flex>
                      )}
                    </>
                  ),
                },
                {
                  id: 'discount-type-percent',
                  label: 'Percent',
                  value: 'percent',
                  wrapper: (children) => (
                    <>
                      {children}
                      {discountType === 'percent' && (
                        <Flex flexDirection="column" gap={12} mx={24} px={12}>
                          <Form.NumberField
                            width="calc(50% - 12px)"
                            name="discountPercent"
                            label="Amount"
                            disableMobileInputStyling
                            disableOnWheel
                            endUnit="%"
                            shouldUnregister={false}
                            max={99}
                            min={0.01}
                            required
                            autoFocus={createMode}
                          />
                          <Typography color="grays-mid">
                            Cannot exceed 99%
                          </Typography>
                        </Flex>
                      )}
                    </>
                  ),
                },
                {
                  id: 'discount-type-target-price',
                  label: 'Target price',
                  value: 'target_price',
                  wrapper: (children) => (
                    <>
                      {children}
                      {discountType === 'target_price' && (
                        <Flex flexDirection="column" gap={12} mx={24} px={12}>
                          <Form.NumberField
                            width="calc(50% - 12px)"
                            name="discountTargetPrice"
                            label="Amount"
                            disableMobileInputStyling
                            disableOnWheel
                            startUnit="$"
                            shouldUnregister={false}
                            allowedDecimalPlaces={2}
                            min={0.01}
                            required
                            autoFocus={createMode}
                          />
                        </Flex>
                      )}
                    </>
                  ),
                },
              ]}
            />
          </Flex>
          {errors.discountType && (
            <Typography color="error" mt={16}>
              {errors.discountType.message}
            </Typography>
          )}
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Flex flexDirection="row" width="100%" gap={24}>
            <Form.NumberField
              name="budgetTotal"
              label={editMode ? 'Starting budget total' : 'Budget total'}
              required
              disableMobileInputStyling
              disableOnWheel
              width="100%"
              startUnit="$"
              allowedDecimalPlaces={2}
              min={0.01}
              {...(editMode
                ? {
                    onBlur: (value: number) => handleBlurTotal(value),
                    helperText:
                      'Adjusting starting budget will also impact remaining budget',
                  }
                : {})}
            />
            <Flex flexDirection="column" width="100%" gap={12}>
              <Form.NumberField
                name="minimumAvailableBudget"
                label="Minimum available budget"
                required
                disableMobileInputStyling
                disableOnWheel
                startUnit="$"
                defaultValue={10}
                allowedDecimalPlaces={2}
                min={5}
              />
              <Typography color="grays-mid">
                Cannot be less than $5.00
              </Typography>
            </Flex>
          </Flex>
        </SpecialFormBlock>
        {editMode && (
          <SpecialFormBlock>
            <Form.NumberField
              name="availableBudget"
              label="Remaining Budget"
              disableMobileInputStyling
              disableOnWheel
              width="100%"
              startUnit="$"
              allowedDecimalPlaces={2}
              onBlur={(value: number) => handleBlurRemaining(value)}
              helperText="Adjusting remaining budget will also impact starting budget"
            />
          </SpecialFormBlock>
        )}

        <SpecialFormBlock>
          <Flex flexDirection="row" width="100%" gap={24}>
            <Form.NumberField
              name="managementFeePercentage"
              label="Management fee"
              required
              disableMobileInputStyling
              disableOnWheel
              width="100%"
              endUnit="%"
              defaultValue={12}
            />
            <Form.NumberField
              name="serviceFeeRate"
              label="Service fee"
              required
              disableMobileInputStyling
              disableOnWheel
              width="100%"
              endUnit="%"
              defaultValue={15}
            />
          </Flex>
        </SpecialFormBlock>
      </StyledCardGroup>
    </Card>
  );
};
