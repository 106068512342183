import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useBrandsNavigate } from '@jane/brands/hooks';
import type { ProductReportParams } from '@jane/catalog-cms/data-access';
import { createOrUpdateReport } from '@jane/catalog-cms/data-access';
import { editFormProductQueryKey, useProductId } from '@jane/catalog-cms/hooks';
import { ApiRequestError } from '@jane/shared/data-access';
import {
  ArrowLeftIcon,
  Banner,
  Button,
  Drawer,
  Flex,
  InfoIcon,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { FormValidationError } from '@jane/shared/reefer-hook-form';

import type { ReportIssueFormData } from '../../ProductNotices/ReportIssueForm';
import { ReportIssueForm } from '../../ProductNotices/ReportIssueForm';
import { DrawerHeader } from '../DrawerHeader';

export const CreateProductNotice = () => {
  const formId = 'report-product-issue';
  const [isDirty, setIsDirty] = useState(false);
  const toast = useToast();
  const productUuid = useProductId();

  const brandsNavigate = useBrandsNavigate();
  const navigate = useNavigate();

  const queryClient = useQueryClient();
  const createReport = useMutation({
    mutationFn: createOrUpdateReport,
    onSuccess: () => {
      return Promise.all([
        queryClient.invalidateQueries(editFormProductQueryKey(productUuid)),
        queryClient.invalidateQueries({ queryKey: ['products'] }),
      ]);
    },
  });

  const submit = async (data: ReportIssueFormData) => {
    const reportParams = toProductReportParams(data, productUuid);

    validateReportParams(reportParams);

    try {
      await createReport.mutateAsync(reportParams);
      toast.add({ label: 'Report submitted', variant: 'success' });
      brandsNavigate.editProduct(productUuid);
    } catch (error) {
      if (
        error instanceof ApiRequestError &&
        error.response.status.toString().match(/^4/)
      ) {
        const { error: errorMessage } = await error.response.json();

        if (errorMessage) {
          throw new FormValidationError('create-localization', [
            { name: 'form', message: errorMessage },
          ]);
        }
      }

      throw new Error(
        [
          'There was an unexpected error when submitting the form.',
          'Please try again or contact support if this problem persists.',
        ].join(' ')
      );
    }
  };

  return (
    <>
      <DrawerHeader>
        <Flex
          width="100%"
          justifyContent="space-between"
          alignItems="center"
          gap={24}
        >
          <ArrowLeftIcon
            altText="Cancel product report"
            color="inherit"
            onClick={() => navigate(-1)}
            data-testid="drawer-header-return-icon"
          />
          <Flex grow={1}>
            <Typography variant="header-bold" as="h2">
              Report a problem
            </Typography>
          </Flex>
          <Button
            label="Send"
            type="submit"
            form={formId}
            disabled={!isDirty}
          />
        </Flex>
      </DrawerHeader>
      <Drawer.Content data-testid="drawer-content">
        <Banner
          icon={<InfoIcon />}
          label="Jane's content team will review the request and adjust the product accordingly."
          variant="info"
          full
        />

        <ReportIssueForm
          formId={formId}
          onSubmit={submit}
          onDirty={setIsDirty}
        />
      </Drawer.Content>
    </>
  );
};

/**
 * Utility that takes the output from the report issues forma and converts it to
 * the format expected by the create report function.
 */

const toProductReportParams = (
  data: ReportIssueFormData,
  productUuid: string
): ProductReportParams => {
  const { activeProductId, comment, notice } = data;

  const params: ProductReportParams = { productUuid, notice };

  if (activeProductId) params.activeProductId = activeProductId;
  if (comment) params.comment = comment.trim();

  return params;
};

const validateReportParams = (data: ProductReportParams) => {
  const errors = [];

  if (data.notice === 'duplicate' && !data.activeProductId) {
    errors.push({
      name: 'form',
      message:
        'When reporting a duplicate you must select the primary product. ',
    });
  }

  if (
    data.notice === 'duplicate' &&
    data.activeProductId === data.productUuid
  ) {
    errors.push({
      name: 'form',
      message: [
        'Reporting a product as a duplicate of itself is not allowed.',
        'Please choose a different product to report this as a duplicate.',
      ].join(' '),
    });
  }

  if (errors.length > 0) {
    throw new FormValidationError('report-issue', errors);
  }
};
