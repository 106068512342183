import { useSpecialsFilters } from '@jane/gold-manager/hooks';
import { Flex } from '@jane/shared/reefer';

import { MultiSelectFilter } from '../multiSelectFilter';
import { BudgetRangeFilter } from './budgetRangeFilter';
import { DateRangeFilter } from './dateRangeFilter';
import { DeselectFiltersButton } from './deselectFiltersButton';
import {
  DISCOUNT_TYPE_OPTIONS,
  STATUS_OPTIONS,
} from './specialsFiltersOptions';

export const SpecialsFilters = () => {
  const { filters, onFilterChange, onClearFilters } = useSpecialsFilters();

  return (
    <Flex gap={16} alignItems="center" flexWrap="wrap">
      <DateRangeFilter
        currentValue={filters.dateRange || []}
        handleChange={(value) => onFilterChange('dateRange', value)}
      />
      <MultiSelectFilter
        currentValue={filters.discountType}
        handleChange={(value) => onFilterChange('discountType', value)}
        label="Discount"
        options={DISCOUNT_TYPE_OPTIONS}
      />
      <BudgetRangeFilter
        currentValue={filters.remainingBudgetRange || []}
        handleChange={(value) => onFilterChange('remainingBudgetRange', value)}
      />
      <MultiSelectFilter
        currentValue={filters.status}
        handleChange={(value) => onFilterChange('status', value)}
        label="Status"
        options={STATUS_OPTIONS}
      />
      <DeselectFiltersButton
        onClick={onClearFilters}
        label="Clear filters"
        inList={false}
      />
    </Flex>
  );
};
