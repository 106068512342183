import { flexRender } from '@tanstack/react-table';
import { useRef } from 'react';
import { useInView } from 'react-intersection-observer';

import { NewTable as Table } from '@jane/shared/reefer-table';

import {
  FixedContainer,
  HeaderCell,
  ScrollableContainer,
  SpecialsTableRow,
  StyledTable,
} from './specialsTable.styles';
import type { SpecialsTableProps } from './specialsTable.types';

export const SpecialsTable = ({
  bulkEdit,
  filterHeight,
  isFetchingNextPage,
  onNextPage,
  specialsTable,
}: SpecialsTableProps) => {
  const wrapperRef = useRef(null);
  const { ref } = useInView({
    root: wrapperRef.current,
    threshold: 0,
    onChange: (inView) => {
      if (inView && !isFetchingNextPage) {
        onNextPage();
      }
    },
  });

  const tableHeaders = specialsTable
    .getFlatHeaders()
    .filter((header) => header.column.getIsVisible());

  return (
    <FixedContainer height={bulkEdit ? 0 : filterHeight} ref={wrapperRef}>
      <ScrollableContainer>
        <StyledTable>
          <colgroup>
            <col id="actions" style={{ width: '72px' }} />
            <col id="brand" style={{ width: '220px' }} />
            <col id="title" style={{ width: '220px' }} />
            <col id="status" style={{ width: '112px' }} />
            <col id="specialId" style={{ width: '112px' }} />
            <col id="startTime" style={{ width: '112px' }} />
            <col id="endTime" style={{ width: '112px' }} />
            <col id="discount" style={{ width: '112px' }} />
            <col id="budget" style={{ width: '220px' }} />
            <col id="states" style={{ width: '148px' }} />
          </colgroup>
          <Table.Head>
            <Table.Row>
              {tableHeaders.map((header) => (
                <HeaderCell key={header.id}>
                  {flexRender(
                    header.column.columnDef.header,
                    header.getContext()
                  )}
                </HeaderCell>
              ))}
            </Table.Row>
          </Table.Head>

          <Table.Body>
            {specialsTable.getRowModel().rows.map((row, i) => (
              <SpecialsTableRow
                data-testid={`row-special-${row.original.title}`}
                key={row.id}
                ref={
                  i === specialsTable.getRowModel().rows.length - 1
                    ? ref
                    : undefined
                }
              >
                {row.getVisibleCells().map((cell, i) => (
                  <Table.Cell key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </Table.Cell>
                ))}
              </SpecialsTableRow>
            ))}
          </Table.Body>
        </StyledTable>
      </ScrollableContainer>
    </FixedContainer>
  );
};
