import type { RefObject } from 'react';
import { useCallback, useRef, useState } from 'react';
import type { ZodError } from 'zod';

import {
  makeSaveDraftErrorMessage,
  useSaveDraft,
} from '@jane/ad-manager/data-access';
import type {
  BrandMediaTagWithMediaCompliances,
  BrandTheme,
} from '@jane/ad-manager/types';
import {
  DEFAULT_WHITE,
  combineCustomMedia,
  getFormData,
} from '@jane/ad-manager/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import {
  Banner,
  ButtonToggle,
  ErrorIcon,
  Flex,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { ColorPicker } from './ColorPicker';
import { MediaEditor } from './MediaEditor';

interface AssetsModalProps {
  brandTheme: BrandTheme;
  mediaTags: BrandMediaTagWithMediaCompliances[];
  open: boolean;
  setOpen: (open: boolean) => void;
}
export const AssetsModal = ({
  brandTheme,
  mediaTags,
  open,
  setOpen,
}: AssetsModalProps) => {
  const { mutate: saveDraft } = useSaveDraft();
  const [error, setErrorMessage] = useState('');
  const [formIsDirty, setFormIsDirty] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState(false);

  const onRequestClose = useCallback(() => {
    if (!isSaving) {
      setOpen(false);
      setErrorMessage('');
    }
  }, [isSaving, setOpen]);

  const modalContentRef = useRef<HTMLDivElement>(null);

  const setError = useCallback((errorMessage: string) => {
    if (modalContentRef.current && errorMessage.length > 0) {
      modalContentRef.current.scrollTop = 0;
    }
    setErrorMessage(errorMessage);
  }, []);

  const onSubmit = async (data: Record<string, unknown>) => {
    const updates = getFormData(data);
    const newBrandTheme: BrandTheme = { ...brandTheme, ...updates };
    setIsSaving(true);

    saveDraft(newBrandTheme, {
      onSuccess: () => {
        setErrorMessage('');
        setOpen(false);
      },
      onError: (submitError) => {
        if (
          'format' in submitError &&
          typeof (submitError as ZodError).format === 'function'
        ) {
          setError(makeSaveDraftErrorMessage(submitError as ZodError));
        }
      },
      onSettled: () => {
        setIsSaving(false);
      },
    });
  };

  return (
    <ConfirmDiscardWrapper
      appId="root"
      open={open}
      setOpen={onRequestClose}
      hasChanges={formIsDirty}
    >
      <Form
        name="brand-theme-assets"
        onSubmit={onSubmit}
        onDirty={setFormIsDirty}
      >
        <AssetsModalContent
          isSaving={isSaving}
          setIsSaving={setIsSaving}
          brandTheme={brandTheme}
          error={error}
          setError={setError}
          modalContentRef={modalContentRef}
          mediaTags={mediaTags}
        />
      </Form>
    </ConfirmDiscardWrapper>
  );
};

interface AssetsModalContentProps {
  brandTheme: BrandTheme;
  error: string;
  isSaving: boolean;
  mediaTags: BrandMediaTagWithMediaCompliances[];
  modalContentRef: RefObject<HTMLDivElement>;
  setError: (error: string) => void;
  setIsSaving: (isSaving: boolean) => void;
}

const AssetsModalContent = ({
  brandTheme,
  error,
  setError,
  isSaving,
  setIsSaving,
  modalContentRef,
  mediaTags,
}: AssetsModalContentProps) => {
  const {
    colorHex = DEFAULT_WHITE,
    customImages = [],
    customVideo,
  } = brandTheme;

  const customMedia = combineCustomMedia(customImages, customVideo);

  const defaultActiveTab =
    customMedia.length > 0 ? 'imagesAndVideo' : 'brandColor';
  const [assetType, setAssetType] = useState(defaultActiveTab);

  return (
    <>
      <Modal.Header
        title="Edit cover"
        actions={
          <Form.SubmitButton
            variant="primary"
            label="Save"
            loading={isSaving}
          />
        }
      />
      <Modal.Content ref={modalContentRef}>
        <Flex width="100%" flexDirection="column">
          {error.length > 0 && (
            <Banner
              variant="error"
              full
              icon={<ErrorIcon />}
              label={error}
              mb={24}
              onDismiss={() => setError('')}
            />
          )}
          <Typography mb={16}>Cover style</Typography>
          <ButtonToggle
            variant="tertiary"
            value={assetType}
            onChange={(value) => setAssetType(value.toString())}
          >
            <ButtonToggle.Button
              label="Images and video"
              disabled={isSaving}
              value="imagesAndVideo"
            />
            <ButtonToggle.Button
              label="Brand color"
              disabled={isSaving}
              value="brandColor"
            />
          </ButtonToggle>
        </Flex>
        <Modal.ContentDivider />
        <ColorPicker
          isSelected={assetType === 'brandColor'}
          colorHex={colorHex}
        />
        <MediaEditor
          isSaving={isSaving}
          isSelected={assetType === 'imagesAndVideo'}
          setIsSaving={setIsSaving}
          customMedia={customMedia}
          mediaTags={mediaTags}
          setError={setError}
        />
      </Modal.Content>
    </>
  );
};
