import { brandsPaths } from '@jane/brands/util';
import { Flex, Link } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { StyledSubmit } from './StyledSubmitButton';

type HandleLogin = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => Promise<unknown>;

export const LoginForm = ({ handleLogin }: { handleLogin: HandleLogin }) => {
  return (
    <Form name="login" onSubmit={handleLogin} width="100%">
      <Form.ErrorBanner />
      <Form.TextField label="Email" name="email" type="email" required />
      <Form.TextField
        label="Password"
        mt={12}
        name="password"
        type="password"
        required
        pattern={/.*/}
      />
      <Flex
        flexDirection="row-reverse"
        justifyContent="space-between"
        alignItems="baseline"
        mt={40}
      >
        <StyledSubmit label="Login" />
        <Link to={brandsPaths.forgotPassword()}>Forgot password?</Link>
      </Flex>
    </Form>
  );
};
