/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { DynamicIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const LocationPin24 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <g className="Location pin">
      <g className="SVG">
        <path
          fill="#0E0E0E"
          fillRule="evenodd"
          d="M14.416 17.497C16.338 15.005 18 11.947 18 9A6.001 6.001 0 0 0 6 9c0 2.948 1.662 6.005 3.584 8.497A30.06 30.06 0 0 0 12 20.253a30.065 30.065 0 0 0 2.416-2.756ZM12 23s8-6.75 8-14A8.001 8.001 0 0 0 4 9c0 7.25 8 14 8 14Z"
          clipRule="evenodd"
        />
        <path fill="#0E0E0E" d="M14 9a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z" />
      </g>
    </g>
  </svg>
);

const SIZE_MAP = {
  md: LocationPin24,
  sm: LocationPin24,
  lg: LocationPin24,
  xl: LocationPin24,
  xxl: LocationPin24,
  xxxl: LocationPin24,
};

export const LocationPinIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: DynamicIconProps) => {
  return (
    <Icon
      data-testid={testId || 'location-pin-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
    />
  );
};
