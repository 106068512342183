import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { dayAbbreviationFromIndex } from '@jane/ad-manager/util';

import type { ApiFlightDetails } from '../productBrands/schemas';

interface DailySchedule {
  endTime: string;
  startTime: string;
}

interface ApiDayPart {
  endTime: string;
  startTime: string;
  weekdays: string[];
}

const formatDayPartTime = (selectedTime: string): string => {
  dayjs.extend(customParseFormat);
  dayjs.extend(localizedFormat);
  return dayjs(selectedTime, 'hh:mm A').format('HH:mm:ss');
};

export const transformDayParts = (
  days: Record<string, DailySchedule>
): ApiDayPart[] => {
  const result: ApiDayPart[] = [];

  // group days with identical start and end times
  const groupedDays: Record<string, string[]> = {};

  for (const [day, params] of Object.entries(days)) {
    const startTime = params.startTime;
    const endTime = params.endTime;
    const dayAbbreviation = day.substring(0, 2).toUpperCase();

    // check if a group with identical start and end times already exists
    const key = `${startTime}-${endTime}`;
    if (!(key in groupedDays)) {
      groupedDays[key] = [];
    }

    groupedDays[key].push(dayAbbreviation);
  }

  // convert the grouped days into the required format for the backend
  for (const [timeKey, days] of Object.entries(groupedDays)) {
    const [startTime, endTime] = timeKey.split('-');
    const backendParam: ApiDayPart = {
      startTime: formatDayPartTime(startTime),
      endTime: formatDayPartTime(endTime),
      weekdays: days,
    };
    result.push(backendParam);
  }

  return result;
};

export const convertIdsToNumber = (ids: string[]): number[] =>
  ids.map((id) => Number(id));

export const scheduleForWeek = (schedule: ApiFlightDetails['schedule']) =>
  [1, 2, 3, 4, 5, 6, 0]
    .map(dayAbbreviationFromIndex)
    .reduce((acc, dayAbbreviation) => {
      const scheduleForDay = schedule.find((dayPart) =>
        dayPart.weekDays.includes(dayAbbreviation)
      );

      if (!scheduleForDay) return acc;
      return {
        ...acc,
        [dayAbbreviation]: scheduleForDay,
      };
    }, {} as { [k: string]: ApiFlightDetails['schedule'][0] | undefined });

export const zoneToPlatforms = (selectedPlatform: string): string[] => {
  if (selectedPlatform === 'all-platforms') return ['web', 'kiosk'];
  return [selectedPlatform];
};

export const PLACEMENTS_TO_ZONES_MAP: Record<string, string> = {
  'Menu row': 'storeMenu',
  'Inline product': 'storeMenuInline',
  'Product page': 'storePDP',
  'Cart toppers': 'cart',
  'Recommended row': 'magicRow',
};

export const getPlacements = (): string[] =>
  Object.keys(PLACEMENTS_TO_ZONES_MAP);

export const getZoneFromPlacement = (placement: string): string => {
  const zone = PLACEMENTS_TO_ZONES_MAP[placement];
  if (!zone) return '';
  return zone;
};

export const getPlacementFromZone = (zone: string): string => {
  const placement = Object.entries(PLACEMENTS_TO_ZONES_MAP).find(
    ([_, value]) => value === zone
  );
  if (!placement) return '';
  return placement[0];
};

export const MAX_TARGET_LENGTH = 6;

export const placementsToZones = (selectedPlacements: string[]): string[] =>
  selectedPlacements.map((placement) => PLACEMENTS_TO_ZONES_MAP[placement]);
