import clsx from 'clsx';
import type { MouseEvent, ReactElement } from 'react';
import React from 'react';

import type { ReeferBaseProps } from '../../utils/baseProps.types';
import styles from './buttonToggle.module.css';
import type { ButtonToggleButtonProps } from './buttonToggleButton/buttonToggleButton';
import { ButtonToggleButton } from './buttonToggleButton/buttonToggleButton';

export interface ButtonToggleProps extends ReeferBaseProps {
  /** Aria label */
  ariaLabel?: string;

  /** Only allows `ButtonToggle.Button` components as children */
  children: ReactElement<ButtonToggleButtonProps>[];

  /** Stretches the container to fit its parent; buttons positioned with space-between */
  full?: boolean;

  /** `onChange` handler, returns the value of the clicked button */
  onChange: (value: string | number) => void;

  /** The currently selected value */
  value: string | number;

  /** Set the variant of button to display */
  variant?: 'primary' | 'inverse' | 'tertiary';
}

/**
 * The `ButtonToggle` component allows the user to toggle between a series of options.
 * An `onChange` handler will be fired when the selected option is changed.
 */
export function ButtonToggleBase({
  ariaLabel,
  children,
  className,
  'data-testid': testId,
  full = true,
  id,
  onChange,
  style,
  value,
  variant = 'primary',
}: ButtonToggleProps) {
  return (
    <div
      aria-label={ariaLabel}
      className={clsx(className, styles.buttonToggle, {
        [styles.buttonToggle__full]: full,
      })}
      data-testid={testId}
      id={id}
      style={style}
    >
      {React.Children.map(children, (child) =>
        React.cloneElement(child, {
          full,
          onClick: (e: MouseEvent<HTMLButtonElement>) => {
            child.props?.onClick && child.props.onClick(e);
            onChange(child.props.value);
          },
          selected: child.props.value === value,
          variant,
        })
      )}
    </div>
  );
}

export const ButtonToggle = Object.assign(ButtonToggleBase, {
  Button: ButtonToggleButton,
});
