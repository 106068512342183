import type { ReactNode } from 'react';
import { createContext, useEffect, useRef, useState } from 'react';

import type { PromptsType } from '@jane/shared-ecomm/hooks';
import { usePrompt } from '@jane/shared-ecomm/hooks';
import { Box, useMobileMediaQuery } from '@jane/shared/reefer';

import { PromptContainer } from './prompt.styles';
import { PromptContent } from './promptContent';

/**
 * The prompt component displays a popover below the passed target.
 * If passed the dismissible prop, the prompt will persist until the x button is clicked.
 * If not passed the dismissible prop, the prompt will disappear after ten seconds.
 * Once the prompt disappears, it will not appear again for 24 hours.
 * */

export interface PromptProps {
  children: ReactNode;
  dismissible?: boolean;
  prompt: PromptsType;
  target: ReactNode;
}

interface PromptContextProps {
  dismissPrompt: () => void;
  dismissible: boolean;
}
export const PromptContext = createContext<PromptContextProps>(
  {} as PromptContextProps
);

const BasePrompt = ({
  children,
  dismissible = false,
  prompt,
  target,
}: PromptProps) => {
  const promptRef = useRef<HTMLDivElement>(null);
  const isMobile = useMobileMediaQuery({});
  const [position, setPosition] = useState<null | {
    right: number;
    top: number;
  }>(null);
  const targetRef = useRef<HTMLDivElement>(null);
  const [showPrompt, dismissPrompt] = usePrompt({ prompt, dismissible });

  useEffect(() => {
    if (showPrompt && targetRef.current) {
      const rect = targetRef.current.getBoundingClientRect();
      setPosition({
        top: rect.height + 16.5,
        right: 0 - (isMobile ? 32 : 37) + rect.width / 2,
      });
    }
  }, [showPrompt, targetRef.current]);

  return (
    <PromptContext.Provider value={{ dismissible, dismissPrompt }}>
      <Box position="relative" width="fit-content">
        <div ref={targetRef}>{target}</div>

        {showPrompt && position && (
          <PromptContainer
            ref={promptRef}
            top={position.top}
            right={position.right}
          >
            {children}
          </PromptContainer>
        )}
      </Box>
    </PromptContext.Provider>
  );
};

export const Prompt = Object.assign(BasePrompt, {
  Content: PromptContent,
});
