import { deepMerge } from '../internal/utils/deepMerge';
import { BASE_CONFIG } from './baseConfig';
import { STATIC_CONFIG } from './staticConfig';
import type {
  BorderRadiusConfig,
  CustomThemeConfig,
  ReeferTheme,
} from './theme.types';
import type { ColorConfig, ThemeColors } from './themeColors.types';
import type { ComponentsConfig } from './themeComponents.types';
import { calculateColorGroup } from './utils';

const generateColorGroups = (colorConfig: ColorConfig): ThemeColors => ({
  ...colorConfig,
  primary: calculateColorGroup(colorConfig.primary),
  secondary: calculateColorGroup(colorConfig.secondary),
  info: calculateColorGroup(colorConfig.info),
  success: calculateColorGroup(colorConfig.success),
  error: calculateColorGroup(colorConfig.error),
});

export const generateTheme = (
  customThemeConfig?: CustomThemeConfig
): ReeferTheme => {
  const borderRadius = deepMerge(
    {} as BorderRadiusConfig,
    BASE_CONFIG.borderRadius,
    customThemeConfig?.borderRadius
  );

  const colors = deepMerge(
    {} as ColorConfig,
    BASE_CONFIG.colors,
    customThemeConfig?.colors
  );

  const components = deepMerge(
    {} as ComponentsConfig,
    BASE_CONFIG.components,
    customThemeConfig?.components
  );

  return {
    borderRadius,
    colors: {
      ...generateColorGroups(colors),
      ...STATIC_CONFIG.colors,
    },
    components,
  };
};
