import { useContext } from 'react';

import { useBrandsNavigate } from '@jane/brands/hooks';
import { FLAGS } from '@jane/brands/util';
import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { NextProductIdContext } from '@jane/catalog-cms/providers';
import { useFlag } from '@jane/shared/feature-flags';

import { useEditFormProduct } from '../useEditFormProduct';

/**
 * This hook returns a function that when called will navigate to the edit
 * product page of the next product in the products list, if there is a next
 * product to navigate to. Otherwise it will navigate to the product list page.
 */
export const useGoToNextProduct = () => {
  const context = useContext(NextProductIdContext);
  const nav = useBrandsNavigate();
  const { data } = useEditFormProduct(context?.nextProductId || '');
  const stateViewEnabled = useFlag(FLAGS.pcatStateViewEnabled);

  if (context === undefined) {
    throw new Error(
      'useUpdateNextProductId must be a child of NextProductIdProvider.'
    );
  }

  return (options: { selectedSubdivision?: string } = {}) => {
    const { selectedSubdivision } = options;

    if (stateViewEnabled) {
      const localization = getLocalization(data, selectedSubdivision);

      if (context.nextProductId) {
        context.setNextProductId(undefined);

        if (localization) {
          nav.editLocalization(context.nextProductId, localization.id);
          return;
        } else if (!localization && !selectedSubdivision) {
          nav.editProduct(context.nextProductId);
          return;
        } else if (!localization && selectedSubdivision) {
          nav.createLocalization(context.nextProductId);
          return;
        }
      }
    } else {
      if (context.nextProductId) {
        context.setNextProductId(undefined);
        nav.editProduct(context.nextProductId);
        return;
      }
    }

    nav.productList();
  };
};

const getLocalization = (
  editProduct?: EditFormProduct,
  selectedSubdivision?: string
) => {
  if (editProduct === undefined || selectedSubdivision === undefined)
    return undefined;

  const [countryCode, subdivision] = selectedSubdivision.split('-');

  return editProduct.localizations.find(
    (el) => el.country === countryCode && el.subdivision === subdivision
  );
};
