import clsx from 'clsx';
import type { ChangeEvent, FocusEvent } from 'react';
import { forwardRef, useEffect, useState } from 'react';

import { Box } from '../../box/box';
import { DismissFilledIcon } from '../../icon/icons/dynamic/DismissFilledIcon';
import type { TextFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import { TextFieldAutocomplete } from './autocomplete/textFieldAutocomplete';
import styles from './textField.module.css';

/**
 * Text fields allow users to enter text into a UI.
 *
 * Use this component *outside forms* for inputs of `type`:
 * `email`, `password`, `tel`, `text` or `url`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms,
 * see [`Form.TextField`](/story/reefer-hook-form-form-textfield--default).*
 */
export const TextField = forwardRef<HTMLInputElement, TextFieldProps>(
  (
    {
      autoCapitalize,
      autocomplete,
      autocompleteFooter,
      autocompleteOptions,
      autoFocus = false,
      children,
      className,
      clearIcon,
      borderRadius = 'sm',
      disableMobileInputStyling = false,
      defaultValue = '',
      disabled = false,
      endIcon,
      enterKeyHint,
      helperText,
      id,
      label,
      labelHidden = false,
      maxLength,
      minLength,
      name,
      onBlur,
      onChange,
      onSelection,
      onUpdate,
      placeholder,
      readOnly = false,
      startIcon,
      'data-testid': testId,
      type = 'text',
      value: externalValue,
      width = 'auto',
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);
    const [openAutocomplete, setOpenAutocomplete] = useState(false);
    const showDismissFilledIcon = clearIcon && value.length > 0;

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== value) {
        setValue(externalValue);
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, value]);

    useEffect(() => {
      if (autoFocus && autocompleteOptions) {
        setOpenAutocomplete(true);
      }
    }, [autoFocus]);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      onBlur && onBlur(event.target.value);
      setValue(event.target.value);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onChange && onChange(event.currentTarget.value);
      setValue(event.currentTarget.value);

      if (autocompleteOptions) {
        handleOpenAutocomplete();
      }
    };

    const handleClear = () => {
      onBlur && onBlur('');
      onChange && onChange('');
      onSelection && onSelection('');
      setValue('');
    };

    const handleSelectAutocomplete = (value: string) => {
      onChange && onChange(value);
      onBlur && onBlur(value);
      onSelection && onSelection(value);
      setValue(value);
      handleCloseAutocomplete();
    };

    const handleOpenAutocomplete = () => {
      setOpenAutocomplete(true);
    };

    const handleCloseAutocomplete = () => {
      setOpenAutocomplete(false);
    };

    const handleClick = () => {
      if (!openAutocomplete) {
        return handleOpenAutocomplete();
      }
    };

    return (
      <Box width="100%">
        <FieldWrapper
          className={className}
          disabled={disabled}
          disableMobileInputStyling={disableMobileInputStyling}
          helperText={autocompleteOptions ? undefined : helperText}
          id={id}
          label={label}
          labelHidden={labelHidden}
          labelPosition="t"
          name={name}
          isWrappingTextInput
          render={(renderProps) => (
            <div
              className={clsx(
                styles.textField_wrapper,
                styles[`textField_wrapper__borderRadius_${borderRadius}`],
                {
                  [styles.textField_wrapper__mobile]:
                    !disableMobileInputStyling,
                  [styles.textField_wrapper__readOnly]: readOnly,
                }
              )}
            >
              {startIcon && <Box mr={4}>{startIcon}</Box>}
              <input
                autoCapitalize={autoCapitalize}
                className={clsx(styles.textField_input, {
                  [styles.textField_input__mobile]: !disableMobileInputStyling,
                })}
                onFocus={autocompleteOptions && handleClick}
                autoFocus={autoFocus}
                disabled={disabled}
                enterKeyHint={enterKeyHint}
                autoComplete={autocompleteOptions ? 'off' : autocomplete}
                id={name}
                maxLength={maxLength}
                minLength={minLength}
                name={name}
                onBlur={handleBlur}
                onInput={handleChange}
                placeholder={placeholder}
                readOnly={readOnly}
                ref={ref}
                type={type}
                value={value}
                {...renderProps}
              />
              {showDismissFilledIcon && (
                <Box>
                  <DismissFilledIcon
                    altText="clear-input"
                    color="text-light"
                    onClick={handleClear}
                    size="sm"
                  />
                </Box>
              )}
              {endIcon && (
                <Box ml={showDismissFilledIcon ? 16 : 4}>{endIcon}</Box>
              )}
            </div>
          )}
          data-testid={testId}
          width={width}
          {...props}
        >
          {children}
        </FieldWrapper>

        {autocompleteOptions && (
          <TextFieldAutocomplete
            name={name}
            handleClose={handleCloseAutocomplete}
            isOpen={openAutocomplete}
            errorMessage={helperText}
            options={autocompleteOptions}
            onSelection={handleSelectAutocomplete}
            popoverFooter={autocompleteFooter}
          />
        )}
      </Box>
    );
  }
);
