import clsx from 'clsx';
import { Link } from 'react-router-dom';

import type { ReeferBaseProps } from '../../../utils/baseProps.types';
import { Flex } from '../../flex/flex';
import { Typography } from '../../typography/typography';
import type { TabDirection } from '../tabs';
import styles from './tab.module.css';

export interface TabProps extends ReeferBaseProps {
  /** Aria label */
  ariaLabel?: string;

  /** Optionally accepts children that render to the right of the label */
  children?: React.ReactNode;

  /** Direction of the tabs, defaults to horizontal */
  direction?: TabDirection;

  /** Whether to disable the tab */
  disabled?: boolean;

  /** Tabs component stretches across the whole container (controlled by parent) */
  full?: boolean;

  /** Tab label and name */
  label: string | React.ReactNode;

  /** Tab `onClick` handler (controlled by parent) */
  onClick?(): void;

  /** Whether tab is selected (controlled by parent) */
  selected?: boolean;

  /** An internal route: if passed, it renders a `<Link>` from `react-router-dom` */
  to?: string;

  /** Value that is returned to the onChange handler when clicked */
  value?: string;

  /** Width of each tab (controlled by parent) */
  width?: number | string;
}

/**
 * Each individual tab within the `Tabs` component is defined using the `Tab.Tab` component.
 */

export function Tab({
  ariaLabel,
  children,
  className: classNameProp,
  'data-testid': testId,
  direction = 'horizontal',
  disabled = false,
  full,
  id,
  label,
  onClick,
  selected = false,
  style,
  to,
  value,
  width = 'auto',
  ...props
}: TabProps) {
  const sharedProps = {
    'aria-label': ariaLabel,
    'data-testid': testId,
    id,
    style: { '--width': width, ...style },
    ...props,
  };

  const className = clsx(
    classNameProp,
    styles.tab,
    styles[`tab__direction__${direction}`],
    {
      [styles.tab__disabled]: disabled,
      [styles.tab__full]: full,
      [styles.tab__selected]: selected,
    }
  );

  const tabLabel = (
    <Flex
      className={clsx(styles.tabLabel)}
      justifyContent={direction === 'horizontal' ? 'center' : 'space-between'}
    >
      <Typography variant={selected ? 'body-bold' : 'body'} as="span">
        {label}
      </Typography>
      {children}
    </Flex>
  );

  if (!to || disabled) {
    return (
      <button
        className={className}
        disabled={disabled}
        onClick={disabled ? undefined : onClick}
        type="button"
        value={value}
        {...sharedProps}
      >
        {tabLabel}
      </button>
    );
  }
  return (
    <Link className={className} onClick={onClick} to={to} {...sharedProps}>
      {tabLabel}
    </Link>
  );
}
