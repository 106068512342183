import type { BidModifiers } from '@jane/ad-manager/types';

import { FlightDetailSection } from './FlightDetailSection';

type BaseProps = {
  bidModifiers?: BidModifiers;
  creditPercent?: number;
};
interface PriorityPricingProps extends BaseProps {
  priority: true;
  totalBudget: string;
}
export const PriorityPricingDetails = (props: PriorityPricingProps) => {
  const { totalBudget, creditPercent } = props;
  return (
    <FlightDetailSection
      data-testid="pricing-details"
      headerLabel="Pricing"
      rows={[
        [
          { name: 'Priority', value: 'Enabled' },
          { name: 'Total Budget', value: totalBudget },
          {
            name: 'Flight Credit Percentage',
            value: `${String(creditPercent)}%`,
          },
        ],
      ]}
      bidModifiers={props.bidModifiers}
    />
  );
};

interface AuctionPricingProps extends BaseProps {
  budget: string;
  cpmBid: string;
  priority?: false;
}
export const AuctionPricingDetails = ({
  bidModifiers,
  budget,
  cpmBid,
  creditPercent,
}: AuctionPricingProps) => (
  <FlightDetailSection
    data-testid="pricing-details"
    headerLabel="Pricing"
    rows={[
      [
        { name: 'Priority', value: 'Off' },
        { name: 'Budget', value: budget },
        { name: 'Cost Per 1,000 Impression Bid', value: cpmBid },
        {
          name: 'Flight Credit Percentage',
          value: `${String(creditPercent)}%`,
        },
      ],
    ]}
    bidModifiers={bidModifiers}
  />
);

type Props = AuctionPricingProps | PriorityPricingProps;
export const PricingDetails = (props: Props) => {
  if (props.priority === true) {
    return <PriorityPricingDetails {...props} />;
  }

  return <AuctionPricingDetails {...props} />;
};
