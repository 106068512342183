import type { AdType } from '@jane/ad-manager/data-access';
import { AdTypeEnum } from '@jane/ad-manager/data-access';
import { Flex, Typography } from '@jane/shared/reefer';
import {
  Form,
  useController,
  useFormContext,
} from '@jane/shared/reefer-hook-form';

import {
  DEFAULT_CPC_FREQUENCY_CAP_DENOMINATOR,
  DEFAULT_CPC_FREQUENCY_CAP_NUMERATOR,
  DEFAULT_CPM_FREQUENCY_CAP_DENOMINATOR,
  DEFAULT_CPM_FREQUENCY_CAP_NUMERATOR,
  DEFAULT_FLAT_FREQUENCY_CAP_DENOMINATOR,
  DEFAULT_FLAT_FREQUENCY_CAP_NUMERATOR,
  defaultAdSubmissionFormData,
} from '../formState/defaults';
import type { AdSubmissionFormState } from '../formState/types';

export const IsPriority = () => {
  const {
    field: { onChange: onChangeAdPriority },
  } = useController<AdSubmissionFormState>({
    name: 'isPriority',
  });
  const { getValues, getFieldState, resetField } =
    useFormContext<AdSubmissionFormState>();

  const getDefaultFrequencyCapNumerator = ({
    isPriority,
    adType,
  }: {
    adType: AdType;
    isPriority: boolean;
  }) => {
    if (isPriority) return DEFAULT_FLAT_FREQUENCY_CAP_NUMERATOR;
    if (adType === AdTypeEnum.enum.cpc)
      return DEFAULT_CPC_FREQUENCY_CAP_NUMERATOR;
    return DEFAULT_CPM_FREQUENCY_CAP_NUMERATOR;
  };

  const getDefaultFrequencyCapDenominator = ({
    isPriority,
    adType,
  }: {
    adType: AdType;
    isPriority: boolean;
  }) => {
    if (isPriority) return DEFAULT_FLAT_FREQUENCY_CAP_DENOMINATOR;
    if (adType === AdTypeEnum.enum.cpc)
      return DEFAULT_CPC_FREQUENCY_CAP_DENOMINATOR;
    return DEFAULT_CPM_FREQUENCY_CAP_DENOMINATOR;
  };

  const handleOnChangePriority = (isPriority: boolean) => {
    const adType = getValues('adType');

    if (!getFieldState('frequencyCapNumerator').isTouched) {
      resetField('frequencyCapNumerator', {
        defaultValue: getDefaultFrequencyCapNumerator({ adType, isPriority }),
      });
    }

    if (!getFieldState('frequencyCapDenominator').isTouched) {
      resetField('frequencyCapDenominator', {
        defaultValue: getDefaultFrequencyCapDenominator({ adType, isPriority }),
      });
    }

    const { bid: defaultBid, budget: defaultBudget } =
      defaultAdSubmissionFormData;
    resetField('bid', { defaultValue: defaultBid });
    resetField('budget', { defaultValue: defaultBudget });

    onChangeAdPriority(isPriority);
  };

  return (
    <Flex gap={16} alignItems="center" mt={16}>
      <Form.CheckboxField
        label="Prioritize your ad over others"
        labelHidden
        name="isPriority"
        /**
         * We use 'onClick' here instead of 'onChange' because of a reefer bug that causes the onChange function to be called
         * when duplicating an isPriority=true flight, which was resetting the bid and budget values.
         */
        onClick={() => handleOnChangePriority(!getValues('isPriority'))}
        shouldUnregister={false}
      />
      <Flex flexDirection="column">
        <Typography variant="body">Prioritize your ad over others</Typography>
        <Typography color="grays-mid">
          Highlight specials or product drops. Guarantee your ad will be seen on
          load, with flat fee pricing.
        </Typography>
      </Flex>
    </Flex>
  );
};
