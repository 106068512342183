import { createColumnHelper } from '@tanstack/react-table';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';

const columnHelper = createColumnHelper<ApiFlightSummary>();

export const getColumns = () => {
  return [
    columnHelper.accessor('active', {
      header: 'Active',
    }),
    columnHelper.accessor('states', {
      header: 'States',
    }),
    columnHelper.accessor('stores', {
      header: 'Stores',
    }),
    columnHelper.accessor('startDate', {
      header: 'Starts',
    }),
    columnHelper.accessor('endDate', {
      header: 'Ends',
    }),
    columnHelper.accessor('model', {
      header: 'Type',
    }),
    columnHelper.accessor('selectedProducts', {
      header: 'Products',
    }),
    columnHelper.accessor('zones', {
      header: 'Placement',
    }),
    columnHelper.accessor('dailyBudget', {
      header: 'Daily Budget',
    }),
    columnHelper.accessor('impressions', {
      header: 'Impressions',
    }),
  ];
};
