import { useCallback, useMemo } from 'react';

import { Box } from '../box/box';
import { SearchField } from '../fields/searchField/searchField';
import { Popover } from '../popover/popover';
import { usePopoverContext } from '../popover/popoverContext/popoverContext';
import type { InternalTypeAheadProps, TypeAheadProps } from './typeAhead.types';
import { TypeAheadContext } from './typeAheadContext';
import { TypeAheadResults } from './typeAheadResults/typeAheadResults';

/**
 * CustomOptions is a component used in TypeAhead to support the closePopover function from the popover context
 */

function CustomOptions({
  customOptions,
}: {
  customOptions:
    | React.ReactNode
    | (({ closePopover }: { closePopover: () => void }) => React.ReactNode);
}) {
  const { closePopover } = usePopoverContext();
  return typeof customOptions === 'function' ? (
    <>{customOptions({ closePopover })}</>
  ) : (
    <>{customOptions}</>
  );
}

/**
 * The TypeAhead component combines the searchField and popover to create a reusable search/filter ui.
 */

export function InternalTypeAhead({
  'data-testid': testId,
  alignment = { horizontal: 'left', vertical: 'bottom' },
  ariaLabel,
  className,
  customOptions,
  debounceDelay,
  field,
  handleSelection,
  hideDeselect = false,
  hideSearchIcon = false,
  id,
  isDebounced,
  labelHidden,
  listAriaLabel,
  loading,
  onBlur,
  onChange,
  onPopoverClose,
  options = [],
  optionType = 'button',
  popoverFooter,
  popoverTarget,
  preResultsText,
  query = '',
  selection = [],
  style,
  width,
  ...spacingProps
}: InternalTypeAheadProps) {
  const handlePopoverClose = () => {
    onPopoverClose && onPopoverClose();
  };

  const contextValue = useMemo(
    () => ({
      listAriaLabel,
      loading,
      onSelection: handleSelection,
      optionType,
      options,
      query,
      selection,
      width,
    }),
    [
      listAriaLabel,
      loading,
      handleSelection,
      optionType,
      options,
      query,
      selection,
      width,
    ]
  );
  return (
    <TypeAheadContext.Provider value={contextValue}>
      {popoverTarget ? (
        <Popover
          alignment={alignment}
          className={className}
          id={id}
          label={ariaLabel}
          target={popoverTarget}
          data-testid={testId}
          style={style}
          {...spacingProps}
        >
          <Box pt={16}>
            <Box px={16}>{field}</Box>
          </Box>
          <Box py={16} width={width}>
            {customOptions ? (
              <CustomOptions customOptions={customOptions} />
            ) : (
              <TypeAheadResults
                hideDeselect={hideDeselect}
                popoverFooter={popoverFooter}
                preResultsText={preResultsText}
              />
            )}
          </Box>
        </Popover>
      ) : (
        <Popover
          alignment={alignment}
          className={className}
          closeOnTargetClick={false}
          id={id}
          onClose={handlePopoverClose}
          label={ariaLabel}
          style={style}
          targetWidth="100%"
          target={field}
          data-testid={testId}
          {...spacingProps}
        >
          <Box py={16} width={width}>
            {customOptions ? (
              <CustomOptions customOptions={customOptions} />
            ) : (
              <TypeAheadResults
                hideDeselect={hideDeselect}
                popoverFooter={popoverFooter}
                preResultsText={preResultsText}
              />
            )}
          </Box>
        </Popover>
      )}
    </TypeAheadContext.Provider>
  );
}

export function TypeAhead(props: TypeAheadProps) {
  const { onSelection } = props;
  const handleSelection = useCallback(
    (value: string | string[]) => {
      onSelection && onSelection(value);
    },
    [onSelection]
  );

  return (
    <InternalTypeAhead
      handleSelection={handleSelection}
      field={
        <SearchField
          value={props.query}
          defaultValue={props.query}
          debounceDelay={props.debounceDelay}
          hideSearchIcon={props.hideSearchIcon}
          isDebounced={props.isDebounced}
          label={props.ariaLabel}
          labelHidden={props.labelHidden ?? false}
          name="Typeahead Search"
          onBlur={props.onBlur}
          onChange={props.onChange}
          width="100%"
        />
      }
      {...props}
    />
  );
}
