import clsx from 'clsx';
import type { CSSProperties } from 'react';
import { useCallback, useRef, useState } from 'react';

import {
  useMobileMediaQuery,
  useTabletMediaQuery,
} from '../../hooks/mediaQueries';
import { useWindowListener } from '../../hooks/useWindowListener';
import { fakeButtonProps, handleEnterKey } from '../../utils/handleEnterKey';
import { marginCSSVars } from '../../utils/spacing';
import styles from './popover.module.css';
import type { PopoverProps } from './popover.types';
import { PopoverContent } from './popoverContent/popoverContent';
import { PopoverContext } from './popoverContext/popoverContext';
import { PopoverDivider } from './popoverDivider/popoverDivider';
import { useHandleMobilePopover } from './useHandleMobilePopover';

/**
 * Popover is used to display content on top of another, used for dropdown menus or for filtering.
 */

function BasePopover({
  alignment = { horizontal: 'left', vertical: 'bottom' },
  children,
  className,
  closeOnTargetClick = true,
  'data-testid': testId,
  disableMobileStyling = false,
  id,
  isLayered = false,
  label,
  noMinWidth,
  onClose,
  openOn = 'click',
  style,
  target,
  targetWidth = 'fit-content',
  variant = 'action',
  ...marginProps
}: PopoverProps) {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const isTablet = useTabletMediaQuery({});
  const isMobile = useMobileMediaQuery({});

  const closePopover = useCallback(() => {
    setIsOpen(false);
    onClose && onClose();
  }, [onClose]);

  const openPopover = () => {
    setIsOpen(true);
  };

  const handleClick = () => {
    if (openOn !== 'disabled') {
      if (isOpen && closeOnTargetClick) closePopover();
      if (!isOpen) openPopover();
    }
  };

  const handleMouseEnter = () => {
    openOn === 'hover' && setIsOpen(true);
  };

  const handleMouseLeave = () => {
    openOn === 'hover' && setIsOpen(false);
  };

  /** close when user clicks outside of popover */
  const handleClickAway = (event: MouseEvent) => {
    const domPath = event.composedPath && event.composedPath();

    const eventTarget = domPath ? domPath[0] : (event.target as HTMLElement);

    const popoverContainsTarget = popoverRef.current?.contains(
      eventTarget as Node
    );

    if (!popoverContainsTarget && isOpen) {
      closePopover();
    }
  };

  /** allows keyboard users to close the popover using esc key  */
  const handleEscapeKey = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        event.preventDefault();
        if (isOpen) {
          closePopover();
        }
      }
      return;
    },
    [closePopover, isOpen]
  );

  /** openOn changed to 'click' for mobile screens */
  const openOnHandler = () => {
    if (openOn === 'hover' && !isTablet) {
      openOn = 'click';
    }
    return;
  };

  /** different states of children depending how PopoverContext is used */
  const renderedChildren =
    typeof children === 'function'
      ? children({ closePopover, isOpen })
      : children;

  openOnHandler();

  useWindowListener('keydown', handleEscapeKey);

  useWindowListener('click', handleClickAway);

  /** on mobile, it resizes iframe when popover is open
   * allowing the drawer to appear at the bottom of the screen */
  useHandleMobilePopover(isOpen, isMobile && !disableMobileStyling, isLayered);

  return (
    <PopoverContext.Provider value={{ closePopover, isOpen }}>
      {isOpen && !disableMobileStyling && (
        <div
          className={styles.popover_mobileOverlay}
          style={
            {
              '--position': isMobile
                ? disableMobileStyling
                  ? 'relative'
                  : 'fixed'
                : 'static',
            } as CSSProperties
          }
        />
      )}

      <div
        className={clsx(className, styles.popover_container)}
        id={id}
        style={
          {
            '--width': targetWidth,
            ...marginCSSVars(marginProps),
            ...style,
          } as CSSProperties
        }
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={popoverRef}
      >
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <span
          className={styles.popover_target}
          aria-label={label}
          onClick={handleClick}
          onKeyUp={(event) => handleEnterKey(event, openPopover)}
          data-testid={testId}
          {...(closeOnTargetClick && { ...fakeButtonProps })}
          {...(isOpen && {
            'aria-controls': 'popover-content',
          })}
        >
          {target}
        </span>
        {isOpen && (
          <div
            className={clsx(styles.popover_alignment, {
              [styles.popover_alignment__action]: variant === 'action',
              [styles.popover_alignment__info]: variant === 'info',
              [styles.popover_alignment__top]: alignment.vertical === 'top',
              [styles.popover_alignment__left]: alignment.horizontal === 'left',
              [styles.popover_alignment__right]:
                alignment.horizontal === 'right',
              [styles.popover_alignment__center]:
                alignment.horizontal === 'center',
              [styles.popover_alignment__noMinWidth]: noMinWidth,
            })}
          >
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
            <div
              className={clsx(styles.popover_content, {
                [styles.popover_content__mobile]: !disableMobileStyling,
                [styles.popover_content__alignment_top]:
                  alignment.vertical === 'top',
                [styles.popover_content__alignment_bottom]:
                  alignment.vertical === 'bottom',
              })}
              id="popover-content"
              onClick={(e) => {
                // Prevent clicks within popover content from triggering the
                // "Click away" behavior that closes popovers.
                e.stopPropagation();
              }}
            >
              {renderedChildren}
            </div>
          </div>
        )}
      </div>
    </PopoverContext.Provider>
  );
}

export const Popover = Object.assign(BasePopover, {
  Content: PopoverContent,
  Divider: PopoverDivider,
});
