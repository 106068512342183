import { isFutureDate, isPastDate } from './time';

interface GetFlightStatusProps {
  active: boolean;
  endDate: string | null;
  readOnly: boolean;
  startDate: string;
}

export const getFlightStatus = ({
  startDate,
  endDate,
  active,
  readOnly,
}: GetFlightStatusProps) => {
  if (active) {
    if (!!endDate && isPastDate(endDate)) return 'Completed';
    if (isFutureDate(startDate)) return 'Pending';

    return 'Active';
  }

  if (readOnly) return 'Deactivated';

  return 'Inactive';
};

export const PLACEMENTS_TO_ZONES_MAP: Record<string, string> = {
  'Menu row': 'storeMenu',
  'Inline product': 'storeMenuInline',
  'Product page': 'storePDP',
  'Cart toppers': 'cart',
  'Recommended row': 'magicRow',
};

export const getPlacements = (): string[] =>
  Object.keys(PLACEMENTS_TO_ZONES_MAP);

export const getZoneFromPlacement = (placement: string): string => {
  const zone = PLACEMENTS_TO_ZONES_MAP[placement];
  if (!zone) return '';
  return zone;
};

export const getPlacementFromZone = (zone: string): string => {
  const placement = Object.entries(PLACEMENTS_TO_ZONES_MAP).find(
    ([_, value]) => value === zone
  );
  if (!placement) return '';
  return placement[0];
};

const PLACEMENTS_TO_INDEX_MAP: Record<string, number> = {
  'Menu row': 0,
  'Inline product': 1,
  'Product page': 2,
  'Cart toppers': 3,
  'Recommended row': 4,
};

export const MAX_TARGET_LENGTH = 6;

export const getPlacementIndex = (placement: string): number => {
  const index = PLACEMENTS_TO_INDEX_MAP[placement];
  if (index === undefined) return -1;
  return index;
};

export const getPlacementFromIndex = (index: number): string => {
  const placement = Object.entries(PLACEMENTS_TO_INDEX_MAP).find(
    ([_, value]) => value === index
  );
  if (!placement) return '';
  return placement[0];
};

export const placementsToZones = (selectedPlacements: string[]): string[] =>
  selectedPlacements.map((placement) => PLACEMENTS_TO_ZONES_MAP[placement]);
