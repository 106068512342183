import clsx from 'clsx';
import type { CSSProperties } from 'react';

import { toCSSSize } from '../../../utils/css';
import type { PopoverContentProps } from '../popover.types';
import styles from './popoverContent.module.css';

export function PopoverContent({
  children,
  className,
  'data-testid': testId,
  id,
  label,
  maxHeight,
  padding = true,
}: PopoverContentProps) {
  return (
    <div
      className={clsx(className, styles.popover_content, {
        [styles.popover_content__padding]: padding,
      })}
      aria-label={label}
      data-testid={testId}
      id={id}
      style={
        {
          '--max-height': maxHeight ? toCSSSize(maxHeight) : '90vh',
          '--max-height-desktop': maxHeight ? toCSSSize(maxHeight) : '420px',
        } as CSSProperties
      }
    >
      {children}
    </div>
  );
}
