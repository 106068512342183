import clsx from 'clsx';
import type { ReactElement } from 'react';
import React from 'react';

import type { ReeferBaseProps } from '../../utils/baseProps.types';
import type { TabProps } from './tab/tab';
import { Tab } from './tab/tab';
import styles from './tabs.module.css';

type TabDirection = 'horizontal' | 'vertical';

interface TabsProps extends ReeferBaseProps {
  /** Aria label */
  ariaLabel?: string;

  /** Only allows Tabs.Tab children */
  children: ReactElement<TabProps>[];

  /** Direction of the tabs, defaults to horizontal */
  direction?: TabDirection;

  /** Stretch to full width of container (overrides tabWidth) */
  full?: boolean;

  /** Function that returns the value of the clicked button */
  onChange?(value: string): void;

  /** Set the width of each tab */
  tabWidth?: number | string;

  /** The currently selected value */
  value: string;
}

/**
 * The `Tabs` components is used to display a series of selectable tabs.
 *
 * Each individual tab within the `Tabs` component is defined using the `Tab.Tab` component.
 */

function BaseTabs({
  ariaLabel,
  children,
  className,
  'data-testid': testId,
  direction = 'horizontal',
  full,
  id,
  onChange,
  style,
  tabWidth,
  value,
}: TabsProps) {
  const renderedTabs = React.Children.map(children, (child) => {
    /** value for buttons, to for router links */
    const childValue = child.props.to || child.props.value;

    const isSelected = value === child.props.to || value === child.props.value;

    const onClickHandler = () => {
      child.props?.onClick && child.props.onClick();

      if (childValue) {
        onChange && onChange(childValue);
      }
    };

    return React.cloneElement(child, {
      direction,
      full,
      onClick: onClickHandler,
      selected: isSelected,
      width: tabWidth,
    });
  });

  return (
    <div
      aria-label={ariaLabel}
      className={clsx(
        className,
        styles.tabs,
        styles[`tabs__direction__${direction}`],
        {
          [styles.tabs__full]: full,
        }
      )}
      data-testid={testId}
      id={id}
      style={style}
    >
      {renderedTabs}
    </div>
  );
}

export type { TabDirection, TabProps, TabsProps };

export const Tabs = Object.assign(BaseTabs, {
  Tab,
});
