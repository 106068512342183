import styled from '@emotion/styled';

import { AdTypeEnum } from '@jane/ad-manager/data-access';
import type { CPCAdPlacement, CPMAdPlacement } from '@jane/ad-manager/types';
import { Accordion } from '@jane/shared/components';
import { Flex, Typography } from '@jane/shared/reefer';
import {
  Form,
  useController,
  useFormContext,
  useWatch,
} from '@jane/shared/reefer-hook-form';

import type { AdPlacement, AdSubmissionFormState } from '../formState/types';

const PlacementAccordionHeading = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
}));

const AdPlacementCheckbox = ({
  currentPlacement,
  label,
  description,
  onChangeAdPlacement,
}: {
  currentPlacement: AdPlacement;
  description: string;
  label: CPMAdPlacement | CPCAdPlacement;
  onChangeAdPlacement: (placement: AdPlacement) => void;
}) => {
  return (
    <Flex gap={12} alignItems="center" mt={16}>
      <Form.CheckboxField
        ml={4}
        data-testid={`${label}-checkbox`}
        checked={currentPlacement.includes(label)}
        label={label}
        labelHidden
        name={label}
        onChange={() => {
          const updatedPlacement = currentPlacement.includes(label)
            ? currentPlacement.filter((item) => item !== label)
            : [...currentPlacement, label];
          onChangeAdPlacement(updatedPlacement);
        }}
      />
      <Flex flexDirection="column">
        <Typography variant="body">{label}</Typography>
        <Typography color="grays-mid">{description}</Typography>
      </Flex>
    </Flex>
  );
};

export const Placement = () => {
  const {
    formState: { errors },
  } = useFormContext<AdSubmissionFormState>();

  const adType = useWatch<AdSubmissionFormState, 'adType'>({
    name: 'adType',
  });

  const {
    field: { value: adPlacement, onChange: onChangeAdPlacement },
  } = useController<AdSubmissionFormState, 'adPlacement'>({
    name: 'adPlacement',
    rules: {
      validate: (placement: AdSubmissionFormState['adPlacement']) => {
        if (placement.length === 0)
          return 'At least one placement needs to be selected.';

        return true;
      },
    },
  });

  const selectedPlacementsText = (placementValues: string[]) => {
    if (placementValues.length === 1) {
      return placementValues[0];
    } else if (placementValues.length === 2) {
      return placementValues.join(' and ');
    } else if (placementValues.length >= 3) {
      return `${placementValues
        .slice(0, placementValues.length - 1)
        .join(', ')}, and ${placementValues[placementValues.length - 1]}`;
    }

    return '';
  };

  const cpmCheckBoxes: { description: string; label: CPMAdPlacement }[] = [
    {
      description: "Promote your brand as a row of products on a store's menu.",
      label: 'Menu row',
    },
    {
      description: 'Upsell your products contextually on product detail pages.',
      label: 'Product page',
    },
  ];

  const cpcCheckBoxes: { description: string; label: CPCAdPlacement }[] = [
    {
      description:
        'Upsell your product in thematic or recommended product rows.',
      label: 'Recommended row',
    },
    {
      description: "Upsell your products contextually in the customer's cart.",
      label: 'Cart toppers',
    },
    {
      description: 'Promote individual products across targeted categories.',
      label: 'Inline product',
    },
    {
      description: 'Upsell your products contextually on product detail pages.',
      label: 'Product page',
    },
  ];

  return (
    <Accordion>
      <Accordion.Item id="ad-placement">
        <Accordion.Heading>
          <PlacementAccordionHeading>
            <Flex justifyContent="space-between">
              <Typography variant="body-bold">Ad Placement</Typography>
              <Accordion.Indicator size={18} />
            </Flex>
            <Typography variant="body">
              {selectedPlacementsText(adPlacement)}
            </Typography>
          </PlacementAccordionHeading>
        </Accordion.Heading>
        <Accordion.Content destroyOnClose>
          {errors.adPlacement && (
            <Typography color="error-dark">
              At least one placement needs to be selected.
            </Typography>
          )}
          <Flex flexDirection="column" mt={16}>
            {adType === AdTypeEnum.enum.cpm &&
              cpmCheckBoxes.map(({ label, description }) => (
                <AdPlacementCheckbox
                  description={description}
                  label={label}
                  currentPlacement={adPlacement}
                  key={label}
                  onChangeAdPlacement={onChangeAdPlacement}
                />
              ))}
            {adType === AdTypeEnum.enum.cpc &&
              cpcCheckBoxes.map(({ label, description }) => (
                <AdPlacementCheckbox
                  description={description}
                  label={label}
                  currentPlacement={adPlacement}
                  key={label}
                  onChangeAdPlacement={onChangeAdPlacement}
                />
              ))}
          </Flex>
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
};
