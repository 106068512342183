import { useFormContext } from 'react-hook-form';

import { Button } from '@jane/shared/reefer';
import type { ButtonProps } from '@jane/shared/reefer';

export interface FormSubmitButtonProps
  extends Omit<ButtonProps, 'href' | 'onClick' | 'ref' | 'to' | 'type'> {
  disableIfClean?: boolean;
}

/**
 * Use `Form.SubmitButton` to add a `button` of `type='submit'` to a `Form`.
 *
 * `Form.SubmitButton` is wired up to `Form` state, amd will be `disabled` if:
 * - the `Form` has any invalid fields.
 * - the `Form` is currently submitting.
 */
export function FormSubmitButton({
  disabled = false,
  disableIfClean = false,
  ...props
}: FormSubmitButtonProps) {
  const {
    formState: { isDirty, isSubmitting, isValid },
  } = useFormContext();

  return (
    <Button
      disabled={
        disabled || !isValid || isSubmitting || (disableIfClean && !isDirty)
      }
      loading={isSubmitting || props.loading}
      type="submit"
      {...props}
    />
  );
}
