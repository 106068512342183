import { formatTime } from '@jane/shared/util';

export const validators = {
  isValidDateRange: (startDate?: string, endDate?: string): boolean => {
    if (!startDate || !endDate) return true;

    const formattedStartDate = formatTime(startDate, undefined, 'YYYY-MM-DD');
    const formattedEndDate = formatTime(endDate, undefined, 'YYYY-MM-DD');

    return formattedStartDate <= formattedEndDate;
  },
  isValidTimeRange: (startTime: string, endTime: string): boolean => {
    const formattedStartTime = formatTime(startTime, undefined, 'hh:mm A');
    const formattedEndTime = formatTime(endTime, undefined, 'hh:mm A');

    return formattedStartTime < formattedEndTime;
  },
};

export const validationMessages = {
  invalidStartTime: 'Start time should be earlier than the end time',
  invalidEndTime: 'End time should be later than the start time',
  invalidStartDateRange: 'Start date should be earlier than the end date',
  invalidEndDateRange: 'End date should be later than the start date',
};
