import { z } from 'zod';

import {
  zBrandSpecialUserSegmentIds,
  zInventoryPotency,
  zLabResult,
  zProductUnitsEnum,
} from '@jane/shared/types';

import { BrandSpecialPricesSchema } from './brandSpecials';

export const ProductCategoryValues = [
  'indica',
  'sativa',
  'hybrid',
  'cbd',
  '',
] as const;
export const ProductCategoryEnum = z.enum(ProductCategoryValues);
export type ProductCategory = z.infer<typeof ProductCategoryEnum>;

export const ProductCategoriesSchema = z.array(ProductCategoryEnum);

export const ProductKindEnum = z.enum([
  'gear',
  'pre-roll',
  'extract',
  'edible',
  'merch',
  'grow',
  'tincture',
  'flower',
  'topical',
  'vape',
]);
export type ProductKind = z.infer<typeof ProductKindEnum>;
export const ProductTypeEnum = z.enum([
  'indica',
  'sativa',
  'hybrid',
  'cbd',
  'vape',
  'extract',
  'pre-roll',
  'edible',
  'tincture',
  'topical',
  'gear',
  'grow',
  'merch',
  'flower',
]);
export type ProductType = z.infer<typeof ProductTypeEnum>;

export const ProductWeightEnum = z.enum([
  'half gram',
  'gram',
  'two gram',
  'eighth ounce',
  'quarter ounce',
  'half ounce',
  'ounce',
]);

export type ProductWeight = z.infer<typeof ProductWeightEnum>;

export const PriceIdEnum = z.enum([...ProductWeightEnum.options, 'each']);

export type PriceId = z.infer<typeof PriceIdEnum>;

const OptionalStringOrNumber = z.union([z.string(), z.number(), z.null()]);
const OptionalArrayOrString = z.union([z.string(), z.array(z.string())]);

export const AlgoliaProductSchema = z.object({
  activities: z.array(z.string()).nullable(),
  aggregate_rating: z.number(),
  allow_multiple_flower_count: z.boolean(),
  amount: z.string().nullish(),
  applicable_brand_special_ids: z.array(z.number()).optional(),
  applicable_brand_specials_included_user_segments: zBrandSpecialUserSegmentIds,
  applicable_brand_specials_excluded_user_segments: zBrandSpecialUserSegmentIds,
  applicable_special_ids: z.array(z.number()).optional().nullable(),
  available_weights: z.array(PriceIdEnum),
  best_seller_rank: z.number().nullable().optional(),
  brand: z.union([z.string(), z.null()]),
  brand_special_prices: BrandSpecialPricesSchema.nullable().optional(),
  brand_subtype: z.string().nullable(),
  bucket_price: z.number(),
  category: ProductCategoryEnum.nullable(),
  description: z.string(),
  discounted_price_half_gram: z.union([z.string(), z.number()]).nullable(),
  discounted_price_gram: z.union([z.string(), z.number()]).nullable(),
  discounted_price_two_gram: z.union([z.string(), z.number()]).nullable(),
  discounted_price_eighth_ounce: z.union([z.string(), z.number()]).nullable(),
  discounted_price_quarter_ounce: z.union([z.string(), z.number()]).nullable(),
  discounted_price_half_ounce: z.union([z.string(), z.number()]).nullable(),
  discounted_price_ounce: z.union([z.string(), z.number()]).nullable(),
  discounted_price_each: z.union([z.string(), z.number()]).nullable(),
  dosage: z.string().nullish(),
  feelings: z.array(z.string()),
  flavors: z.array(z.string()).optional(),
  has_brand_discount: z.boolean().optional(),
  image_urls: z.array(z.string()),
  indexed_at: z.string().optional(),
  inventory_potencies: z.array(zInventoryPotency).optional(),
  kind: ProductKindEnum,
  lab_results: z.array(zLabResult),
  max_cart_quantity: z.number(),
  max_cart_quantity_half_gram: z.number(),
  max_cart_quantity_gram: z.number(),
  max_cart_quantity_two_gram: z.number(),
  max_cart_quantity_eighth_ounce: z.number(),
  max_cart_quantity_quarter_ounce: z.number(),
  max_cart_quantity_half_ounce: z.number(),
  max_cart_quantity_ounce: z.number(),
  max_cart_quantity_each: z.number(),
  name: z.string(),
  net_weight_grams: z.number().nullish(),
  objectID: z.string(),
  percent_cbd: OptionalStringOrNumber,
  percent_cbda: OptionalStringOrNumber,
  percent_tac: OptionalStringOrNumber,
  percent_thc: OptionalStringOrNumber,
  percent_thca: OptionalStringOrNumber,
  price_each: z.union([z.string(), z.number()]).nullable(),
  price_eighth_ounce: z.union([z.string(), z.number()]).nullable(),
  price_gram: z.union([z.string(), z.number()]).nullable(),
  price_half_gram: z.union([z.string(), z.number()]).nullable(),
  price_half_ounce: z.union([z.string(), z.number()]).nullable(),
  price_ounce: z.union([z.string(), z.number()]).nullable(),
  price_quarter_ounce: z.union([z.string(), z.number()]).nullable(),
  price_two_gram: z.union([z.string(), z.number()]).nullable(),
  product_brand_id: z.optional(OptionalStringOrNumber),
  product_id: z.coerce.number(),
  product_percent_cbd: OptionalStringOrNumber,
  product_percent_thc: OptionalStringOrNumber,
  quantity_units: zProductUnitsEnum.nullish(),
  quantity_value: z.number().nullish(),
  review_count: z.number(),
  root_subtype: z.string().nullable(),
  root_types: z.array(OptionalArrayOrString),
  special_amount: z.string().nullable(),
  special_custom_badge: z.string().nullable(),
  special_id: z.number().nullable(),
  special_title: z.string().nullable(),
  store_id: z.coerce.number().nullable().optional(),
  store_notes: z.string().nullable(),
  store_specific_product: z.boolean().optional(),
  type: ProductTypeEnum.nullable(),
  unique_slug: z.string().optional(),
});

export type AlgoliaProduct = z.infer<typeof AlgoliaProductSchema>;

export const AlgoliaProductsSchema = z.array(AlgoliaProductSchema);

export type AlgoliaProducts = z.infer<typeof AlgoliaProductsSchema>;

export const AlgoliaProductKeySchema = AlgoliaProductSchema.keyof();

export type AlgoliaProductKey = z.infer<typeof AlgoliaProductKeySchema>;
