import { CampaignFlightList } from '@jane/ad-manager/components';
import { Loading } from '@jane/shared/reefer';

export const CampaignFlightListPage = () => {
  const isCampaignLoading = false;
  const isCampaignSuccess = true;
  const campaign = {
    startDate: '3/1/2024',
    endDate: '3/31/2025',
    updatedDate: '2/29/2024',
    updatedBy: '[author]',
    isActive: true,
  };
  return (
    <>
      {isCampaignLoading && <Loading />}
      {isCampaignSuccess && campaign && <CampaignFlightList />}
    </>
  );
};
