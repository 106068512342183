import styled from '@emotion/styled';
import type { MouseEventHandler } from 'react';
import { useCallback } from 'react';

import { FLAGS, marketplacePaths } from '@jane/brands/util';
import type { ProductListItem } from '@jane/catalog-cms/data-access';
import { shortDateFormat } from '@jane/catalog-cms/util';
import { useFlag } from '@jane/shared/feature-flags';
import {
  ExternalIcon,
  Flex,
  Image,
  Link,
  LocationPinIcon,
  Typography,
  useToast,
} from '@jane/shared/reefer';
import { Table } from '@jane/shared/reefer-table';

import { StatusCell } from './StatusCell';

type ProductProps =
  | 'id'
  | 'productId'
  | 'name'
  | 'category'
  | 'subcategory'
  | 'categoryLabel'
  | 'brand'
  | 'status'
  | 'imageUrl'
  | 'createdTime'
  | 'updatedTime'
  | 'storeCount'
  | 'selectedSubdivision';

// Custom typography component to limit product name to max 250px and 2 lines
const ProductNameTypography = styled(Typography)({
  whiteSpace: 'pre-line',
  width: '250px',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
  // maxHeight is fallback if line clamp isn't supported
  maxHeight: '2lh',
});

// Applies color conditionally to gray out non-active product rows
const StyledTableRow = styled(Table.Row)<{ status?: string }>(
  ({ status, theme }) => ({
    color:
      status === 'Active' ? theme.colors.grays.black : theme.colors.grays.mid,
  })
);

export interface ProductsListRowProps {
  onClick?: MouseEventHandler<HTMLTableRowElement>;
  /** Product object */
  product: Pick<ProductListItem, ProductProps>;
  /** Controls background color for the selected row.  */
  selected: boolean;
}

export const ProductsListRow = ({
  product,
  onClick,
  selected,
}: ProductsListRowProps) => {
  const stateViewEnabled = useFlag(FLAGS.pcatStateViewEnabled);
  const localizedStoreCountEnabled = useFlag(FLAGS.pcatLocalizedStoreCount);
  const createdTime = shortDateFormat(new Date(product.createdTime));
  const updatedTime = shortDateFormat(new Date(product.updatedTime));
  const toast = useToast();

  const copyProductId = useCallback(
    async (productId: number | string) => {
      try {
        await navigator.clipboard.writeText(`${productId}`);
        toast.add({
          variant: 'success',
          label: `Product ID ${productId} copied to clipboard`,
        });
      } catch (error) {
        toast.add({
          variant: 'error',
          label: 'Product ID could not be copied to clipboard',
        });
      }
    },
    [toast]
  );

  const mergedProductViewProps = mergeStateViewOverrides(
    product,
    product.selectedSubdivision
  );

  return (
    <StyledTableRow
      onClick={onClick}
      selected={selected}
      data-testid={`product-row-${product.productId}`}
      status={product.status}
    >
      <Table.Cell>
        <Flex alignItems="stretch" justifyContent="space-between" gap={24}>
          <Flex alignItems="center" gap={24}>
            <Image
              key={
                stateViewEnabled
                  ? mergedProductViewProps.imageUrl
                  : product.imageUrl
              }
              height="48px"
              width="48px"
              borderRadius="12px"
              border={true}
              objectFit="cover"
              altText={`${
                stateViewEnabled ? mergedProductViewProps.name : product.name
              } image`}
              src={
                stateViewEnabled
                  ? mergedProductViewProps.imageUrl || ''
                  : product.imageUrl || ''
              }
              responsive
            />
            <ProductNameTypography
              as="span"
              variant="body-bold"
              data-testid={`product-${product.productId}-name`}
              truncateAt={'250px'}
              title={product.name}
              color="inherit"
            >
              {stateViewEnabled ? mergedProductViewProps.name : product.name}
            </ProductNameTypography>
          </Flex>
          <Flex alignItems="center" gap={4}>
            {stateViewEnabled && product.selectedSubdivision?.id && (
              <LocationPinIcon
                title="Product has state view content"
                altText="Product has state view content"
                color="inherit"
                size="md"
              />
            )}
            <MarketplaceLink {...product} />
          </Flex>
        </Flex>
      </Table.Cell>
      <Table.Cell>
        <Link
          onClick={(e) => {
            e.stopPropagation();
            copyProductId(product.productId);
          }}
        >
          {product.productId}
        </Link>
      </Table.Cell>
      <Table.Cell>{product.category}</Table.Cell>
      <Table.Cell>{product.subcategory}</Table.Cell>
      <Table.Cell>
        <Typography
          truncateAt="200px"
          title={product.categoryLabel || undefined}
          color="inherit"
        >
          {stateViewEnabled
            ? mergedProductViewProps.categoryLabel
            : product.categoryLabel}
        </Typography>
      </Table.Cell>
      <Table.Cell>
        <Typography
          truncateAt="200px"
          title={product.brand || undefined}
          color="inherit"
        >
          {product.brand}
        </Typography>
      </Table.Cell>
      <StatusCell status={product.status} />
      <Table.Cell>{createdTime}</Table.Cell>
      <Table.Cell>{updatedTime}</Table.Cell>
      <Table.Cell>
        {localizedStoreCountEnabled
          ? mergedProductViewProps.storeCount
          : product.storeCount}
      </Table.Cell>
    </StyledTableRow>
  );
};

/**
 * Generates a link to the product on the jane marketplace with appropriate icon
 */

const MarketplaceLink = ({
  productId,
  name,
}: Pick<ProductListItem, 'productId' | 'name'>) => {
  const url = marketplacePaths.productPage(productId, name);
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      onClick={(e) => e.stopPropagation()}
      data-testid={`productlist-marketplace-link-${productId}`}
      style={{ color: 'inherit' }}
    >
      <ExternalIcon
        title="View on Jane"
        altText="View product on Jane marketplace (opens in new tab)"
        color="inherit"
        size="md"
      />
    </a>
  );
};

const mergeStateViewOverrides = (
  product: Pick<
    ProductListItem,
    'name' | 'categoryLabel' | 'imageUrl' | 'storeCount'
  >,
  selectedSubdivision: ProductListItem['selectedSubdivision']
) => {
  // Collects all subdivision values that should override product values
  const overrides = Object.fromEntries(
    Object.entries(selectedSubdivision || {}).filter(([_key, val]) => {
      return val || val === '';
    })
  ) as { [k: string]: string };

  const storeCount = selectedSubdivision
    ? `${selectedSubdivision.storeCount}/${product.storeCount}`
    : product.storeCount;

  const categoryLabel =
    overrides['categoryLabel'] === undefined
      ? product.categoryLabel
      : overrides['categoryLabel'];

  const imageUrl =
    overrides['imageUrl'] === undefined
      ? product.imageUrl
      : overrides['imageUrl'];

  return {
    name: overrides['name'] || product.name,
    categoryLabel: categoryLabel,
    imageUrl: imageUrl,
    storeCount,
  };
};
