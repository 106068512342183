import styled from '@emotion/styled';
import { useCallback, useState } from 'react';

import { useSaveDraft } from '@jane/ad-manager/data-access';
import type { BrandTheme } from '@jane/ad-manager/types';
import { uploadLogoImage } from '@jane/ad-manager/util';
import { FileUploadArea } from '@jane/shared-b2b/components';
import { Banner, ErrorIcon, Flex, InfoIcon, Modal } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

interface Props {
  brandTheme: BrandTheme;
  onClose: () => void;
  open: boolean;
}

export const BannerContainer = styled.div<{
  whiteBackground?: boolean;
}>(({ theme, whiteBackground }) => ({
  height: 72,
  border: `1px solid ${theme.colors.grays.light}`,
  borderRadius: theme.borderRadius.sm,
  width: '100%',
  display: 'flex',
  '> div': {
    backgroundColor: whiteBackground ? theme.colors.grays.white : '',
  },
}));

export const LogoModal = ({ open, onClose, brandTheme }: Props) => {
  const { mutate: saveDraft } = useSaveDraft();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onDrop = async (acceptedFiles: Array<File>) => {
    setError('');
    if (acceptedFiles.length === 0) return;
    setIsLoading(true);
    const file = acceptedFiles[0];
    try {
      const logoSrc = await uploadLogoImage(file);
      saveLogoAndCloseModal(logoSrc);
    } catch (error: any) {
      setError(error);
      setIsLoading(false);
    }
  };

  const saveLogoAndCloseModal = (logoUrl: string) => {
    const newBrandTheme = { ...brandTheme, logoUrl };
    setIsLoading(true);
    saveDraft(newBrandTheme, {
      onSuccess: () => onClose(),
      onSettled: () => setIsLoading(false),
    });
  };

  const onRequestClose = useCallback(() => {
    if (!isLoading) onClose();
  }, [isLoading, onClose]);

  return (
    <Modal
      appId="root"
      open={open}
      onRequestClose={onRequestClose}
      overlayClose={true}
      variant="standard-dialogue"
    >
      <Form name="brand-theme-assets" onSubmit={onRequestClose}>
        <Modal.Header
          title="Edit logo"
          actions={
            <Form.SubmitButton
              variant="primary"
              label="Save"
              loading={isLoading}
            />
          }
        />

        <Modal.Content>
          <Flex
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            gap={24}
          >
            {error.length > 0 && (
              <Banner
                variant="error"
                full
                icon={<ErrorIcon />}
                label={error}
                mb={24}
              />
            )}
            <FileUploadArea onDrop={onDrop} isLoading={isLoading} />
            <BannerContainer whiteBackground={true}>
              <Banner
                variant="info"
                full
                label="Logo aspect ratio must be 1:1, at least 400x400, and formatted
                  as jpg, png, or svg."
                icon={<InfoIcon />}
              />
            </BannerContainer>
          </Flex>
        </Modal.Content>
      </Form>
    </Modal>
  );
};
