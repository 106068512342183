import type { FetchStoresParams } from './stores.types';

const BASE_STORES_URL = '/jane_gold/stores';

const generateStoresUrl = (props: FetchStoresParams): string => {
  const urlParams = new URLSearchParams();

  if (props?.ids?.length) {
    urlParams.set('ids', props.ids.join(','));
  }
  if (props?.page) {
    urlParams.set('page', props.page.toString());
  }
  if (props?.perPage) {
    urlParams.set('per_page', props.perPage.toString());
  }
  if (props?.query) {
    urlParams.set('query', props.query);
  }

  const queryString = urlParams.toString();
  return `${BASE_STORES_URL}${queryString ? `?${queryString}` : ''}`;
};

export const queryUrls = {
  stores: (props: FetchStoresParams) => generateStoresUrl(props),
};

export const queryKeys = {
  stores: (props: FetchStoresParams) => ['stores', props],
};
