import { brandsPaths } from '@jane/brands/util';

export type BrandPermission =
  | 'accessAnalytics'
  | 'accessBrandPages'
  | 'accessDigitalMerchandising'
  | 'accessJaneGold'
  | 'accessProductsPage'
  | 'manageUsers';

export type BrandRole =
  | 'analytics:external'
  | 'brand_page:external'
  | 'catalog:external'
  | 'catalog:internal'
  | 'catalog:internal_admin'
  | 'dm:external'
  | 'dm:internal'
  | 'gold:internal'
  | 'super_admin';

type BrandPermissionMap = {
  [key in BrandRole]: BrandPermission[];
};

export const BRAND_PERMISSION_MAP: BrandPermissionMap = {
  'analytics:external': ['accessAnalytics', 'accessProductsPage'],
  'brand_page:external': ['accessBrandPages', 'accessProductsPage'],
  'catalog:external': ['accessProductsPage'],
  'catalog:internal': ['accessProductsPage', 'manageUsers'],
  'catalog:internal_admin': ['accessProductsPage', 'manageUsers'],
  'dm:external': ['accessDigitalMerchandising', 'accessProductsPage'],
  'dm:internal': [
    'manageUsers',
    'accessDigitalMerchandising',
    'accessProductsPage',
    'accessBrandPages',
  ],
  'gold:internal': ['accessJaneGold'],
  super_admin: [
    'accessAnalytics',
    'accessBrandPages',
    'accessDigitalMerchandising',
    'accessJaneGold',
    'accessProductsPage',
    'manageUsers',
  ],
};

export const checkPermission = (
  roles: string[],
  permission: BrandPermission
): boolean =>
  roles.some((role) => {
    // typecast role to allow for looser typing of string[] for roles on the user object
    const brandRole = role as BrandRole;

    return BRAND_PERMISSION_MAP[brandRole].includes(permission);
  });

/* Fallbacks are determined by the order of the nav links */
export const getFallbackPath = (roles: string[]): string => {
  if (checkPermission(roles, 'accessProductsPage')) {
    return brandsPaths.products();
  }
  if (checkPermission(roles, 'accessBrandPages')) {
    return brandsPaths.brandThemes();
  }

  if (checkPermission(roles, 'accessAnalytics')) {
    return brandsPaths.analytics();
  }

  if (checkPermission(roles, 'accessDigitalMerchandising')) {
    return brandsPaths.digitalMerchandising();
  }

  if (checkPermission(roles, 'accessJaneGold')) {
    return brandsPaths.specials();
  }

  return brandsPaths.products();
};
