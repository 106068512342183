import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';

import type {
  DailySchedule,
  ManualScheduleForDays,
} from '@jane/shared-b2b/components';
import {
  DailyScheduleSelect,
  TIME_SLOT_OPTIONS,
  validators,
} from '@jane/shared-b2b/components';
import { Card, ChevronDownIcon, Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';
import { formatTime } from '@jane/shared/util';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import {
  DEFAULT_END_TIME,
  DEFAULT_START_TIME,
} from '../utils/specialForm.util';
import type { SpecialFormData } from '../utils/useSpecialFormData';

export const ORDERED_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export const ScheduleSection = () => {
  const {
    trigger,
    clearErrors,
    formState: { errors },
    setError,
    setValue,
    watch,
  } = useFormContext<SpecialFormData>();

  const [endDate, endTime, schedule, startDate, startTime] = watch([
    'endDate',
    'endTime',
    'schedule',
    'startDate',
    'startTime',
  ]);

  const { dailySchedule, scheduledDays, hasCustomSchedule } = schedule || {};
  const today = formatTime(dayjs(), 'YYYY-MM-DD');

  const [open, setOpen] = useState(hasCustomSchedule);
  const [customSchedule, setCustomSchedule] = useState<boolean>(
    hasCustomSchedule || false
  );
  const [dailyScheduleState, setDailyScheduleState] = useState<DailySchedule>(
    dailySchedule || {}
  );
  const [scheduledDaysState, setScheduledDaysState] =
    useState<ManualScheduleForDays>(scheduledDays || {});
  const hasDefaultSchedule = Object.keys(dailyScheduleState).length !== 0;

  const onOptionChange = useCallback(
    (newOption: 'allDays' | 'customSchedule') => {
      if (newOption === 'allDays') {
        setValue('scheduleType', 'all');
        setCustomSchedule(false);
        setValue('schedule', {
          dailySchedule: {},
          hasCustomSchedule: false,
          scheduledDays: {},
        });

        if (errors?.scheduleType) {
          clearErrors('scheduleType');
          trigger('scheduleType');
        }
      } else if (!hasCustomSchedule || !hasDefaultSchedule) {
        const defaultSchedule: DailySchedule = {};
        ORDERED_DAYS.forEach((day) => {
          defaultSchedule[day] = {
            endTime: DEFAULT_END_TIME,
            startTime: DEFAULT_START_TIME,
          };
        });
        setDailyScheduleState(defaultSchedule);
        setScheduledDaysState({});
        setValue('scheduleType', 'custom');
        setCustomSchedule(true);
      } else {
        setValue('scheduleType', 'custom');
        setCustomSchedule(true);
      }
    },
    [hasCustomSchedule, hasDefaultSchedule]
  );

  useEffect(() => {
    const hasEndTimeWithoutEndDate = Boolean(
      (!endDate || endDate?.length === 0) && endTime
    );

    clearErrors(['startDate', 'endDate', 'startTime']);

    if (
      startDate === endDate &&
      startTime &&
      endTime &&
      !validators.isValidTimeRange(startTime, endTime)
    ) {
      setError('startTime', {
        type: 'custom',
        message: 'Invalid time range. Please check the start and end times.',
      });
    }

    if (
      startDate &&
      endDate &&
      !validators.isValidDateRange(startDate, endDate)
    ) {
      setError('startDate', {
        type: 'custom',
        message: 'Invalid date range. Please check the start and end dates.',
      });
    }

    if (hasEndTimeWithoutEndDate) {
      setError('endDate', {
        type: 'custom',
        message: 'Setting an end time requires setting an end date.',
      });
    }
  }, [startDate, endDate, startTime, endTime, clearErrors, setError]);

  return (
    <Card width="100%">
      <StyledCardGroup width="100%">
        <SpecialFormBlock first>
          <Typography variant="header-bold" branded mb={32}>
            Schedule
          </Typography>
          <Flex flexDirection="column" gap={24}>
            <Typography variant="body-bold" id="missing-status">
              Status
            </Typography>
            <Form.RadioFieldGroup
              name="enabled"
              options={[
                { id: 'enabled', label: 'Enabled', value: true },
                { id: 'disabled', label: 'Disabled', value: false },
              ]}
              required
            />
          </Flex>
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Flex flexDirection="column" gap={24}>
            <Typography variant="body-bold" id="missing-schedule">
              Date and time
            </Typography>
            <Flex gap={24}>
              <Form.DateTimeField
                label="Start date"
                name="startDate"
                min={today}
                width="100%"
                required
              />
              <Form.DateTimeField
                label={
                  <Typography variant="body-bold">
                    End date <Typography as="span">(Optional)</Typography>
                  </Typography>
                }
                name="endDate"
                min={today}
                width="100%"
              />
            </Flex>

            <Flex gap={24}>
              <Form.SelectField
                label={
                  <Typography variant="body-bold">
                    Start time <Typography as="span">(Optional)</Typography>
                  </Typography>
                }
                name="startTime"
                width="100%"
                options={TIME_SLOT_OPTIONS}
              />
              <Form.SelectField
                label={
                  <Typography variant="body-bold">
                    End time <Typography as="span">(Optional)</Typography>
                  </Typography>
                }
                name="endTime"
                width="100%"
                options={TIME_SLOT_OPTIONS}
              />
            </Flex>
          </Flex>
          {errors?.schedule && (
            <Typography color="error" mt={16}>
              {errors.schedule.message}
            </Typography>
          )}
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Flex
            flexDirection="row"
            width="100%"
            alignItems="center"
            mb={open ? 24 : 0}
            onClick={() => setOpen((prev) => !prev)}
            aria-label={`${open ? 'Close' : 'Open'} days active section`}
          >
            <Flex flexDirection="column" mb={open ? 24 : 0}>
              <Typography variant="body-bold">Days active</Typography>
              {!open && (
                <Typography>
                  {!customSchedule ? 'All days' : 'Custom schedule'}
                </Typography>
              )}
            </Flex>

            <ChevronDownIcon ml="auto" rotate={open ? 'down' : 'up'} />
          </Flex>
          {open && (
            <>
              <Form.RadioFieldGroup
                name="daysActive"
                options={[
                  { id: 'allDays', label: 'All days', value: 'allDays' },
                  {
                    id: 'customSchedule',
                    label: 'Custom schedule',
                    value: 'customSchedule',
                  },
                ]}
                defaultChecked={customSchedule ? 'customSchedule' : 'allDays'}
                disableMobileInputStyling
                onChange={onOptionChange}
              />
              {customSchedule && (
                <DailyScheduleSelect
                  schedule={dailyScheduleState}
                  onChangeSchedule={(value) => {
                    setDailyScheduleState(value);
                    setValue('schedule', {
                      dailySchedule: value,
                      hasCustomSchedule: true,
                      scheduledDays: scheduledDaysState,
                    });

                    if (errors?.scheduleType) {
                      clearErrors('scheduleType');
                      trigger('scheduleType');
                    }
                  }}
                  daysWithScheduling={scheduledDaysState}
                  setDaysWithScheduling={(value) => {
                    setScheduledDaysState(value);
                    setValue('schedule', {
                      dailySchedule: dailyScheduleState,
                      hasCustomSchedule: true,
                      scheduledDays: value,
                    });
                  }}
                  buttonColor="secondary"
                  timeSlotInfo="Configure specific times on specific days for this special to
              serve."
                />
              )}
            </>
          )}
          {errors?.scheduleType && (
            <Typography color="error" mt={16}>
              {errors.scheduleType.message}
            </Typography>
          )}
        </SpecialFormBlock>
      </StyledCardGroup>
    </Card>
  );
};
