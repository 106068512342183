import { useInfiniteQuery } from '@tanstack/react-query';
import qs from 'qs';

import { brandsApi } from '@jane/brands/data-access';
import { createUseQueryHook } from '@jane/shared/data-access';
import { parseData } from '@jane/shared/util';

import type {
  BrandCategory,
  BrandProduct,
  BrandProductFilters,
  FetchBrandProductFiltersParams,
  FetchBrandProductsParams,
  UseInfiniteBrandProductsParams,
} from './brandProducts.types';
import {
  BrandCategoriesResponse,
  BrandProductFiltersResponseSchema,
  BrandProductsResponseSchema,
} from './brandProducts.types';

const BASE_KEY = ['brand'];

//Note: If changing the products queryKey; update the associated function in productSelection.tsx with the new key
const queryKeys = {
  brandCategories: (brandId: number) => [
    ...BASE_KEY,
    'products',
    'categories',
    brandId,
  ],
  products: (params: FetchBrandProductsParams) => [
    ...BASE_KEY,
    'products',
    params,
  ],
  infiniteProducts: (params: FetchBrandProductsParams) => [
    ...BASE_KEY,
    'infiniteProducts',
    params,
  ],
  filters: (params: FetchBrandProductFiltersParams) => [
    ...BASE_KEY,
    'filters',
    params,
  ],
};

export const getBrandProductsUrl = (brandId: string, queryParams?: string) =>
  `/jane_gold/brands/${brandId}/products${queryParams ? `${queryParams}` : ''}`;

export const fetchBrandProductFilters = async ({
  brandId,
  category,
}: FetchBrandProductFiltersParams) => {
  const params = `/filters${
    category?.length
      ? `?${qs.stringify({ category }, { arrayFormat: 'brackets' })}`
      : ''
  }`;
  const filtersUrl = getBrandProductsUrl(brandId, params);
  const { data } = await brandsApi.get(filtersUrl);
  const parsedData = parseData(BrandProductFiltersResponseSchema, data);
  return parsedData.filters;
};

export const useBrandProductFilters = createUseQueryHook<
  FetchBrandProductFiltersParams,
  BrandProductFilters
>(fetchBrandProductFilters, queryKeys.filters, (params) => !!params.brandId);

export const fetchBrandProducts = async ({
  brandId,
  category,
  ids,
  limit = 100000,
  lineage,
  offset,
  query,
  subcategory,
}: FetchBrandProductsParams) => {
  const queryParams = {
    ids,
    limit,
    offset,
    category,
    subcategory,
    lineage,
    query,
  };

  const queryString = `?${qs.stringify(queryParams, {
    arrayFormat: 'brackets',
  })}`;

  const productsUrl = getBrandProductsUrl(brandId, queryString);
  const { data } = await brandsApi.get(productsUrl);

  const parsedData = parseData(BrandProductsResponseSchema, data);
  return parsedData.products;
};

export const useBrandProducts = createUseQueryHook<
  FetchBrandProductsParams,
  BrandProduct[]
>(
  fetchBrandProducts,
  queryKeys.products,
  (params) =>
    !!params.brandId && (typeof params.enabled !== 'boolean' || params.enabled)
);

export const useInfiniteBrandProducts = ({
  enabled,
  ...params
}: UseInfiniteBrandProductsParams) => {
  return useInfiniteQuery({
    enabled,
    queryFn: async ({ pageParam = 0 }) => {
      const products = await fetchBrandProducts({
        ...params,
        brandId: params.brandId,
        limit: params.perPage,
        offset: pageParam * params.perPage,
      });
      return {
        products,
        pageParam,
      };
    },
    queryKey: queryKeys.infiniteProducts(params),
    getNextPageParam: (lastPage) => {
      const isLastPage = (lastPage.products || []).length < params.perPage;
      return isLastPage ? undefined : lastPage.pageParam + 1;
    },
  });
};

const fetchBrandCategories = async (brandId: number) => {
  const { data } = await brandsApi.get(
    `/jane_gold/brands/${brandId}/products/brand_categories`
  );
  const parsedData = parseData(BrandCategoriesResponse, data);
  return parsedData.brand_categories;
};

export const useBrandCategories = createUseQueryHook<number, BrandCategory[]>(
  fetchBrandCategories,
  queryKeys.brandCategories,
  (brandId: number) => !!brandId
);
