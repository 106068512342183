import { z } from 'zod';

const zStore = z.object({
  address: z.string(),
  city: z.string().nullable(),
  id: z.number(),
  medical: z.boolean().optional(),
  name: z.string(),
  recreational: z.boolean(),
  state: z.string().nullable(),
  zip: z.string(),
});

export type Store = z.infer<typeof zStore>;

export { zStore };
