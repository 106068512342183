import { z } from 'zod';

import { zStore } from '@jane/gold-manager/types';

export const StoresSchema = z.object({
  stores: z.array(zStore),
  meta: z.object({
    number_of_pages: z.number(),
    page: z.number(),
    page_total: z.number(),
    per_page: z.number(),
    total: z.number(),
  }),
});

export type StoresResponse = z.infer<typeof StoresSchema>;

export interface FetchStoresParams {
  ids?: number[] | string[];
  page?: number;
  perPage?: number;
  query?: string;
}
