/**
 * DO NOT MODIFY THIS FILE!
 * It was created by the reefer-icons generator.
 * Please update & re-run the generator if need be.
 */
import type { SVGProps } from 'react';

import type { StaticIconProps } from '../../../icon/icon.types';
import { Icon } from '../../icon';

const Megaphone64 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    fill="none"
    viewBox="0 0 65 64"
    {...props}
  >
    <g className="Megaphone 64">
      <path
        stroke="#0E0E0E"
        strokeWidth={4}
        d="M10.603 23.333h2.555c.443 0 .879-.111 1.267-.325L36.06 11.102c2.221-1.222 4.94.385 4.94 2.92v35.922c0 2.54-2.728 4.147-4.95 2.915l-21.619-11.99a2.626 2.626 0 0 0-1.274-.33h-2.554a8.603 8.603 0 0 1 0-17.206Z"
        className="Vector 165"
      />
      <path
        stroke="#0E0E0E"
        strokeWidth={4}
        d="M43 40a8 8 0 1 0 0-16"
        className="Ellipse 280"
      />
      <path
        stroke="#0E0E0E"
        strokeLinecap="round"
        strokeWidth={4}
        d="M56 47.17c8.331-8.331 8.331-21.839 0-30.17"
        className="Ellipse 281"
      />
      <path
        stroke="#0E0E0E"
        strokeLinecap="round"
        strokeWidth={4}
        d="M13.333 24v26.667"
        className="Vector 166"
      />
      <path
        stroke="#0E0E0E"
        strokeLinecap="round"
        strokeWidth={4}
        d="M33 43v8"
        className="Vector 167"
      />
      <path
        stroke="#0E0E0E"
        strokeLinecap="round"
        strokeWidth={4}
        d="M33 14v8"
        className="Vector 168"
      />
    </g>
  </svg>
);

const SIZE_MAP = {
  xxl: Megaphone64,
  xl: Megaphone64,
  lg: Megaphone64,
  md: Megaphone64,
  sm: Megaphone64,
  xxxl: Megaphone64,
};

export const MegaphoneIcon = ({
  size = 'md',
  'data-testid': testId,
  ...props
}: StaticIconProps) => {
  return (
    <Icon
      data-testid={testId || 'megaphone-icon'}
      icon={SIZE_MAP[size]}
      {...props}
      size={size}
      color={undefined}
    />
  );
};
