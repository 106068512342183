import styled from '@emotion/styled';

import type { DisplayPlatformsOptionIdentifier } from '@jane/ad-manager/types';
import { Accordion } from '@jane/shared/components';
import { Flex, Typography } from '@jane/shared/reefer';
import { Form, useFormContext, useWatch } from '@jane/shared/reefer-hook-form';

import type { AdSubmissionFormState } from '../formState/types';

const PlacementAccordionHeading = styled.div(({ theme }) => ({
  background: theme.colors.grays.white,
}));

type DisplayPlatformOption = {
  id: DisplayPlatformsOptionIdentifier;
  label: string;
  value: DisplayPlatformsOptionIdentifier;
};

const displayPlatformOptions: DisplayPlatformOption[] = [
  {
    label: 'All platforms',
    id: 'all-platforms',
    value: 'all-platforms',
  },
  {
    label: 'Web',
    id: 'web',
    value: 'web',
  },
  {
    label: 'Kiosk',
    id: 'kiosk',
    value: 'kiosk',
  },
];

export const DisplayPlatforms = () => {
  const { getValues } = useFormContext();
  const displayPlatforms = useWatch<AdSubmissionFormState, 'displayPlatforms'>({
    name: 'displayPlatforms',
    defaultValue: getValues('displayPlatforms'),
  });
  const selectedPlatformText = () => {
    const selectedOption = displayPlatformOptions.find(
      (option) => option.value === displayPlatforms
    );
    return selectedOption?.label;
  };

  return (
    <Accordion>
      <Accordion.Item id="ad-display-platforms">
        <Accordion.Heading>
          <PlacementAccordionHeading>
            <Flex justifyContent="space-between">
              <Typography variant="body-bold">Displays on</Typography>
              <Accordion.Indicator size={18} />
            </Flex>
            <Typography variant="body">{selectedPlatformText()}</Typography>
          </PlacementAccordionHeading>
        </Accordion.Heading>
        <Accordion.Content>
          <Form.RadioFieldGroup
            name="displayPlatforms"
            options={displayPlatformOptions}
            mt={32}
            ml={4}
            shouldUnregister={false}
            validate={(
              platforms: AdSubmissionFormState['displayPlatforms']
            ) => {
              if (!platforms || platforms.length === 0)
                return 'At least one placement needs to be selected.';
              return true;
            }}
          />
        </Accordion.Content>
      </Accordion.Item>
    </Accordion>
  );
};
