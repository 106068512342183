import { useInfiniteQuery } from '@tanstack/react-query';

import { brandsApi } from '@jane/brands/data-access';
import { parseData } from '@jane/shared/util';

import type { FetchStoresParams, StoresResponse } from './stores.types';
import { StoresSchema } from './stores.types';
import { queryKeys, queryUrls } from './stores.util';

const fetchStores = async (props: FetchStoresParams) => {
  const { data } = await brandsApi.get(queryUrls.stores(props));
  const parsedData = parseData(StoresSchema, data);
  return parsedData;
};

export const useInfiniteStores = ({
  enabled = true,
  ...props
}: FetchStoresParams & { enabled?: boolean }) =>
  useInfiniteQuery<StoresResponse>({
    queryFn: async ({ pageParam = props.page ?? 1 }) => {
      const data = await fetchStores({ ...props, page: pageParam });
      return { ...data, pageParam };
    },
    queryKey: queryKeys.stores(props),
    getNextPageParam: (lastPage) => {
      const hasNextPage = lastPage.meta.page < lastPage.meta.number_of_pages;
      return hasNextPage ? lastPage.meta.page + 1 : undefined;
    },
    enabled,
    cacheTime: 5 * 1000 * 60,
    staleTime: 5 * 1000 * 60,
  });
