// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media (min-width: 768px) {\n  .S79bTbwVftULnJYLo1mA {\n    flex-direction: column;\n  }\n}\n\n.LiogObVScIjnXAtNzNYA:not(:focus):not(:active) {\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  overflow: hidden;\n  opacity: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n}\n\n.ZRWEoIle5cFb2Inxo69p {\n  order: 3;\n}\n\n@media (max-width: 768px) {\n  .ASKQg7gGjmAJUyTWU_dG {\n    margin-top: 16px;\n  }\n}\n\n.D4votBfvvWASVe8R8IpB {\n  opacity: 0.3;\n}\n", "",{"version":3,"sources":["webpack://./../../libs/shared/reefer/src/lib/components/fields/sliderField/doubleSliderField.module.css"],"names":[],"mappings":"AAAA;EACE;IACE,sBAAsB;EACxB;AACF;;AAEA;EACE,mBAAmB;EACnB,qBAAqB;EACrB,WAAW;EACX,gBAAgB;EAChB,UAAU;EACV,kBAAkB;EAClB,mBAAmB;EACnB,UAAU;AACZ;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE;IACE,gBAAgB;EAClB;AACF;;AAEA;EACE,YAAY;AACd","sourcesContent":["@media (min-width: 768px) {\n  .doubleSlider_fieldset {\n    flex-direction: column;\n  }\n}\n\n.doubleSlider_legend__isHidden:not(:focus):not(:active) {\n  clip: rect(0 0 0 0);\n  clip-path: inset(50%);\n  height: 1px;\n  overflow: hidden;\n  opacity: 0;\n  position: absolute;\n  white-space: nowrap;\n  width: 1px;\n}\n\n.doubleSlider_helperText {\n  order: 3;\n}\n\n@media (max-width: 768px) {\n  .doubleSlider_helperText__labelHidden {\n    margin-top: 16px;\n  }\n}\n\n.doubleSlider_sliders__disabled {\n  opacity: 0.3;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"doubleSlider_fieldset": "S79bTbwVftULnJYLo1mA",
	"doubleSlider_legend__isHidden": "LiogObVScIjnXAtNzNYA",
	"doubleSlider_helperText": "ZRWEoIle5cFb2Inxo69p",
	"doubleSlider_helperText__labelHidden": "ASKQg7gGjmAJUyTWU_dG",
	"doubleSlider_sliders__disabled": "D4votBfvvWASVe8R8IpB"
};
export default ___CSS_LOADER_EXPORT___;
