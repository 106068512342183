import { useEffect } from 'react';

import { Card, Typography } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import { handleScroll } from '../utils/specialForm.util';
import { BrandsDropdown } from './brandsDropdown';
import { ImageUpload } from './imageUpload';

export const DetailsSection = () => {
  const { userSegments } = useSpecialForm();
  const { clearErrors, setError } = useFormContext();
  const hasSegments = userSegments?.length;

  const aeropayIdHash = window.location.hash.includes('aeropay')
    ? window.location.hash.split('#')[1]
    : '';

  useEffect(() => {
    handleScroll(aeropayIdHash);
  }, [aeropayIdHash]);

  return (
    <Card width="100%" id="details-card">
      <StyledCardGroup width="100%">
        <SpecialFormBlock first>
          <Typography variant="header-bold" branded mb={32}>
            Details
          </Typography>
          <BrandsDropdown />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Form.TextField
            name="title"
            label="Title"
            required
            disableMobileInputStyling
          />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Form.TextField
            name="description"
            label={
              <Typography variant="body-bold">
                Description <Typography as="span">(Optional)</Typography>
              </Typography>
            }
            disableMobileInputStyling
          />
        </SpecialFormBlock>

        <SpecialFormBlock bottomPadding={'16px'}>
          <Typography mb={24} variant="body-bold">
            Type
          </Typography>
          <Form.RadioFieldGroup
            name="campaignType"
            id="campaign-type"
            options={[
              { id: 'type-new', label: 'New', value: 'new' },
              { id: 'type-lapsed', label: 'Lapsed', value: 'lapsed' },
              {
                id: 'type-untargeted',
                label: 'Untargeted',
                value: 'untargeted',
              },
            ]}
            required
            onChange={(value) => {
              if ((value === 'new' || value === 'lapsed') && !hasSegments) {
                setError('userSegments', {
                  type: 'required',
                  message: 'User segments are required.',
                });
              } else if (value === 'untargeted' && hasSegments) {
                setError('userSegments', {
                  type: 'required',
                  message: "Special type must be 'New' or 'Lapsed'.",
                });
              } else {
                clearErrors('userSegments');
              }
            }}
          />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <ImageUpload />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Form.TextAreaField
            name="terms"
            label="Terms"
            rows={4}
            resize={false}
            defaultValue={`Getting cash back requires three steps: \n1) Join by signing in or creating a Jane account\n2) Link your bank account quickly and safely\n3) Earn by purchasing a qualifying product from a participating brand. While supplies last.`}
            disableMobileInputStyling
          />
        </SpecialFormBlock>

        <SpecialFormBlock>
          <Form.TextField
            id="aeropay-location-id"
            name="aeropayLocationId"
            label="Aeropay Location ID"
            disableMobileInputStyling
            autoFocus={!!aeropayIdHash}
            required
          />
        </SpecialFormBlock>
      </StyledCardGroup>
    </Card>
  );
};
