import { createContext, useContext, useMemo, useState } from 'react';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

import type {
  BrandSpecial,
  BrandSpecialRules,
} from '@jane/gold-manager/data-access';
import type { KindCondition, ProductWeight } from '@jane/shared/models';

import { getExclusionRule } from '../utils/specialForm.util';

export type RulesKeys =
  | 'kinds'
  | 'product_ids'
  | 'states'
  | 'store_ids'
  | 'user_segment_ids'
  | 'weights';
type RuleSection = 'includes' | 'excludes';
type RuleValue = ProductWeight[] | KindCondition[] | string[] | number[];

interface SpecialFormProviderValue {
  alertEmails: string[];
  brandSpecial?: BrandSpecial;
  duplicate?: boolean;
  excludedUserSegments?: number[];
  resetRulesValue: () => void;
  rules: {
    excludes?: BrandSpecialRules['excludes'];
    includes?: BrandSpecialRules['includes'];
  };
  setAlertEmails: Dispatch<SetStateAction<string[]>>;
  setRulesValue: (key: `${RuleSection}.${RulesKeys}`, rule: RuleValue) => void;
  userSegments?: number[];
}

const SpecialFormContext = createContext<SpecialFormProviderValue>(
  {} as SpecialFormProviderValue
);

export const SpecialFormProvider = ({
  children,
  brandSpecial,
  duplicate,
}: {
  brandSpecial?: BrandSpecial;
  children: ReactNode;
  duplicate?: boolean;
}) => {
  const [alertEmails, setAlertEmails] = useState<string[]>(
    brandSpecial?.alert_emails ?? []
  );
  const [rules, setRules] = useState<BrandSpecialRules>(
    brandSpecial?.rules ?? {}
  );

  // ex. handleSetRules("includes.weights", ["ounce"])
  // This updates the one includes object or adds/replaces the proper excludes object
  const handleSetRules = (
    key: `${RuleSection}.${RulesKeys}`,
    rule: RuleValue
  ) => {
    setRules((prevValue: BrandSpecialRules) => {
      const [section, field] = key.split('.') as [RuleSection, RulesKeys];

      if (section === 'includes') {
        return {
          ...prevValue,
          includes: [
            {
              ...(prevValue.includes?.[0] ?? {}),
              [field]: rule,
            },
          ],
        };
      }

      return {
        ...prevValue,
        excludes: [
          // Keep all other exclude rules that don't match this field
          ...(prevValue.excludes?.filter((r) => !(field in r)) ?? []),
          // Add/update the new rule
          { [field]: rule } as any,
        ],
      };
    });
  };

  const excludedUserSegments = useMemo(
    () => getExclusionRule(rules, 'user_segment_ids'),
    [rules]
  );

  const userSegments = useMemo(
    () => [
      ...excludedUserSegments,
      ...(rules?.includes?.[0].user_segment_ids ?? []),
    ],
    [rules, excludedUserSegments]
  );

  const value = useMemo(
    () => ({
      alertEmails,
      brandSpecial,
      duplicate,
      excludedUserSegments,
      resetRulesValue: () => setRules({}),
      rules,
      setAlertEmails,
      setRulesValue: handleSetRules,
      userSegments,
    }),
    [
      alertEmails,
      brandSpecial,
      duplicate,
      excludedUserSegments,
      rules,
      userSegments,
    ]
  );

  return (
    <SpecialFormContext.Provider value={value}>
      {children}
    </SpecialFormContext.Provider>
  );
};

export const useSpecialForm = () => {
  const context = useContext(SpecialFormContext);

  if (context === undefined) {
    throw new Error('useSpecialForm must be used within a SpecialFormProvider');
  }

  return context;
};
