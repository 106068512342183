import { forwardRef, useImperativeHandle, useState } from 'react';

import { Banner, ErrorIcon, Flex, Link } from '@jane/shared/reefer';
import { Form, useForm } from '@jane/shared/reefer-hook-form';

import { AlertsSection } from './alertsSection/alertsSection';
import { BudgetSection } from './budgetSection/budgetSection';
import { DetailsSection } from './detailsSection/detailsSection';
import { ProductsSection } from './productsSection/productsSection';
import { ScheduleSection } from './scheduleSection/scheduleSection';
import { SpecialFormWrapper } from './specialForm.styles';
import { TargetingSection } from './targetingSection/targetingSection';
import { handleScroll } from './utils/specialForm.util';
import { useSpecialFormData } from './utils/useSpecialFormData';
import type { SpecialFormData } from './utils/useSpecialFormData';

export const SpecialForm = forwardRef(
  ({ duplicate }: { duplicate?: boolean }, ref) => {
    const { defaultValues, submitForm } = useSpecialFormData();
    const formMethods = useForm<SpecialFormData>({ defaultValues });
    const [showBanners, setShowBanners] = useState(duplicate);
    const [formValidationError, setFormValidationError] = useState('');
    const invalidSchedule =
      formMethods.getValues('scheduleType') === 'custom' &&
      Object.keys(formMethods.getValues('schedule').dailySchedule).length === 0;
    const isNotValid =
      Object.keys(formMethods.formState.errors).length > 0 ||
      !formMethods.formState.isValid ||
      !formMethods.getValues('productBrandId') ||
      !formMethods.getValues('aeropayLocationId') ||
      invalidSchedule;

    const onSubmit = async (formValues: SpecialFormData) => {
      const result = await submitForm(formValues);
      return result;
    };

    const onBannerClick = (scrollId: string) => {
      handleScroll(scrollId);
      setShowBanners(false);
    };

    const handleValidation = () => {
      if (isNotValid) {
        if (!formMethods.getValues('productBrandId')) {
          formMethods.setError('productBrandId', {
            type: 'required',
            message: 'Please fill out this field.',
          });
        }
        if (!formMethods.getValues('aeropayLocationId')) {
          formMethods.setError('aeropayLocationId', {
            type: 'required',
            message: 'Please fill out this field.',
          });
        }
        if (invalidSchedule) {
          formMethods.setError('scheduleType', {
            type: 'required',
            message:
              'If choosing a custom schedule, please select days when the special will be active.',
          });
        }
        setFormValidationError(
          'Please fill out required fields before submitting form.'
        );
        handleScroll('validation-error');
        return false;
      }
      setFormValidationError('');
      return true;
    };

    useImperativeHandle(ref, () => ({
      handleValidation: handleValidation,
      submit: () =>
        new Promise((resolve) => {
          formMethods.handleSubmit(async (data) => {
            const result = await onSubmit(data);
            resolve(result);
          })();
        }),
    }));

    return (
      <SpecialFormWrapper>
        <Form.BaseForm
          name="brand-special-form"
          id="brand-special-form-id"
          formMethods={formMethods}
          onSubmit={(data) => formMethods.handleSubmit(onSubmit)(data as any)}
        >
          <Flex flexDirection="column" gap={24}>
            {showBanners && (
              <Flex flexDirection="column" gap={16}>
                <Banner
                  icon={<ErrorIcon />}
                  label="Missing status"
                  variant="error"
                  actions={
                    <Link
                      onClick={() => onBannerClick('missing-status')}
                      mr={16}
                    >
                      View
                    </Link>
                  }
                />
                <Banner
                  icon={<ErrorIcon />}
                  label="Missing start date"
                  variant="error"
                  actions={
                    <Link
                      onClick={() => onBannerClick('missing-schedule')}
                      mr={16}
                    >
                      View
                    </Link>
                  }
                />
              </Flex>
            )}
            {formValidationError && (
              <Banner
                id="validation-error"
                label={formValidationError}
                variant="error"
                icon={<ErrorIcon />}
              />
            )}
            <DetailsSection />
            <BudgetSection />
            <ProductsSection />
            <TargetingSection />
            <ScheduleSection />
            <AlertsSection />
          </Flex>
        </Form.BaseForm>
      </SpecialFormWrapper>
    );
  }
);
