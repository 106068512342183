import type { PropsWithChildren } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Outlet } from 'react-router-dom';

import { useGetAllBrands, useGetBrand } from '@jane/ad-manager/data-access';
import {
  useBrandIdSearchParam,
  useSetDefaultBrandOrNavigate,
} from '@jane/ad-manager/hooks';
import type { ProductBrand } from '@jane/ad-manager/types';

interface BrandContextValue {
  defaultBrand?: ProductBrand;
  isLoading: boolean;
  isSuccess: boolean;
  selectedBrand?: ProductBrand;
  setSelectedBrand: (newBrand?: ProductBrand) => unknown;
}

export const SelectedBrandContext = createContext<BrandContextValue>({
  defaultBrand: undefined,
  selectedBrand: undefined,
  setSelectedBrand: () => undefined,
  isLoading: true,
  isSuccess: false,
});

export const SelectedBrandProvider = ({ children }: PropsWithChildren) => {
  const [selectedBrand, setSelectedBrand] =
    useState<BrandContextValue['selectedBrand']>(undefined); // Initial brand state

  const getAllBrandsQueryResult = useGetAllBrands();

  const { brandIdFromSearchParams, setBrandIdInSearchParams } =
    useBrandIdSearchParam();

  const getBrandFromSearchParamsQueryResult = useGetBrand(
    brandIdFromSearchParams
  );

  useSetDefaultBrandOrNavigate({
    setSelectedBrand,
    selectedBrand,
    getAllBrandsQueryResult,
    getBrandFromSearchParamsQueryResult,
  });

  const handleSetSelectedBrand: typeof setSelectedBrand = useCallback(
    (brand) => {
      setSelectedBrand(brand);
      if (brand && typeof brand !== 'function') {
        setBrandIdInSearchParams(brand.id);
      }
    },
    [setBrandIdInSearchParams]
  );

  const { isLoading: isAllBrandsLoading, isSuccess: isAllBrandsSuccess } =
    getAllBrandsQueryResult;

  const contextValue = useMemo(
    () => ({
      selectedBrand,
      setSelectedBrand: handleSetSelectedBrand,
      defaultBrand: getAllBrandsQueryResult.data?.[0],
      isLoading: isAllBrandsLoading,
      isSuccess: isAllBrandsSuccess,
    }),
    [
      getAllBrandsQueryResult.data,
      handleSetSelectedBrand,
      isAllBrandsLoading,
      isAllBrandsSuccess,
      selectedBrand,
    ]
  );

  return (
    <SelectedBrandContext.Provider value={contextValue}>
      {children || <Outlet />}
    </SelectedBrandContext.Provider>
  );
};

export function useSelectedBrandContext() {
  return useContext(SelectedBrandContext);
}
