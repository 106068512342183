import { getPastDate, getToday } from '@jane/gold-manager/util';
import {
  StyledFilterListCheckboxField,
  StyledFilterListItem,
} from '@jane/shared-b2b/components';
import type { FormRadioFieldGroupOption } from '@jane/shared/reefer-hook-form';

import type { MultiSelectOption } from '../multiSelectFilter';

export const DATE_RANGE_MAP: { [key: string]: [string, string] } = {
  '14': [getPastDate(14), getToday()],
  '30': [getPastDate(30), getToday()],
  '90': [getPastDate(90), getToday()],
};

export const DATE_RANGE_ID_MAP: { [key: string]: string } = {
  '14': 'past-14-days',
  '30': 'past-30-days',
  '90': 'past-90-days',
};

export const DATE_RANGE_OPTIONS: FormRadioFieldGroupOption[] = [
  {
    id: 'past-14-days',
    label: 'Past 14 days',
    value: '14',
    wrapper: (children: React.ReactNode) => (
      <StyledFilterListItem>
        <StyledFilterListCheckboxField>
          {children}
        </StyledFilterListCheckboxField>
      </StyledFilterListItem>
    ),
  },
  {
    id: 'past-30-days',
    label: 'Past 30 days',
    value: '30',
    wrapper: (children: React.ReactNode) => (
      <StyledFilterListItem>
        <StyledFilterListCheckboxField>
          {children}
        </StyledFilterListCheckboxField>
      </StyledFilterListItem>
    ),
  },
  {
    id: 'past-90-days',
    label: 'Past 90 days',
    value: '90',
    wrapper: (children: React.ReactNode) => (
      <StyledFilterListItem>
        <StyledFilterListCheckboxField>
          {children}
        </StyledFilterListCheckboxField>
      </StyledFilterListItem>
    ),
  },
  {
    id: 'custom',
    label: 'Custom date range',
    value: 'custom',
    wrapper: (children: React.ReactNode) => (
      <StyledFilterListItem>
        <StyledFilterListCheckboxField>
          {children}
        </StyledFilterListCheckboxField>
      </StyledFilterListItem>
    ),
  },
];

export const DISCOUNT_TYPE_OPTIONS: MultiSelectOption[] = [
  {
    label: 'Percent',
    value: 'percent',
  },
  {
    label: 'Dollar',
    value: 'dollar_amount',
  },
  {
    label: 'Targeted price',
    value: 'target_price',
  },
];

export const STATUS_OPTIONS: MultiSelectOption[] = [
  {
    label: 'Live',
    value: 'live',
  },
  { label: 'Upcoming', value: 'upcoming' },
  {
    label: 'Expired',
    value: 'expired',
  },
  {
    label: 'Disabled',
    value: 'disabled',
  },
];

export const BUDGET_RANGE_OPTIONS: {
  label: string;
  value: [number, number];
}[] = [
  {
    label: '$1-5',
    value: [1, 5],
  },
  {
    label: '$5-50',
    value: [5, 50],
  },
  {
    label: '$50-500',
    value: [50, 500],
  },
  {
    label: '$500-1,000',
    value: [500, 1000],
  },
];
