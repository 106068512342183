import { useMemo, useState } from 'react';

import { useBrandCategories } from '@jane/gold-manager/data-access';
import type { CategoryOption } from '@jane/shared-b2b/components';
import { CategorySelectInputModal } from '@jane/shared-b2b/components';
import {
  Button,
  DismissIcon,
  Flex,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import { useWatch } from '@jane/shared/reefer-hook-form';
import { sentenceCase } from '@jane/shared/util';

import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import {
  getExclusionRule,
  transformCategories,
  transformKinds,
  untransformKinds,
} from '../utils/specialForm.util';
import type { BrandSpecialRulesVariant } from './productsSection';

export const CategorySelection = ({
  variant,
}: {
  variant: BrandSpecialRulesVariant;
}) => {
  const { rules, setRulesValue } = useSpecialForm();
  const brandId = useWatch({ name: 'productBrandId' });
  const [open, setOpen] = useState(false);

  const { data: categoriesData = [], isLoading } = useBrandCategories(
    Number(brandId)
  );

  const categories = useMemo(
    () => transformCategories(categoriesData),
    [categoriesData]
  );

  const isExclusion = variant === 'exclusion';
  const key = isExclusion ? 'excludes.kinds' : 'includes.kinds';

  const kinds = isExclusion
    ? getExclusionRule(rules, 'kinds')
    : rules.includes?.[0].kinds ?? [];

  const selectedCategories = transformKinds(kinds);

  const handleSave = (updatedCategories: CategoryOption[]) => {
    setRulesValue(key, untransformKinds(updatedCategories));
    setOpen(false);
  };

  const handleRemoveCategory = ({ value, subItems }: CategoryOption) => {
    const filteredCategories = selectedCategories.filter(
      (category) =>
        category.value !== value &&
        (!subItems || category.label !== subItems[0].label)
    );

    setRulesValue(key, untransformKinds(filteredCategories));
  };

  return (
    <Flex flexDirection="column" gap={16} my={16}>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Typography>Categories</Typography>

        <Button
          variant="tertiary"
          label="Select"
          disabled={!brandId}
          aria-label={`Select ${variant} categories`}
          onClick={() => setOpen(true)}
          loading={brandId && isLoading}
        />
        <Modal appId="parent" open={open} onRequestClose={() => setOpen(false)}>
          <CategorySelectInputModal
            categoryOptions={categories}
            defaultShowSubItems={false}
            onSave={handleSave}
            selectedCategories={selectedCategories}
          />
        </Modal>
      </Flex>
      {selectedCategories && selectedCategories.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap">
          {selectedCategories.map((category) => (
            <Button
              key={category.label}
              label={sentenceCase(category.label)}
              aria-label={`remove ${category.label}`}
              startIcon={<DismissIcon size="sm" />}
              variant={isExclusion ? 'destructive-secondary' : 'secondary'}
              onClick={() => handleRemoveCategory(category)}
            />
          ))}
        </Flex>
      )}
    </Flex>
  );
};
