import { useCallback, useContext } from 'react';

import { ProductsPageParamsContext } from '@jane/catalog-cms/providers';

export const useSelectedBrands = () => {
  const context = useContext(ProductsPageParamsContext);

  if (context === null) {
    throw new Error(
      'useSelectedBrands must be a child of ProductsPageParamsProvider.'
    );
  }

  const {
    productsPageParams,
    updateProductsPageParams,
    clearProductsPageParams,
  } = context;

  const selectedBrandIds = productsPageParams.brand || [];

  const updateSelectedBrands = useCallback(
    (brandIds: string[]) => {
      if (brandIds.length > 0) {
        updateProductsPageParams('brand', brandIds);
      } else {
        clearProductsPageParams(['brand']);
      }
    },
    [clearProductsPageParams, updateProductsPageParams]
  );

  return {
    selectedBrandIds,
    updateSelectedBrands,
  };
};
