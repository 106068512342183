import { createColumnHelper } from '@tanstack/react-table';

// temporary mocked type for campaign
export type ApiCampaign = {
  endDate: string;
  flightsBudget: number;
  id: number;
  pacing: number;
  startDate: string;
  states: string[];
  targetBudget: number;
};

const columnHelper = createColumnHelper<ApiCampaign>();

export const getColumns = () => {
  return [
    columnHelper.accessor('id', {
      header: 'ID',
    }),
    columnHelper.accessor('endDate', {
      header: 'End Date',
    }),
    columnHelper.accessor('flightsBudget', {
      header: 'Flights Budget',
    }),
    columnHelper.accessor('pacing', {
      header: 'Pacing',
    }),
    columnHelper.accessor('startDate', {
      header: 'Start Date',
    }),
    columnHelper.accessor('states', {
      header: 'States',
    }),
    columnHelper.accessor('targetBudget', {
      header: 'Target Budget',
    }),
  ];
};
