import { useCallback, useState } from 'react';
import { v4 as uuid } from 'uuid';

import type {
  ToastContentProps,
  ToastProviderProps,
} from '../../toast/toast.types';
import { renderToasts, renderToastsInPortal } from './renderToasts';
import { ToastContext } from './toastContext';

// Note: ToastProvider is not SSR compatible when `usePortal` is true
// This is because the `document` object is referenced in `renderToastsInPortal`
export const ToastProvider = ({
  children,
  position,
  usePortal = false,
  render = usePortal ? renderToastsInPortal : renderToasts,
  verticalOffset,
}: ToastProviderProps) => {
  const [toasts, setToasts] = useState<ToastContentProps[]>([]);

  const add = useCallback(
    (content: Omit<ToastContentProps, 'id'>) => {
      setToasts((toasts: ToastContentProps[]) => [
        { id: uuid(), ...content },
        ...toasts,
      ]);
    },
    [setToasts]
  );

  const remove = useCallback(
    (id: string) => {
      setToasts((toasts: ToastContentProps[]) =>
        toasts.filter((t: ToastContentProps) => t.id !== id)
      );
    },
    [setToasts]
  );

  const clear = useCallback(() => setToasts([]), []);

  return (
    <ToastContext.Provider value={{ add, clear, toasts }}>
      {children}
      {render({ remove, toasts, verticalOffset, position })}
    </ToastContext.Provider>
  );
};
