import { useEffect } from 'react';

import {
  formatDatepickerString,
  nowInPacificTime,
  validationMessages,
  validators,
} from '@jane/ad-manager/util';
import { Flex } from '@jane/shared/reefer';
import { Form, useFormContext } from '@jane/shared/reefer-hook-form';

import type { AdSubmissionFormState } from '../formState/types';

export const FlightDateRange = () => {
  const { trigger, watch, getFieldState } =
    useFormContext<AdSubmissionFormState>();

  const [endDate, startDate] = watch(['endDate', 'startDate']);
  const today = formatDatepickerString(nowInPacificTime());

  useEffect(() => {
    const { isTouched: isStartDateTouched } = getFieldState('startDate');
    if (isStartDateTouched) trigger('startDate');

    const { isTouched: isEndDateTouched } = getFieldState('endDate');
    if (isEndDateTouched) trigger('endDate');
  }, [endDate, getFieldState, startDate, trigger]);

  return (
    <Flex gap={24} data-testid="date-range-section">
      <Form.DateTimeField
        label="Start date"
        name="startDate"
        min={today}
        width="100%"
        shouldUnregister={false}
        validate={(newStartDate) => {
          if (newStartDate === '') {
            return 'Please enter a start date';
          }

          if (
            typeof newStartDate === 'string' &&
            !validators.isDateRangeValid(newStartDate, endDate)
          ) {
            return validationMessages.invalidEndDateRange;
          }

          return true;
        }}
      />
      <Form.DateTimeField
        label="End date"
        name="endDate"
        min={today}
        width="100%"
        shouldUnregister={false}
        validate={(newEndDate) => {
          if (newEndDate === '') {
            return 'Please enter a end date';
          }

          if (
            typeof newEndDate === 'string' &&
            !validators.isDateRangeValid(startDate, newEndDate)
          ) {
            return validationMessages.invalidStartDateRange;
          }

          return true;
        }}
      />
    </Flex>
  );
};
