import { useState } from 'react';

import { Flex, SelectField, Typography } from '@jane/shared/reefer';

import { TIME_SLOT_OPTIONS } from '../util/constants';
import { validationMessages, validators } from '../util/validators';

export interface TimeSlotSelectorProps {
  /** Optional method triggered when errors are cleared */
  clearError?: () => void;

  /** Prop that stores end time as it is updated */
  endTime: string;

  /** Optional label for the end time field */
  endTimeLabel?: React.ReactNode;

  /** Method called to update the specified timeframe in a schedule */
  onChangeTimeframeSchedule: (newSchedule: {
    endTime: string;
    startTime: string;
  }) => void;

  /** Optional method triggered when errors are set */
  setError?: (message: string) => void;

  /** Prop that stores start time as it is updated */
  startTime: string;

  /** Optional label for the start time field */
  startTimeLabel?: React.ReactNode;

  /** String that describes the timeframe for which the selector is being used */
  timeframe: string;
}

export const TimeSlotSelector = ({
  onChangeTimeframeSchedule,
  endTime,
  startTime,
  timeframe,
  startTimeLabel = 'Start time',
  endTimeLabel = 'End time',
  setError,
  clearError,
}: TimeSlotSelectorProps) => {
  const [startTimeError, setStartTimeError] = useState('');
  const [endTimeError, setEndTimeError] = useState('');

  return (
    <Flex gap={24}>
      <Flex width="420px" flexDirection="column">
        <SelectField
          name={`startTime-${timeframe}`}
          data-testid={`startTime-${timeframe}`}
          label={startTimeLabel}
          defaultValue={startTime || '12:00 AM'}
          options={TIME_SLOT_OPTIONS}
          onChange={(newStartTime) => {
            if (!validators.isValidTimeRange(newStartTime, endTime)) {
              setStartTimeError(validationMessages.invalidStartTime);
              setError &&
                setError(
                  `Invalid ${timeframe} schedule. ${validationMessages.invalidStartTime}`
                );
            } else {
              setStartTimeError('');
              setEndTimeError('');
              clearError && clearError();
            }
            onChangeTimeframeSchedule({
              endTime,
              startTime: newStartTime,
            });
          }}
          width="100%"
          required
        />
        {startTimeError && (
          <Typography color="error" mt={16} role="alert">
            {startTimeError}
          </Typography>
        )}
      </Flex>
      <Flex width="420px" flexDirection="column">
        <SelectField
          name={`endTime-${timeframe}`}
          data-testid={`endTime-${timeframe}`}
          label={endTimeLabel}
          defaultValue={endTime || '11:59 PM'}
          options={TIME_SLOT_OPTIONS}
          onChange={(newEndTime) => {
            if (!validators.isValidTimeRange(startTime, newEndTime)) {
              setEndTimeError(validationMessages.invalidEndTime);
              setError &&
                setError(
                  `Invalid ${timeframe} schedule. ${validationMessages.invalidEndTime}`
                );
            } else {
              setStartTimeError('');
              setEndTimeError('');
              clearError && clearError();
            }
            onChangeTimeframeSchedule({
              startTime,
              endTime: newEndTime,
            });
          }}
          width="100%"
          required
        />
        {endTimeError && (
          <Typography color="error" mt={16} role="alert">
            {endTimeError}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};
