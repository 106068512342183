import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';

import type { TypeAheadProps } from '@jane/shared/reefer';
import { InternalTypeAhead } from '@jane/shared/reefer';

import { FormSearchField } from '../formSearchField/formSearchField';

export interface FormTypeAheadProps extends TypeAheadProps {
  formName: string;
}

export function FormTypeAhead(props: FormTypeAheadProps) {
  const { formName, onSelection } = props;
  const methods = useFormContext();

  const handleSelection = useCallback(
    (value: string | string[]) => {
      onSelection && onSelection(value);
      methods && methods.setValue(formName, value);
    },
    [methods, formName, onSelection]
  );

  return (
    <InternalTypeAhead
      handleSelection={handleSelection}
      field={
        <FormSearchField
          defaultValue={props.query}
          debounceDelay={props.debounceDelay}
          isDebounced={props.isDebounced}
          label={props.ariaLabel}
          labelHidden={props.labelHidden}
          name={props.formName}
          onBlur={props.onBlur}
          onChange={props.onChange}
        />
      }
      {...props}
    />
  );
}
