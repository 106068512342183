import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import { SrOnly } from '@jane/brands/components';
import { useBrandsNavigate } from '@jane/brands/hooks';
import type {
  EditFormProduct,
  UpdateProductParams,
} from '@jane/catalog-cms/data-access';
import { updateProduct } from '@jane/catalog-cms/data-access';
import {
  editFormProductQueryKey,
  useGoToNextProduct,
  useSelectedSubdivision,
} from '@jane/catalog-cms/hooks';
import { ApiRequestError } from '@jane/shared/data-access';
import {
  Box,
  Button,
  CheckIcon,
  Drawer,
  Flex,
  Link,
  useDrawerContext,
  useToast,
} from '@jane/shared/reefer';
import { FormValidationError } from '@jane/shared/reefer-hook-form';

import { NavigationDropdown } from '../../NavigationDropdown';
import { DrawerHeader } from '../DrawerHeader';
import { CategoryDetails } from '../shared/CategoryDetails/CategoryDetails';
import { ProductHeader } from '../shared/ProductHeader/ProductHeader';
import { ProductHistory } from '../shared/ProductHistory/ProductHistory';
import { ProductNotice } from '../shared/ProductNotice';
import { ReadonlyValues } from '../shared/ReadonlyValues/ReadonlyValues';
import type { EditProductFormData } from './EditProductForm';
import { EditProductForm } from './EditProductForm';

export interface EditProductProps {
  product: EditFormProduct;
}

export const EditProduct = ({ product }: EditProductProps) => {
  const formId = 'edit-product-form';
  const nav = useBrandsNavigate();
  const toast = useToast();
  const queryClient = useQueryClient();
  const goToNextProduct = useGoToNextProduct();
  const isSaveAndNext = useRef<boolean>(false);
  const { onRequestClose: closeProductDrawer } = useDrawerContext();
  const { selectedSubdivision } = useSelectedSubdivision();
  const [isDirty, setIsDirty] = useState(false);

  const productMutation = useMutation({
    mutationFn: updateProduct,
    onSuccess: () => {
      toast.add({ label: 'Saved!', variant: 'success' });

      return Promise.all([
        queryClient.invalidateQueries(editFormProductQueryKey(product.id)),
        queryClient.invalidateQueries({
          queryKey: ['products'],
        }),
      ]);
    },
  });

  const onSubmit: SubmitHandler<EditProductFormData> = async (data) => {
    const updateParams = toUpdateProductParams(data, product.id);

    validateUpdateData(updateParams, product);

    try {
      await productMutation.mutateAsync(updateParams);

      if (isSaveAndNext.current) {
        goToNextProduct({ selectedSubdivision: selectedSubdivision });
      } else {
        closeProductDrawer();
      }
    } catch (error) {
      if (error instanceof ApiRequestError) {
        const { errors } = await error.response.json();

        if (Array.isArray(errors)) {
          const message = errors.join(', ') + '.';
          throw new FormValidationError('edit-product', [
            { name: 'form', message },
          ]);
        }
      }
      throw new Error(
        [
          'There was an unexpected error when submitting the form.',
          'Please try again or contact support if this problem persists.',
        ].join(' ')
      );
    }
  };

  return (
    <>
      <DrawerHeader showDismiss>
        <Flex gap={16} grow={1} justifyContent="end">
          <Button
            type="submit"
            variant="secondary"
            form={formId}
            label="Save"
            loading={productMutation.isLoading}
            disabled={!isDirty}
          />
          {isDirty && (
            <Button
              type="submit"
              form={formId}
              label="Save and next"
              onClick={() => {
                isSaveAndNext.current = true;
              }}
              loading={productMutation.isLoading}
            />
          )}
          {!isDirty && (
            <Button
              label="Next"
              onClick={(e) => {
                e.preventDefault();
                goToNextProduct({ selectedSubdivision: selectedSubdivision });
              }}
            />
          )}
        </Flex>
      </DrawerHeader>
      <Drawer.Content data-testid="drawer-content">
        <SrOnly as="h2">Edit product</SrOnly>
        <ProductHeader {...product} />
        <CategoryDetails {...product} />

        <Box pt={24}>
          <NavigationDropdown label="View as default">
            <NavigationDropdown.ListItem>
              <Flex justifyContent="space-between" alignItems="center">
                Default <CheckIcon size="sm" color="purple" />
              </Flex>
            </NavigationDropdown.ListItem>
            <NavigationDropdown.Divider />
            <NavigationDropdown.ListItem>
              <Link
                variant="minimal"
                onClick={() => nav.createLocalization(product.id)}
              >
                Add a state
              </Link>
            </NavigationDropdown.ListItem>
            <NavigationDropdown.Localizations
              localizations={product.localizations}
              productId={product.id}
            />
          </NavigationDropdown>
        </Box>

        <Box pt={24} pb={24}>
          <EditProductForm
            formId={formId}
            onSubmit={onSubmit}
            product={product}
            onDirty={(isDirty) => setIsDirty(isDirty)}
            key={product.id}
          />
        </Box>

        <Drawer.ContentDivider />

        <Box py={24}>
          <ReadonlyValues {...product} />
        </Box>

        <Drawer.ContentDivider />

        <Box py={24}>
          <ProductHistory {...product} />
        </Box>

        <ProductNotice {...product} />
      </Drawer.Content>
    </>
  );
};

const validateUpdateData = (
  data: Record<string, unknown>,
  editProduct: EditFormProduct
) => {
  const errors = [];

  if (editProduct.category?.match(/flower/i) && !data['lineage']) {
    errors.push({
      name: 'form',
      message: 'Lineage is required for products with category: Flower',
    });

    errors.push({
      name: 'lineage',
      message: 'Please select a lineage',
    });
  }

  if (data['name'] === '')
    errors.push({ name: 'name', message: 'Name is required' });

  if (data['description'] === '')
    errors.push({ name: 'description', message: 'Description is required' });

  if (errors.length > 0) {
    throw new FormValidationError('edit-product', errors);
  }
};

/**
 * Utility that takes the output of the product form and converts it to
 * parameters expected by the product update function
 */
const toUpdateProductParams = (
  params: EditProductFormData,
  uuid: string
): UpdateProductParams => {
  // TODO: Deprecate images attribute clickup ticket #862k4c4ew
  const images = JSON.stringify(params['imageURLs']);

  const [name, description, categoryLabel] = [
    params.name,
    params.description,
    params.categoryLabel,
  ].map((s) => s.trim());

  return {
    ...params,
    name,
    description,
    categoryLabel,
    uuid,
    images,
  };
};
