import type { AdType } from '@jane/ad-manager/data-access';
import { AdTypeEnum } from '@jane/ad-manager/data-access';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Flex } from '@jane/shared/reefer';
import { useController, useFormContext } from '@jane/shared/reefer-hook-form';

import {
  DEFAULT_CPC_FREQUENCY_CAP_DENOMINATOR,
  DEFAULT_CPC_FREQUENCY_CAP_NUMERATOR,
  defaultAdSubmissionFormData,
} from '../formState/defaults';
import {
  type AdSubmissionFormState,
  defaultCPCPlacements,
} from '../formState/types';
import { ProductGroupOption } from './ProductGroupOption';

export const Type = () => {
  const { reset } = useFormContext<AdSubmissionFormState>();
  const { selectedBrand } = useSelectedBrandContext();
  const {
    field: { onChange, value: adType },
  } = useController({ name: 'adType' });

  const handleAdTypeChange = (adType: AdType) => {
    if (adType === AdTypeEnum.enum.cpm) {
      reset({
        ...defaultAdSubmissionFormData,
        menuRowTitle: selectedBrand?.name || '',
      });
    }
    if (adType === AdTypeEnum.enum.cpc) {
      reset({
        ...defaultAdSubmissionFormData,
        adType: AdTypeEnum.enum.cpc,
        adPlacement: defaultCPCPlacements,
        frequencyCapNumerator: DEFAULT_CPC_FREQUENCY_CAP_NUMERATOR,
        frequencyCapDenominator: DEFAULT_CPC_FREQUENCY_CAP_DENOMINATOR,
        menuRowTitle: selectedBrand?.name || '',
      });
      onChange(adType);
    }
  };

  return (
    <Flex gap={16} justifyContent="center">
      <ProductGroupOption
        adType={AdTypeEnum.enum.cpm}
        isSelected={adType === AdTypeEnum.enum.cpm}
        onClick={() => handleAdTypeChange(AdTypeEnum.enum.cpm)}
      />
      <ProductGroupOption
        adType={AdTypeEnum.enum.cpc}
        isSelected={adType === AdTypeEnum.enum.cpc}
        onClick={() => handleAdTypeChange(AdTypeEnum.enum.cpc)}
      />
    </Flex>
  );
};
