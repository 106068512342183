import clsx from 'clsx';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';

import { Banner } from '../banner/banner';
import { CheckIcon } from '../icon/icons/dynamic/CheckIcon';
import { ErrorIcon } from '../icon/icons/dynamic/ErrorIcon';
import styles from './toast.module.css';
import type { ToastProps, ToastVariants } from './toast.types';

const icons: { [Property in keyof ToastVariants as string]: ReactElement } = {
  success: <CheckIcon />,
  error: <ErrorIcon />,
};

type ToastAnimationStates =
  | 'right_in'
  | 'right_out'
  | 'middle_top_in'
  | 'middle_bottom_in'
  | 'middle_out';

/**
 * Toast is used to create a notification at the top right of the user's window.
 * Toasts are created using the useToast() hook.
 */

export function Toast({
  className,
  'data-testid': testId,
  variant = 'success',
  label,
  id,
  onClose,
  position = 'top-right',
  style,
  time = 5000,
}: ToastProps) {
  const toastAnimationIn = position.includes('right')
    ? 'right_in'
    : position.includes('top')
    ? 'middle_top_in'
    : 'middle_bottom_in';

  const toastAnimationOut = position.includes('right')
    ? 'right_out'
    : 'middle_out';

  const [animationMode, setAnimationMode] =
    useState<ToastAnimationStates>(toastAnimationIn);

  const handleClose = () => {
    setAnimationMode(toastAnimationOut);
    setTimeout(() => onClose(id), 220);
  };

  useEffect(() => {
    let timeout: NodeJS.Timeout;

    if (time) {
      timeout = setTimeout(() => handleClose(), time);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <Banner
      className={clsx(
        className,
        styles.toast,
        styles[`toast_animated__${animationMode}`]
      )}
      data-testid={testId}
      icon={icons[variant]}
      id={id}
      label={label}
      variant={variant}
      style={style}
      {...(!time && { onDismiss: handleClose })}
    />
  );
}
