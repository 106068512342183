import styled from '@emotion/styled';
import { useState } from 'react';

import type { BrandProduct } from '@jane/gold-manager/data-access';
import {
  Button,
  Flex,
  Image,
  List,
  Skeleton,
  TrashIcon,
  Typography,
} from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';
import { sentenceCase } from '@jane/shared/util';

import { handleScroll } from '../utils/specialForm.util';
import type { BrandSpecialRulesVariant } from './productsSection';

export const StyledListItem = styled(List.Item)<{ border: boolean }>(
  ({ border }) => ({
    width: '100%',
    ' > div': {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: '47% 18.5% 18.5% 7%',
      alignItems: 'center',
      gap: '24px',
      ...spacing({ px: 24, py: 12 }),
      ...(border && {
        borderBottom: '1px solid var(--colors-grays-light)',
      }),
    },
  })
);

export const StyledListHeader = styled.div({
  display: 'grid',
  width: '100%',
  gridTemplateColumns: '47% 18.5% 18.5% 7%',
  alignItems: 'center',
  gap: '24px',
  ...spacing({ px: 24, py: 12 }),
  borderBottom: '1px solid var(--colors-grays-light)',
});

interface SelectedProductsProps {
  isLoading: boolean;
  numberOfProducts: number;
  onRemove: (productId: string) => void;
  products: BrandProduct[];
  variant: BrandSpecialRulesVariant;
}

export const SelectedProducts = ({
  isLoading,
  numberOfProducts,
  products,
  onRemove,
  variant,
}: SelectedProductsProps) => {
  const [showAll, setShowAll] = useState(false);

  const onShowToggle = () => {
    if (showAll) {
      setShowAll(false);
      handleScroll(`product-list-${variant}`);
    } else {
      setShowAll(true);
    }
  };

  const productsToRender = showAll ? products : products.slice(0, 5);
  const totalVisibleProducts = showAll
    ? numberOfProducts
    : numberOfProducts > 5
    ? 5
    : numberOfProducts;

  const renderedProducts = productsToRender.map((product, i) => (
    <StyledListItem key={product.id} border={i !== numberOfProducts - 1}>
      <Flex alignItems="center" gap={16}>
        <Image
          src={product.photos?.[0]?.urls?.small || ''}
          altText={`Product image for ${product.name}`}
          sizes="thumbnail"
          borderRadius="rounded"
          border
          height="64px"
          width="64px"
          responsive
        />
        <Typography variant="body-bold" truncateAt="100%">
          {product.name}
        </Typography>
      </Flex>
      <Typography>{sentenceCase(product.category || '')}</Typography>
      <Typography>{sentenceCase(product.subcategory || '')}</Typography>
      <Button.Icon
        onClick={() => onRemove(product.id)}
        icon={<TrashIcon />}
        label={`Remove ${product.name} from products for ${variant}`}
      />
    </StyledListItem>
  ));

  return (
    <Flex flexDirection="column">
      <StyledListHeader id={`product-list-${variant}`}>
        <Typography variant="caps" color="grays-mid">
          Item
        </Typography>
        <Typography variant="caps" color="grays-mid">
          Category
        </Typography>
        <Typography variant="caps" color="grays-mid">
          Subcategory
        </Typography>
        <Typography variant="caps" color="grays-mid"></Typography>
      </StyledListHeader>
      {isLoading ? (
        <Flex
          pb={12}
          width="100%"
          flexDirection="column"
          role="alert"
          aria-label="Loading"
        >
          {Array.from({ length: totalVisibleProducts }).map((_, i) => (
            <StyledListItem key={i} border={i !== numberOfProducts - 1}>
              <Flex alignItems="center" gap={16}>
                <Skeleton.Bone height="64px" width="64px" />
                <Skeleton.Bone width="150px" />
              </Flex>
              <Skeleton.Bone width="75px" />
              <Skeleton.Bone width="75px" />
              <Skeleton.Bone width="48px" height="48px" borderRadius="sm" />
            </StyledListItem>
          ))}
        </Flex>
      ) : (
        <List label={`Selected products for ${variant}`} pb={12}>
          {renderedProducts}
        </List>
      )}
      {numberOfProducts > 5 && (
        <Button
          label={showAll ? 'Show less' : 'Show all'}
          variant="tertiary"
          onClick={onShowToggle}
          loading={isLoading}
          mt={12}
        />
      )}
    </Flex>
  );
};
