import styled from '@emotion/styled';
import omit from 'lodash/omit';
import { useCallback, useMemo } from 'react';
import { InView } from 'react-intersection-observer';
import { useLocation, useParams } from 'react-router-dom';

import { useGetJaneDMIdentifiers } from '@jane/dm/internal';
import { useDeepCompareMemo } from '@jane/shared-ecomm/hooks';
import { useUserPreferences } from '@jane/shared-ecomm/providers';
import {
  EventNames,
  track,
  trackProductListingTap,
} from '@jane/shared-ecomm/tracking';
import { LegacyMenuProductCard } from '@jane/shared/components';
import { useUserSegmentIds } from '@jane/shared/data-access';
import { FLAGS, useFlag } from '@jane/shared/feature-flags';
import { useShouldShowGold } from '@jane/shared/hooks';
import type {
  MenuProduct,
  PriceId,
  StoreSpecial as ReduxStoreSpecial,
  StoreSpecial,
} from '@jane/shared/models';
import { useRuntimeConfig } from '@jane/shared/runtime-config';
import type { MenuProduct as ZMenuProduct } from '@jane/shared/types';
import {
  deserializeCannabinoids,
  getJaneGoldTagLabel,
  getMaxCartQuantity,
  monolithPaths,
} from '@jane/shared/util';

import { buildClickTrackingEvent } from './buildTrackingEvent';
import { findSpecialForProduct } from './findSpecialForProduct';
import type { ProductCardWithTrackingProps } from './productCardWithTracking.types';

const InViewFlex = styled(InView)({
  display: 'flex',
  flexDirection: 'column',
});

export const ProductCardWithTracking = ({
  algoliaIndexName,
  attemptToAddToCart,
  cardIndex,
  closeCart,
  columnPosition,
  deleteCartItem,
  janeDeviceId,
  menuRowName,
  numColumns,
  product,
  productLocation,
  rowPosition,
  specials,
  withoutDrawerOnAddToCart,
  zone,
  ...props
}: ProductCardWithTrackingProps) => {
  const { appMode, store, carouselView, listView } = props;
  const { isPartnerHosted, partnerHostedPath } = useRuntimeConfig();
  const { userLocation, userPreferences = {} } = useUserPreferences();
  const shouldShowGold = useShouldShowGold({
    product: product.attributes,
    store,
  });
  const { special_id } = useParams();
  const { storeFulfillmentType, storeSearchRadius, storeType } =
    userPreferences;
  const { pathname } = useLocation();
  const janeGoldSegmentation = useFlag(FLAGS.janeGoldUserSegmentation);
  const userSegments = useUserSegmentIds();
  const { jdid } = useGetJaneDMIdentifiers();

  const {
    city: storeCity,
    state: storeState,
    name: storeName,
    id: storeId,
  } = store;

  const menuProduct = useDeepCompareMemo<any>(() => {
    const mp = {
      ...deserializeCannabinoids(product.attributes, 'menuProduct'),
      id: product.attributes.product_id,
    };
    return omit(mp, 'product_id') as MenuProduct;
  }, [product]);

  const janeGoldLabel = shouldShowGold
    ? getJaneGoldTagLabel({
        menuProduct: menuProduct,
      })
    : undefined;

  const pageSpecial = specials?.find(
    (special) => special.id === Number(special_id)
  );

  const currentSpecial =
    pageSpecial?.special_type === 'product'
      ? pageSpecial
      : findSpecialForProduct(menuProduct, specials as StoreSpecial[], appMode);

  const clickTrackingEvent = buildClickTrackingEvent({
    indexName: algoliaIndexName,
    store,
    menuProduct,
    carouselView,
    rowPosition,
    cardIndex,
    columnPosition,
    listView,
    menuRowName,
    userSegments: janeGoldSegmentation ? userSegments : undefined,
  });

  const handleTrackImpression = useCallback(
    async (isInView: boolean) => {
      if (isInView && !props.isMenuTopRow) {
        product.impress();
      }
    },
    [product]
  );

  const handleMenuProductCardClick = useMemo(() => {
    return (selectedWeight: PriceId | null) => {
      product.click();

      clickTrackingEvent && track(clickTrackingEvent);
      if (product.isSponsored) {
        track({ event: EventNames.ClickedAd, janeDeviceId: jdid });
      }

      const inventoryCount =
        !!menuProduct && !!selectedWeight
          ? getMaxCartQuantity(menuProduct, selectedWeight)
          : null;

      trackProductListingTap({
        almostGone: inventoryCount < 4 ? inventoryCount : null,
        cityState: userLocation?.cityState,
        columnPosition,
        product: menuProduct,
        productLocation,
        rowPosition,
        source: pathname,
        storeId: String(storeId),
        storeName,
        storeCity: storeCity || undefined,
        storeState: storeState || undefined,
        storeFulfillmentType,
        storeSearchRadius,
        storeType,
        userSegments: janeGoldSegmentation ? userSegments : undefined,
        zipcode: userLocation?.zipcode,
      });
    };
  }, [
    clickTrackingEvent,
    columnPosition,
    janeDeviceId,
    janeGoldSegmentation,
    menuProduct,
    pathname,
    product,
    productLocation,
    rowPosition,
    storeCity,
    storeFulfillmentType,
    storeId,
    storeName,
    storeSearchRadius,
    storeState,
    storeType,
    userLocation?.cityState,
    userLocation?.zipcode,
    userSegments,
  ]);

  return (
    <InViewFlex
      data-testid="menu-product-card-with-tracking"
      delay={1000}
      onChange={(isInView) => handleTrackImpression(isInView)}
      threshold={0.5}
      triggerOnce
    >
      <LegacyMenuProductCard
        currentSpecial={currentSpecial as ReduxStoreSpecial}
        product={menuProduct}
        productInstance={product}
        onAddToCart={(addData) => {
          attemptToAddToCart({
            ...addData,
            columnPosition,
            menuProduct: addData.menuProduct as ZMenuProduct,
            productLocation,
            rowPosition,
            withoutDrawer: withoutDrawerOnAddToCart,
          });
          product.click({ isAddToCart: true });
        }}
        onDeleteFromCart={(deleteData) => {
          deleteCartItem(deleteData.itemId, deleteData.selectedWeight);
        }}
        onCloseCart={() => closeCart()}
        onClick={handleMenuProductCardClick}
        routeAppMode={monolithPaths.getAppMode()}
        routePartnerHostedConfig={{ isPartnerHosted, partnerHostedPath }}
        userLocation={userLocation}
        janeGoldLabel={janeGoldLabel}
        {...props}
      />
    </InViewFlex>
  );
};
