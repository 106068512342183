import { useState } from 'react';

import {
  ActiveFilterTag,
  StyledFilterListItem,
} from '@jane/shared-b2b/components';
import { StyledFilterList } from '@jane/shared-ecomm/components';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Button,
  ChevronDownIcon,
  DateTimeField,
  Flex,
  Popover,
  RadioFieldGroup,
} from '@jane/shared/reefer';

import { DeselectFiltersButton } from './deselectFiltersButton';
import {
  DateRangeFilterPopoverContent,
  FilterListDateTimeField,
} from './specialsFilter.styles';
import {
  DATE_RANGE_ID_MAP,
  DATE_RANGE_MAP,
  DATE_RANGE_OPTIONS,
} from './specialsFiltersOptions';

export interface DateRangeFilterProps {
  currentValue: string[];
  handleChange: (updatedValue: string[]) => void;
}

export const DateRangeFilter = ({
  currentValue,
  handleChange,
}: DateRangeFilterProps) => {
  const label = 'Date range';
  const [optionId, start, end] = currentValue;
  const [showDateFields, setShowDateFields] = useState(optionId === 'custom');

  const handleDatePickerChange = (value: string, index: 1 | 2) => {
    const updatedValue = ['custom', start || '', end || ''];
    updatedValue[index] = value;
    handleChange(updatedValue);
  };

  const handleRadioDateChange = (value: string) => {
    if (value === 'custom') {
      setShowDateFields(true);
      handleChange([]);
    } else {
      setShowDateFields(false);
      handleChange([DATE_RANGE_ID_MAP[value], ...DATE_RANGE_MAP[value]]);
    }
  };

  return (
    <Popover
      target={
        <Button
          label={label}
          variant={currentValue.length === 0 ? 'tertiary' : 'tertiary-selected'}
          endIcon={
            <Flex alignItems="center">
              <ActiveFilterTag
                valuesCount={currentValue.length ? 1 : 0}
                label={label}
              />
              <ChevronDownIcon color="inherit" size="sm" />
            </Flex>
          }
        />
      }
      label={label}
      disableMobileStyling
    >
      {({ closePopover }: PopoverContextProps) => (
        <DateRangeFilterPopoverContent padding={false} maxHeight="500px">
          <StyledFilterList aria-label={`${label} inputs`}>
            <RadioFieldGroup
              options={DATE_RANGE_OPTIONS}
              name="Date range"
              onChange={handleRadioDateChange}
              defaultChecked={
                !currentValue.length ? undefined : currentValue[0]
              }
            />
            {showDateFields ? (
              <>
                <StyledFilterListItem>
                  <FilterListDateTimeField>
                    <DateTimeField
                      label="Start date"
                      name={`${label}-start`}
                      onChange={(value) => {
                        handleDatePickerChange(value, 1);
                      }}
                      defaultValue={start}
                      disableMobileInputStyling
                    />
                  </FilterListDateTimeField>
                </StyledFilterListItem>
                <StyledFilterListItem>
                  <FilterListDateTimeField disabled={!start}>
                    <DateTimeField
                      label="End date"
                      name={`${label}-end`}
                      onChange={(value) => {
                        handleDatePickerChange(value, 2);
                      }}
                      defaultValue={end}
                      min={start}
                      disabled={!start}
                      disableMobileInputStyling
                    />
                  </FilterListDateTimeField>
                </StyledFilterListItem>
              </>
            ) : null}
            <DeselectFiltersButton
              onClick={() => {
                handleChange([]);
                setShowDateFields(false);
                closePopover();
              }}
              label="Clear dates"
            />
          </StyledFilterList>
        </DateRangeFilterPopoverContent>
      )}
    </Popover>
  );
};
