import type {
  ColumnFiltersState,
  RowSelectionState,
} from '@tanstack/react-table';

import type { Filters, ProductFilters } from './productSelect.types';

export function getSelectedProducts<R extends { id: string | number }>(
  products: R[]
): RowSelectionState {
  return products.reduce<RowSelectionState>((acc, product) => {
    acc[product.id] = true;
    return acc;
  }, {});
}

export function getSelectedProductIds(ids: string[]): RowSelectionState {
  return ids.reduce<RowSelectionState>((acc, product) => {
    acc[product] = true;
    return acc;
  }, {});
}

export function getSelectedFilters(filters: Filters): ColumnFiltersState {
  return Object.entries(filters).map((filter) => {
    const id = filter[0] as ProductFilters;
    return {
      id,
      value: filter[1],
    };
  });
}

export function columnFiltersToFilters(
  columnFilters: ColumnFiltersState
): Filters {
  return columnFilters.reduce<Filters>((acc, filter) => {
    acc[filter.id as ProductFilters] = filter.value as string | string[];

    return acc;
  }, {});
}
