import type * as ReactTable from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { Storage } from '@jane/shared/util';

const LOCAL_STORAGE_KEY = 'jane-ad-manager-column-visibility';

const getColVisibilityFromStorage = (key: string) => {
  const colVisJson = Storage.get(`${LOCAL_STORAGE_KEY}-${key}`);
  if (!colVisJson) return;
  try {
    return JSON.parse(colVisJson);
  } catch (e) {
    return;
  }
};

export const useColumnVisibilityInStorage = (
  key: string,
  defaultState: ReactTable.VisibilityState
) => {
  const [columnVisibility, setColumnVisibility] = useState(
    getColVisibilityFromStorage(key) || defaultState
  );

  useEffect(() => {
    try {
      Storage.set(
        `${LOCAL_STORAGE_KEY}-${key}`,
        JSON.stringify(columnVisibility)
      );
    } catch (e) {
      return;
    }
  }, [columnVisibility, key]);

  return [columnVisibility, setColumnVisibility];
};
