import type { UseQueryResult } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import type { BrandPageState, ProductBrand } from '@jane/ad-manager/types';
import type { ApiRequestError } from '@jane/shared/data-access';
import { useToast } from '@jane/shared/reefer';

export type GetAllBrandsQueryResult = Pick<
  UseQueryResult<ProductBrand[], ApiRequestError>,
  'data' | 'isLoading'
>;

export type GetBrandFromSearchParamsQueryResult = Pick<
  UseQueryResult<ProductBrand, ApiRequestError>,
  'data' | 'isFetching'
>;

interface Props {
  getAllBrandsQueryResult: GetAllBrandsQueryResult;
  getBrandFromSearchParamsQueryResult: GetBrandFromSearchParamsQueryResult;
  selectedBrand?: ProductBrand;
  setSelectedBrand: (productBrand?: ProductBrand) => unknown;
}

export const useSetDefaultBrandOrNavigate = ({
  selectedBrand,
  setSelectedBrand,
  getAllBrandsQueryResult,
  getBrandFromSearchParamsQueryResult,
}: Props) => {
  const { state }: Partial<BrandPageState> = useLocation();
  const redirectBrand = state?.brand;
  const toast = useToast();
  const navigate = useNavigate();

  const { isLoading: isAllBrandsLoading, data: allBrands } =
    getAllBrandsQueryResult;

  const hasNoBrands = allBrands === undefined || allBrands?.length === 0;

  const {
    isFetching: isBrandFromSearchParamsFetching,
    data: brandFromSearchParams,
  } = getBrandFromSearchParamsQueryResult;

  useEffect(() => {
    if (
      selectedBrand ||
      isBrandFromSearchParamsFetching ||
      isAllBrandsLoading
    ) {
      return;
    }

    if (redirectBrand) {
      setSelectedBrand(redirectBrand);
    } else if (brandFromSearchParams) {
      setSelectedBrand(brandFromSearchParams);
    }

    if (hasNoBrands) {
      toast.add({ label: 'No authorized brands found', variant: 'error' });
      navigate('/');
    }
  }, [
    brandFromSearchParams,
    hasNoBrands,
    isAllBrandsLoading,
    isBrandFromSearchParamsFetching,
    navigate,
    redirectBrand,
    selectedBrand,
    setSelectedBrand,
    toast,
  ]);
};
