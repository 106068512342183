import { useMemo } from 'react';

import {
  useCreateBrandSpecial,
  useUpdateBrandSpecial,
} from '@jane/gold-manager/data-access';
import type {
  DailySchedule,
  ManualScheduleForDays,
} from '@jane/shared-b2b/components';
import { ApiRequestError } from '@jane/shared/data-access';
import { Flex, Typography, useToast } from '@jane/shared/reefer';
import { sentenceCase } from '@jane/shared/util';

import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import {
  formatExclusionRules,
  formatInclusionProductRules,
  getExclusionRule,
  hasProductExclusionRules,
  hasProductInclusionRules,
  transformDate,
  transformSchedule,
  untransformDate,
  untransformSchedule,
} from './specialForm.util';

interface ScheduleData {
  dailySchedule: DailySchedule;
  hasCustomSchedule: boolean;
  scheduledDays: ManualScheduleForDays;
}

export interface SpecialFormData {
  aeropayLocationId: string;
  availableBudget: number;
  budgetTotal: number;
  campaignType: string;
  description: string;
  discountDollarAmount: number;
  discountPercent: number;
  discountTargetPrice: number;
  discountType: string;
  enabled: boolean;
  endDate: string;
  endTime: string;
  excludeCustomers: boolean;
  excludeStates: boolean;
  excludeStores: boolean;
  image: string | null;
  managementFeePercentage: number;
  minimumAvailableBudget: number;
  productBrandId: number;
  productExclusionType: string;
  productInclusionType: string;
  schedule: ScheduleData;
  scheduleType: string;
  serviceFeeRate: number;
  startDate: string;
  startTime: string;
  terms: string;
  title: string;
  type: string;
  userSegments: number[] | undefined;
}

const floatOrUndefined = (string?: string) =>
  string ? parseFloat(string) : undefined;

export const useSpecialFormData = () => {
  const { alertEmails, brandSpecial, duplicate, rules } = useSpecialForm();
  const { mutateAsync: createBrandSpecial } = useCreateBrandSpecial();
  const { add } = useToast();
  const { mutateAsync: updateBrandSpecial } = useUpdateBrandSpecial({});

  const specialStartTime = transformDate(brandSpecial?.start_time).time;
  const specialEndTime = transformDate(brandSpecial?.end_time).time;

  const defaultValues = useMemo(
    () => ({
      aeropayLocationId: brandSpecial?.aeropay_location_id ?? undefined,
      availableBudget: duplicate
        ? undefined
        : floatOrUndefined(brandSpecial?.available_budget),
      budgetTotal: floatOrUndefined(brandSpecial?.budget_total),
      campaignType: brandSpecial?.campaign_type,
      description: brandSpecial?.description ?? undefined,
      discountType: brandSpecial?.discount_type,
      discountDollarAmount: brandSpecial?.discount_dollar_amount,
      discountPercent: brandSpecial?.discount_percent,
      discountTargetPrice: brandSpecial?.discount_target_price,
      enabled: duplicate ? undefined : brandSpecial?.enabled,
      endDate: duplicate
        ? undefined
        : transformDate(brandSpecial?.end_time).date,
      endTime: specialEndTime,
      excludeCustomers: !!getExclusionRule(rules, 'user_segment_ids').length,
      excludeStates: !!getExclusionRule(rules, 'states').length,
      excludeStores: !!getExclusionRule(rules, 'store_ids').length,
      image: brandSpecial?.photo?.urls.original,
      managementFeePercentage: brandSpecial?.management_fee_percentage,
      minimumAvailableBudget: floatOrUndefined(
        brandSpecial?.minimum_available_budget
      ),
      productBrandId: brandSpecial?.product_brand_id,
      productExclusionType: hasProductExclusionRules(brandSpecial?.rules)
        ? 'specific'
        : 'none',
      productInclusionType: hasProductInclusionRules(
        brandSpecial?.rules?.includes
      )
        ? 'specific'
        : 'all',
      schedule: brandSpecial?.schedule
        ? transformSchedule(brandSpecial?.schedule)
        : {
            dailySchedule: {},
            hasCustomSchedule: false,
            scheduledDays: {},
          },
      serviceFeeRate: brandSpecial?.service_fee_rate
        ? parseFloat(brandSpecial?.service_fee_rate) * 100
        : undefined,
      startDate: duplicate
        ? undefined
        : transformDate(brandSpecial?.start_time).date,
      startTime: specialStartTime,
      terms: brandSpecial?.terms ?? undefined,
      title: brandSpecial?.title,
    }),
    [brandSpecial]
  );

  const buildPayload = (formValues: SpecialFormData) => {
    const {
      discountDollarAmount, // Removed if not discount_type = dollar_amount
      discountPercent, // Removed if not discount_type === percent
      discountTargetPrice, // Removed if not discount_type = target_price
      discountType, // Used for the above three values
      endDate, // Requires formatting
      endTime, // Requires formatting
      excludeCustomers, // Used to determine if customer exclusions should be added to payload
      excludeStates, // Used to determine if state exclusions should be added to payload
      excludeStores, // Used to determine if store exclusions should be added to payload
      image, // Nested attribute
      productBrandId, // Isn't included with PATCH request
      productExclusionType, // Used to format rules array
      productInclusionType, // Used to format rules array
      schedule, // requires formatting
      serviceFeeRate, // Needs to be converted to decimal
      startDate, // requires formatting
      startTime, // requires formatting
      ...basicKeys // These can all just be snaked_cased
    } = formValues;

    const snakeCasedValues = Object.fromEntries(
      Object.entries(basicKeys).map(([key, value]) => [
        key.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`),
        value,
      ])
    );

    // The API Does not want these values for PATCH requests
    const createOnlyValues = {
      product_brand_id: productBrandId,
      special_type: 'product',
    };

    // The backend wants html encoded or null/undefined as the image,
    // not the url that the fetch would return if a special already has an image.
    const updateImage =
      (typeof image === 'string' && image.startsWith('data:image/')) || !image;

    const payload = {
      ...snakeCasedValues,
      ...(!brandSpecial || duplicate ? createOnlyValues : {}),
      ...(discountType === 'dollar_amount' && {
        discount_dollar_amount: discountDollarAmount,
      }),
      ...(discountType === 'percent' && { discount_percent: discountPercent }),
      ...(discountType === 'target_price' && {
        discount_target_price: discountTargetPrice,
      }),
      ...(updateImage && {
        photo_attributes: {
          file: image,
        },
      }),
      alert_emails: alertEmails,
      discount_type: discountType,
      end_time: untransformDate(endDate, endTime, true) || null,
      schedule: untransformSchedule(schedule.dailySchedule),
      service_fee_rate: String(serviceFeeRate / 100),
      start_time: untransformDate(startDate, startTime),
      rules: {
        excludes: formatExclusionRules(rules.excludes, {
          productExclusionType,
          excludeCustomers,
          excludeStates,
          excludeStores,
        }),
        includes: formatInclusionProductRules(
          rules.includes,
          productInclusionType === 'all'
        ),
      },
    };

    return payload;
  };

  const submitForm = async (formValues: SpecialFormData) => {
    const payload = buildPayload(formValues);

    try {
      if (brandSpecial && !duplicate) {
        await updateBrandSpecial({ ...payload, id: brandSpecial.id });
      } else {
        await createBrandSpecial(payload);
      }
      return { success: true };
    } catch (error) {
      if (
        error instanceof ApiRequestError &&
        error.response.status.toString().match(/^4/)
      ) {
        const { errors } = await error.response.json();

        console.error('[jane gold form submission]', errors);

        Object.entries(errors).forEach(([field, value]) => {
          add({
            label: (
              <Flex maxWidth={500}>
                <Typography color="inherit" variant="body-bold">
                  {sentenceCase(field)}:&nbsp;
                </Typography>
                <Typography color="inherit" variant="body-bold">
                  {JSON.stringify(value)}
                </Typography>
              </Flex>
            ),
            variant: 'error',
          });
        });
      }
      return { success: false };
    }
  };

  return { defaultValues, submitForm };
};
