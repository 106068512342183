import escapeRegExp from 'lodash/escapeRegExp';
import { useMemo, useState } from 'react';

import { useSelectedSubdivision } from '@jane/catalog-cms/hooks';
import { LOCALIZATION_OPTIONS } from '@jane/catalog-cms/util';
import {
  Box,
  Button,
  ChevronDownIcon,
  Popover,
  RadioFieldGroup,
  SearchField,
} from '@jane/shared/reefer';

const DEFAULT_OPTION = {
  id: 'default',
  value: '',
  label: 'Default',
};

const LOCALIZATION_RADIO_OPTIONS = [DEFAULT_OPTION].concat(
  LOCALIZATION_OPTIONS.map((el) => {
    return { ...el, id: el.value };
  })
);

export const StateSelector = () => {
  const { selectedSubdivision, updateSelectedSubdivision } =
    useSelectedSubdivision();
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const selectedOption = useMemo(() => {
    return LOCALIZATION_RADIO_OPTIONS.find(
      (el) => el.value === selectedSubdivision
    );
  }, [selectedSubdivision]);

  const filteredOptions = useMemo(() => {
    let filtered = LOCALIZATION_RADIO_OPTIONS;
    const regex = new RegExp(escapeRegExp(searchTerm), 'i');

    if (searchTerm) {
      filtered = LOCALIZATION_RADIO_OPTIONS.filter(
        (option) => option.label.match(regex) || option.value.match(regex)
      );
    }

    return filtered;
  }, [searchTerm]);

  return (
    <Popover
      target={
        <Button
          full
          variant="tertiary"
          label={`Viewing as ${selectedOption?.label || 'Default'}`}
          endIcon={<ChevronDownIcon size="sm" />}
        ></Button>
      }
      label="State view selector"
      alignment={{ horizontal: 'right', vertical: 'bottom' }}
    >
      <Popover.Content padding={true}>
        <Box pb={12}>
          <SearchField
            label={'State Search'}
            name={'state-search'}
            onChange={(searchTerm) => setSearchTerm(searchTerm)}
            defaultValue={searchTerm}
          />
        </Box>
        <Box p={8} minWidth={298}>
          <RadioFieldGroup
            name="state-view"
            options={filteredOptions}
            defaultChecked={selectedSubdivision || 'default'}
            onChange={(value: string) => updateSelectedSubdivision(value)}
          />
        </Box>
      </Popover.Content>
    </Popover>
  );
};
