import { useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import { useInfiniteStores } from '@jane/gold-manager/data-access';
import {
  SUBMIT_BUTTON_VARIANTS,
  StoreSelectModal,
} from '@jane/shared-b2b/components';
import {
  Box,
  Button,
  DismissIcon,
  Flex,
  Typography,
} from '@jane/shared/reefer';
import { Form, useWatch } from '@jane/shared/reefer-hook-form';

import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import { getExclusionRule } from '../utils/specialForm.util';

const StoresSelectionModal = ({
  setStoreIds,
  storeIds,
  type,
}: {
  setStoreIds: (storeIds: number[]) => void;
  storeIds: number[];
  type: 'inclusion' | 'exclusion';
}) => {
  const queryClient = useQueryClient();
  const [showAll, setShowAll] = useState(false);
  const [open, setOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState<string>();
  const [searchedStoreIds, setSearchedStoreIds] = useState<string[]>([]);

  const { data: searchedStoresData } = useInfiniteStores({
    ids: searchedStoreIds,
    query: searchFilter,
    perPage: 10,
  });

  const selectedProps = {
    ids: storeIds,
    perPage: 1000,
  };

  const { data: selectedStoresData } = useInfiniteStores({
    enabled: !!storeIds.length,
    ...selectedProps,
  });

  const onRemoveStore = (storeId: number) => {
    const newProps = {
      ...selectedProps,
      ids: selectedProps.ids.filter((id) => id !== storeId),
    };

    // Preemptively create a cache for what _would_ be the next fetch (all the same stores minus 1)
    // This prevents a network request and a UI flicker as the query has no data for a second
    queryClient.setQueryData(['stores', newProps], {
      ...selectedStoresData,
      pages: selectedStoresData?.pages.map((page) => ({
        ...page,
        stores: page.stores.filter((store) => store.id !== Number(storeId)),
      })),
    });

    setStoreIds(
      storeIds.filter((selectedStoreId) => selectedStoreId !== storeId)
    );
  };

  const searchedStores =
    searchedStoresData?.pages.flatMap((page) => page.stores) ?? [];
  const selectedStores =
    selectedStoresData?.pages.flatMap((page) => page.stores) ?? [];

  return (
    <Flex flexDirection="column" gap={24}>
      <Button
        variant="tertiary"
        label="Select stores"
        data-testid={`${type}-stores-button`}
        onClick={() => setOpen(true)}
      />
      {storeIds && storeIds.length > 0 && (
        <Box>
          <Flex flexDirection="row" gap={24} flexWrap="wrap">
            {selectedStores.slice(0, showAll ? Infinity : 19).map((store) => (
              <Button
                key={store.id}
                label={`${store.name} (${store.id})`}
                aria-label={`Remove ${store.name}`}
                startIcon={<DismissIcon size="sm" />}
                variant={
                  type === 'inclusion' ? 'secondary' : 'destructive-secondary'
                }
                onClick={() => onRemoveStore(store.id)}
              />
            ))}
          </Flex>
          {storeIds.length > 20 && (
            <Button
              label={showAll ? 'Show less' : 'Show all'}
              variant="tertiary"
              onClick={() => setShowAll((prevValue) => !prevValue)}
              mt={12}
            />
          )}
        </Box>
      )}
      {open && (
        <StoreSelectModal
          storesData={searchedStores}
          isFetchingStores={false}
          isLoadingStores={false}
          searchFilterPlaceholder="Search"
          selectedStoreIds={storeIds.map(String)}
          onSubmit={(storeIds) => {
            setStoreIds(storeIds);
            setOpen(false);
          }}
          submitButtonType={SUBMIT_BUTTON_VARIANTS.save}
          initialSearchFilter={searchFilter}
          onSearchCallback={(s) => setSearchFilter(s)}
          searchedStoreIds={searchedStoreIds}
          showId
          onSearchedStoreIdsChange={setSearchedStoreIds}
          closeModal={() => {
            setOpen(false);
            setSearchFilter('');
            setSearchedStoreIds([]);
          }}
        />
      )}
    </Flex>
  );
};

export const StoresSelection = () => {
  const { rules, setRulesValue } = useSpecialForm();
  const showExcludeStores = useWatch({ name: 'excludeStores' });

  const setExclusionStoreIds = (value: number[]) =>
    setRulesValue('excludes.store_ids', value);
  const setInclusionStoreIds = (value: number[]) =>
    setRulesValue('includes.store_ids', value);
  const exclusionStoreIds = getExclusionRule(rules, 'store_ids');
  const inclusionStoreIds = rules?.includes?.[0]?.store_ids ?? [];

  const sameStoresError = inclusionStoreIds.some((storeId: number) =>
    exclusionStoreIds.includes(storeId)
  );

  return (
    <Flex flexDirection="column" gap={24}>
      <Typography variant="body-bold">Stores to target</Typography>
      <StoresSelectionModal
        type="inclusion"
        storeIds={inclusionStoreIds}
        setStoreIds={setInclusionStoreIds}
      />
      {sameStoresError && (
        <Typography color="error">Same stores are not permitted.</Typography>
      )}
      <Form.CheckboxField name="excludeStores" label="Exclude stores" my={12} />
      {showExcludeStores && (
        <>
          <StoresSelectionModal
            type="exclusion"
            storeIds={exclusionStoreIds}
            setStoreIds={setExclusionStoreIds}
          />
          {sameStoresError && (
            <Typography color="error">
              Same stores are not permitted.
            </Typography>
          )}
        </>
      )}
    </Flex>
  );
};
