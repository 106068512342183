import isUndefined from 'lodash/isUndefined';
import omitBy from 'lodash/omitBy';
import range from 'lodash/range';

import {
  AdTypeEnum,
  type ApiFlightDetails,
  scheduleForWeek,
} from '@jane/ad-manager/data-access';
import type {
  DisplayPlatformsOptionIdentifier,
  ManualScheduleForDays,
  ProductBrand,
} from '@jane/ad-manager/types';
import {
  dayAbbreviationFromIndex,
  dayNameFromIndex,
  parsePacificTime,
} from '@jane/ad-manager/util';

import { duplicateBidModifiers } from '../Pricing/BidModifiers';
import type { SelectionModeOptions } from '../Products/AdProductsSection';
import { PRODUCT_CATEGORIES } from '../Products/categories';
import { defaultAdSubmissionFormData } from './defaults';
import { DISPLAY_PLATFORMS_IDENTIFIER, SpendAllocationOption } from './types';
import type { AdPlacement, AdSubmissionFormState, GoalType } from './types';

export const buildDuplicateFlightDetailsFormData = (
  flight: ApiFlightDetails,
  selectedBrand?: ProductBrand
): AdSubmissionFormState => {
  // we don't have a way of displaying "Mixed" in the UI currently so we filter it out
  const adPlacement = flight.zones.filter((z) => z !== 'Mixed') as AdPlacement;

  const isPriority = flight.model === 'Flat';
  const adType = isPriority
    ? AdTypeEnum.enum.cpm
    : (flight.model.toLocaleLowerCase() as AdSubmissionFormState['adType']);
  const weekSchedule = scheduleForWeek(flight.schedule);
  const dayParting = range(0, 7).reduce((acc, dayIndex) => {
    const scheduleForDay: ApiFlightDetails['schedule'][0] | undefined =
      weekSchedule[dayAbbreviationFromIndex(dayIndex)];

    if (scheduleForDay === undefined) return acc;

    return {
      ...acc,
      [dayNameFromIndex(dayIndex)]: {
        startTime: parsePacificTime(scheduleForDay.startTime).format('hh:mm A'),
        endTime: parsePacificTime(scheduleForDay.endTime).format('hh:mm A'),
      },
    };
  }, {});

  const schedulesWithoutAllDaySetting = flight.schedule
    // remove all-day entries
    .filter(
      (entry) =>
        !(entry.startTime === '00:00:00' && entry.endTime === '23:59:00')
    );

  const manuallyScheduledDays =
    schedulesWithoutAllDaySetting.reduce<ManualScheduleForDays>(
      (acc, { weekDays }) => {
        weekDays.forEach((value) => {
          acc[value] = true;
        });
        return acc;
      },
      {}
    );

  const getDuplicateProductTargetingOption = (): SelectionModeOptions => {
    if (productCategoriesFromKeywords(flight.keywords).length > 0) {
      return 'category';
    } else if (flight.selectedProducts.length > 0) {
      return 'manual';
    }
    return 'automatic';
  };

  return fillFormDefaults({
    _spendAllocationOption:
      flight.schedule.length === 0
        ? SpendAllocationOption.Consistent
        : SpendAllocationOption.Manual,
    _manualScheduleForDays: manuallyScheduledDays,
    _productTargetingOption: getDuplicateProductTargetingOption(),
    _hasStoreExclusion: flight.excludedStores.length > 0,
    adPlacement,
    adType,
    bid: flight.bid,
    bidModifiers: duplicateBidModifiers(flight.bidModifiers),
    budget: budgetFromApiFlight(flight),
    creditPercent: flight.creditPercent || 0,
    displayPlatforms: platformsFromDisplaysOnProp(flight.displaysOn),
    dayParting,
    frequencyCapDenominator: flight.frequencyCapDenominator || undefined,
    frequencyCapNumerator: flight.frequencyCapNumerator || undefined,
    frequencyCapUnit:
      flight.frequencyCapUnit as AdSubmissionFormState['frequencyCapUnit'],
    isPriority,
    goalAmount: flight.goalAmount,
    goalType: flight.goalType as GoalType,
    menuRowTitle: flight.title || selectedBrand?.name,
    productIds: flight.selectedProducts.map((pdt) => String(pdt.id)),
    productCategories: productCategoriesFromKeywords(flight.keywords),
    states: flight.states || undefined,
    excludedStoreIds: flight.excludedStores.map((store) => store.id.toString()),
    storeIds: flight.stores.map((store) => store.id.toString()),
  });
};

const fillFormDefaults: (
  formData: Partial<AdSubmissionFormState>
) => AdSubmissionFormState = (formData) => {
  const sparseFormData = omitBy(
    formData,
    isUndefined
  ) as Partial<AdSubmissionFormState>;
  sparseFormData['endDate'] = '';
  sparseFormData['startDate'] = '';

  return {
    ...defaultAdSubmissionFormData,
    ...sparseFormData,
    _isDuplicate: true,
  };
};

const productCategoriesFromKeywords = (
  keywords: ApiFlightDetails['keywords']
) =>
  keywords.reduce((acc, keyword) => {
    const matchingCategory = PRODUCT_CATEGORIES.find(
      (category) => category.value === keyword.name
    );

    if (!matchingCategory) {
      return acc;
    }

    return [...acc, matchingCategory];
  }, [] as typeof PRODUCT_CATEGORIES);

const platformsFromDisplaysOnProp = (
  displaysOn: string | null
): DisplayPlatformsOptionIdentifier => {
  switch (displaysOn) {
    case 'Web':
      return DISPLAY_PLATFORMS_IDENTIFIER.web;
    case 'Kiosk':
      return DISPLAY_PLATFORMS_IDENTIFIER.kiosk;
    default:
    case null:
    case '-':
    case 'All':
      return DISPLAY_PLATFORMS_IDENTIFIER.all;
  }
};

const budgetFromApiFlight = ({
  bid,
  dailyBudget,
  goalAmount,
  model,
  goalType,
}: Pick<
  ApiFlightDetails,
  'model' | 'bid' | 'dailyBudget' | 'goalAmount' | 'goalType'
>): number | undefined => {
  if (model === 'Flat') {
    // priority = true
    return bid;
  }

  if (goalType === 'percentage') {
    return dailyBudget || undefined;
  }

  return goalAmount;
};
