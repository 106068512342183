import { useParams } from 'react-router-dom';

import { useBrandsNavigate } from '@jane/brands/hooks';
import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { getLocalizationName } from '@jane/catalog-cms/util';
import { CheckIcon, Flex, List, usePopoverContext } from '@jane/shared/reefer';

import { NavigationDropdown } from '.';

export interface NavigationDropdownLocalizationsProps {
  localizations: EditFormProduct['localizations'];
  productId: string;
}

export const Localizations = ({
  localizations,
  productId,
}: NavigationDropdownLocalizationsProps) => {
  const brandsNavigate = useBrandsNavigate();
  const { localizationId } = useParams();
  const { closePopover } = usePopoverContext();

  return (
    <>
      {localizations.length > 0 ? <NavigationDropdown.Divider /> : null}
      {localizations.map((localization) => {
        return (
          <LocalizationLink
            localization={localization}
            onClick={() => {
              brandsNavigate.editLocalization(productId, localization.id);
              closePopover();
            }}
            key={localization.id}
            selected={localizationId === localization.id}
          />
        );
      })}
    </>
  );
};

interface LocalizationLinkProps {
  localization: EditFormProduct['localizations'][number];
  onClick: () => void;
  selected?: boolean;
}

const LocalizationLink = ({
  localization,
  onClick,
  selected,
}: LocalizationLinkProps & { selected?: boolean }) => {
  const localizationName = getLocalizationName(
    localization.country,
    localization.subdivision
  );

  return (
    <List.Item>
      <Flex
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        onClick={onClick}
        aria-label={`Select localization ${localizationName}`}
      >
        {localizationName}
        {selected && <CheckIcon size="sm" color="purple" />}
      </Flex>
    </List.Item>
  );
};
