import clsx from 'clsx';

import type { ReeferBaseProps } from '../../../utils/baseProps.types';
import type { MarginProperties } from '../../../utils/spacing.types';
import { Typography } from '../../typography/typography';
import styles from './helperText.module.css';

export interface HelperTextProps extends MarginProperties, ReeferBaseProps {
  children?: React.ReactNode;
  disabled?: boolean;
  isHidden?: boolean;
}

export const HelperText = ({
  children,
  className,
  'data-testid': testId,
  disabled,
  id,
  isHidden,
  ...marginProps
}: HelperTextProps) => {
  if (!children) {
    return null;
  }

  return (
    <Typography
      className={clsx(className, {
        [styles.helperText__disabled]: disabled,
      })}
      color="grays-mid"
      data-testid={testId}
      id={`${id}-helper-text`}
      {...marginProps}
    >
      {children}
    </Typography>
  );
};
