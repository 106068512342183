import type { ReactNode } from 'react';
import { createContext, useCallback, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { ProductsPageParams } from '@jane/catalog-cms/types';

const ARRAY_KEYS = ['category', 'categoryLabel', 'status', 'brand'] as const;
const STRING_KEYS = [
  'searchTerm',
  'sortField',
  'reverse',
  'subdivision',
] as const;
const DATETIME_KEYS = ['createdTime', 'updatedTime'] as const;
const PARAM_KEYS = [...ARRAY_KEYS, ...STRING_KEYS, ...DATETIME_KEYS];

type UpdateProductsPageParamsType = (
  filterKey: keyof ProductsPageParams,
  filterValue: string[] | string
) => void;

export const ProductsPageParamsContext = createContext<{
  clearProductsPageParams: (
    paramsToClear?: (keyof ProductsPageParams)[]
  ) => void;
  productsPageParams: ProductsPageParams;
  updateProductsPageParams: UpdateProductsPageParamsType;
} | null>(null);

export const ProductsPageParamsProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const updateProductsPageParams = useCallback(
    (filterKey: keyof ProductsPageParams, filterValue: string[] | string) => {
      setSearchParams(() => {
        // See link for why using window.location.search
        // https://github.com/remix-run/react-router/issues/9757#issuecomment-1358336279
        const updated = new URLSearchParams(window.location.search);
        updated.delete(filterKey);

        if (Array.isArray(filterValue)) {
          // Array values need to be added individually
          if (filterValue.length > 0) {
            filterValue.forEach((val) => updated.append(filterKey, val));
          } else {
            // empty arrays are considered explicitly empty, add empty
            // string to search params
            updated.set(filterKey, '');
          }
        } else {
          updated.set(filterKey, filterValue);
        }
        return updated;
      });
    },
    [setSearchParams]
  );

  const clearProductsPageParams = useCallback(
    (paramsToClear?: (keyof ProductsPageParams)[]) => {
      const paramKeys = paramsToClear || PARAM_KEYS;

      setSearchParams(() => {
        // See link for why using window.location.search
        // https://github.com/remix-run/react-router/issues/9757#issuecomment-1358336279
        const updatedParams = new URLSearchParams(window.location.search);

        paramKeys.forEach((key) => {
          updatedParams.delete(key);
        });

        return updatedParams;
      });
    },
    [setSearchParams]
  );

  const productsPageParams = useMemo(() => {
    const parsedParams: ProductsPageParams = {};

    STRING_KEYS.forEach((key) => {
      if (searchParams.has(key)) {
        const value = searchParams.get(key) as string;
        value.length > 0
          ? (parsedParams[key] = value)
          : (parsedParams[key] = '');
      }
    });

    ARRAY_KEYS.forEach((key) => {
      if (searchParams.has(key)) {
        const values = searchParams.getAll(key).filter((str) => str !== '');
        if (values.length > 0) {
          parsedParams[key] = values;
        } else {
          parsedParams[key] = [];
        }
      }
    });

    DATETIME_KEYS.forEach((key) => {
      if (searchParams.has(key)) {
        const [time1, time2] = searchParams.getAll(key);
        const timeTuple = [];

        time1 && timeTuple.push(time1);
        time2 && timeTuple.push(time2);

        if (timeTuple.length > 0) {
          parsedParams[key] = timeTuple as [string] | [string, string];
        } else {
          parsedParams[key] = [];
        }
      }
    });

    return parsedParams;
  }, [searchParams]);

  const value = useMemo(
    () => ({
      productsPageParams,
      updateProductsPageParams,
      clearProductsPageParams,
    }),
    [clearProductsPageParams, productsPageParams, updateProductsPageParams]
  );

  return (
    <ProductsPageParamsContext.Provider value={value}>
      {children}
    </ProductsPageParamsContext.Provider>
  );
};
