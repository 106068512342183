import { useMemo, useState } from 'react';

import {
  Button,
  ChevronDownIcon,
  DismissIcon,
  Flex,
  TypeAhead,
  Typography,
} from '@jane/shared/reefer';
import { Form, useWatch } from '@jane/shared/reefer-hook-form';
import { us_states } from '@jane/shared/util';

import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import { getExclusionRule } from '../utils/specialForm.util';

export const StatesSelection = () => {
  const { rules, setRulesValue } = useSpecialForm();
  const showExcludeStates = useWatch({ name: 'excludeStates' });

  const [includesQuery, setIncludesQuery] = useState('');
  const [excludesQuery, setExcludesQuery] = useState('');

  const setExclusionStates = (value: string[]) =>
    setRulesValue('excludes.states', value);
  const setInclusionStates = (value: string[]) =>
    setRulesValue('includes.states', value);
  const exclusionStates = getExclusionRule(rules, 'states');
  const inclusionStates = rules?.includes?.[0]?.states ?? [];

  const filteredInclusionStates = useMemo(
    () =>
      us_states
        .filter(
          (state) =>
            state.abbreviation !== 'AS' &&
            state.name.toLowerCase().includes(includesQuery.toLowerCase()) &&
            !exclusionStates.includes(state.name)
        )
        .map((state) => ({ label: state.name })),
    [us_states, includesQuery, exclusionStates]
  );

  const filteredExclusionStates = useMemo(
    () =>
      us_states
        .filter(
          (state) =>
            state.abbreviation !== 'AS' &&
            state.name.toLowerCase().includes(includesQuery.toLowerCase()) &&
            !inclusionStates.includes(state.name)
        )
        .map((state) => ({ label: state.name })),
    [us_states, includesQuery, inclusionStates]
  );

  return (
    <Flex flexDirection="column" gap={24}>
      <Typography variant="body-bold">States to target</Typography>
      <TypeAhead
        ariaLabel="Search for inclusion states"
        listAriaLabel="Select a state from the list or search by name"
        labelHidden
        onChange={setIncludesQuery}
        onSelection={(value: string | string[]) => {
          if (Array.isArray(value)) {
            setInclusionStates(value);
          }
        }}
        selection={inclusionStates}
        optionType="checkbox"
        options={filteredInclusionStates}
        popoverTarget={
          <Button
            variant="tertiary"
            data-testid="inclusion-states-button"
            label="All states"
            endIcon={<ChevronDownIcon size="sm" />}
          />
        }
        query={includesQuery}
      />

      {inclusionStates && inclusionStates.length > 0 && (
        <Flex flexDirection="row" gap={24} flexWrap="wrap">
          {inclusionStates.map((state) => (
            <Button
              label={
                <Typography color="inherit" variant="body-bold">
                  {state}
                </Typography>
              }
              key={state}
              aria-label={`Remove ${state}`}
              startIcon={<DismissIcon size="sm" />}
              variant="secondary"
              onClick={() =>
                setRulesValue(
                  'includes.states',
                  inclusionStates.filter((st) => st !== state)
                )
              }
            />
          ))}
        </Flex>
      )}

      <Form.CheckboxField name="excludeStates" label="Exclude states" my={12} />
      {showExcludeStates && (
        <>
          <TypeAhead
            ariaLabel="Search for exclusion states"
            listAriaLabel="Select a state from the list or search by name"
            labelHidden
            onChange={setExcludesQuery}
            onSelection={(value: string | string[]) => {
              if (Array.isArray(value)) {
                setExclusionStates(value);
              }
            }}
            selection={exclusionStates}
            optionType="checkbox"
            options={filteredExclusionStates}
            popoverTarget={
              <Button
                variant="tertiary"
                label="All states"
                data-testid="exclusion-states-button"
                endIcon={<ChevronDownIcon size="sm" />}
              />
            }
            query={excludesQuery}
          />

          {exclusionStates?.length > 0 && (
            <Flex flexDirection="row" gap={24} flexWrap="wrap">
              {exclusionStates.map((state) => (
                <Button
                  label={
                    <Typography color="inherit" variant="body-bold">
                      {state}
                    </Typography>
                  }
                  key={state}
                  aria-label={`Remove ${state}`}
                  startIcon={<DismissIcon size="sm" />}
                  variant="destructive-secondary"
                  onClick={() =>
                    setRulesValue(
                      'excludes.states',
                      exclusionStates.filter((st) => st !== state)
                    )
                  }
                />
              ))}
            </Flex>
          )}
        </>
      )}
    </Flex>
  );
};
