import uniq from 'lodash/uniq';

import type { AlgoliaProduct } from '@jane/search/types';
import type {
  BrandSpecial,
  MenuProduct,
  PendingCartProduct,
  Product,
  ReservationCartProduct,
} from '@jane/shared/models';
import type { UserSegmentIds } from '@jane/shared/types';

export type AlgoliaUserSegmentIds = UserSegmentIds | -1;

type AnyProductType =
  | Product
  | MenuProduct
  | AlgoliaProduct
  | PendingCartProduct
  | ReservationCartProduct;

export type ProductWithBrandSpecialSegments = Pick<
  AnyProductType,
  | 'applicable_brand_specials_included_user_segments'
  | 'applicable_brand_specials_excluded_user_segments'
>;

export type ProductWithBrandSpecialPrices = Pick<
  AnyProductType,
  'brand_special_prices' | 'has_brand_discount'
>;

export interface ShouldSeeSpecialProps {
  excludedSegments?: AlgoliaUserSegmentIds | null;
  includedSegments?: AlgoliaUserSegmentIds | null;
  userSegments?: number[];
}

const shouldShowSpecial = ({
  userSegments = [],
  includedSegments,
  excludedSegments,
}: ShouldSeeSpecialProps): boolean => {
  const noIncludedSegments =
    !includedSegments ||
    includedSegments === -1 ||
    !(includedSegments as UserSegmentIds).length;
  const noExcludedSegments =
    !excludedSegments ||
    excludedSegments === -1 ||
    !(excludedSegments as UserSegmentIds).length;

  if (!userSegments || userSegments.length === 0) {
    // If the user has no segments, they can't see
    // specials that have any inclusion segments
    return noIncludedSegments;
  }

  const userIsIncluded = noIncludedSegments
    ? true
    : userSegments.some((segmentId) => includedSegments?.includes(segmentId));

  const userIsExcluded = noExcludedSegments
    ? false
    : userSegments.some((segmentId) => excludedSegments?.includes(segmentId));

  return userIsIncluded && !userIsExcluded;
};

interface ShouldProductShowSpecialProps {
  product: ProductWithBrandSpecialSegments;
  userSegments?: number[];
}

export const shouldProductShowSpecial = ({
  product,
  userSegments,
}: ShouldProductShowSpecialProps): boolean => {
  const includedSegments =
    product.applicable_brand_specials_included_user_segments as AlgoliaUserSegmentIds;
  const excludedSegments =
    product.applicable_brand_specials_excluded_user_segments as AlgoliaUserSegmentIds;

  return shouldShowSpecial({
    userSegments,
    includedSegments,
    excludedSegments,
  });
};

interface ShouldBrandSpecialShowForUserProps {
  brandSpecial: Pick<BrandSpecial, 'rules'>;
  userSegments?: number[];
}

export const shouldBrandSpecialShowForUser = ({
  brandSpecial,
  userSegments,
}: ShouldBrandSpecialShowForUserProps) => {
  const includedSegments = uniq(
    brandSpecial.rules.includes?.flatMap((rule) => rule.user_segment_ids || [])
  );
  const excludedSegments = uniq(
    brandSpecial.rules.excludes?.flatMap((rule) => rule.user_segment_ids || [])
  );

  return shouldShowSpecial({
    userSegments,
    includedSegments,
    excludedSegments,
  });
};

interface ShouldBrandShowSpecialsProps {
  brandSpecials: Pick<BrandSpecial, 'rules'>[];
  userSegments?: number[];
}

export const shouldBrandShowSpecials = ({
  brandSpecials,
  userSegments,
}: ShouldBrandShowSpecialsProps) => {
  return brandSpecials.some((brandSpecial) =>
    shouldBrandSpecialShowForUser({ brandSpecial, userSegments })
  );
};
