import { z } from 'zod';

import { zBrandSpecialUserSegmentIds } from './brandSpecials';
import { zId } from './id';
import {
  zBrandSpecialPrices,
  zEffect,
  zFlavor,
  zPhoto,
  zProductKindEnum,
  zProductLineageEnum,
  zProductTypeEnum,
  zProductUnitsEnum,
} from './product';

export const zAlmostGone = z.union([
  z.literal(1),
  z.literal(2),
  z.literal(3),
  z.null(),
]);

export const zProductWeightEnum = z.enum([
  'eighth_ounce',
  'gram',
  'half_gram',
  'half_ounce',
  'ounce',
  'quarter_ounce',
  'two_gram',
]);

export const zPriceId = z.union([zProductWeightEnum, z.literal('each')]);

export const zLabResult = z.object({
  price_id: zPriceId,
  lab_results: z.array(
    z.object({
      compound_name: z.string(),
      value: z.number(),
      unit: z.string(),
      unit_id: z.string(),
    })
  ),
});

const zInventoryTax = z.object({
  apply_to_discounted_price: z.boolean(),
  apply_to_non_cannabis_item: z.boolean(),
  is_taxable: z.boolean(),
  rate: z.number(),
  title: z.string(),
});

const zInventory = z.object({
  id: zId,
  bulk: z.boolean(),
  equivalency: z.number(),
  menu_product_id: z.number(),
  price_id: zPriceId.nullable(),
  tax: zInventoryTax.nullish(),
});

const zLabResultUrl = z.object({
  price_id: z.string().nullable(),
  lab_result_url: z.string().nullable(),
});

export const zInventoryPotency = z.object({
  cbd_potency: z.number().nullable(),
  price_id: zPriceId.nullable(),
  tac_potency: z.number().nullable(),
  thc_potency: z.number().nullable(),
});

// This lines up with api/app/serializers/abbreviated_menu_product_serializer.rb
export const zAbbreviatedMenuProduct = z.object({
  id: zId,
  brand_subtype: z.string().nullable(),
  brand: z.string().nullable(),
  kind: zProductKindEnum,
  name: z.string(),
  price_each: z.string().nullable(),
  price_eighth_ounce: z.string().nullable(),
  price_gram: z.string().nullable(),
  price_half_gram: z.string().nullable(),
  price_half_ounce: z.string().nullable(),
  price_id: zPriceId.nullish(),
  price_ounce: z.string().nullable(),
  price_quarter_ounce: z.string().nullable(),
  price_two_gram: z.string().nullable(),
  product_brand_id: z.number().nullish(),
  product_id: z.number(),
  type: zProductTypeEnum,
});
export const zAbbreviatedMenuProducts = z.array(zAbbreviatedMenuProduct);

const zPhotoWeight = z.object({
  id: zId,
  menu_product_id: z.number(),
  public_image_id: z.number(),
  weights: z.array(zPriceId).optional(),
});

export const zProductWeightSpecial = z.object({
  discount_amount: z.string().nullable(),
  discount_percent: z.string().nullable(),
  discount_price: z.string().nullable(),
  discount_type: z.string().nullable(),
  price: z.number(),
  special_id: z.number(),
  special_custom_badge: z.string().nullable().optional(),
});

// This lines up with api/app/serializers/menu_product_serializer.rb which
// inherits from AbbreviatedMenuProductSerializer It duplicates several things
// from zProduct, but so does the serializer, so it's easier to keep them in
// sync if we don't couple to zProduct. It also includes things that only exist
// in the algolia model. Those are included in a partial.
const zMenuProductBase = zAbbreviatedMenuProduct
  .merge(
    z.object({
      aggregate_rating: z.number().nullish(),
      allow_multiple_flower_count: z.boolean(),
      category: zProductLineageEnum.nullable(),
      cbd_dosage_milligrams: z.number().nullish(),
      description: z.string(),
      discounted_price_each: z.string().nullable(),
      discounted_price_eighth_ounce: z.string().nullable(),
      discounted_price_gram: z.string().nullable(),
      discounted_price_half_gram: z.string().nullable(),
      discounted_price_half_ounce: z.string().nullable(),
      discounted_price_ounce: z.string().nullable(),
      discounted_price_quarter_ounce: z.string().nullable(),
      discounted_price_two_gram: z.string().nullable(),
      effects: z.array(zEffect),
      flavors: z.array(zFlavor),
      id: z.number(),
      image_urls: z.array(z.string()),
      lab_results: z.array(zLabResult),
      max_cart_quantity: z.number(),
      net_weight_grams: z.number().nullable(),
      percent_cbd: z.number().nullable(),
      percent_thc: z.number().nullable(),
      photos: z.array(zPhoto),
      product_percent_cbd: z.number().nullable(),
      product_percent_thc: z.number().nullable(),
      quantity_units: zProductUnitsEnum.nullable(),
      quantity_value: z.number().nullable(),
      review_count: z.number().nullish(),
      root_subtype: z.string().nullable(),
      special_id: z.number().nullable(),
      special_title: z.string().nullable(),
      store_notes: z.string().nullable(),
      thc_dosage_milligrams: z.number().nullish(),
      url_slug: z.string(),
      visible: z.boolean(),
    })
  )
  .merge(
    // From algolia, reference api/app/models/menu_product.rb
    z
      .object({
        allergens: z.array(z.string()),
        amount: z.string().nullable(),
        applicable_brand_special_ids: z.array(z.number()).nullable(),
        applicable_bundle_special_ids: z
          .object({
            qualify: z.array(z.number()),
            discount: z.array(z.number()),
          })
          .nullable(),
        applicable_special_ids: z.array(z.number()),
        available_weights: z.array(z.string()),
        best_seller_rank: z.number().nullable(),
        brand_special_prices: zBrandSpecialPrices.nullable(),
        applicable_brand_specials_included_user_segments:
          zBrandSpecialUserSegmentIds,
        applicable_brand_specials_excluded_user_segments:
          zBrandSpecialUserSegmentIds,
        bucket_price: z.number().optional(),
        dosage: z.string().nullable(),
        effects: z.array(zEffect),
        flavors: z.array(zFlavor),
        has_brand_discount: z.boolean().nullable(),
        image_urls: z.array(z.string()),
        ingredients: z.array(z.string()),
        lab_result_urls: z.array(zLabResultUrl),
        operator_store_rank: z.number().nullable(),
        percent_tac: z.number().nullable(),
        photo_weights: z.array(zPhotoWeight),
        photos: z.array(zPhoto),
        quantity_units: zProductUnitsEnum.nullable(),
        quantity_value: z.number().nullable(),
        review_count: z.number().nullish(),
        root_subtype: z.string().nullable(),
        special_amount: z.string().nullable(),
        special_custom_badge: z.string().nullable(),
        special_id: z.number().nullable(),
        special_price_each: zProductWeightSpecial.nullable(),
        special_price_eighth_ounce: zProductWeightSpecial.nullable(),
        special_price_gram: zProductWeightSpecial.nullable(),
        special_price_half_gram: zProductWeightSpecial.nullable(),
        special_price_half_ounce: zProductWeightSpecial.nullable(),
        special_price_ounce: zProductWeightSpecial.nullable(),
        special_price_quarter_ounce: zProductWeightSpecial.nullable(),
        special_price_two_gram: zProductWeightSpecial.nullable(),
        special_title: z.string().nullable(),
        store_notes: z.string().nullable(),
        terpenes: z.array(z.record(z.string())),
        url_slug: z.string(),
      })
      .partial()
  );

const zMenuProductAdditions = z.object({
  inventories: z.array(zInventory).optional(),
  inventory_potencies: z.array(zInventoryPotency).optional(),
  percentCBDA: z.number(),
  percentTAC: z.number(),
  percentTHCA: z.number(),
  // These are from the client side cannabinoids serializer
  primaryPercentCBD: z.number(),
  primaryPercentTHC: z.number(),
  // This is added for some recommendation tracking on the client
  recommended: z.boolean(),
  secondaryPercentCBD: z.number(),
  secondaryPercentTHC: z.number(),
});

export const zMenuProduct = zMenuProductBase.merge(zMenuProductAdditions);

export type AbbreviatedMenuProduct = z.infer<typeof zAbbreviatedMenuProduct>;
export type AlmostGone = z.infer<typeof zAlmostGone>;
export type InventoryTax = z.infer<typeof zInventoryTax>;
export type MenuProduct = z.infer<typeof zMenuProduct>;
export type PhotoWeight = z.infer<typeof zPhotoWeight>;
export type PriceId = z.infer<typeof zPriceId>;
export type ProductWeight = z.infer<typeof zProductWeightEnum>;
export type ProductWeightSpecial = z.infer<typeof zProductWeightSpecial>;
export type Inventory = z.infer<typeof zInventory>;
export type InventoryPotency = z.infer<typeof zInventoryPotency>;
export type LabResult = z.infer<typeof zLabResult>;
