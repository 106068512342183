import type { AdType, ApiFlightSummary } from '@jane/ad-manager/data-access';
import type {
  BidModifiers,
  CPCAdPlacement,
  CPMAdPlacement,
  DisplayPlatformsOptionIdentifier,
  ManualScheduleForDays,
} from '@jane/ad-manager/types';
import type { DailySchedule } from '@jane/shared-b2b/components';

import type { SelectionModeOptions } from '../Products/AdProductsSection';
import type { ProductCategory } from '../Products/categories';

export const defaultCPCPlacements = [
  'Recommended row',
  'Cart toppers',
  'Inline product',
  'Product page',
] as CPCAdPlacement[];

export type AdPlacement = (CPMAdPlacement | CPCAdPlacement)[];
type FrequencyCapUnit = ApiFlightSummary['frequencyCapUnit'];

export enum SpendAllocationOption {
  Consistent = 'consistent_spend_allocation',
  Manual = 'manual_spend_allocation',
  Weekly = 'weekly_spend_allocation',
}

export enum GoalType {
  MonthlyRevenue = 'monthly_revenue',
  Percentage = 'percentage',
  Revenue = 'revenue',
}

export type AdSubmissionFormState = {
  _hasStoreExclusion?: boolean;
  _isDuplicate?: boolean;
  _manualScheduleForDays: ManualScheduleForDays;
  _productTargetingOption: SelectionModeOptions;
  _spendAllocationOption?: SpendAllocationOption;
  adPlacement: AdPlacement;
  adType: AdType;
  bid: number | null;
  bidModifiers: BidModifiers;
  budget: number;
  creditPercent: number;
  customerSegments: string;
  dayParting: DailySchedule;
  displayPlatforms: DisplayPlatformsOptionIdentifier;
  endDate: string;
  excludedStoreIds: string[];
  frequencyCapDenominator: number;
  frequencyCapNumerator: number;
  frequencyCapUnit: FrequencyCapUnit; //is disabled a valid value for this input?
  goalAmount: number;
  goalType: GoalType;
  isPriority: boolean;
  menuRowTitle: string | null;
  productCategories: ProductCategory[];
  productIds: string[];
  startDate: string;
  states: string[];
  storeIds: string[];
};

export const DISPLAY_PLATFORMS_IDENTIFIER = {
  all: 'all-platforms',
  web: 'web',
  kiosk: 'kiosk',
} as const;
