import type { RouteObject } from 'react-router-dom';
import { Navigate, Outlet } from 'react-router-dom';

import { AdBuilderPage } from '@jane/ad-manager/pages';
import { SelectedBrandProvider } from '@jane/ad-manager/providers';
import { AnalyticsError } from '@jane/brands-analytics/components';
import {
  BrandPortalLayout,
  ExternalIdentityManager,
  RedirectAuthed,
  RedirectIf,
  RedirectUnauthorized,
  RequireAuth,
  RouteLoading,
} from '@jane/brands/components';
import { FLAGS } from '@jane/brands/util';
import {
  LocalizationError,
  ProductDrawerLayout,
  ProductError,
  ProductsList,
} from '@jane/catalog-cms/components';
import { SpecialError } from '@jane/gold-manager/components';
import { useFlag } from '@jane/shared/feature-flags';
import { load } from '@jane/shared/util';

const Login = RouteLoading(load(() => import('./pages/Login')));
const ForgotPassword = RouteLoading(
  load(() => import('./pages/ForgotPassword'))
);
const ResetPassword = RouteLoading(load(() => import('./pages/ResetPassword')));
const Error = RouteLoading(load(() => import('./pages/Error')));
const Products = RouteLoading(
  load(() => import('./pages/CatalogCMS/Products'))
);
const EditProduct = RouteLoading(
  load(() => import('./pages/CatalogCMS/EditProduct')),
  false
);
const CreateProductNotice = RouteLoading(
  load(() => import('./pages/CatalogCMS/CreateProductNotice')),
  false
);
const CreateLocalization = RouteLoading(
  load(() => import('./pages/CatalogCMS/CreateLocalization')),
  false
);
const EditLocalization = RouteLoading(
  load(() => import('./pages/CatalogCMS/EditLocalization')),
  false
);
const AnalyticsPage = RouteLoading(
  load(() => import('./pages/Analytics/Analytics'))
);
const BrandThemeEditor = RouteLoading(
  load(() => import('./pages/AdManager/BrandThemeEditor'))
);
const BrandThemes = RouteLoading(
  load(() => import('./pages/AdManager/BrandThemes'))
);
const CampaignsPage = RouteLoading(
  load(() => import('./pages/AdManager/Campaigns'))
);
const DigitalMerchandising = RouteLoading(
  load(() => import('./pages/AdManager/DigitalMerchandising'))
);
const CampaignFlightList = RouteLoading(
  load(() => import('./pages/AdManager/CampaignFlightList'))
);
const FlightDetail = RouteLoading(
  load(() => import('./pages/AdManager/FlightDetail'))
);
const EditSpecial = RouteLoading(
  load(() => import('./pages/GoldManager/EditSpecial'))
);
const NewSpecial = RouteLoading(
  load(() => import('./pages/GoldManager/NewSpecial'))
);
const SpecialDetail = RouteLoading(
  load(() => import('./pages/GoldManager/SpecialDetail'))
);
const Specials = RouteLoading(
  load(() => import('./pages/GoldManager/Specials'))
);

const GatedGoldRoute = ({ children }: { children: React.ReactNode }) => {
  const isAllowed = useFlag(FLAGS.goldManager);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return !isAllowed ? <Navigate to={'/products'} /> : <>{children}</>;
};

const GatedCampaignsRoute = ({ children }: { children: React.ReactNode }) => {
  const isAllowed = useFlag(FLAGS.jamCampaignManagement);
  return <RedirectIf condition={!isAllowed}>{children}</RedirectIf>;
};

const publicRoutes: RouteObject[] = [
  {
    element: <RedirectAuthed />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'forgot-password', element: <ForgotPassword /> },
      { path: 'reset-password', element: <ResetPassword /> },
    ],
  },
];

const productsRoutes: RouteObject[] = [
  {
    element: <BrandPortalLayout />,
    children: [
      {
        path: 'products',
        element: <Products />,
        children: [
          {
            path: '',
            element: (
              <>
                <ProductsList />
                <Outlet />
              </>
            ),
            children: [
              {
                element: <ProductDrawerLayout />,
                children: [
                  {
                    path: ':productId',
                    element: <EditProduct />,
                    errorElement: <ProductError />,
                  },
                  {
                    path: ':productId/report',
                    element: <CreateProductNotice />,
                    errorElement: <ProductError />,
                  },
                  {
                    path: ':productId/localizations/:localizationId',
                    element: <EditLocalization />,
                    errorElement: <ProductError />,
                  },
                  {
                    path: ':productId/localizations/new',
                    element: <CreateLocalization />,
                    errorElement: <LocalizationError />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];

const brandThemeRoutes: RouteObject[] = [
  {
    element: <SelectedBrandProvider />,
    children: [
      {
        element: <RedirectUnauthorized requiredPermission="accessBrandPages" />,
        children: [
          {
            element: <BrandPortalLayout />,
            children: [{ path: 'brand-themes', element: <BrandThemes /> }],
          },
          {
            path: 'brand-theme-editor/:id',
            element: <BrandThemeEditor />,
          },
        ],
      },
    ],
  },
];

const analyticsRoutes: RouteObject[] = [
  {
    element: <RedirectUnauthorized requiredPermission="accessAnalytics" />,
    children: [
      {
        element: <BrandPortalLayout />,
        children: [
          {
            path: 'analytics',
            element: <AnalyticsPage />,
            errorElement: <AnalyticsError />,
          },
          {
            path: 'analytics/:liveboardId',
            element: <AnalyticsPage />,
            errorElement: <AnalyticsError />,
          },
        ],
      },
    ],
  },
];

const digitalMerchandisingRoutes: RouteObject[] = [
  {
    element: <SelectedBrandProvider />,
    children: [
      {
        element: (
          <RedirectUnauthorized requiredPermission="accessDigitalMerchandising" />
        ),
        children: [
          {
            element: <BrandPortalLayout />,
            children: [
              {
                path: 'digital-merchandising',
                element: <DigitalMerchandising />,
              },
              {
                path: 'digital-merchandising/campaigns',
                element: (
                  <GatedCampaignsRoute>
                    <CampaignsPage />
                  </GatedCampaignsRoute>
                ),
              },
              {
                path: 'digital-merchandising/flight/new',
                element: <AdBuilderPage />,
              },
              {
                path: 'digital-merchandising/flight/:id',
                element: <FlightDetail />,
              },
              {
                path: 'digital-merchandising/campaign/:id',
                element: (
                  <GatedCampaignsRoute>
                    <CampaignFlightList />,
                  </GatedCampaignsRoute>
                ),
              },
            ],
          },
        ],
      },
    ],
  },
];

const janeGoldRoutes: RouteObject[] = [
  {
    element: <RedirectUnauthorized requiredPermission="accessJaneGold" />,
    children: [
      {
        element: <BrandPortalLayout />,
        children: [
          {
            path: 'jane-gold',
            element: (
              <GatedGoldRoute>
                <Specials />
              </GatedGoldRoute>
            ),
            children: [
              {
                path: 'new',
                element: <NewSpecial />,
              },
              {
                path: ':specialId/edit',
                element: <EditSpecial />,
                errorElement: <SpecialError />,
              },
              {
                path: 'duplicate/:specialId',
                element: <NewSpecial />,
                errorElement: <SpecialError />,
              },
            ],
          },
          {
            path: 'jane-gold/:specialId',
            element: (
              <GatedGoldRoute>
                <SpecialDetail />
              </GatedGoldRoute>
            ),
            errorElement: <SpecialError />,
          },
        ],
      },
    ],
  },
];

export const routes: RouteObject[] = [
  {
    element: <ExternalIdentityManager />,
    children: [...publicRoutes],
    errorElement: <Error />,
  },
  {
    element: <ExternalIdentityManager />,
    children: [
      {
        element: <RequireAuth />,
        children: [
          ...productsRoutes,
          ...brandThemeRoutes,
          ...analyticsRoutes,
          ...digitalMerchandisingRoutes,
          ...janeGoldRoutes,
        ],
      },
    ],
    errorElement: <Error />,
  },
  {
    element: <Navigate to="/login" />,
    path: '*',
  },
];
