import type { SVGProps } from 'react';

import { Icon } from '../../icon/icon';
import type { LogoProps } from '../logo.types';

const JaneGold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 60 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M38.1201 7.83989C38.1201 6.07258 36.6874 4.63989 34.9201 4.63989C33.1528 4.63989 31.7201 6.07258 31.7201 7.83989V15.869C31.7201 17.797 33.283 19.3599 35.211 19.3599H41.3201C43.0874 19.3599 44.5201 17.9272 44.5201 16.1599C44.5201 14.3926 43.0874 12.9599 41.3201 12.9599H38.1201V7.83989Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M38.7601 12.32H41.3201C43.4409 12.32 45.1601 14.0392 45.1601 16.16C45.1601 18.2808 43.4409 20 41.3201 20H35.211C32.9295 20 31.0801 18.1505 31.0801 15.8691V7.84C31.0801 5.71923 32.7993 4 34.9201 4C37.0409 4 38.7601 5.71923 38.7601 7.84V12.32ZM41.3201 12.96C43.0874 12.96 44.5201 14.3927 44.5201 16.16C44.5201 17.9273 43.0874 19.36 41.3201 19.36H35.211C33.283 19.36 31.7201 17.7971 31.7201 15.8691V7.84C31.7201 6.07269 33.1528 4.64 34.9201 4.64C36.6874 4.64 38.1201 6.07269 38.1201 7.84V12.96H41.3201Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58.6002 11.9999C58.6002 7.93508 55.305 4.63989 51.2402 4.63989H46.4402C45.7332 4.63989 45.1602 5.21297 45.1602 5.91989V18.0799C45.1602 18.7868 45.7332 19.3599 46.4402 19.3599H51.2402C55.305 19.3599 58.6002 16.0647 58.6002 11.9999ZM51.2402 14.8799C51.4169 14.8799 51.5602 14.7366 51.5602 14.5599V9.43989C51.5602 9.26316 51.4169 9.11989 51.2402 9.11989C51.0634 9.11989 50.9202 9.26316 50.9202 9.43989V14.5599C50.9202 14.7366 51.0634 14.8799 51.2402 14.8799Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M46.4399 4H51.2399C55.6582 4 59.2399 7.58172 59.2399 12C59.2399 16.4183 55.6582 20 51.2399 20H46.4399C45.3795 20 44.5199 19.1404 44.5199 18.08V5.92C44.5199 4.85961 45.3795 4 46.4399 4ZM51.5599 9.44V14.56C51.5599 14.7367 51.4166 14.88 51.2399 14.88C51.0632 14.88 50.9199 14.7367 50.9199 14.56V9.44C50.9199 9.26327 51.0632 9.12 51.2399 9.12C51.4166 9.12 51.5599 9.26327 51.5599 9.44ZM51.2399 4.64C55.3047 4.64 58.5999 7.93518 58.5999 12C58.5999 16.0648 55.3047 19.36 51.2399 19.36H46.4399C45.733 19.36 45.1599 18.7869 45.1599 18.08V5.92C45.1599 5.21308 45.733 4.64 46.4399 4.64H51.2399Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.08 11.9999C31.08 7.93508 27.7848 4.63989 23.72 4.63989C19.6552 4.63989 16.36 7.93508 16.36 11.9999C16.36 16.0647 19.6552 19.3599 23.72 19.3599C27.7848 19.3599 31.08 16.0647 31.08 11.9999Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M31.7201 12C31.7201 16.4183 28.1384 20 23.7201 20C19.3018 20 15.7201 16.4183 15.7201 12C15.7201 7.58172 19.3018 4 23.7201 4C28.1384 4 31.7201 7.58172 31.7201 12ZM23.7201 4.64C27.7849 4.64 31.0801 7.93518 31.0801 12C31.0801 16.0648 27.7849 19.36 23.7201 19.36C19.6553 19.36 16.3601 16.0648 16.3601 12C16.3601 7.93518 19.6553 4.64 23.7201 4.64Z"
      fill="#0E0E0E"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 4H13.48C15.0706 4 16.36 5.28942 16.36 6.88C16.36 7.82083 15.9089 8.65629 15.2111 9.18186C15.8643 9.29868 16.36 9.86963 16.36 10.5564V15.84C16.36 18.1375 14.4975 20 12.2 20H9C4.58172 20 1 16.4183 1 12C1 7.58172 4.58172 4 9 4ZM7.40002 12.01C7.4054 10.7885 8.39726 9.8 9.62 9.8H14.9636C15.3814 9.8 15.72 10.1386 15.72 10.5564V15.84C15.72 17.784 14.144 19.36 12.2 19.36H9C4.93518 19.36 1.64 16.0648 1.64 12C1.64 7.93518 4.93518 4.64 9 4.64H13.48C14.7171 4.64 15.72 5.64288 15.72 6.88C15.72 8.11712 14.7171 9.12 13.48 9.12H9.64C8.04942 9.12 6.76 10.4094 6.76 12C6.76 12.0033 6.76001 12.0067 6.76002 12.01C6.76003 12.0133 6.76005 12.0167 6.76007 12.02C6.77078 13.5947 8.04529 14.8692 9.62 14.8799C9.62666 14.88 9.63333 14.88 9.64 14.88H10.28C10.4567 14.88 10.6 14.7367 10.6 14.56C10.6 14.3833 10.4567 14.24 10.28 14.24H9.62C8.39393 14.24 7.4 13.2461 7.4 12.02C7.4 12.0167 7.40001 12.0133 7.40002 12.01Z"
      fill="#0E0E0E"
    />
    <path
      d="M6.76002 11.9999C6.76002 10.4063 8.04642 9.11989 9.64002 9.11989H13.48C14.7152 9.11989 15.72 8.11509 15.72 6.87989C15.72 5.64469 14.7152 4.63989 13.48 4.63989H9.00002C4.93601 4.63989 1.64001 7.93589 1.64001 11.9999C1.64001 16.0639 4.93601 19.3599 9.00002 19.3599H12.2C14.1456 19.3599 15.72 17.7855 15.72 15.8399V10.5535C15.72 10.1375 15.3808 9.79829 14.9648 9.79829H9.62082C8.39842 9.79829 7.40641 10.7839 7.40001 12.0063V12.0191C7.40001 13.2479 8.39202 14.2399 9.62082 14.2399H10.28C10.4592 14.2399 10.6 14.3807 10.6 14.5599C10.6 14.7391 10.4592 14.8799 10.28 14.8799H9.64002C9.64002 14.8799 9.62722 14.8799 9.62082 14.8799C8.04642 14.8671 6.77282 13.5935 6.76002 12.0191V11.9999Z"
      fill="#FFC220"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8799 11.9998C27.8799 12.1766 27.7367 12.3198 27.5599 12.3198C25.6159 12.3198 24.0399 13.8958 24.0399 15.8398C24.0399 16.0166 23.8967 16.1598 23.7199 16.1598C23.5432 16.1598 23.3999 16.0166 23.3999 15.8398C23.3999 13.8958 21.824 12.3198 19.8799 12.3198C19.7032 12.3198 19.5599 12.1766 19.5599 11.9998C19.5599 11.8231 19.7032 11.6798 19.8799 11.6798C21.824 11.6798 23.3999 10.1039 23.3999 8.15984C23.3999 7.98311 23.5432 7.83984 23.7199 7.83984C23.8967 7.83984 24.0399 7.98311 24.0399 8.15984C24.0399 10.1039 25.6159 11.6798 27.5599 11.6798C27.7367 11.6798 27.8799 11.8231 27.8799 11.9998Z"
      fill="white"
    />
  </svg>
);
export const JaneGoldLogo = ({
  height = 48,
  width = 'auto',
  ...props
}: LogoProps) => {
  return (
    <Icon
      height={height}
      width={width}
      {...props}
      icon={JaneGold}
      color={undefined}
      rotate={undefined}
      size={undefined}
    />
  );
};
