import { useState } from 'react';

import {
  Banner,
  Button,
  Card,
  DismissIcon,
  ErrorIcon,
  Flex,
  TextField,
  Typography,
} from '@jane/shared/reefer';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import { useValidateEmails } from './useValidateEmails';

export const AlertsSection = () => {
  const { alertEmails, setAlertEmails } = useSpecialForm();
  const [emailValue, setEmailValue] = useState('');
  const { error, setError, validateEmails } = useValidateEmails();

  const defaultEmail = 'gold@iheartjane.com';

  const addEmails = (emailValue: string) => {
    setError('');

    const filteredEmailsArray = emailValue
      .split(',')
      .map((email) => email.trim())
      .filter((email) => email !== '')
      .filter(
        (email) => !alertEmails.some((currentEmail) => email === currentEmail)
      );

    if (!filteredEmailsArray.length) {
      setError('Email(s) already added.');
      return;
    }

    if (validateEmails(filteredEmailsArray)) {
      setEmailValue('');
      setAlertEmails(filteredEmailsArray);
    }
  };

  const removeEmail = (email: string) => {
    const filteredArray = alertEmails.filter((em) => em !== email);
    setAlertEmails(filteredArray);
  };

  return (
    <Card width="100%">
      <StyledCardGroup width="100%">
        <SpecialFormBlock first>
          <Typography branded variant="header-bold" mb={32}>
            Alerts
          </Typography>

          {error && (
            <Banner
              label={error}
              variant="error"
              mb={24}
              icon={<ErrorIcon />}
              onDismiss={() => setError('')}
            />
          )}

          <Flex alignItems="center" gap={16}>
            <TextField
              value={emailValue}
              onChange={(value) => setEmailValue(value)}
              name="email"
              label="Email"
              helperText="Only iheartjane.com emails may be added"
            />

            <Button
              variant="tertiary"
              label="Add"
              onClick={() => addEmails(emailValue)}
            />
          </Flex>

          <Flex mt={24} gap={24} flexWrap="wrap">
            <Button
              key={defaultEmail}
              label={defaultEmail}
              variant="secondary"
              aria-label={`Alerts are sent to ${defaultEmail} by default`}
            />
            {alertEmails.map((email) => (
              <Button
                key={email}
                label={email}
                onClick={() => removeEmail(email)}
                aria-label={`Remove ${email}`}
                variant="secondary"
                startIcon={<DismissIcon size="sm" />}
              />
            ))}
          </Flex>
        </SpecialFormBlock>
      </StyledCardGroup>
    </Card>
  );
};
