import styled from '@emotion/styled';

import { Popover } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const DateRangeFilterPopoverContent = styled(Popover.Content)({
  '> ul': {
    maxHeight: '500px',
  },
});

export const FilterListDateTimeField = styled.div(
  ({ disabled }: { disabled?: boolean }) => ({
    ...spacing({ px: 24, py: 12 }),
    ...(!disabled && {
      input: {
        cursor: 'pointer',
      },
      'input::-webkit-calendar-picker-indicator': {
        cursor: 'pointer',
      },
    }),
  })
);

export const FilterButtonGrid = styled.div({
  display: 'grid',
  gridGap: 16,
  gridAutoRows: '1fr',
  width: '100%',
  gridTemplateColumns: 'repeat(2, minmax(0, 1fr))',
  ...spacing({ px: 24, pb: 12, pt: 24 }),
});
