import clsx from 'clsx';
import { forwardRef, useEffect, useState } from 'react';
import type { ChangeEvent, FocusEvent } from 'react';

import { Box } from '../../box/box';
import { Flex } from '../../flex/flex';
import type { TextAreaFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import styles from './textAreaField.module.css';

/**
 * TextArea fields define multi-line text input controls.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.TextAreaField`](/story/reefer-hook-form-form-textareafield--default).*
 */
export const TextAreaField = forwardRef<
  HTMLTextAreaElement,
  TextAreaFieldProps
>(
  (
    {
      autoFocus = false,
      children,
      className,
      defaultValue = '',
      disabled = false,
      autocomplete,
      borderRadius = 'sm',
      disableMobileInputStyling = false,
      helperText,
      id,
      label,
      labelHidden = false,
      name,
      onBlur,
      onChange,
      onUpdate,
      placeholder,
      readOnly = false,
      resize = true,
      rows,
      'data-testid': testId,
      value: externalValue,
      width = 'auto',
      ...props
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);

    useEffect(() => {
      if (externalValue !== undefined && externalValue !== value) {
        setValue(externalValue);
        onUpdate && onUpdate(externalValue);
      }
    }, [externalValue, onUpdate, value]);

    const handleBlur = (event: FocusEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onBlur && onBlur(event.target.value);
    };

    const handleChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
      setValue(event.target.value);
      onChange && onChange(event.target.value);
    };

    return (
      <FieldWrapper
        className={className}
        disabled={disabled}
        disableMobileInputStyling={disableMobileInputStyling}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        labelPosition="t"
        name={name}
        isWrappingTextInput
        render={(renderProps) => (
          <Flex position="relative">
            <textarea
              autoFocus={autoFocus}
              disabled={disabled}
              autoComplete={autocomplete}
              className={clsx(
                styles.textAreaField_textarea,
                styles[`textAreaField_textarea__borderRadius_${borderRadius}`],
                {
                  [styles.textAreaField_textarea__readOnly]: readOnly,
                  [styles.textAreaField_textarea__resize]: resize,
                  [styles.textAreaField_textarea__mobile]:
                    !disableMobileInputStyling,
                }
              )}
              id={name}
              name={name}
              onBlur={handleBlur}
              onChange={handleChange}
              placeholder={placeholder}
              readOnly={readOnly}
              rows={rows}
              ref={ref}
              value={value}
              {...renderProps}
            />
            <Box
              borderRadius={borderRadius}
              height="100%"
              position="absolute"
              role="presentation"
              top={0}
              width="100%"
            />
          </Flex>
        )}
        data-testid={testId}
        width={width}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);
