import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { BaseForm } from '../baseForm/baseForm';
import type { FormProps as FormPropsShared } from './form.types';

export interface FormProps extends FormPropsShared {
  /** Function called when after after the user modifies any of the inputs for the first time **/
  onDirty?(isDirty: boolean): void;
}

/**
 * Use the `Form` component to wrap form fields and create simpler submittable forms, in which access to
 * `react-hook-form`'s `useForm` hook's methods and state are not needed in the component containing the form.
 *
 * The `useForm` hook is included within the `Form` component.
 *
 * The `Form` component supports an `onDirty` prop, for monitoring when form is dirty.
 *
 * For more complex forms, in which access to `react-hook-form`'s `useForm` hook's methods and/or state are needed,
 * use [`Form.BaseForm`](/story/reefer-hook-form-form-baseform--default), where the `useForm` hook is not
 * included within the component.
 */
export function Form({
  autocomplete = 'on',
  children,
  className,
  'data-testid': testId,
  formErrorName = 'form',
  height,
  id,
  maxHeight,
  maxWidth,
  name,
  onDirty,
  onSubmit,
  style,
  width,
}: FormProps) {
  const formMethods = useForm({
    mode: 'all',
  });
  const {
    formState: { isDirty },
  } = formMethods;

  useEffect(() => {
    onDirty && onDirty(isDirty);
  }, [isDirty, onDirty]);

  return (
    <BaseForm
      autocomplete={autocomplete}
      className={className}
      data-testid={testId}
      formErrorName={formErrorName}
      formMethods={formMethods}
      height={height}
      id={id}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      name={name}
      onSubmit={onSubmit}
      style={style}
      width={width}
    >
      {children}
    </BaseForm>
  );
}
