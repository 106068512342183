import { useMutation, useQueryClient } from '@tanstack/react-query';

import { brandsApi } from '@jane/brands/data-access';
import type { Id } from '@jane/shared/types';

import { BRAND_SPECIALS_URL } from './brandSpecials.queries';
import type {
  BrandSpecial,
  FetchBrandSpecialsParams,
} from './brandSpecials.types';
import { queryKeys } from './brandSpecials.utils';

type BrandSpecialWithId = Partial<BrandSpecial> & { id: number };

const updateBrandSpecial = async (brandSpecial: BrandSpecialWithId) =>
  await brandsApi.patch(BRAND_SPECIALS_URL + `/${brandSpecial.id}`, {
    brand_special: brandSpecial,
  });

export const useUpdateBrandSpecial = ({
  onError,
  onSuccess,
}: {
  onError?: () => void;
  onSuccess?: () => void;
}) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (brandSpecial: BrandSpecialWithId) =>
      updateBrandSpecial(brandSpecial),
    onError: () => {
      onError && onError();
    },
    onSuccess: ({ data }) => {
      queryClient.invalidateQueries(['infiniteBrandSpecials']);
      queryClient.setQueryData(
        queryKeys.brandSpecial({ id: (data as BrandSpecial).id }),
        () => data
      );
      onSuccess && onSuccess();
    },
  });
};

interface BulkUpdateBrandSpecialParams {
  brand_special_ids: Id[];
  schedule_attributes?: {
    enabled?: boolean;
    end_time?: string;
    start_time?: string;
  };
  special_attributes?: { available_budget?: string; budget_total?: string };
}

const bulkUpdateBrandSpecial = async (params: BulkUpdateBrandSpecialParams) => {
  await brandsApi.patch(BRAND_SPECIALS_URL + `/bulk_update`, params);
};

export const useBulkUpdateBrandSpecial = ({
  ...queryParams
}: FetchBrandSpecialsParams & { perPage: number }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: BulkUpdateBrandSpecialParams) =>
      bulkUpdateBrandSpecial(params),
    onSuccess: () =>
      queryClient.invalidateQueries(
        queryKeys.infiniteBrandSpecials(queryParams)
      ),
  });
};

const createBrandSpecial = async (brandSpecial: Partial<BrandSpecial>) =>
  await brandsApi.post(BRAND_SPECIALS_URL, {
    brand_special: brandSpecial,
  });

export const useCreateBrandSpecial = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (brandSpecial: Partial<BrandSpecial>) =>
      createBrandSpecial(brandSpecial),
    onSuccess: () => queryClient.invalidateQueries(['infiniteBrandSpecials']),
  });
};
