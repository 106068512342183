import debounce from 'lodash/debounce';
import { useState } from 'react';

import { ActiveFilterTag, StyledFilterList } from '@jane/shared-b2b/components';
import type { PopoverContextProps } from '@jane/shared/reefer';
import {
  Box,
  Button,
  ChevronDownIcon,
  Flex,
  NumberField,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import { DeselectFiltersButton } from './deselectFiltersButton';
import { FilterButtonGrid } from './specialsFilter.styles';
import { BUDGET_RANGE_OPTIONS } from './specialsFiltersOptions';

export interface BudgetRangeFilterProps {
  currentValue: number[];
  handleChange: (updatedValue: number[]) => void;
}

export const BudgetRangeFilter = ({
  currentValue,
  handleChange,
}: BudgetRangeFilterProps) => {
  const label = 'Budget';
  const [min, max] = currentValue;
  const isPresetRange = currentValue.length
    ? BUDGET_RANGE_OPTIONS.some(
        ({ value: [optionMin, optionMax] }) =>
          optionMin === Number(min) && optionMax === Number(max)
      )
    : false;
  const isCustomRange = currentValue.length > 0 && !isPresetRange;
  const [showNumberFields, setShowNumberFields] = useState(isCustomRange);

  const handleNumberFieldChange = debounce((value: number, index: 0 | 1) => {
    const updatedValue = [min, max];
    updatedValue[index] = value;
    handleChange(updatedValue);
  }, 150);

  return (
    <Popover
      target={
        <Button
          label={label}
          variant={currentValue.length === 0 ? 'tertiary' : 'tertiary-selected'}
          endIcon={
            <Flex alignItems="center">
              <ActiveFilterTag
                valuesCount={currentValue.length ? 1 : 0}
                label={label}
              />
              <ChevronDownIcon color="inherit" size="sm" />
            </Flex>
          }
        />
      }
      label={label}
      disableMobileStyling
    >
      {({ closePopover }: PopoverContextProps) => (
        <Popover.Content padding={false}>
          <StyledFilterList aria-label={`${label} inputs`}>
            <FilterButtonGrid>
              {BUDGET_RANGE_OPTIONS.map(({ label, value }) => {
                const [optionMin, optionMax] = value;
                const isSelected =
                  optionMin === Number(min) && optionMax === Number(max);

                return (
                  <Box as="li" width="100%" key={label}>
                    <Button
                      label={label}
                      variant={isSelected ? 'tertiary-selected' : 'tertiary'}
                      onClick={() => {
                        setShowNumberFields(false);
                        handleChange(value);
                      }}
                      full
                    />
                  </Box>
                );
              })}
            </FilterButtonGrid>
            <Box as="li" key="custom-range" px={24} width="296px">
              <Button
                label="Custom budget range"
                variant={
                  showNumberFields || isCustomRange
                    ? 'tertiary-selected'
                    : 'tertiary'
                }
                onClick={() => {
                  handleChange([]);
                  setShowNumberFields(!showNumberFields);
                }}
                full
              />
            </Box>
            {showNumberFields ? (
              <Flex as="li" alignItems="center" px={24} pt={12}>
                <NumberField
                  label="Min budget"
                  name="Min budget"
                  defaultValue={min}
                  onChange={(value) => {
                    if (value && typeof value === 'number') {
                      handleNumberFieldChange(value, 0);
                    } else if (max) {
                      handleChange([max]);
                    } else {
                      handleChange([]);
                    }
                  }}
                  placeholder="Min price"
                  labelHidden
                  width="118px"
                  disableMobileInputStyling
                  startUnit="$"
                />
                <Typography as="span" mx={2}>
                  &#8211;
                </Typography>
                <NumberField
                  label="Max budget"
                  name="Max budget"
                  defaultValue={max}
                  onChange={(value) => {
                    if (value && typeof value === 'number') {
                      handleNumberFieldChange(value, 1);
                    } else if (min) {
                      handleChange([min]);
                    } else {
                      handleChange([]);
                    }
                  }}
                  placeholder="Max price"
                  labelHidden
                  width="118px"
                  disableMobileInputStyling
                  startUnit="$"
                  disabled={!min}
                />
              </Flex>
            ) : null}
            <DeselectFiltersButton
              onClick={() => {
                handleChange([]);
                setShowNumberFields(false);
                closePopover();
              }}
              label="Clear budgets"
            />
          </StyledFilterList>
        </Popover.Content>
      )}
    </Popover>
  );
};
