import { brandsApi } from '@jane/brands/data-access';
import { createUseQueryHook } from '@jane/shared/data-access';
import { parseData } from '@jane/shared/util';

import { UserSegmentsResponseSchema } from './userSegments.types';
import type { UserSegment } from './userSegments.types';

const BASE_KEY = ['userSegments'];

const queryKeys = {
  allDmSegments: () => [...BASE_KEY, 'all'],
};

const USER_SEGMENTS_URL = '/jane_gold/user_segments';

const fetchUserSegments = async () => {
  const { data } = await brandsApi.get(USER_SEGMENTS_URL);
  const parsedData = parseData(UserSegmentsResponseSchema, data);

  return parsedData.user_segments;
};

export const useUserSegments = createUseQueryHook<void, UserSegment[]>(
  fetchUserSegments,
  queryKeys.allDmSegments
);
