import styled from '@emotion/styled';
import { useEffect, useState } from 'react';

import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Loading } from '@jane/shared/reefer';

import { BrandSelectHeader } from '../Header/BrandSelectHeader';
import { BrandThemesBody } from './Body/Container';
import { BrandThemesFooter } from './Footer';

const HeaderContainer = styled.div(() => ({
  gridColumn: '1 / 4',
}));

const BodyContainer = styled.div(() => ({
  alignSelf: 'center',
  gridColumn: '2 / 3',
}));

const FooterContainer = styled.div(() => ({
  gridColumn: '1 / 4',
  justifySelf: 'center',
}));

export const BrandThemes = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const {
    defaultBrand,
    isLoading,
    isSuccess,
    selectedBrand,
    setSelectedBrand,
  } = useSelectedBrandContext();

  useEffect(() => {
    if (!selectedBrand) {
      setSelectedBrand(defaultBrand);
    }
  }, [defaultBrand, selectedBrand, setSelectedBrand]);

  return (
    <>
      {isLoading && <Loading />}
      {isSuccess && selectedBrand && (
        <>
          <HeaderContainer>
            <BrandSelectHeader
              hasBoxShadow={true}
              brand={selectedBrand}
              setBrand={setSelectedBrand}
              isSearchOpen={isSearchOpen}
              setIsSearchOpen={setIsSearchOpen}
            />
          </HeaderContainer>
          <BodyContainer>
            <BrandThemesBody brand={selectedBrand} />
          </BodyContainer>
          <FooterContainer>
            <BrandThemesFooter />
          </FooterContainer>
        </>
      )}
    </>
  );
};
