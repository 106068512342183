import styled from '@emotion/styled';

import { CheckboxField, Flex, Tag, Typography } from '@jane/shared/reefer';

import { idConversions } from '../util/idConversions';
import type { ExtraColumnOptions, Store } from './StoreSelectModal';

const concatAddress = (store: Store): string => {
  return `${store.address}${store.address2 ? `, ${store.address2}` : ''}, ${
    store.city
  }, ${store.state} ${store.zip}`;
};

const StyledStoreWrapper = styled(Flex)<{ isHidden: boolean }>(
  ({ isHidden }) => ({
    ...(isHidden && { display: 'none' }),
  })
);

export const StoreRow = ({
  extraColumn,
  handleChange,
  isChecked,
  isHidden = false,
  showId = false,
  store,
}: {
  extraColumn?: ExtraColumnOptions;
  handleChange: (checked: boolean) => void;
  isChecked?: boolean;
  isHidden?: boolean;
  showId?: boolean;
  store: Store;
}) => {
  const stringifiedId = idConversions.asString(store.id);

  const tagProps = store.recreational
    ? ({
        label: 'Rec',
        color: 'primary-dark',
        background: 'primary-light',
      } as const)
    : ({
        label: 'Med',
        color: 'palm-dark',
        background: 'palm-light',
      } as const);

  const extraColumnMarkup = extraColumn?.render(store);

  return (
    <Flex justifyContent="space-between" alignItems="center">
      <StyledStoreWrapper
        isHidden={isHidden}
        my={16}
        alignItems="center"
        gap={16}
      >
        <CheckboxField
          label={stringifiedId}
          labelHidden
          name={stringifiedId}
          defaultChecked={isChecked}
          checked={isChecked}
          onChange={handleChange}
        />
        <Tag {...tagProps} />
        <div>
          <Typography variant="body-bold">
            {store.name}
            {showId ? ` (${store.id})` : ''}
          </Typography>
          <Typography color="grays-mid">{concatAddress(store)}</Typography>
        </div>
      </StyledStoreWrapper>
      {extraColumnMarkup ? (
        <StyledStoreWrapper
          isHidden={isHidden}
          my={16}
          alignItems="center"
          gap={16}
        >
          <Typography>{extraColumnMarkup}</Typography>
        </StyledStoreWrapper>
      ) : null}
    </Flex>
  );
};
