import { brandsPaths } from '@jane/brands/util';
import { Flex, Link, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { StyledSubmit } from './StyledSubmitButton';

type HandleSubmit = ({ email }: { email: string }) => Promise<unknown>;

export const ForgotPasswordForm = ({
  handleSubmit,
}: {
  handleSubmit: HandleSubmit;
}) => {
  return (
    <Form name="password-reset" onSubmit={handleSubmit} width="100%">
      <Flex flexDirection="column" gap={40}>
        <Form.ErrorBanner />
        <div>
          <Typography variant="body-bold">Forgot password?</Typography>
          <Typography>
            We'll send you a link to update your password.
          </Typography>
        </div>
        <Form.TextField label="Email" name="email" type="email" required />
        <Flex
          flexDirection="row-reverse"
          justifyContent="space-between"
          alignItems="baseline"
        >
          <StyledSubmit label="Email me" />
          <Link to={brandsPaths.login()}>Return to login</Link>
        </Flex>
      </Flex>
    </Form>
  );
};
