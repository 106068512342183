import type * as ReactTable from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';

import type { ApiFlightSummary } from '@jane/ad-manager/data-access';
import {
  Button,
  CheckboxField,
  ColumnsIcon,
  List,
  Popover,
} from '@jane/shared/reefer';

import type { CustomColumnMeta } from './FlightsTable/GetColumns';

interface Props<T> {
  columnOptions: string[];
  table: ReactTable.Table<T>;
}

export const ColumnVisibilityPopover = <T,>({
  table,
  columnOptions,
}: Props<T>) => {
  return (
    <Popover
      label="column-selector-popover"
      noMinWidth={true}
      target={
        <Button.Icon
          label="select visible columns"
          variant="secondary"
          icon={<ColumnsIcon color="primary" />}
        />
      }
    >
      <Popover.Content label="column-selector-popover-content">
        <List label="column-selector-list">
          {columnOptions.map((col) => {
            if (col === 'dateRange') {
              const startDateCol = table.getColumn('startDate');
              const endDateCol = table.getColumn('endDate');
              if (!startDateCol || !endDateCol) return null;
              return (
                <List.Item key="dateRange">
                  <CheckboxField
                    label="Date range"
                    name="dateRange"
                    checked={
                      startDateCol.getIsVisible() && endDateCol.getIsVisible()
                    }
                    onChange={(value) => {
                      startDateCol.toggleVisibility(value);
                      endDateCol.toggleVisibility(value);
                    }}
                  />
                </List.Item>
              );
            } else {
              const currentCol = table.getColumn(col);
              if (!currentCol) return null;
              const headerLabel = (
                currentCol.columnDef.meta as CustomColumnMeta<
                  ApiFlightSummary,
                  unknown
                >
              )?.headerLabel;
              return (
                <List.Item key={currentCol.id}>
                  <CheckboxField
                    label={
                      typeof currentCol.columnDef.header === 'string'
                        ? capitalize(currentCol.columnDef.header)
                        : headerLabel
                    }
                    name={currentCol.id}
                    checked={currentCol.getIsVisible()}
                    onChange={currentCol.toggleVisibility}
                    width={135}
                  />
                </List.Item>
              );
            }
          })}
        </List>
      </Popover.Content>
    </Popover>
  );
};
