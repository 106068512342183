import type { ReactElement, ReactNode } from 'react';
import type { ControllerRenderProps } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { Typography } from '@jane/shared/reefer';

import type { FormFieldProps } from '../formField.types';

export interface RenderProps {
  children?: ReactNode;
  field: ControllerRenderProps;
}

export interface FormFieldWrapperProps extends FormFieldProps {
  /** The initial value of the field */
  defaultValue: unknown;

  /** Name of the field. Submitted with the form as part of a name/value pair */
  name: string;

  /** Render function */
  render: (renderProps: RenderProps) => ReactElement;

  /** Same as required validation, but allow value `false` to pass required validation */
  requiredAllowFalse?: boolean;
}

/**
 * This is a wrapper component for common functionality of form fields.
 */
export function FormFieldWrapper({
  defaultValue,
  errorMessage,
  errorOnBlur,
  max,
  maxLength,
  minLength,
  min,
  name,
  pattern,
  render,
  required = false,
  requiredAllowFalse = false,
  shouldUnregister = true,
  validate,
}: FormFieldWrapperProps) {
  return (
    <Controller
      defaultValue={defaultValue}
      name={name}
      render={({ field, fieldState: { error, isTouched } }) =>
        render({
          children: error &&
            error.message &&
            ((errorOnBlur && isTouched) || !errorOnBlur) && (
              <Typography color="error" mt={16} role="alert">
                {error.message}
              </Typography>
            ),
          field,
        })
      }
      rules={{
        max: max && {
          message: `Please enter a numeric value less than or equal to ${max}.`,
          value: max,
        },
        maxLength: maxLength && {
          message: `Please use no more than ${maxLength} characters.`,
          value: maxLength,
        },
        min: min && {
          message: `Please enter a numeric value greater than or equal to ${min}.`,
          value: min,
        },
        minLength: minLength && {
          message: `Please use at least ${minLength} characters.`,
          value: minLength,
        },
        pattern: pattern && {
          message:
            errorMessage || `Please enter a value matching pattern ${pattern}.`,
          value: pattern,
        },
        validate: {
          requiredAllowFalse: (v) => {
            if (requiredAllowFalse) {
              if (v === false) {
                return true;
              } else {
                return !!v || 'Please fill out this field.';
              }
            } else {
              return true;
            }
          },
          required: (v) => {
            if (!required) return true;
            const trimmedValue = typeof v === 'string' ? v.trim() : v;
            return (
              !!trimmedValue ||
              trimmedValue === 0 ||
              'Please fill out this field.'
            );
          },
          validate: (v) =>
            typeof validate === 'function' ? validate(v) : true,
        },
      }}
      shouldUnregister={shouldUnregister}
    />
  );
}
