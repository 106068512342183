import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import type {
  EditFormProduct,
  Localization,
} from '@jane/catalog-cms/data-access';
import { Box, Drawer } from '@jane/shared/reefer';
import { Form, useForm } from '@jane/shared/reefer-hook-form';

import { Images } from '../shared/Images';
import { LocalizationInputs } from './LocalizationInputs';

export type EditLocalizationFormData = {
  categoryLabel: string;
  description: string;
  imageURLs: string[];
  lineage: string;
  name: string;
};

export interface EditLocalizationFormProps {
  formId: string;
  localization: Localization;
  onDirty?: (isDirty: boolean) => void;
  onSubmit: SubmitHandler<EditLocalizationFormData>;
  parentProduct: EditFormProduct;
}

export const EditLocalizationForm = ({
  localization,
  parentProduct,
  formId,
  onDirty,
  onSubmit,
}: EditLocalizationFormProps) => {
  const defaultValues = generateDefaultValues(localization, parentProduct);
  const formMethods = useForm<EditLocalizationFormData>({ defaultValues });

  const { reset } = formMethods;

  useEffect(() => {
    if (onDirty) {
      onDirty(formMethods.formState.isDirty);
    }
  }, [formMethods.formState.isDirty, onDirty]);

  return (
    <Form.BaseForm
      name="edit-localization"
      id={formId}
      autocomplete="off"
      formMethods={formMethods}
      onSubmit={async (data) => {
        await onSubmit(data as EditLocalizationFormData);
        // After successful submit reset form so default values are updated
        reset();
      }}
    >
      <Box pb={24}>
        <Form.ErrorBanner />
        <LocalizationInputs product={parentProduct} />
      </Box>
      <Drawer.ContentDivider />
      <Box pt={24}>
        <Images
          defaultValue={localization.images || []}
          revertToImageUrls={parentProduct.imageURLs}
        />
      </Box>
    </Form.BaseForm>
  );
};

/**
 * Given the localization and the product this function will generate the
 * default values for the localization form, falling back to the product value
 * and then the default value where appropriate.
 */
const generateDefaultValues = (
  localization: Localization,
  product: EditFormProduct
): EditLocalizationFormData => {
  return {
    categoryLabel: localization.categoryLabel ?? product.categoryLabel ?? '',
    description: localization.description ?? product.description,
    imageURLs: localization.images ?? product.imageURLs,
    lineage: localization.lineage ?? product.lineage ?? '',
    name: localization.name ?? product.name,
  };
};
