import { useEffect, useState } from 'react';

import { useFilterParams } from '@jane/ad-manager/hooks';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import { Box, Button, Flex } from '@jane/shared/reefer';

import { BrandSelectHeader } from '../Header/BrandSelectHeader';
import { useCampaignsTable } from './Campaigns/useCampaignsTable';
import { ColumnVisibilityPopover } from './ColumnVisibilityPopover';
import { NoBrandSelectedState } from './NoBrandSelectedState';
import { StatisticsFilters } from './StatisticsFilters';

const CAMPAIGN_COLUMN_VISIBILITY_OPTIONS = [
  'dateRange',
  'flightsBudget',
  'pacing',
  'states',
  'targetBudget',
];

export const Campaigns = () => {
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const { selectedBrand, setSelectedBrand } = useSelectedBrandContext();
  const { filterParams, onChangeFilterParams } = useFilterParams();

  useEffect(() => {
    // If no brand is selected, open the search modal (initial load)
    if (!selectedBrand) setIsSearchOpen(true);
  }, [selectedBrand]);

  const campaignsTable = useCampaignsTable({
    data: [],
  });

  const openCampaignBuilder = () => {
    console.log('openCampaignBuilder');
  };

  return (
    <Box data-testid="campaigns-container">
      {selectedBrand ? (
        <Box data-testid="campaigns-results-container">
          <BrandSelectHeader
            hasBoxShadow={false}
            brand={selectedBrand}
            setBrand={setSelectedBrand}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <Flex justifyContent="space-between" px={24} pt={24}>
            <StatisticsFilters
              filterParams={filterParams}
              onChangeFilterParams={onChangeFilterParams}
              hasAdTypeFilter={false}
            />
            <Flex gap={12}>
              <ColumnVisibilityPopover
                table={campaignsTable}
                columnOptions={CAMPAIGN_COLUMN_VISIBILITY_OPTIONS}
              />
              <Button
                variant="primary"
                label="Create Campaign"
                data-testid="create-campaign"
                onClick={openCampaignBuilder}
              />
            </Flex>
          </Flex>
        </Box>
      ) : (
        <Box data-testid="no-brand-selected-state-container">
          <BrandSelectHeader
            hasBoxShadow={false}
            brand={selectedBrand}
            setBrand={setSelectedBrand}
            isSearchOpen={isSearchOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
          <NoBrandSelectedState onClick={() => setIsSearchOpen(true)} />
        </Box>
      )}
    </Box>
  );
};
