import styled from '@emotion/styled';
import { useWatch } from 'react-hook-form';

import { validationMessages } from '@jane/ad-manager/util';
import { Banner, Flex, InfoIcon, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import type { AdSubmissionFormState } from '../formState/types';

const FrequencyCappingInputs = styled.div(() => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '2fr 1fr 1fr',
  gap: 24,
}));

const FREQUENCY_CAP_UNIT_OPTIONS = [
  { label: 'Minutes', value: 'minutes' },
  { label: 'Hours', value: 'hours' },
  { label: 'Days', value: 'days' },
];

export const FrequencyCapping = () => {
  const [
    frequencyCapNumerator,
    isPriority,
    frequencyCapDenominator,
    frequencyCapUnit,
  ] = useWatch<
    AdSubmissionFormState,
    [
      'frequencyCapNumerator',
      'isPriority',
      'frequencyCapDenominator',
      'frequencyCapUnit'
    ]
  >({
    name: [
      'frequencyCapNumerator',
      'isPriority',
      'frequencyCapDenominator',
      'frequencyCapUnit',
    ],
  });

  return (
    <Flex gap={24} flexDirection="column">
      <Flex flexDirection="column">
        <Typography variant="body-bold">Frequency capping</Typography>
        <Typography variant="body">
          Cap the number of times the flight can be displayed to any particular
          person in a particular time period.
        </Typography>
      </Flex>
      <FrequencyCappingInputs>
        <Form.NumberField
          label="Impressions"
          name="frequencyCapNumerator"
          disabled={!!isPriority}
          shouldUnregister={false}
          validate={(impressions) =>
            impressions ? true : validationMessages.requiredField
          }
        />
        <Form.NumberField
          label="Time period"
          name="frequencyCapDenominator"
          disabled={!!isPriority}
          shouldUnregister={false}
          validate={(period) =>
            period ? true : validationMessages.requiredField
          }
        />
        <Form.SelectField
          name="frequencyCapUnit"
          label="Time unit"
          labelHidden
          options={FREQUENCY_CAP_UNIT_OPTIONS}
          required={true}
          mt={32}
          disabled={!!isPriority}
          shouldUnregister={false}
        />
      </FrequencyCappingInputs>
      {!!frequencyCapNumerator &&
        !!frequencyCapDenominator &&
        !!frequencyCapUnit && (
          <Banner
            icon={<InfoIcon />}
            typography="body"
            label={`For each user, display this flight up to ${frequencyCapNumerator} times every ${frequencyCapDenominator} ${frequencyCapUnit}.`}
            variant="info"
          />
        )}
    </Flex>
  );
};
