import { z } from 'zod';

const zUserSegment = z.object({
  id: z.number(),
  description: z.string(),
});

export const UserSegmentsResponseSchema = z.object({
  user_segments: z.array(zUserSegment),
});

export type UserSegment = z.infer<typeof zUserSegment>;
