import { AdTypeEnum } from '@jane/ad-manager/data-access';

import {
  type AdSubmissionFormState,
  DISPLAY_PLATFORMS_IDENTIFIER,
  GoalType,
} from './types';

export const DEFAULT_FLAT_FREQUENCY_CAP_NUMERATOR = 1;
export const DEFAULT_FLAT_FREQUENCY_CAP_DENOMINATOR = 1;
export const DEFAULT_CPM_FREQUENCY_CAP_NUMERATOR = 2;
export const DEFAULT_CPM_FREQUENCY_CAP_DENOMINATOR = 30;
export const DEFAULT_CPC_FREQUENCY_CAP_NUMERATOR = 5;
export const DEFAULT_CPC_FREQUENCY_CAP_DENOMINATOR = 10;

export const defaultAdSubmissionFormData: AdSubmissionFormState = {
  _isDuplicate: false,
  _manualScheduleForDays: {},
  _productTargetingOption: 'automatic',
  _hasStoreExclusion: false,
  adPlacement: ['Menu row', 'Product page'],
  adType: AdTypeEnum.enum.cpm,
  bid: 0,
  bidModifiers: {},
  budget: 0,
  creditPercent: 0,
  customerSegments: '',
  dayParting: {},
  displayPlatforms: DISPLAY_PLATFORMS_IDENTIFIER.all,
  endDate: '',
  excludedStoreIds: [],
  frequencyCapDenominator: DEFAULT_CPM_FREQUENCY_CAP_DENOMINATOR,
  frequencyCapNumerator: DEFAULT_CPM_FREQUENCY_CAP_NUMERATOR,
  frequencyCapUnit: 'minutes',
  goalType: GoalType.Percentage,
  goalAmount: 100,
  isPriority: false,
  menuRowTitle: '',
  productCategories: [],
  productIds: [],
  startDate: '',
  states: [],
  storeIds: [],
};
