/* eslint-disable-next-line no-restricted-imports */
import { useLDClient, useLDClientError } from 'launchdarkly-react-client-sdk';

export { EVENT_NAMES } from './lib/eventNames';

export {
  DEFAULT_USER_CONTEXT,
  DEFAULT_USER_KEY,
  GUEST_BUCKET_COUNT,
  getFlagContext,
} from './lib/flagContext';
export type {
  UserContext,
  ExtraContextAttributes,
  FlagContextProps,
} from './lib/flagContext';
export { withFlagProvider, asyncFlagProvider } from './lib/flagProvider';
export { FLAGS } from './lib/flags';
export type { FlagsType } from './lib/useFlags';

export { useFlag, useFlags } from './lib/useFlags';
export const useFlagClient = useLDClient;
export const useFlagClientError = useLDClientError;
