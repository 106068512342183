import styled from '@emotion/styled';
import { useState } from 'react';

import { uploadBrandSpecialImage } from '@jane/gold-manager/util';
import { FileUploadArea } from '@jane/shared-b2b/components';
import {
  Banner,
  Box,
  Button,
  ErrorIcon,
  Flex,
  Image,
  InfoIcon,
  TrashIcon,
  Typography,
} from '@jane/shared/reefer';
import { useFormContext, useWatch } from '@jane/shared/reefer-hook-form';

const StyledImageBox = styled(Box)(() => ({
  height: '260px',
  overflow: 'hidden',
  position: 'relative',
  isolation: 'isolate',
}));

const DeleteButton = styled(Button.Icon)(() => ({
  zIndex: 2,
  position: 'absolute',
  top: 24,
  right: 24,
}));

export const ImageUpload = () => {
  const { setValue } = useFormContext();
  const image = useWatch({ name: 'image' });
  const [isUploading, setIsUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = async (acceptedFiles: Array<File>) => {
    setErrorMessage('');
    if (acceptedFiles.length === 0) return;

    setIsUploading(true);
    const file = acceptedFiles[0];
    try {
      const imageSrc = await uploadBrandSpecialImage(file);
      setValue('image', imageSrc);
    } catch (errorMessage: unknown) {
      setErrorMessage(errorMessage as string);
    }
    setIsUploading(false);
  };

  return (
    <Flex flexDirection="column" gap={24}>
      <Typography variant="body-bold">
        Image <Typography as="span">(Optional)</Typography>
      </Typography>
      {image ? (
        <StyledImageBox width="100%" borderRadius="lg">
          <Image
            src={image}
            altText="Preview of uploaded image"
            height="100%"
          />
          <DeleteButton
            aria-label="Delete image"
            variant="tertiary"
            onClick={() => setValue('image', undefined)}
            icon={<TrashIcon />}
          />
        </StyledImageBox>
      ) : (
        <FileUploadArea
          onDrop={onDrop}
          isLoading={isUploading}
          label="Upload image"
          dragLabel="Drag and drop image here"
        />
      )}

      {errorMessage ? (
        <Banner
          full
          variant="error"
          icon={<ErrorIcon />}
          label={<Typography>{errorMessage}</Typography>}
        />
      ) : !image ? (
        <Banner
          full
          icon={<InfoIcon />}
          label={<Typography>Images cannot exceed 10MB.</Typography>}
        />
      ) : null}
    </Flex>
  );
};
