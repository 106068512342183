import { useMatch } from 'react-router-dom';

import { useShouldShowGold } from '@jane/shared/hooks';
import {
  Flex,
  JaneGoldIcon,
  PARTNER_LOGO_SIZES,
  PartnerLogo,
  Typography,
  useTabletMediaQuery,
} from '@jane/shared/reefer';
import type { MarginProperties, PartnerLogoProps } from '@jane/shared/reefer';

import { GoldTagWrapper, LinkStyled } from './brandCard.styles';

export interface BrandCardProps
  extends MarginProperties,
    Pick<PartnerLogoProps, 'data-testid' | 'image' | 'name'> {
  /** `onClick` function the card should trigger */
  onClick?: () => void;

  /** Number of products from that brand */
  productCount?: number;

  /** Whether or not to display the Jane Gold icon beside the name */
  showGoldIcon?: boolean;

  /** Internal route to be linked to */
  to?: string;
}

export const BRAND_CARD_WIDTH = PARTNER_LOGO_SIZES.xl;
export const BRAND_CARD_WIDTH_MOBILE = PARTNER_LOGO_SIZES.lg;
export const BRAND_CARD_HEIGHT = 176;
export const BRAND_CARD_HEIGHT_MOBILE = 152;

/**
 * A card component used for representing individual brands in carousels or similar.
 */
export function BrandCard({
  'data-testid': testId,
  image,
  name,
  onClick,
  productCount,
  showGoldIcon = false,
  to,
  ...props
}: BrandCardProps) {
  const shouldShowGold = useShouldShowGold() && showGoldIcon;
  const isTablet = useTabletMediaQuery({ width: 'min' });
  const match = useMatch({ path: '/brands/all' });

  return (
    <LinkStyled onClick={onClick} to={to} variant="minimal">
      <Flex
        alignItems="center"
        data-testid={testId}
        flexDirection="column"
        width={isTablet ? BRAND_CARD_WIDTH : BRAND_CARD_WIDTH_MOBILE}
        {...props}
      >
        <PartnerLogo
          className="brandCard_partnerLogo"
          image={image}
          name={name}
          size={isTablet ? 'xl' : 'lg'}
          variant="brand"
        />
        <Flex alignItems="center" justifyContent="center" mt={8}>
          <Typography
            color="grays-black"
            textAlign="center"
            variant="body-bold"
          >
            {name}
          </Typography>
          {shouldShowGold && (
            <GoldTagWrapper allBrands={!!match}>
              <JaneGoldIcon size="sm" ml={4} />
            </GoldTagWrapper>
          )}
        </Flex>
        {productCount && (
          <Typography color="grays-mid">
            {productCount} {productCount > 1 ? 'products' : 'product'}
          </Typography>
        )}
      </Flex>
    </LinkStyled>
  );
}
