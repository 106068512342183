import { useEffect, useMemo } from 'react';
import type { SubmitHandler } from 'react-hook-form';

import type { EditFormProduct } from '@jane/catalog-cms/data-access';
import { LOCALIZATION_OPTIONS } from '@jane/catalog-cms/util';
import { Box, Drawer } from '@jane/shared/reefer';
import { Form, useForm } from '@jane/shared/reefer-hook-form';

import { Images } from '../shared/Images';
import { LocalizationInputs } from './LocalizationInputs';

export type CreateLocalizationFormData = {
  categoryLabel: string;
  description: string;
  imageURLs: string[];
  lineage: string;
  name: string;
  subdivision: string;
};
export interface CreateLocalizationFormProps {
  formId: string;
  onDirty?: (isDirty: boolean) => void;
  onSubmit: SubmitHandler<CreateLocalizationFormData>;
  parentProduct: EditFormProduct;
  selectedSubdivision?: string;
}

export const CreateLocalizationForm = ({
  formId,
  onDirty,
  onSubmit,
  parentProduct,
  selectedSubdivision,
}: CreateLocalizationFormProps) => {
  const defaultValues = generateDefaultValues(
    parentProduct,
    selectedSubdivision
  );
  const formMethods = useForm<CreateLocalizationFormData>({ defaultValues });

  const filteredStateOptions = useMemo(
    () =>
      filterLocalizationOptions(
        LOCALIZATION_OPTIONS,
        parentProduct.localizations
      ),
    [parentProduct.localizations]
  );

  useEffect(() => {
    if (onDirty) {
      onDirty(formMethods.formState.isDirty);
    }
  }, [formMethods.formState.isDirty, onDirty]);

  return (
    <Form.BaseForm
      id={formId}
      name="create-localization"
      autocomplete="off"
      formMethods={formMethods}
      onSubmit={onSubmit}
    >
      <Box pb={24}>
        <Form.ErrorBanner />
        <Box pb={24}>
          <Form.SelectField
            name="subdivision"
            options={filteredStateOptions}
            label="State"
            placeholder="Select a state"
            labelHidden={true}
            width="50%"
          />
        </Box>
        <LocalizationInputs product={parentProduct} />
      </Box>
      <Drawer.ContentDivider />
      <Box pt={24}>
        <Images
          defaultValue={parentProduct.imageURLs}
          revertToImageUrls={parentProduct.imageURLs}
        />
      </Box>
    </Form.BaseForm>
  );
};

/**
 * Given the product this function will generate the default values for the
 * create localization form, falling back to the default value when appropriate
 */
const generateDefaultValues = (
  product: Pick<
    EditFormProduct,
    'categoryLabel' | 'description' | 'imageURLs' | 'lineage' | 'name'
  >,
  subdivision?: string
): CreateLocalizationFormData => {
  return {
    categoryLabel: product.categoryLabel ?? '',
    description: product.description,
    imageURLs: product.imageURLs,
    lineage: product.lineage ?? '',
    name: product.name,
    subdivision: subdivision || '',
  };
};

/**
 * Given an array of localization options and the parent product's localizations
 * this will remove localization options for all localizations that parent
 * already has.
 */
const filterLocalizationOptions = (
  options: typeof LOCALIZATION_OPTIONS,
  productLocalizations: { country: string; subdivision: string }[]
) => {
  const subdivisionValues = productLocalizations.reduce<
    Record<string, boolean>
  >((acc, loc) => {
    const value = [loc.country, loc.subdivision].join('-').toUpperCase();
    acc[value] = true;
    return acc;
  }, {});

  return options.filter((option) => !(option.value in subdivisionValues));
};
