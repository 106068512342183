import styled from '@emotion/styled';

import { ButtonToggle } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

export const StyledTableRow = styled.tr(({ theme }) => ({
  verticalAlign: 'middle',
  '> td': {
    ...spacing({ px: 24 }),
  },
  '&:hover > td': {
    backgroundColor: theme.colors.grays.hover,
  },
}));

export const StyledButtonToggle = styled(ButtonToggle)(({ theme }) => ({
  backgroundColor: theme.colors.grays.ultralight,
  borderRadius: theme.borderRadius.sm,
  '> button': {
    borderRadius: theme.borderRadius.sm,
  },
}));
