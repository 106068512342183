import { useQuery } from '@tanstack/react-query';

import type { Coordinates } from '@jane/search/types';
import { config } from '@jane/shared/config';
import { api } from '@jane/shared/data-access';
import type { Preferences } from '@jane/shared/models';
import { encodeQuery } from '@jane/shared/util';

interface ApiParams {
  distance: Preferences['storeSearchRadius'];
  fulfillment_type: Preferences['storeFulfillmentType'];
  lat?: Coordinates['lat'];
  long?: Coordinates['long'];
  open_now?: boolean;
  store_type: Preferences['storeType'];
  zipcode?: string;
}

interface ApiResponse {
  store_ids: number[];
}

export const fetchUserPreferredStoreIds = async ({
  coordinates,
  zipcode,
  userPreferences = {},
}: {
  coordinates?: Coordinates;
  userPreferences: Preferences;
  zipcode?: string;
}): Promise<ApiResponse['store_ids'] | undefined> => {
  const params: ApiParams = {
    ...coordinates,
    zipcode,
    distance: userPreferences.storeSearchRadius,
    fulfillment_type: userPreferences.storeFulfillmentType,
    store_type: userPreferences.storeType,
  };

  if (userPreferences.storeAvailability === 'openNow') {
    params.open_now = true;
  }

  const url = encodeQuery(
    `${config.apiPath}/stores/ids_by_shopping_preferences`,
    params
  );

  const res: ApiResponse = await api.get(url);

  return res.store_ids;
};

export const useUserPreferredStoreIds = ({
  coordinates,
  zipcode,
  userPreferences = {},
}: {
  coordinates?: Coordinates;
  userPreferences?: Preferences;
  zipcode?: string;
}) =>
  useQuery({
    enabled: true,
    queryFn: () =>
      fetchUserPreferredStoreIds({ coordinates, zipcode, userPreferences }),
    queryKey: ['userPreferredStores', coordinates, userPreferences, zipcode],
  });
