import { useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { parseSearch } from '@jane/shared/util';

const FILTER_KEYS = [
  'brandId',
  'dateRange',
  'discountType',
  'remainingBudgetRange',
  'status',
];

const filterParamsToFilters = (parsedParams: Record<string, any>) => {
  const filters = Object.entries(parsedParams).map(([key, value]) => {
    return {
      [key]: value.split(','),
    };
  });

  return filters.length ? Object.assign({}, ...filters) : {};
};

export const useSpecialsFilters = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const filterParams = searchParams.toString();

  const onFilterChange = useCallback(
    (filterKey: string, filterValue: (string | number)[] | string) => {
      setSearchParams((searchParams) => {
        const newFilters = new URLSearchParams(searchParams);
        newFilters.delete(filterKey);

        if (Array.isArray(filterValue) && filterValue.length) {
          newFilters.append(filterKey, filterValue.join(','));
        } else if (!Array.isArray(filterValue) && filterValue.length) {
          newFilters.set(filterKey, filterValue);
        }
        return newFilters;
      });
    },
    [setSearchParams]
  );

  const onClearFilters = useCallback(() => {
    setSearchParams((searchParams) => {
      const updatedParams = new URLSearchParams(searchParams);

      FILTER_KEYS.forEach((key) => {
        updatedParams.delete(key);
      });

      return updatedParams;
    });
  }, [setSearchParams]);

  const specialsFilters = useMemo(() => {
    const parsedFilters = parseSearch(filterParams);
    return filterParamsToFilters(parsedFilters);
  }, [filterParams]);

  useEffect(() => {
    // set default search params
    if (!searchParams.has('status')) {
      searchParams.set('status', 'live,upcoming');
      setSearchParams(searchParams);
    }
  }, []);

  return {
    onFilterChange,
    onClearFilters,
    filters: specialsFilters,
  };
};
