import styled from '@emotion/styled';
import { useCallback, useEffect, useState } from 'react';

import { TimeSlotSelector } from '../timeSlotSelector/timeSlotSelector';
import { ORDERED_DAYS } from '../util/constants';
import type { DailySchedule } from '../util/schedulers.types';

const TimeSlotSelectorWrapper = styled.div(() => ({
  padding: '24px',
}));

export interface WeeklyScheduleSelectProps {
  /** Optional method to clear errors in the TimeSlotSelector */
  clearError?: () => void;

  /** Method called to update the schedule prop */
  onChangeSchedule: (...event: any[]) => void;

  /** Optional method to set errors in the TimeSlotSelector */
  setError?: (message: string) => void;
}

export const WeeklyScheduleSelect = ({
  clearError,
  onChangeSchedule,
  setError,
}: WeeklyScheduleSelectProps) => {
  const [weeklyStartTime, setWeeklyStartTime] = useState<string>('12:00 AM');
  const [weeklyEndTime, setWeeklyEndTime] = useState<string>('11:59 PM');

  useEffect(() => {
    const initialSchedule: DailySchedule = {};

    ORDERED_DAYS.forEach((day) => {
      initialSchedule[day] = {
        endTime: weeklyEndTime,
        startTime: weeklyStartTime,
      };
    });

    onChangeSchedule(initialSchedule);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeWeeklySchedule = useCallback(
    (newSchedule: { endTime: string; startTime: string }) => {
      setWeeklyStartTime(newSchedule.startTime);
      setWeeklyEndTime(newSchedule.endTime);

      const schedules: DailySchedule = {};

      ORDERED_DAYS.forEach((day) => {
        schedules[day] = newSchedule;
      });

      onChangeSchedule(schedules);
    },
    [onChangeSchedule]
  );

  return (
    <TimeSlotSelectorWrapper>
      <TimeSlotSelector
        timeframe="weekly"
        startTime={weeklyStartTime}
        endTime={weeklyEndTime}
        onChangeTimeframeSchedule={onChangeWeeklySchedule}
        setError={setError}
        clearError={clearError}
        startTimeLabel="Daily start time"
      />
    </TimeSlotSelectorWrapper>
  );
};
