import type * as ReactTable from '@tanstack/react-table';
import {
  getCoreRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import type {
  ApiFlightDetails,
  ApiFlightSummary,
} from '@jane/ad-manager/data-access';
import { useUpdateFlight } from '@jane/ad-manager/data-access';
import { useColumnVisibilityInStorage } from '@jane/ad-manager/hooks';
import { endOfDayInPacificTimeWithUTCTimezone } from '@jane/ad-manager/util';
import { FLAGS } from '@jane/brands/util';
import { useFlag } from '@jane/shared/feature-flags';
import { useToast } from '@jane/shared/reefer';

import { getColumns } from './GetColumns';

const DEFAULT_COLUMN_VISIBILITY_STATE: ReactTable.VisibilityState = {
  states: true,
  stores: false,
  startDate: true,
  endDate: true,
  model: true,
  products: false,
  placement: false,
  dailyBudget: true,
  spend: true,
  bid: true,
  frequencyCap: true,
  impressions: true,
  displaysOn: false,
};

export type RowActions = {
  onDuplicateRow: (row: ReactTable.Row<ApiFlightSummary>) => void;
  onSetActive: (flightId: number, isActive: boolean) => void;
};
type Props = Omit<
  ReactTable.TableOptions<ApiFlightSummary>,
  'getCoreRowModel' | 'columns'
> &
  Pick<RowActions, 'onDuplicateRow'> & {
    onFlightUpdated: () => Promise<unknown>;
  };

export const useFlightsTable = ({
  onDuplicateRow,
  onFlightUpdated,
  ...props
}: Props) => {
  const [loadingFlightIds, setLoadingFlightIds] = useState<number[]>([]);
  const toast = useToast();
  const { mutate: updateFlight } = useUpdateFlight({
    onSuccess: async (updatedFlight: ApiFlightDetails) => {
      await onFlightUpdated();

      toast.add({ label: 'Flight Updated' });

      // remove flight row from "loading" list
      setLoadingFlightIds((prevLoadingFlightIds) =>
        prevLoadingFlightIds.filter(
          (loadingFlightId) => loadingFlightId !== updatedFlight.id
        )
      );
    },
    onError: (err) =>
      toast.add({
        variant: 'error',
        label: `Flight update error: ${err.message}`,
      }),
  });

  const onSetActive = async (flightIdForUpdate: number, isActive: boolean) => {
    setLoadingFlightIds((prevLoadingFlightIds) => [
      ...prevLoadingFlightIds,
      flightIdForUpdate,
    ]);
    const endDate = endOfDayInPacificTimeWithUTCTimezone();
    updateFlight({
      id: flightIdForUpdate,
      isActive: isActive,
      endDate: endDate,
      isReadOnly: !isActive,
    });
  };

  const navigate = useNavigate();
  const enableMonthlyRevenueGoalType = useFlag(FLAGS.jamMonthlyRevenueGoalType);
  const columns = getColumns({
    enableMonthlyRevenueGoalType,
    navigate,
    onDuplicateRow,
    onSetActive,
    loadingFlightIds,
  });

  const [sorting, setSorting] = useState<ReactTable.SortingState>([
    { desc: true, id: 'id' },
  ]);

  const [columnVisibility, setColumnVisibility] = useColumnVisibilityInStorage(
    'flights',
    DEFAULT_COLUMN_VISIBILITY_STATE
  );

  return useReactTable({
    columns,
    onColumnVisibilityChange: setColumnVisibility,
    state: { columnVisibility, sorting },
    onSortingChange: setSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    ...props,
  });
};
