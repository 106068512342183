import { Flex, Grid } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { GoalType } from '../useAdBuilderForm';

const GOAL_TYPE_OPTIONS = [
  { label: 'Percentage', value: GoalType.Percentage },
  { label: 'Monthly', value: GoalType.MonthlyRevenue },
  { label: 'Revenue', value: GoalType.Revenue },
];

export const BudgetGoalType = () => (
  <Grid.Container>
    <Grid.Item xs={6}>
      <Flex gap={16} alignItems="center" mt={16}>
        <Form.SelectField
          label="Budget Type"
          name="goalType"
          defaultValue={GoalType.Percentage}
          options={GOAL_TYPE_OPTIONS}
          required={true}
          shouldUnregister={false}
          data-testid="pricing-goalType"
          width="100%"
        />
      </Flex>
    </Grid.Item>
    <Grid.Item xs={6} />
  </Grid.Container>
);
