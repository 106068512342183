import styled from '@emotion/styled';

import { Flex, Typography, useDesktopMediaQuery } from '@jane/shared/reefer';

const Dot = styled(Flex)<{ clickable: boolean }>(({ clickable }) => ({
  borderRadius: '50%',
  pointerEvents: clickable ? 'auto' : 'none',
}));

export const CountDot = ({
  ariaLabel,
  count,
  gold = false,
  onClick,
}: {
  ariaLabel: string;
  count: number | string;
  gold?: boolean;
  onClick?: () => void;
}) => {
  const isDesktop = useDesktopMediaQuery({});

  return (
    <Dot
      ariaLabel={ariaLabel}
      alignItems="center"
      background={gold ? 'gold' : 'ember'}
      data-testid="count-dot"
      height={16}
      justifyContent="center"
      onClick={onClick}
      position="absolute"
      right={-5}
      bottom={-3}
      width={16}
      clickable={!!onClick}
    >
      <Typography
        position="absolute"
        top={isDesktop ? '-4px' : '0px'}
        variant="mini-bold"
        color={gold ? 'grays-black' : 'grays-white'}
      >
        {count}
      </Typography>
    </Dot>
  );
};
