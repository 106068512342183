import clsx from 'clsx';
import type { ChangeEvent, FocusEvent, KeyboardEvent, WheelEvent } from 'react';
import { forwardRef, useEffect, useState } from 'react';
import type { NumberFormatValues } from 'react-number-format';
import { PatternFormat, usePatternFormat } from 'react-number-format';

import { numberRegex } from '../../../utils/regex';
import { Typography } from '../../typography/typography';
import type { NumberFieldProps } from '../field.types';
import { FieldWrapper } from '../fieldWrapper/fieldWrapper';
import styles from './numberField.module.css';

/**
 * Number fields allow users to enter numeric values into a UI.
 *
 * Use this component *outside forms* for inputs of `type`:
 * `number`.
 *
 * **NOTE: DO NOT USE THIS COMPONENT WITHIN FORMS**
 *
 * *For a similar component for use within forms, see [`Form.NumberField`](/story/reefer-hook-form-form-numberfield--default).*
 */
export const NumberField = forwardRef<HTMLInputElement, NumberFieldProps>(
  (
    {
      allowedDecimalPlaces,
      allowLeadingZeros,
      autocomplete,
      autoFocus = false,
      borderRadius = 'sm',
      children,
      className,
      defaultValue,
      disabled = false,
      disableMobileInputStyling = false,
      disableOnWheel = false,
      enterKeyHint,
      helperText,
      id,
      label,
      labelHidden = false,
      maskFormat,
      name,
      onBlur,
      onChange,
      onUpdate,
      placeholder,
      readOnly = false,
      step = 'any',
      width = 'auto',
      startUnit,
      endUnit,
      'data-testid': testId,
      value: externalValue,
      ...props
    },
    ref
  ) => {
    const { removeFormatting } = usePatternFormat({
      format: maskFormat || '',
    });
    const parseNumber = Number.isInteger(step) ? parseInt : parseFloat;
    const [value, setValue] = useState<string | undefined>(
      // Since this is a number, we need to check for undefined rather than a falsey value cause 0 should be valid
      defaultValue !== undefined
        ? allowedDecimalPlaces
          ? defaultValue.toFixed(allowedDecimalPlaces)
          : defaultValue.toString()
        : ''
    );

    useEffect(() => {
      if (
        externalValue !== undefined &&
        value !== undefined &&
        externalValue !== parseNumber(value)
      ) {
        setValue(
          allowedDecimalPlaces && typeof externalValue === 'number'
            ? externalValue.toFixed(allowedDecimalPlaces)
            : externalValue.toString()
        );
        onUpdate && onUpdate(externalValue);
      }
    }, [allowedDecimalPlaces, externalValue, parseNumber, onUpdate, value]);

    const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
      const unformattedValue = maskFormat
        ? removeFormatting(event.target.value)
        : event.target.value;
      let myValue: number | undefined = parseNumber(unformattedValue);
      myValue = isNaN(myValue) ? undefined : myValue;

      setValue(unformattedValue);
      onBlur && onBlur(allowLeadingZeros ? unformattedValue : myValue);
    };

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      handleValueChange({
        floatValue: undefined,
        formattedValue: '',
        value: event.target.value,
      });
    };

    const handleValueChange = (values: NumberFormatValues) => {
      const numValue = values.floatValue || parseNumber(values.value);
      const parsedValue = allowedDecimalPlaces
        ? numValue.toFixed(allowedDecimalPlaces)
        : values.value;

      let myValue: number | undefined = parseNumber(parsedValue);
      myValue = isNaN(myValue) ? undefined : myValue;

      setValue(values.value);
      onChange && onChange(allowLeadingZeros ? values.value : myValue);
    };

    const handleKeyPress = (event: KeyboardEvent<HTMLInputElement>) => {
      const myValue = event.key;

      const isValid = numberRegex.test(myValue) || event.key === 'Enter';

      if (!isValid) {
        event.preventDefault();
      }
    };

    // blur and refocus to prevent wheel event from changing value
    const handleOnWheel = (event: WheelEvent<HTMLInputElement>) => {
      const inputElement = event.target as HTMLInputElement;
      inputElement.blur();

      setTimeout(() => {
        inputElement.focus();
      }, 0);
    };

    const commonInputProps = {
      autoComplete: autocomplete,
      autoFocus: autoFocus,
      className: clsx(styles.numberField_input, {
        [styles.numberField_input__mobile]: !disableMobileInputStyling,
      }),
      disabled: disabled,
      enterKeyHint,
      id: name,
      name: name,
      onBlur: handleBlur,
      onKeyPress: handleKeyPress,
      placeholder: placeholder,
      readOnly: readOnly,
      step: step,
      value: value,
    };

    return (
      <FieldWrapper
        className={className}
        disabled={disabled}
        disableMobileInputStyling={disableMobileInputStyling}
        helperText={helperText}
        id={id}
        label={label}
        labelHidden={labelHidden}
        labelPosition="t"
        name={name}
        width={width}
        data-testid={testId}
        isWrappingTextInput
        render={(renderProps) => (
          <div
            className={clsx(
              styles.numberField_wrapper,
              styles[`numberField_wrapper__borderRadius_${borderRadius}`],
              {
                [styles.numberField_wrapper__readOnly]: readOnly,
                [styles.numberField_wrapper__mobile]:
                  !disableMobileInputStyling,
              }
            )}
          >
            {startUnit && (
              <Typography as="span" color="text-light" mr={4}>
                {startUnit}
              </Typography>
            )}
            {maskFormat ? (
              <PatternFormat
                {...commonInputProps}
                format={maskFormat}
                onValueChange={handleValueChange}
                getInputRef={ref}
                type="tel"
                valueIsNumericString={typeof value === 'string'}
                {...renderProps}
              />
            ) : (
              <input
                {...commonInputProps}
                onChange={handleChange}
                ref={ref}
                type="number"
                onWheel={disableOnWheel ? handleOnWheel : undefined}
                {...renderProps}
              />
            )}
            {endUnit && (
              <Typography as="span" color="text-light" ml={4}>
                {endUnit}
              </Typography>
            )}
          </div>
        )}
        {...props}
      >
        {children}
      </FieldWrapper>
    );
  }
);
