import { fetchUserPreferredStoreIds } from '@jane/shared-ecomm/data-access';
import { EventNames, track } from '@jane/shared-ecomm/tracking';
import type { GeocodingResult, Preferences } from '@jane/shared/models';

import type { LocationErrors } from './unservedLocationModalContent';

const illegalStates = ['ID', 'KS', 'KY', 'NC', 'NE', 'SC', 'WY'];

const additionalRegulationStates = ['FL', 'PA', 'AB'];

export const validateUserPreferences = async (
  location: GeocodingResult,
  userPreferences: Preferences
): Promise<{ error: LocationErrors | null }> => {
  track({ event: EventNames.SetLocation, ...location });

  const { coordinates, city, cityState, state, street, zipcode } = location;

  const {
    storeFulfillmentType,
    storeAvailability,
    storeType,
    storeSearchRadius,
  } = userPreferences;
  const delivery = storeFulfillmentType === 'delivery';

  if (!city || cityState === 'Unknown') {
    return { error: 'noCity' };
  }
  if (delivery && !street) {
    return { error: 'deliveryAddress' };
  }

  if (illegalStates.includes(state as string)) {
    return { error: 'illegal' };
  }

  if (additionalRegulationStates.includes(state as string)) {
    return { error: 'regulations' };
  }

  try {
    const stores = await fetchUserPreferredStoreIds({
      coordinates,
      zipcode,
      userPreferences: {
        storeFulfillmentType,
      },
    });

    if (stores?.length) {
      if (!storeAvailability && !storeType && !storeSearchRadius) {
        return { error: null };
      }

      const storesByAllPreferences = await fetchUserPreferredStoreIds({
        coordinates,
        zipcode,
        userPreferences,
      });

      if (storesByAllPreferences?.length) {
        return { error: null };
      } else {
        return { error: 'noStoresInSettings' };
      }
    } else {
      return { error: delivery ? 'noDeliveryStores' : 'noPickupStores' };
    }
  } catch (_e) {
    return {
      error: 'error',
    };
  }
};
