import React, { useState } from 'react';

import { parseIds } from '@jane/ad-manager/util';
import { Button, Flex, TextAreaField } from '@jane/shared/reefer';

export const ProductIdsSearch = ({
  setSearchIds,
}: {
  setSearchIds: (ids: string[]) => void;
}) => {
  const [ids, setIds] = useState('');

  const handleSubmit = () => {
    const productIds = parseIds(ids);
    setSearchIds(productIds);
  };

  return (
    <Flex gap={16} alignItems="center">
      <TextAreaField
        label="Product IDs"
        name="search_ids_input"
        autocomplete="off"
        placeholder="Enter Product IDs"
        defaultValue={ids}
        autoFocus
        labelHidden
        onChange={(val) => setIds(val)}
        enterKeyHint="search"
        width={416}
      />
      <Button variant="secondary" label="Search" onClick={handleSubmit} />
    </Flex>
  );
};
