import { useEffect } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import { Box, Typography } from '@jane/shared/reefer';
import { Form, useForm } from '@jane/shared/reefer-hook-form';

import { DuplicateProductSearch } from './DuplicateProductSearch';

export type ReportIssueFormData = {
  activeProductId: string;
  comment: string;
  notice: string;
};

export interface ReportIssueFormProps {
  formId: string;
  onDirty: (isDirty: boolean) => void;
  onSubmit: SubmitHandler<ReportIssueFormData>;
}

const defaultValues: ReportIssueFormData = {
  activeProductId: '',
  comment: '',
  notice: '',
};

export const ReportIssueForm = ({
  formId,
  onSubmit,
  onDirty,
}: ReportIssueFormProps) => {
  const formMethods = useForm<ReportIssueFormData>({ defaultValues });
  const noticeReason = formMethods.watch('notice');

  useEffect(() => {
    onDirty(formMethods.formState.isDirty);
  }, [formMethods.formState.isDirty, onDirty]);

  return (
    <Form.BaseForm
      id={formId}
      formMethods={formMethods}
      name="report-issue"
      onSubmit={onSubmit}
    >
      <Form.ErrorBanner />
      <Box>
        <Typography variant="body-bold" my={24}>
          Select the option that best describes the problem.
        </Typography>

        <Form.RadioFieldGroup
          name="notice"
          required
          options={[
            {
              id: 'report-duplicate',
              label: 'Report duplicate',
              value: 'duplicate',
            },
            {
              id: 'request-discontinue',
              label: 'Request discontinue',
              value: 'discontinued',
            },
            {
              id: 'report-wrong-brand',
              label: 'Report wrong brand',
              value: 'incorrect-brand',
            },
          ]}
        />
      </Box>

      {noticeReason === 'duplicate' && (
        <Box>
          <Controller
            name="activeProductId"
            render={({ field: { onChange } }) => {
              return <DuplicateProductSearch onChange={onChange} />;
            }}
          />
        </Box>
      )}

      <Box mt={24}>
        <Form.TextAreaField
          label="Comment (optional)"
          name="comment"
          typography="body-bold"
        />
      </Box>
    </Form.BaseForm>
  );
};
