import { getCSSVar } from '../../internal/utils/css';
import { getColorCSSVar } from '../../utils/colors';
import type { BorderRadiusKey, ReeferTheme } from '../theme.types';
import { BORDER_RADIUS_KEYS } from '../theme.types';
import type { ColorKey } from '../themeColors.types';
import { COLOR_KEYS } from '../themeColors.types';
import type { TypographyVariant } from '../themeComponents.types';
import { generateCSSVars } from './utils';

const generateButtonCSSVars = (button: ReeferTheme['components']['Button']) => {
  const iconSizeConfigs = button.icons.sizes;
  const variantConfigs = button.variants;
  const sizesConfigs = button.sizes;
  const sharedVariantConfig = button.shared;
  const buttonCSSVars = {};

  const getButtonSectionCSSVars = (name: string, sectionConfig: object) => {
    const config = {
      ...sharedVariantConfig,
      ...sectionConfig,
    };

    return generateCSSVars(config, name, (value: string) => {
      if (BORDER_RADIUS_KEYS.includes(value as BorderRadiusKey)) {
        return getCSSVar(`--border-radius-${value}`);
      }
      if (COLOR_KEYS.includes(value as ColorKey)) {
        return getColorCSSVar(value as ColorKey);
      }
      if (typeof value === 'number') {
        return `${value}px`;
      }
      return value;
    });
  };

  Object.entries(iconSizeConfigs).forEach(([size, sizeConfig]) => {
    Object.assign(
      buttonCSSVars,
      getButtonSectionCSSVars(`btn-icon-${size}`, sizeConfig)
    );
  });

  Object.entries(sizesConfigs).forEach(([size, sizeConfig]) => {
    Object.assign(
      buttonCSSVars,
      getButtonSectionCSSVars(`btn-${size}`, sizeConfig)
    );
  });

  Object.entries(variantConfigs).forEach(([variant, variantConfig]) => {
    Object.assign(
      buttonCSSVars,
      getButtonSectionCSSVars(`btn-${variant}`, variantConfig)
    );
  });

  return buttonCSSVars;
};

const generateCardCSSVars = (card: ReeferTheme['components']['Card']) => {
  return {
    '--card-border-radius': getCSSVar(
      `--border-radius-${card.styles.borderRadius}`
    ),
  };
};

const generateTypographyCSSVars = (
  typography: ReeferTheme['components']['Typography']
) => {
  let typographyCSSVars = {};
  Object.keys(typography.variants).forEach((variantName) => {
    const variant = typography.variants[variantName as TypographyVariant];
    const { desktopStyles, mobileStyles } = variant;
    const variantCSSVars = {
      [`--typography-${variantName}-font-size-desktop`]:
        desktopStyles?.fontSize ?? variant.fontSize,
      [`--typography-${variantName}-font-size-mobile`]:
        mobileStyles?.fontSize ?? variant.fontSize,
      [`--typography-${variantName}-font-weight-desktop`]:
        desktopStyles?.fontWeight ?? variant.fontWeight,
      [`--typography-${variantName}-font-weight-mobile`]:
        mobileStyles?.fontWeight ?? variant.fontWeight,
      [`--typography-${variantName}-letter-spacing-desktop`]:
        desktopStyles?.letterSpacing ?? variant.letterSpacing,
      [`--typography-${variantName}-letter-spacing-mobile`]:
        mobileStyles?.letterSpacing ?? variant.letterSpacing,
      [`--typography-${variantName}-line-height-desktop`]:
        desktopStyles?.lineHeight ?? variant.lineHeight,
      [`--typography-${variantName}-line-height-mobile`]:
        mobileStyles?.lineHeight ?? variant.lineHeight,
      [`--typography-${variantName}-text-transform-desktop`]:
        desktopStyles?.textTransform ?? variant.textTransform,
      [`--typography-${variantName}-text-transform-mobile`]:
        mobileStyles?.textTransform ?? variant.textTransform,
    };
    typographyCSSVars = {
      ...typographyCSSVars,
      ...variantCSSVars,
    };
  });
  return typographyCSSVars;
};

export function generateReeferThemeComponentsCSSVars(
  components: ReeferTheme['components']
) {
  return {
    ...generateButtonCSSVars(components.Button),
    ...generateCardCSSVars(components.Card),
    ...generateCSSVars(components.Icon, 'icon'),
    ...generateTypographyCSSVars(components.Typography),
  };
}
