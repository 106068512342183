import { AlertIcon, Box, Button, Flex, Typography } from '@jane/shared/reefer';

interface EmptyTableStateProps {
  buttonLabel: string;
  description: string;
  errorTitle: string;
  isError: boolean;
  onClick: (isError: boolean) => void;
}

export const EmptyTableState: React.FC<EmptyTableStateProps> = ({
  buttonLabel,
  description,
  errorTitle,
  isError,
  onClick,
}) => {
  return (
    <Box borderRadius="lg" p={128} m={24} border="grays-light">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        gap={24}
        p={72}
      >
        <AlertIcon size="xxl" />
        <Typography variant="header-bold" textAlign="center">
          {isError ? errorTitle : 'No results'}
        </Typography>
        {!isError && (
          <Typography variant="body" color="grays-dark" textAlign="center">
            {description}
          </Typography>
        )}
        <Button
          variant="secondary"
          label={isError ? 'Try again' : buttonLabel}
          onClick={() => onClick(isError)}
        />
      </Flex>
    </Box>
  );
};
