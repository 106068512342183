import styled from '@emotion/styled';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { brandsPaths } from '@jane/brands/util';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import { Flex, Modal } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { AdDetailsSection } from './Details/AdDetailsSection';
import { AdPricingSection } from './Pricing/AdPricingSection';
import { AdProductsSection } from './Products/AdProductsSection';
import { AdSchedulingSection } from './Scheduling/AdSchedulingSection';
import { SubmitButton } from './SubmitButton';
import { AdTargetingSection } from './Targeting/AdTargetingSection';
import type { AdSubmissionFormState } from './formState/types';
import type { UseAdBuilderFormReturn } from './useAdBuilderForm';

interface AdBuilderProps {
  adBuilderForm: UseAdBuilderFormReturn;
  onDismiss: () => void;
}

const AdBuilderFormWrapper = styled.div(() => ({
  maxWidth: 944,
  minWidth: 550,
  margin: '0 auto',
}));

export interface AdBuilderNavigationState {
  adBuilderPrefill?: AdSubmissionFormState | number;
}

export const useNavigateToAdBuilder = () => {
  const navigate = useNavigate();
  return (state: AdBuilderNavigationState = {}) => {
    navigate(brandsPaths.createFlight(), { state });
  };
};

export const useAdBuilderPrefill = (
  onPrefill: (state: number | Partial<AdSubmissionFormState>) => void
) => {
  const { state: locationState }: { state: AdBuilderNavigationState | null } =
    useLocation();

  // prefill ad builder if data exists in location state
  useEffect(() => {
    if (!locationState?.adBuilderPrefill) {
      return;
    }

    onPrefill(locationState.adBuilderPrefill);
  }, [onPrefill, locationState?.adBuilderPrefill]);
};

export const AdBuilder = ({ onDismiss, adBuilderForm }: AdBuilderProps) => {
  const { formMethods, onSubmit } = adBuilderForm;
  const {
    getValues,
    formState: { isSubmitted, isSubmitting, isValid, isDirty },
    handleSubmit,
  } = formMethods;

  const validateAdBuilder = () => {
    let isValid = true;

    const { isPriority, states, storeIds } = getValues();

    if (storeIds.length < 1 && states.length < 1 && !isPriority) {
      formMethods.setError('root.adTargeting', {
        type: 'adTargeting',
        message: 'At least one store or state target must be selected',
      });
      isValid = false;
    }

    if (isPriority && storeIds.length < 1) {
      formMethods.setError('root.priorityTargeting', {
        type: 'priorityTargeting',
        message: 'One store must be selected for a priority ad',
      });
      isValid = false;
    }

    return isValid;
  };

  const handleFormSubmit = handleSubmit(async () => {
    const isValid = validateAdBuilder();
    if (isValid) {
      await onSubmit();
    }
  });

  return (
    // always-open since header styles are tightly-coupled to ConfirmDiscardWrapper
    <ConfirmDiscardWrapper
      appId="root"
      open
      setOpen={() => onDismiss()}
      hasChanges={isDirty}
      variant="full-screen"
      contentLabel="create ad form"
    >
      <Form.BaseForm
        formMethods={formMethods}
        name="ad-builder"
        onSubmit={() => handleFormSubmit()}
        data-testid="ad-builder-form"
      >
        <Modal.Header
          title="Create ad"
          actions={
            <Flex gap={12}>
              <SubmitButton
                data-testid="submit-ad"
                disabled={isSubmitted && !isValid}
                variant="primary"
                label="Create"
                loading={isSubmitting}
              />
            </Flex>
          }
        />
        <Modal.Content>
          <AdBuilderFormWrapper>
            <Flex gap={24} flexDirection="column">
              <AdDetailsSection />
              <AdProductsSection />
              <AdTargetingSection />
              <AdPricingSection />
              <AdSchedulingSection />
            </Flex>
          </AdBuilderFormWrapper>
        </Modal.Content>
      </Form.BaseForm>
    </ConfirmDiscardWrapper>
  );
};
