import capitalize from 'lodash/capitalize';
import { useWatch } from 'react-hook-form';

import { AdTypeEnum } from '@jane/ad-manager/data-access';
import type { NonFlatAdType } from '@jane/ad-manager/data-access';
import {
  MIN_BUDGET,
  campaignTarget,
  formatCurrency,
  minBidForCampaignType,
  validationMessages,
  validators,
} from '@jane/ad-manager/util';
import { FLAGS } from '@jane/brands/util';
import { useFlag } from '@jane/shared/feature-flags';
import {
  Banner,
  Box,
  Flex,
  Grid,
  InfoIcon,
  Modal,
  Typography,
} from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

import { AdBuilderSectionContainer } from '../AdBuilderSectionContainer';
import { type AdSubmissionFormState, GoalType } from '../formState/types';
import { AdBidModifiersSection } from './BidModifiers';
import { BudgetGoalType } from './BudgetGoalType';
import { IsPriority } from './IsPriority';

const validateBudget = (budget?: number) => {
  if (!budget) {
    return validationMessages.requiredField;
  }

  if (budget < MIN_BUDGET) {
    return `Please enter a numeric value greater than or equal to ${formatCurrency(
      MIN_BUDGET
    )}`;
  }

  return true;
};

const Budget = () => {
  const goalType = useWatch<AdSubmissionFormState, 'goalType'>({
    name: 'goalType',
  });

  const fieldPrefixes = {
    [GoalType.Percentage]: 'daily ',
    [GoalType.MonthlyRevenue]: 'monthly ',
    [GoalType.Revenue]: '',
  };
  const fieldTitle = capitalize(`${fieldPrefixes[goalType]}budget`);
  const fieldCaption = `Cap the ${fieldPrefixes[goalType]}amount you want to spend on this ad.`;

  return (
    <>
      <Typography variant="body-bold" mb={12}>
        {fieldTitle}
      </Typography>
      <Form.NumberField
        allowedDecimalPlaces={0}
        step={1}
        label={fieldTitle}
        labelHidden
        mb={12}
        name="budget"
        startUnit="$"
        shouldUnregister={false}
        validate={validateBudget}
      />
      <Typography color="grays-mid">{fieldCaption}</Typography>
    </>
  );
};

const Bid = ({ adType }: { adType: NonFlatAdType }) => {
  const [productIds, storeIds] = useWatch<
    AdSubmissionFormState,
    ['productIds', 'storeIds']
  >({
    name: ['productIds', 'storeIds'],
  });

  const cTarget = campaignTarget({ productIds, storeIds });
  const minBid = minBidForCampaignType({ campaignTarget: cTarget, adType });
  const formattedMinimum = formatCurrency(minBid);

  const headerText =
    adType === AdTypeEnum.enum.cpm
      ? 'Cost per 1,000 impressions bid (CPM)'
      : 'Cost per click bid (CPC)';
  const footerText =
    adType === AdTypeEnum.enum.cpm
      ? `The price you pay for every 1,000 times a customer sees your ad. Minimum of ${formattedMinimum} required for a ${cTarget} ${adType.toUpperCase()} campaign.`
      : `The price you pay for every time a customer clicks your ad. Minimum of ${formattedMinimum} required for a ${cTarget} ${adType.toUpperCase()} campaign.`;
  return (
    <>
      <Typography variant="body-bold" mb={12}>
        {headerText}
      </Typography>
      <Form.NumberField
        allowedDecimalPlaces={2}
        data-testid="bid"
        label={`${adType.toUpperCase()} bid`}
        labelHidden
        mb={12}
        name="bid"
        startUnit="$"
        step={0.01}
        shouldUnregister={false}
        validate={(bid?: number) => {
          if (!bid) {
            return validationMessages.requiredField;
          }

          if (bid < minBid) {
            return `A ${cTarget}-targeted ${adType.toUpperCase()} campaign must have a bid of at least ${formatCurrency(
              minBid
            )}`;
          }

          return true;
        }}
      />
      <Typography color="grays-mid">{footerText}</Typography>
    </>
  );
};

const PriorityCpmBudget = () => {
  const formattedMinimumBudget = formatCurrency(MIN_BUDGET);

  return (
    <Box>
      <Typography variant="body-bold" mb={12}>
        Budget
      </Typography>
      <Form.NumberField
        allowedDecimalPlaces={0}
        step={1}
        data-testid="budget-cpm"
        label="Cpm budget"
        labelHidden
        mb={12}
        name="budget"
        startUnit="$"
        shouldUnregister={false}
        validate={validateBudget}
      />
      <Typography color="grays-mid">
        {`Define a total budget for prioritized placement. Minimum ${formattedMinimumBudget} required.`}
      </Typography>
    </Box>
  );
};

const PricingInfoBanner = () => (
  <Banner
    icon={<InfoIcon />}
    typography="body"
    variant="info"
    label="Based on your current ad configuration, we’ve recommended a daily budget and cost per impression bid for you. Feel free to make adjustments and see how performance and returns are impacted."
    full
  />
);

export const AdPricingSection = () => {
  const [adType, isPriority] = useWatch<
    AdSubmissionFormState,
    ['adType', 'isPriority']
  >({
    name: ['adType', 'isPriority'],
  });

  const enableMonthlyRevenueGoalType = useFlag(FLAGS.jamMonthlyRevenueGoalType);

  const isCpc = adType === AdTypeEnum.enum.cpc;
  const isCpm = adType === AdTypeEnum.enum.cpm;
  const isCpmOrCpc =
    adType === AdTypeEnum.enum.cpm || adType === AdTypeEnum.enum.cpc;
  const enableBidControl = useFlag(FLAGS.jamBidControl, false);

  const isGoalTypeDisplayed = enableMonthlyRevenueGoalType && !isPriority;

  return (
    <AdBuilderSectionContainer>
      <Flex flexDirection="column">
        <Typography variant="header-bold" branded mb={24}>
          Pricing
        </Typography>
        <Flex flexDirection="column" gap={24} data-testid="pricing-section">
          {isCpm && <IsPriority />}
          {isGoalTypeDisplayed && <BudgetGoalType />}
          {!isPriority && isCpmOrCpc && (
            <Grid.Container>
              <Grid.Item xs={6}>
                <Budget />
              </Grid.Item>
              <Grid.Item xs={6}>
                <Bid adType={adType} />
              </Grid.Item>
            </Grid.Container>
          )}
          {isPriority && isCpm && <PriorityCpmBudget />}
          {!isPriority && <PricingInfoBanner />}
          <Modal.ContentDivider color="grays-light" />
          <Form.NumberField
            allowedDecimalPlaces={2}
            data-testid="credit-percent"
            label="Flight credit percentage"
            name="creditPercent"
            endUnit="%"
            step={0.01}
            shouldUnregister={false}
            validate={(creditPercent: number) => {
              if (!validators.isValidPercent(creditPercent)) {
                return validationMessages.invalidPercent;
              }
              return true;
            }}
          />
          {enableBidControl && isCpc && <AdBidModifiersSection />}
        </Flex>
      </Flex>
    </AdBuilderSectionContainer>
  );
};
