import { useQuery } from '@tanstack/react-query';

import { STALE_TIMES, config } from '@jane/shared/config';
import type { GoldConfig } from '@jane/shared/types';
import { encodeQuery, trackError } from '@jane/shared/util';

import { api } from '../api/api';

export const fetchGoldConfig = async (): Promise<GoldConfig | void> => {
  const url = encodeQuery(`${config.apiPath}/gold_configs`);

  try {
    const res: GoldConfig[] = await api.get(url);
    /** There will only ever be one Gold Config in the DB.
     * Grab the first and ignore the rest. */
    return res[0] ?? null;
  } catch (error) {
    trackError(error);
  }
};

export const useGoldConfig = ({ enabled = true } = {}) =>
  useQuery({
    enabled,
    queryFn: () => fetchGoldConfig(),
    queryKey: ['goldConfig'],
    staleTime: STALE_TIMES.long,
  });
