import { useController } from 'react-hook-form';

import { Button, DismissIcon, Flex } from '@jane/shared/reefer';

import type { AdSubmissionFormState } from '../../formState/types';
import type { ProductCategory } from '../categories';

export const SelectedCategories = () => {
  const {
    field: { onChange: onChangeSelectedCategories, value: selectedCategories },
  } = useController<AdSubmissionFormState, 'productCategories'>({
    name: 'productCategories',
  });
  const categoryButtons = selectedCategories.map(
    (category: ProductCategory) => {
      const { label, value } = category;

      return (
        <Button
          key={label}
          label={label}
          startIcon={<DismissIcon altText={`Clear ${label}`} />}
          onClick={() => {
            const updatedCategories = selectedCategories.filter(
              ({ value: cV }) => cV !== value
            );
            onChangeSelectedCategories(updatedCategories);
          }}
          variant="secondary"
          mr={16}
          mb={16}
        />
      );
    }
  );

  return selectedCategories.length > 0 ? (
    <Flex alignItems="center" flexWrap="wrap">
      {categoryButtons}
    </Flex>
  ) : null;
};
