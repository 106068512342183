import { useFormContext } from 'react-hook-form';

import { Button } from '@jane/shared/reefer';
import type { ButtonProps } from '@jane/shared/reefer';

export type FormSubmitButtonProps = Omit<
  ButtonProps,
  'href' | 'onClick' | 'ref' | 'to' | 'type'
>;

/**
 * Modified from Form.SubmitButton in reefer.
 *
 */
export const SubmitButton = ({ ...props }: FormSubmitButtonProps) => {
  const {
    formState: { isSubmitting },
  } = useFormContext();

  return (
    <Button
      disabled={isSubmitting}
      loading={isSubmitting || props.loading}
      type="submit"
      {...props}
    />
  );
};
