import type * as ReactTable from '@tanstack/react-table';
import React from 'react';

import type { Product } from '@jane/ad-manager/data-access';
import { Flex, Typography } from '@jane/shared/reefer';

import { ProductTable } from './ProductTable';
import type { ProductSearchMode } from './ProductTableModal';
import { StyledLoading } from './StyledLoading';

const TableLoading = () => (
  <Flex
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height={'100%'}
  >
    <StyledLoading size="sm" data-testid="product-table-loading" />
  </Flex>
);

const NoProductsFound = ({ searchMode }: { searchMode: ProductSearchMode }) => (
  <Flex
    data-testid="no-products-found"
    width="100%"
    height="100%"
    border="grays-light"
    borderRadius="sm"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
  >
    <Typography variant="header-bold">No Products available</Typography>
    <Typography>
      As you search for
      {searchMode === 'productId' ? ' IDs' : ' product names'}, products will be
      displayed here.
    </Typography>
  </Flex>
);

interface TableContentProps {
  handleFetchMore: () => void;
  hasNextPage?: boolean;
  isFetching?: boolean;
  isFetchingNextPage?: boolean;
  isNotFoundState: boolean;
  searchMode: ProductSearchMode;
  table: ReactTable.Table<Product>;
}

export const TableContent = ({
  isFetchingNextPage,
  isNotFoundState,
  searchMode,
  isFetching,
  handleFetchMore,
  table,
  hasNextPage,
}: TableContentProps) => {
  if (isNotFoundState) {
    return <NoProductsFound searchMode={searchMode} />;
  }
  if (isFetching && !isFetchingNextPage) {
    return <TableLoading />;
  }
  return (
    <ProductTable
      fetchMore={handleFetchMore}
      table={table}
      hasNextPage={hasNextPage}
    />
  );
};
