import { z } from 'zod';

import { zKindCondition, zPriceId } from '@jane/shared/types';

const zBrandSpecialRules = z.object({
  excludes: z
    .array(
      z.union([
        z.object({ kinds: z.array(zKindCondition) }),
        z.object({ lineages: z.array(z.string()) }),
        z.object({ product_ids: z.array(z.number()) }),
        z.object({ states: z.array(z.string()) }),
        z.object({ store_ids: z.array(z.number()) }),
        z.object({ user_segment_ids: z.array(z.number()) }),
        z.object({ weights: z.array(zPriceId) }),
      ])
    )
    .optional(),
  includes: z
    .array(
      z.object({
        kinds: z.array(zKindCondition).optional(),
        lineages: z.array(z.string()).optional(),
        product_ids: z.array(z.number()).nullable().optional(),
        states: z.array(z.string()).nullable().optional(),
        store_ids: z.array(z.number()).nullable().optional(),
        user_segment_ids: z.array(z.number()).nullable().optional(),
        weights: z.array(zPriceId).optional(),
      })
    )
    .optional(),
});

const zBrandSpecialSchedule = z.object({
  enabled_sunday: z.boolean(),
  start_time_sunday: z.string().nullable(),
  end_time_sunday: z.string().nullable(),
  enabled_monday: z.boolean(),
  start_time_monday: z.string().nullable(),
  end_time_monday: z.string().nullable(),
  enabled_tuesday: z.boolean(),
  start_time_tuesday: z.string().nullable(),
  end_time_tuesday: z.string().nullable(),
  enabled_wednesday: z.boolean(),
  start_time_wednesday: z.string().nullable(),
  end_time_wednesday: z.string().nullable(),
  enabled_thursday: z.boolean(),
  start_time_thursday: z.string().nullable(),
  end_time_thursday: z.string().nullable(),
  enabled_friday: z.boolean(),
  start_time_friday: z.string().nullable(),
  end_time_friday: z.string().nullable(),
  enabled_saturday: z.boolean(),
  start_time_saturday: z.string().nullable(),
  end_time_saturday: z.string().nullable(),
});

export const BrandSpecialSchema = z.object({
  active: z.boolean(),
  aeropay_location_id: z.string().nullable().optional(),
  alert_emails: z.array(z.string()).nullable(),
  available_budget: z.string(),
  budget_total: z.string(),
  campaign_type: z.string(),
  created_at: z.string(),
  description: z.string().nullable(),
  discount_dollar_amount: z.number(),
  discount_label: z.string(),
  discount_percent: z.number(),
  discount_target_price: z.number(),
  discount_type: z.string(),
  enabled: z.boolean(),
  end_time: z.string().nullable(),
  excluded_app_modes: z.array(z.string()).nullable(),
  id: z.number(),
  management_fee_percentage: z.number(),
  minimum_available_budget: z.string(),
  photo: z
    .object({
      id: z.number(),
      position: z.number().nullable(),
      urls: z.object({
        extraLarge: z.string().nullable(),
        medium: z.string().nullable(),
        original: z.string().nullable(),
        small: z.string().nullable(),
      }),
    })
    .nullable(),
  schedule: zBrandSpecialSchedule.nullable().optional(),
  product_brand: z.object({
    id: z.number(),
    logo_url: z.string().nullable(),
    name: z.string(),
  }),
  product_brand_id: z.number(),
  rules: zBrandSpecialRules,
  service_fee_rate: z.string(),
  special_type: z.string(),
  start_time: z.string().nullable(),
  terms: z.string().nullable(),
  title: z.string(),
  updated_at: z.string(),
});

export const BrandSpecialsResponseSchema = z.object({
  brand_specials: z.array(BrandSpecialSchema),
});

export type BrandSpecial = z.infer<typeof BrandSpecialSchema>;
export type BrandSpecialRules = z.infer<typeof zBrandSpecialRules>;
export type BrandSpecialSchedule = z.infer<typeof zBrandSpecialSchedule>;

export interface FetchBrandSpecialParams {
  id: number;
}

export interface FetchBrandSpecialsParams {
  brandIds?: string[];
  dateRange?: string[];
  discountType?: string[];
  limit?: number;
  offset?: number;
  remainingBudgetRange?: string[];
  status?: string[];
}
