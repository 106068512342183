import type { CalloutVariants } from '@jane/shared-ecomm/types';

import { JaneGoldLearnMoreLink } from '../janeGoldLearnMoreLink/janeGoldLearnMoreLink';

export interface JaneGoldCalloutDescriptionProps {
  isGuestUser: boolean;
  userHasLinkedBank: boolean;
  variant: CalloutVariants;
}

export const JaneGoldCalloutDescription = ({
  variant,
  isGuestUser,
  userHasLinkedBank,
}: JaneGoldCalloutDescriptionProps) => {
  return isGuestUser && variant !== 'checkout' ? (
    <>
      <JaneGoldLearnMoreLink userHasLinkedBank={false} label="Jane Gold" /> is a
      way to earn exclusive cash back rewards from your favorite cannabis
      brands.
    </>
  ) : !userHasLinkedBank ? (
    <>
      Link your bank account and join{' '}
      <JaneGoldLearnMoreLink userHasLinkedBank={false} label="Jane Gold" /> to
      earn exclusive cash back rewards from this
      {variant === 'pdp' ? ' brand' : ' order'}.
    </>
  ) : variant === 'cart' || variant === 'checkout' ? (
    <>
      Your brand-sponsored cash back reward will be deposited to your bank
      account in 1-3 days.
    </>
  ) : (
    <></>
  );
};
