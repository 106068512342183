import type { MutateOptions } from '@tanstack/react-query';
import type { UseFormReturn } from 'react-hook-form';
import { useForm } from 'react-hook-form';

import type { AdSubmission, JamApiAd } from '@jane/ad-manager/data-access';
import { JamApiAdSchema, useSubmitAd } from '@jane/ad-manager/data-access';
import { useSelectedBrandContext } from '@jane/ad-manager/providers';
import type { JamError } from '@jane/ad-manager/util';
import { handleJamError } from '@jane/ad-manager/util';
import { parseData } from '@jane/shared/util';

import { buildJamApiPost } from './formState/buildJamApiPost';
import { defaultAdSubmissionFormData } from './formState/defaults';
import { GoalType, SpendAllocationOption } from './formState/types';
import type { AdSubmissionFormState } from './formState/types';

export type UseAdBuilderFormReturn = {
  formMethods: UseFormReturn<AdSubmissionFormState>;
  onSubmit: () => Promise<void>;
  resetWithDefaults: () => void;
};

interface UseAdBuilderForm {
  ({
    onSuccess,
    onError,
  }: {
    onError?: (submitError: Error) => void;
    onSuccess?: MutateOptions<AdSubmission, Error, JamApiAd>['onSuccess'];
  }): UseAdBuilderFormReturn;
}

export const useAdBuilderForm: UseAdBuilderForm = ({ onSuccess, onError }) => {
  const { selectedBrand } = useSelectedBrandContext();

  const formMethods = useForm<AdSubmissionFormState>({
    defaultValues: defaultAdSubmissionFormData,
    mode: 'onSubmit',
  });

  const { mutate: submitAd } = useSubmitAd(selectedBrand?.id);
  const { reset, handleSubmit } = formMethods;
  const resetWithDefaults = () => reset(defaultAdSubmissionFormData);
  const onSubmit = (formData: AdSubmissionFormState) => {
    const adSubmission = buildJamApiPost(formData);

    let validatedSubmission: JamApiAd;

    // react-use-form swallows this zod error otherwise
    try {
      validatedSubmission = parseData(JamApiAdSchema, adSubmission);
    } catch (e) {
      handleJamError(e as JamError).then();
      throw e;
    }

    return new Promise((resolve, reject) => {
      submitAd(validatedSubmission, {
        onSuccess: (data, variables, context) => {
          resetWithDefaults();
          if (onSuccess) onSuccess(data, variables, context);
          resolve(data);
        },
        onError: (submitError: Error) => {
          handleJamError(submitError).then();
          if (onError) onError(submitError);
          reject(submitError);
        },
      });
    });
  };
  const onHandleSubmit = handleSubmit(onSubmit);
  return { formMethods, onSubmit: onHandleSubmit, resetWithDefaults };
};

export { GoalType, SpendAllocationOption };
