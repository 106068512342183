import clsx from 'clsx';
import { default as RCDrawer } from 'rc-drawer';
import { useEffect, useState } from 'react';
import type { CSSProperties } from 'react';

import { getColorCSSVar } from '../../utils/colors';
import { Button } from '../button/button';
import { Flex } from '../flex/flex';
import { Modal } from '../modal/modal';
import { Typography } from '../typography/typography';
import { DrawerContext } from './drawer.context';
import styles from './drawer.module.css';
import type { DrawerProps } from './drawer.types';
import { DrawerContent } from './drawerContent/drawerContent';
import { DrawerContentDivider } from './drawerContent/drawerContentDivider';
import { DrawerFooter } from './drawerFooter/drawerFooter';
import { DrawerHeader } from './drawerHeader/drawerHeader';
import './rc-drawer.css';

const FULL_HEIGHT = '100vh';
const FULL_WIDTH = '100vw';

/**
 * `Drawer` component is a modal that slides from the side of the screen
 * with available headers and footers, or full-screen drawers.
 */
const BaseDrawer = ({
  allowScroll = false,
  background = 'grays-white',
  children,
  className,
  closeConfirmation = false,
  container = 'body',
  'data-testid': testId,
  fitScreen = true,
  full = false,
  height = 'fit-content',
  hideOverlay = false,
  id,
  open,
  onRequestClose,
  overlayClose = true,
  placement = 'right',
  style,
  width = 'fit-content',
}: DrawerProps) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [closing, setClosing] = useState(false);
  let timeout: ReturnType<typeof setTimeout>;

  const handleClose = () => {
    setShowConfirmation(false);
    setClosing(true);
    if (!allowScroll) {
      document.body.style.overflow = 'unset';
    }
    timeout = setTimeout(() => {
      onRequestClose();
      setClosing(false);
    }, 475);
  };

  const onClose = () => {
    if (closeConfirmation) {
      setShowConfirmation(true);
    } else {
      handleClose();
    }
  };

  useEffect(() => {
    if (open && !allowScroll) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      setClosing(false);
      clearTimeout(timeout);
      if (!allowScroll) {
        document.body.style.overflow = 'unset';
      }
    };
  }, [open]);

  const horizontal = placement === 'left' || placement === 'right';

  const getHeight = () => {
    if (fitScreen) {
      return (full && !horizontal) || horizontal ? FULL_HEIGHT : height;
    } else {
      return undefined;
    }
  };

  const contentWrapperStyle: CSSProperties = {
    animation: `${
      closing
        ? `reefer-drawer-slide-out-${placement}`
        : `reefer-drawer-slide-in-${placement}`
    } 500ms cubic-bezier(0.16, 1, 0.3, 1)`,
    ...(placement === 'bottom' && { bottom: 0 }),
    zIndex: 'var(--layers-drawer)',
  };

  return (
    <DrawerContext.Provider value={{ onRequestClose: onClose, fitScreen }}>
      <RCDrawer
        data-testid={testId}
        id={id}
        rootClassName={clsx(className, styles.drawer)}
        className={clsx(styles['rc-drawer-content'])}
        contentWrapperStyle={contentWrapperStyle}
        destroyOnClose
        getContainer={container}
        height={getHeight()}
        mask={!hideOverlay}
        maskClassName={clsx({
          [styles['rc-drawer-mask-in']]: !closing,
          [styles['rc-drawer-mask-out']]: closing,
        })}
        maskClosable={overlayClose}
        onClose={onClose}
        open={open}
        placement={placement}
        style={
          {
            '--background-color': getColorCSSVar(background),
            ...style,
          } as CSSProperties
        }
        width={(full && horizontal) || !horizontal ? FULL_WIDTH : width}
      >
        {children}
      </RCDrawer>

      {!!closeConfirmation && (
        <Modal
          open={showConfirmation}
          onRequestClose={handleClose}
          variant="dialogue"
        >
          <Modal.Content>
            <Typography textAlign="center" variant="header-bold">
              Are you sure?
            </Typography>

            <Typography>{closeConfirmation}</Typography>

            <Flex gap={16} mt={24}>
              <Button
                label="Cancel"
                full
                variant="secondary"
                onClick={() => setShowConfirmation(false)}
              />

              <Button label="Confirm" full onClick={handleClose} />
            </Flex>
          </Modal.Content>
        </Modal>
      )}
    </DrawerContext.Provider>
  );
};

export const Drawer = Object.assign(BaseDrawer, {
  Header: DrawerHeader,
  Content: DrawerContent,
  ContentDivider: DrawerContentDivider,
  Footer: DrawerFooter,
});
