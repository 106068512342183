import { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

import {
  useGetFlightDetail,
  useUpdateFlight,
} from '@jane/ad-manager/data-access';
import { useFilterParams } from '@jane/ad-manager/hooks';
import {
  endOfDayInPacificTimeWithUTCTimezone,
  formatCurrency,
  formatISODate,
  formatPacificTimeToUtc,
  formatPacificTimeToUtcEod,
} from '@jane/ad-manager/util';
import { FLAGS, brandsPaths } from '@jane/brands/util';
import { useFlag } from '@jane/shared/feature-flags';
import {
  Box,
  Breadcrumbs,
  Button,
  Flex,
  Loading,
  Tag,
  Typography,
  useToast,
} from '@jane/shared/reefer';

import { useNavigateToAdBuilder } from './AdBuilder/AdBuilder';
import { DateRangeFilter } from './Filters/DateRange';
import { FlightSubmissionDetails } from './FlightSubmissionDetails';
import { StatisticsDisplay } from './StatisticsDisplay';

export const FlightDetail = () => {
  const { id } = useParams();
  const toast = useToast();
  const flightId = Number(id) || undefined;

  const { filterParams, onChangeFilterParams } = useFilterParams();
  const {
    data: flight,
    isFetching: isGetFlightDetailsFetching,
    refetch: fetchFlight,
  } = useGetFlightDetail({
    params: flightId
      ? {
          flightId,
          startDate:
            filterParams.startDatePT &&
            formatPacificTimeToUtc(filterParams.startDatePT),
          endDate:
            filterParams.endDatePT &&
            formatPacificTimeToUtcEod(filterParams.endDatePT),
        }
      : undefined,
    queryOptions: {
      keepPreviousData: true,
    },
  });

  const {
    isLoading: isActiveToggleLoading,
    mutate: mutateFlight,
    error: updateFlightError,
    isError: isUpdateFlightError,
  } = useUpdateFlight({
    onSuccess: () => {
      fetchFlight();
    },
  });

  useEffect(() => {
    if (isUpdateFlightError) {
      toast.add({
        label: `Failed to update flight: ${updateFlightError.message}`,
        variant: 'error',
      });
    }
  });

  const handleClickActiveToggle = () => {
    if (flightId === undefined) {
      return;
    }
    const endDate = endOfDayInPacificTimeWithUTCTimezone();
    mutateFlight({
      id: flightId,
      isActive: !flight?.active,
      endDate: endDate,
      isReadOnly: !!flight?.active,
    });
  };

  const navigateToAdBuilder = useNavigateToAdBuilder();

  const onDuplicate = () => {
    if (!flight) return;

    navigateToAdBuilder({ adBuilderPrefill: flight.id });
  };

  const brandFlightsStatistics = [
    {
      label: 'ROAS',
      valueDisplay: formatCurrency(flight ? flight.roas : 0),
    },
    {
      label: 'Clicks',
      valueDisplay: flight ? flight.clicks : 0,
    },
    {
      label: 'Customers impressed',
      valueDisplay: flight ? flight.customersImpressed : 0,
    },
    {
      label: 'Total billable',
      valueDisplay: formatCurrency(flight ? flight.totalBillable : 0),
    },
    {
      label: 'Unique stores',
      valueDisplay: flight ? flight.uniqueStores : 0,
    },
  ];

  const flightDateRange = flight
    ? `${formatISODate(flight.startDate)} – ${formatISODate(flight.endDate)}`
    : undefined;

  const enableMonthlyRevenueGoalType = useFlag(FLAGS.jamMonthlyRevenueGoalType);
  const duplicableGoalTypes = ['percentage', 'revenue'];
  if (enableMonthlyRevenueGoalType) {
    duplicableGoalTypes.push('monthly_revenue');
  }
  const isDuplicationDisabled =
    !flight || !duplicableGoalTypes.includes(flight.goalType);

  return (
    <Flex flexDirection="column" gap={24} mx={40} py={40}>
      <Breadcrumbs>
        <Link to={brandsPaths.digitalMerchandising()}>All flights</Link>
        <Typography>Flight {flightId}</Typography>
      </Breadcrumbs>
      <Flex mt={40}>
        <Box>
          <Flex alignItems="center">
            <Typography variant="header-bold" as="h1">
              Flight {flightId}
            </Typography>
            {flight && (
              <Box ml={8}>
                {flight.active && (
                  <Tag background="palm-light" color="palm" label="Active" />
                )}
                {!flight.active && (
                  <Tag
                    background="grays-light"
                    color="grays-dark"
                    label="Inactive"
                  />
                )}
              </Box>
            )}
          </Flex>
          <Typography data-testid="header-flight-dates" color="text-light">
            {flightDateRange}
          </Typography>
          {flight && (
            <Typography color="text-light">
              Last updated by {flight?.editedBy?.email} on{' '}
              {flight ? formatISODate(flight.updatedAt) : ''}
            </Typography>
          )}
        </Box>
        <Flex ml="auto">
          <Button
            onClick={() => handleClickActiveToggle()}
            loading={isActiveToggleLoading}
            variant="secondary"
            label={flight && flight.active ? 'Deactivate' : 'Activate'}
            disabled={!flight || flight.readOnly}
          />
          <Button
            variant="secondary"
            label="Duplicate"
            onClick={() => onDuplicate()}
            disabled={isDuplicationDisabled}
            ml={16}
          />
        </Flex>
      </Flex>
      <DateRangeFilter
        onChangeFilterParams={onChangeFilterParams}
        filterParams={filterParams}
      />
      <StatisticsDisplay
        entries={brandFlightsStatistics}
        loading={isGetFlightDetailsFetching}
      />
      {!flight && (
        <Box px={40} py={40} position="relative">
          <Loading data-testid="flight-details-loader" />
        </Box>
      )}
      {flight && <FlightSubmissionDetails flight={flight} />}
    </Flex>
  );
};
