import styled from '@emotion/styled';

import { Loading } from '@jane/shared/reefer';

export const StyledLoading = styled(Loading)({
  position: 'inherit',
  top: 'inherit',
  left: 'inherit',
  margin: 'auto',
  transform: 'inherit',
});
