import styled from '@emotion/styled';

import { Flex, Modal, SliderField } from '@jane/shared/reefer';
import { mediaQueries, spacing } from '@jane/shared/reefer-emotion';

export const StyledModal = styled(Modal)({});

export const AddressInputWrapper = styled(Flex)(({ theme }) => ({
  color: theme.colors.grays.mid,
  minWidth: '380px',
  [mediaQueries.tablet('max')]: {
    ...spacing({ pt: 24 }),
    minWidth: 'auto',
  },
}));

export const StyledSlider = styled(SliderField)({
  input: {
    padding: 0,
  },
  p: {
    display: 'none',
  },
});

export const PreferencesWrapper = styled(Flex)(({ theme }) => ({
  display: 'inline-flex',
  button: {
    background: theme.colors.brand.purple.dark,
  },
}));
