import { useEffect, useState } from 'react';
import type { SetValueConfig } from 'react-hook-form';

import type {
  BrandMediaTag,
  CustomImage,
  CustomMedia,
  CustomVideo,
  MediaTagOption,
} from '@jane/ad-manager/types';
import { NEW_IMAGE } from '@jane/ad-manager/types';
import {
  getFormValuesForAllMedia,
  getFormValuesForImage,
  getFormValuesForVideo,
  makeMediaKey,
  useTempAssetId,
} from '@jane/ad-manager/util';
import { useFormContext } from '@jane/shared/reefer-hook-form';

import { useMediaFormUtil } from './useMediaFormUtil';

export const useMediaForm = (customMedia: CustomMedia[]) => {
  const { setValue } = useFormContext();
  const [selectedMediaKey, setSelectedMediaKey] = useState<string>(NEW_IMAGE);
  const getTempAssetId = useTempAssetId();
  const {
    getDestroy,
    getPosition,
    setDestroy,
    setOrderedMediaKeys,
    getShownMediaKeys,
    setPosition,
    setMediaTags,
  } = useMediaFormUtil();

  /* If you need to set all media keys, you should likely call updateAllMediaKeys instead of _setAllMediaKeys */
  const [allMediaKeys, _setAllMediaKeys] = useState<string[]>([]);
  const updateAllMediaKeys = (
    allMediaKeys: string[],
    options?: SetValueConfig
  ) => {
    _setAllMediaKeys(allMediaKeys);
    setOrderedMediaKeys(allMediaKeys, options);
  };
  let shownMediaKeys = getShownMediaKeys(allMediaKeys);

  useEffect(() => {
    const initialValues = getFormValuesForAllMedia(customMedia);
    const initialAllMediaKeys = getAllMediaKeys(customMedia);
    updateAllMediaKeys(initialAllMediaKeys, {
      shouldDirty: false,
      shouldTouch: false,
    });
    if (initialAllMediaKeys.length > 0)
      setSelectedMediaKey(initialAllMediaKeys[0]);
    Object.entries(initialValues).forEach(([key, value]) =>
      setValue(key, value)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addCustomVideo = (file: string) => {
    const position = getShownMediaKeys(allMediaKeys).length;
    const newVideo: CustomVideo = {
      id: getTempAssetId(),
      brandMediaTags: [],
      videoUrl: '',
      overlayImageUrl: file,
      position,
      _mediaType: 'video',
    };
    const mediaKey = makeMediaKey(newVideo);
    setSelectedMediaKey(mediaKey);
    updateAllMediaKeys([...allMediaKeys, mediaKey]);
    const videoValues = getFormValuesForVideo(newVideo);
    Object.entries(videoValues).forEach(([key, value]) => setValue(key, value));
  };

  const addCustomImage = (file: string) => {
    const position = getShownMediaKeys(allMediaKeys).length;
    const newImage: CustomImage = {
      id: getTempAssetId(),
      brandMediaTags: [],
      imageUrl: file,
      position,
      _mediaType: 'image',
    };
    const mediaKey = makeMediaKey(newImage);
    setSelectedMediaKey(mediaKey);
    updateAllMediaKeys([...allMediaKeys, mediaKey]);
    const imageValues = getFormValuesForImage(newImage);
    Object.entries(imageValues).forEach(([key, value]) => setValue(key, value));
  };

  const deleteMedia = (mediaKey: string) => {
    setDestroy(mediaKey, true);
    updateMediaPositions(allMediaKeys);

    const index = allMediaKeys.indexOf(mediaKey);

    let newSelectedMediaKeyIndex;
    if (index > shownMediaKeys.length) {
      newSelectedMediaKeyIndex = shownMediaKeys.length - 1; // deleting the last image
    } else if (index) {
      newSelectedMediaKeyIndex = index - 1; // deleting an image in between
    } else {
      newSelectedMediaKeyIndex = 0; // deleting the first image
    }

    const newSelectedMediaKey =
      shownMediaKeys[newSelectedMediaKeyIndex] || NEW_IMAGE;
    setSelectedMediaKey(newSelectedMediaKey);
  };

  const updateMediaPositions = (allMediaKeys: string[]) => {
    shownMediaKeys = getShownMediaKeys(allMediaKeys);
    const hiddenMediaKeys: string[] = allMediaKeys.filter((mediaKey) =>
      getDestroy(mediaKey)
    );

    const orderedShownMediaKeys = [...shownMediaKeys].sort(
      ([a, b]) => getPosition(a) - getPosition(b)
    );
    const allOrderedMediaKeys = [...orderedShownMediaKeys, ...hiddenMediaKeys];

    updateAllMediaKeys(allOrderedMediaKeys);
    allOrderedMediaKeys.forEach((mediaKey, index) =>
      setPosition(mediaKey, index)
    );
  };

  const updateMediaTags = (mediaKey: string, tags: MediaTagOption[]) => {
    const updatedTags: BrandMediaTag[] = [];

    tags.forEach((tag) => {
      if (tag.selected) {
        updatedTags.push({ id: tag.id, tag: tag.tag });
      }
    });
    setMediaTags(mediaKey, updatedTags);
  };

  return {
    addCustomImage,
    addCustomVideo,
    allMediaKeys,
    deleteMedia,
    selectedMediaKey,
    shownMediaKeys,
    setSelectedMediaKey,
    updateMediaPositions,
    updateMediaTags,
  };
};

const getAllMediaKeys = (customMedia: CustomMedia[]) => {
  return customMedia.map((media) => makeMediaKey(media));
};
