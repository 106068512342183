import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';

import { brandsPaths } from '@jane/brands/util';
import type { BrandSpecial } from '@jane/gold-manager/data-access';
import { getSpecialStatus } from '@jane/gold-manager/util';
import { CheckboxField, Flex, Link } from '@jane/shared/reefer';
import { formatCurrency, sentenceCase } from '@jane/shared/util';

import { SortableHeader } from './sortableHeader';
import type { SpecialsTableRowActions } from './specialsTable.types';
import {
  StatusTag,
  bodyContent,
  brandContent,
  getScheduledTime,
  headerContent,
} from './specialsTableContent';
import {
  ActionsPopover,
  AlertPopover,
  StatesPopover,
} from './specialsTablePopovers';

export const useSpecialsTableColumns = ({
  bulkEdit,
  onSetEnabled,
}: SpecialsTableRowActions) => {
  const columnHelper = createColumnHelper<BrandSpecial>();

  return useMemo(
    () => [
      /** Selection & Actions */
      bulkEdit
        ? columnHelper.display({
            id: 'select',
            header: ({ table }) => {
              const isChecked = table.getIsAllRowsSelected();
              return (
                <CheckboxField
                  checked={isChecked}
                  indeterminate={table.getIsSomeRowsSelected()}
                  onChange={() => {
                    if (isChecked === false) {
                      table.toggleAllRowsSelected(true);
                    } else {
                      table.toggleAllRowsSelected(false);
                    }
                  }}
                  label="Select all specials"
                  name="Select all specials"
                  labelHidden
                />
              );
            },
            cell: ({ row }) => (
              <CheckboxField
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                onChange={row.getToggleSelectedHandler()}
                label={`Select special ${row.original.id}`}
                name={`Select special ${row.original.id}`}
                labelHidden
                ml={12}
              />
            ),
          })
        : columnHelper.display({
            id: 'actions',
            cell: ({ row }) => {
              return <ActionsPopover row={row} onSetEnabled={onSetEnabled} />;
            },
          }),

      /* Brand name and logo */
      columnHelper.accessor('product_brand', {
        id: 'brand',
        header: () => headerContent('Brand'),
        cell: ({ row }) =>
          brandContent({
            brand: {
              id: row.original.product_brand.id.toString(),
              name: row.original.product_brand.name,
              logoUrl: row.original.product_brand.logo_url,
            },
          }),
      }),

      /* Special name */
      columnHelper.accessor('title', {
        id: 'title',
        header: () => headerContent('Special name'),
        cell: ({ row }) => (
          <Flex alignItems="center" gap={16}>
            {!row.original.aeropay_location_id && <AlertPopover row={row} />}
            {bodyContent(row.original.title)}{' '}
          </Flex>
        ),
      }),

      /* Status */
      columnHelper.accessor(
        (row) =>
          getSpecialStatus({
            enabled: row.enabled,
            endTime: row.end_time,
            startTime: row.start_time,
          }),
        {
          id: 'status',
          header: ({ column }) => (
            <SortableHeader label="Status" column={column} />
          ),
          cell: ({ row }) => <StatusTag row={row} />,
        }
      ),

      /* Special ID */
      columnHelper.accessor(({ id }) => id.toString(), {
        id: 'id',
        header: () => headerContent('Special ID'),
        cell: ({ getValue }) => (
          <Link to={brandsPaths.editSpecial(getValue())}>{getValue()}</Link>
        ),
      }),

      /* Start time */
      columnHelper.accessor('start_time', {
        id: 'start_time',
        header: ({ column }) => (
          <SortableHeader label="Start time" column={column} />
        ),
        cell: ({ getValue }) => bodyContent(getScheduledTime(getValue())),
      }),

      /* End time */
      columnHelper.accessor('end_time', {
        id: 'end_time',
        header: ({ column }) => (
          <SortableHeader label="End time" column={column} />
        ),
        cell: ({ getValue }) => bodyContent(getScheduledTime(getValue())),
      }),

      /* Discount */
      columnHelper.accessor('discount_label', {
        id: 'discount_label',
        header: () => headerContent('Discount'),
        cell: ({ getValue }) => bodyContent(sentenceCase(getValue())),
      }),

      /* Budget available + total */
      columnHelper.accessor('available_budget', {
        id: 'available_budget',
        header: () => headerContent('Budget available/total'),
        cell: ({ row }) =>
          bodyContent(
            `${formatCurrency(row.original.available_budget)}/${formatCurrency(
              row.original.budget_total
            )}`
          ),
      }),

      /* Targeted states */
      columnHelper.display({
        id: 'states',
        header: () => headerContent('Targeted states'),
        cell: ({ row }) => <StatesPopover row={row} />,
      }),
    ],
    [bulkEdit, columnHelper, onSetEnabled]
  );
};
