import styled from '@emotion/styled';
import { forwardRef, useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { useInfiniteBrands } from '@jane/gold-manager/data-access';
import type { Brand } from '@jane/gold-manager/types';
import type { FlexProps, PopoverContextProps } from '@jane/shared/reefer';
import {
  AlertIcon,
  Box,
  ChevronDownIcon,
  Flex,
  List,
  Loading,
  PartnerLogo,
  Popover,
  SearchField,
  Typography,
} from '@jane/shared/reefer';
import { shadows } from '@jane/shared/reefer-emotion';
import { useFormContext, useWatch } from '@jane/shared/reefer-hook-form';

import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import type { SpecialFormData } from '../utils/useSpecialFormData';

const StyledPopover = styled(Popover)(() => ({
  '> *': { width: '100%' },
}));

const Container = forwardRef<HTMLDivElement, FlexProps>((props, ref) => (
  <div ref={ref}>
    <Flex {...props}>{props.children}</Flex>
  </div>
));

export const BrandsDropdown = () => {
  const { brandSpecial, duplicate } = useSpecialForm();
  const editMode = brandSpecial && !duplicate;

  const {
    clearErrors,
    reset,
    setError,
    setValue,
    formState: { errors },
  } = useFormContext<SpecialFormData>();
  const { resetRulesValue, setAlertEmails } = useSpecialForm();
  const [query, setQuery] = useState('');
  const [selectedBrand, setSelectedBrand] = useState<Brand | undefined>(
    undefined
  );

  const {
    brands = [],
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteBrands({
    enabled: !editMode,
    perPage: 100,
    query,
  });

  const { ref } = useInView({
    threshold: 0.1,
    onChange: (inView) => {
      if (inView && !isFetchingNextPage && hasNextPage) {
        fetchNextPage();
      }
    },
  });

  const brandId = useWatch({ name: 'productBrandId' });
  const onBrandSelect = (brandId: number) => {
    const brand = brands?.find((brand) => String(brand.id) === String(brandId));
    setSelectedBrand(brand);
  };

  const resetForm = () => {
    reset();
    resetRulesValue();
    setAlertEmails([]);
    setValue('budgetTotal', '' as any as number);
    setValue('startDate', '');
    setValue('endDate', '');
    setValue('startTime', '');
    setValue('endTime', '');
  };

  useEffect(() => {
    clearErrors('productBrandId');
  }, [brandId]);

  useEffect(() => {
    if (duplicate && brandSpecial && !selectedBrand) {
      const { name, id, logo_url } = brandSpecial.product_brand;
      setSelectedBrand({
        name,
        id: id.toString(),
        logoUrl: logo_url,
      });
    }
  }, [brandSpecial, duplicate, selectedBrand]);

  return (
    <>
      <Typography variant="body-bold" mb={12}>
        Brand
      </Typography>
      <StyledPopover
        label="See brands to select from"
        disableMobileStyling
        targetWidth="100%"
        onClose={() => {
          setQuery('');
          if (!brandId) {
            setError('productBrandId', {
              type: 'required',
              message: 'Please fill out this field.',
            });
          }
        }}
        openOn={editMode ? 'disabled' : 'click'}
        target={
          <Flex
            borderRadius="xs"
            border="grays-light"
            background={editMode ? 'transparent-black-five' : 'grays-white'}
            py={8}
            px={16}
            height={48}
            gap={12}
            flexDirection="row"
            alignItems="center"
            style={{
              boxShadow: shadows.drop,
              cursor: editMode ? 'default' : 'pointer',
            }}
          >
            {(selectedBrand || brandSpecial) && (
              <PartnerLogo
                name={
                  editMode
                    ? brandSpecial.product_brand.name
                    : selectedBrand?.name
                    ? selectedBrand.name
                    : ''
                }
                variant="brand"
                size="xxs"
                image={
                  editMode
                    ? brandSpecial?.product_brand.logo_url
                    : selectedBrand
                    ? selectedBrand.logoUrl
                    : undefined
                }
              />
            )}
            <Typography color={selectedBrand ? 'grays-black' : 'grays-mid'}>
              {editMode
                ? brandSpecial.product_brand.name
                : selectedBrand
                ? selectedBrand.name
                : 'Select brand'}
            </Typography>
            <ChevronDownIcon ml="auto" />
          </Flex>
        }
      >
        {({ closePopover }: PopoverContextProps) => (
          <Popover.Content
            label="Select a brand from the list or search by name"
            maxHeight="490px"
            padding={false}
          >
            <Box pt={24} pb={16}>
              <SearchField
                borderRadius="sm"
                label="Search brands"
                name="brand-search-field"
                onChange={(value) => setQuery(value)}
                placeholder="Search brands"
                mb={8}
                mx={24}
                disableMobileInputStyling
                autoFocus
              />
              {!brands.length ? (
                <Box width="100%" height="100%" px={24}>
                  <Flex
                    alignItems="center"
                    justifyContent="center"
                    border="grays-light"
                    borderRadius="sm"
                    position="relative"
                    height="100%"
                    minHeight="110px"
                  >
                    {isLoading ? (
                      <Loading color="purple" />
                    ) : (
                      <Flex
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                        gap={12}
                        py={24}
                      >
                        <AlertIcon />
                        <Typography>
                          {query
                            ? `No brands matched "${query}"`
                            : 'Please try again'}
                        </Typography>
                      </Flex>
                    )}
                  </Flex>
                </Box>
              ) : (
                <List label="List of brands">
                  {brands.map((brand, i) => (
                    <List.Item
                      key={brand.id}
                      px={24}
                      py={8}
                      onClick={() => {
                        if (!!brandId && brandId !== brand.id) {
                          resetForm();
                        }
                        onBrandSelect(Number(brand.id));
                        setValue('productBrandId', Number(brand.id));
                        setQuery('');
                        closePopover();
                      }}
                      aria-label={brand.name}
                    >
                      <Container
                        flexDirection="row"
                        alignItems="center"
                        gap={12}
                        ref={i === brands.length - 1 ? ref : undefined}
                      >
                        <PartnerLogo
                          name={brand.name}
                          variant="brand"
                          size="xs"
                          image={brand.logoUrl}
                        />
                        <Typography>{brand.name}</Typography>
                      </Container>
                    </List.Item>
                  ))}
                  {isFetchingNextPage && (
                    <Box position="relative">
                      <Loading color="purple" />
                    </Box>
                  )}
                </List>
              )}
            </Box>
          </Popover.Content>
        )}
      </StyledPopover>
      {errors.productBrandId && (
        <Typography color="error" mt={16}>
          {errors.productBrandId.message}
        </Typography>
      )}
    </>
  );
};
