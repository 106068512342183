import styled from '@emotion/styled';
import type * as ReactTable from '@tanstack/react-table';
import React, { useState } from 'react';

import type { Product } from '@jane/ad-manager/data-access';
import { ConfirmDiscardWrapper } from '@jane/shared/components';
import {
  Box,
  Button,
  ButtonToggle,
  Flex,
  Link,
  Modal,
  Typography,
} from '@jane/shared/reefer';

import { ProductIdsSearch } from './ProductIdsSearch';
import { ProductTableColumnFilter } from './ProductTableColumnFilter';
import { ProductTableSearch } from './ProductTableSearch';
import { TableContent } from './TableContent';

const ButtonToggleStylesOverrideWrapper = styled.div(({ theme }) => ({
  '& > div': {
    backgroundColor: theme.colors.grays.ultralight,
    borderRadius: theme.borderRadius.sm,

    '> button': {
      borderRadius: theme.borderRadius.sm,
      paddingLeft: '24px',
      paddingRight: '24px',
      marginRight: 0,
    },
  },
}));

export type ProductColumnWithMeta = {
  meta?: {
    filterLabel?: string;
    filterValues?: string[];
  };
} & ReactTable.ColumnDef<Product>;

export type ProductSearchMode = 'productName' | 'productId';

interface Props {
  fetchMore?: () => void;
  hasChanges: boolean;
  hasNextPage?: boolean;
  isFetching?: boolean;
  isFetchingNextPage?: boolean;
  isLoading?: boolean;
  onDiscardChanges: React.Dispatch<React.SetStateAction<boolean>>;
  onSave: () => void;
  open: boolean;
  setSearchIds: (ids: string[]) => void;
  table: ReactTable.Table<Product>;
}

const StyledModelContent = styled(Modal.Content)`
  padding: 24px 40px;
`;

export const ProductTableModal = ({
  isLoading,
  isFetching,
  fetchMore,
  hasChanges,
  hasNextPage,
  onDiscardChanges,
  onSave,
  open,
  setSearchIds,
  table,
  isFetchingNextPage,
}: Props) => {
  const [searchMode, setSearchMode] =
    useState<ProductSearchMode>('productName');

  const isEmptyResults = table.getRowModel().rows.length === 0;
  const isNotFoundState = !isFetching && isEmptyResults;

  const handleToggleSearchMode = (mode: ProductSearchMode) => {
    setSearchMode(mode);

    // reset filters and search ids
    table.resetColumnFilters();
    setSearchIds([]);
  };

  const handleFetchMore = fetchMore || (() => undefined);

  return (
    <ConfirmDiscardWrapper
      setOpen={onDiscardChanges}
      open={open}
      variant="flex"
      hasChanges={hasChanges}
    >
      <>
        <Modal.Header
          data-testid="product-selector-modal"
          title="Select Products"
          actions={
            <Button
              label="Save"
              onClick={() => onSave()}
              loading={isLoading}
              disabled={isLoading}
            />
          }
        />
        <StyledModelContent>
          <Flex
            flexDirection="column"
            data-testid="product-table"
            gap={16}
            height={'100%'}
          >
            <Flex alignItems="center">
              <ButtonToggleStylesOverrideWrapper>
                <ButtonToggle
                  value={searchMode}
                  onChange={(val) =>
                    handleToggleSearchMode(val as ProductSearchMode)
                  }
                  full={false}
                >
                  <ButtonToggle.Button
                    label="Select products"
                    value="productName"
                  />
                  <ButtonToggle.Button
                    label="By Product IDs"
                    value="productId"
                  />
                </ButtonToggle>
              </ButtonToggleStylesOverrideWrapper>
            </Flex>
            {searchMode === 'productName' && (
              <Flex gap={16} alignItems="center">
                <ProductTableSearch column={table.getColumn('name')} />
                {table
                  .getAllColumns()
                  .filter((col) => col.getCanFilter())
                  .map((col) => (
                    <ProductTableColumnFilter
                      key={col.id}
                      column={col}
                      filterOptions={
                        (col.columnDef as ProductColumnWithMeta)?.meta
                          ?.filterValues ?? []
                      }
                    />
                  ))}
                <Link
                  onClick={() => {
                    table.resetColumnFilters();
                  }}
                >
                  Clear filters
                </Link>
              </Flex>
            )}
            {searchMode === 'productId' && (
              <ProductIdsSearch setSearchIds={setSearchIds} />
            )}
            <TableContent
              hasNextPage={hasNextPage}
              isNotFoundState={isNotFoundState}
              isFetchingNextPage={isFetchingNextPage}
              handleFetchMore={handleFetchMore}
              isFetching={isFetching}
              searchMode={searchMode}
              table={table}
            />
          </Flex>
        </StyledModelContent>
        <Modal.Footer>
          <Flex justifyContent="space-between" alignItems="center">
            <Box>
              <Typography color="grays-mid">
                {Object.keys(table.getState().rowSelection).length} products
                selected
              </Typography>
            </Box>
            <Flex gap={16}>
              <Button
                label="Select All"
                variant="tertiary"
                onClick={() => table.toggleAllRowsSelected(true)}
              />
              <Button
                label="Select none"
                variant="tertiary"
                onClick={() => table.toggleAllRowsSelected(false)}
              />
            </Flex>
          </Flex>
        </Modal.Footer>
      </>
    </ConfirmDiscardWrapper>
  );
};
