import { useEffect, useRef } from 'react';

import { useMediaFormUtil } from '@jane/ad-manager/hooks';
import { mediaFormKeys } from '@jane/ad-manager/util';
import { Link, Typography } from '@jane/shared/reefer';
import { Form } from '@jane/shared/reefer-hook-form';

interface EditVideoDetailsProps {
  disabled: boolean;
  isSelected: boolean;
  mediaKey: string;
}
export const EditVideoDetails = ({
  disabled,
  isSelected,
  mediaKey,
}: EditVideoDetailsProps) => {
  const { getVideoVideoUrl } = useMediaFormUtil();
  const videoUrlKey = mediaFormKeys.videoVideoUrl({ mediaKey });
  const videoUrl = getVideoVideoUrl(mediaKey);
  const videoUrlInputRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (videoUrlInputRef.current && isSelected && !videoUrl) {
      videoUrlInputRef.current.scrollIntoView({ block: 'center' });
      document.getElementById(videoUrlKey)?.focus();
    }
  }, [isSelected]);

  return (
    <div ref={videoUrlInputRef}>
      <Form.TextField
        disabled={disabled}
        mt={24}
        label="Link to video (Required)"
        defaultValue={videoUrl}
        name={videoUrlKey}
      />
      <Typography color="grays-mid" mt={16}>
        Example: https://youtu.be/wxyz
        <br />
        Learn more about supported video sources{' '}
        <Link
          href="https://github.com/CookPete/react-player#supported-media"
          variant="underline"
          color="grays-mid"
        >
          here
        </Link>
        .
      </Typography>
    </div>
  );
};
