import {
  Box,
  Button,
  Flex,
  MegaphoneIcon,
  Typography,
} from '@jane/shared/reefer';

interface NoBrandSelectedStateProps {
  onClick: () => void;
}

export const NoBrandSelectedState: React.FC<NoBrandSelectedStateProps> = ({
  onClick,
}) => {
  return (
    <Box borderRadius="lg" p={128} m={24} border="grays-light">
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="100%"
        gap={24}
        p={72}
        data-testid="no-brand-selected-state"
      >
        <MegaphoneIcon size="xxl" />
        <Typography variant="header-bold" textAlign="center">
          No brand selected
        </Typography>
        <Typography variant="body" color="grays-dark" textAlign="center">
          Please select a brand to create flights
        </Typography>
        <Button
          variant="primary"
          label="Select a brand"
          onClick={onClick}
          data-testid="no-brand-selected-state-button"
        />
      </Flex>
    </Box>
  );
};
