import {
  AdTypeEnum,
  type JamApiAd,
  convertIdsToNumber,
  transformDayParts,
  zoneToPlatforms,
} from '@jane/ad-manager/data-access';
import {
  formatPacificTimeToUtc,
  formatPacificTimeToUtcEod,
  placementsToZones,
} from '@jane/ad-manager/util';

import { transformBidModifiersToJam } from '../Pricing/BidModifiers';
import { type AdSubmissionFormState, GoalType } from './types';

export const buildJamApiPost = (formData: AdSubmissionFormState): JamApiAd => {
  const isPriority = formData.isPriority;
  const adType = formData.adType;
  const goalType = isPriority ? GoalType.Percentage : formData.goalType;

  // Mux between budget fields and constants depending on goal type
  // * monthly_revenue: monthly budget as goalAmount, null budget field
  // * revenue: budget as goalAmount, null budget field
  // * percentage: 100 as goal amount, daily budget as budget field
  const { goalAmount, budget } =
    goalType === GoalType.Percentage
      ? { goalAmount: 100, budget: formData.budget }
      : { goalAmount: formData.budget, budget: null };

  return {
    adModel: isPriority ? AdTypeEnum.enum.flat : adType,
    adType: isPriority ? 'priority' : 'programmatic',
    bid: isPriority ? null : formData.bid,
    bidModifiers: formData.bidModifiers
      ? transformBidModifiersToJam(formData.bidModifiers)
      : [],
    budget: budget,
    categories: formData.productCategories.map(({ value }) => value) || [],
    creditPercent: formData.creditPercent || 0,
    days: formData.dayParting ? transformDayParts(formData.dayParting) : [],
    endDate: formatPacificTimeToUtcEod(formData.endDate),
    excludedStores: convertIdsToNumber(formData.excludedStoreIds || []),
    frequencyCapDenominator: formData.frequencyCapDenominator,
    frequencyCapNumerator: formData.frequencyCapNumerator,
    frequencyCapUnit: formData.frequencyCapUnit,
    goalAmount,
    goalType,
    platforms: zoneToPlatforms(formData.displayPlatforms),
    products: convertIdsToNumber(formData.productIds || []),
    segmentId:
      formData.customerSegments && formData.customerSegments.length > 0
        ? Number(formData.customerSegments[0])
        : null,
    startDate: formatPacificTimeToUtc(formData.startDate),
    states: isPriority ? [] : formData.states,
    stores: convertIdsToNumber(formData.storeIds || []),
    title: adType === AdTypeEnum.enum.cpm ? formData.menuRowTitle : null,
    zones: placementsToZones(formData.adPlacement),
  };
};
