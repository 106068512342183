import { useBrandsNavigate } from '@jane/brands/hooks';
import { brandsPaths } from '@jane/brands/util';
import { getSpecialStatus } from '@jane/gold-manager/util';
import {
  Button,
  ErrorIcon,
  Flex,
  Link,
  List,
  MoreIcon,
  Popover,
  Typography,
} from '@jane/shared/reefer';

import type {
  SpecialsTableRow,
  SpecialsTableRowActions,
} from './specialsTable.types';

interface ActionsPopoverProps {
  onSetEnabled: SpecialsTableRowActions['onSetEnabled'];
  row: SpecialsTableRow;
}

export const ActionsPopover = ({ onSetEnabled, row }: ActionsPopoverProps) => {
  const label = `View actions for ${row.original.title}`;
  const elementId = `actions-popover-${row.original.title}`;
  const statusButtonLabel = row.original.enabled ? 'Disable' : 'Enable';
  const specialStatus = getSpecialStatus({
    enabled: row.original.enabled,
    endTime: row.original.end_time,
    startTime: row.original.start_time,
  });
  const enableStatusButton = specialStatus !== 'Expired';
  const { duplicateSpecial, editSpecial } = useBrandsNavigate();

  return (
    <Popover label={label} target={<Button.Icon icon={<MoreIcon />} />}>
      <Popover.Content padding={false} id={elementId}>
        <List label="List of actions" py={16}>
          {/* <List.Item
            to={brandsPaths.specialDetail(row.original.id.toString())}
            ariaLabel="View special"
            px={24}
          >
            View special
          </List.Item> */}
          <List.Item
            onClick={() => editSpecial(row.original.id.toString())}
            ariaLabel="Edit special"
            px={24}
          >
            Edit special
          </List.Item>
          <List.Item
            onClick={() => duplicateSpecial(row.original.id.toString())}
            ariaLabel="Duplicate"
            px={24}
          >
            Duplicate
          </List.Item>
          <List.Item
            onClick={enableStatusButton ? () => onSetEnabled(row) : undefined}
            ariaLabel={statusButtonLabel}
            px={24}
          >
            <Typography
              color={
                enableStatusButton
                  ? row.original.enabled
                    ? 'error'
                    : 'success'
                  : 'grays-light'
              }
              variant="body-bold"
            >
              {statusButtonLabel}
            </Typography>
          </List.Item>
        </List>
      </Popover.Content>
    </Popover>
  );
};

export const StatesPopover = ({ row }: { row: SpecialsTableRow }) => {
  const includedStates = row.original.rules.includes?.[0]?.['states'];
  const excludedStates = row.original.rules.excludes
    ? row.original.rules.excludes
        .flatMap((exclude) => (exclude as { states: string[] }).states)
        .filter((states) => states !== undefined)
    : undefined;
  const hasExcludedStates = !!excludedStates && excludedStates.length > 0;

  const inclusionAndExclusion = includedStates && hasExcludedStates;

  return (
    <Popover
      label={`View targeted states for ${row.original.title}`}
      target={
        <Typography my={inclusionAndExclusion ? 8 : 16}>
          {includedStates &&
            `${includedStates.length} included state${
              includedStates.length > 1 ? 's' : ''
            }`}
          {inclusionAndExclusion && <br />}
          {hasExcludedStates &&
            `${excludedStates.length} excluded state${
              excludedStates.length > 1 ? 's' : ''
            }`}
          {!includedStates && !hasExcludedStates && 'All states'}
        </Typography>
      }
      openOn="hover"
      alignment={{ horizontal: 'right' }}
    >
      <Popover.Content>
        <Flex flexDirection="column" gap={16} p={8}>
          {includedStates && (
            <Typography>{`${includedStates.join(', ')} included`}</Typography>
          )}
          {hasExcludedStates && (
            <Typography>{`${excludedStates.join(', ')} excluded`}</Typography>
          )}
          {!includedStates && !hasExcludedStates && (
            <Typography>
              {!hasExcludedStates && 'No state targeting'}
            </Typography>
          )}
        </Flex>
      </Popover.Content>
    </Popover>
  );
};

export const AlertPopover = ({ row }: { row: SpecialsTableRow }) => {
  const alertText = 'Missing Aeropay Location ID';

  return (
    <Popover
      label={`${alertText} for ${row.original.title}`}
      target={<ErrorIcon color="error-dark" />}
      openOn="hover"
    >
      <Popover.Content>
        <Flex alignItems="center" gap={24} p={8}>
          <Typography color="error-dark">{alertText}</Typography>
          <Link
            to={brandsPaths.editSpecial(
              row.original.id.toString(),
              'aeropay-location-id'
            )}
          >
            View
          </Link>
        </Flex>
      </Popover.Content>
    </Popover>
  );
};
