import { createColumnHelper } from '@tanstack/react-table';
import type { Column, ColumnDef } from '@tanstack/react-table';
import capitalize from 'lodash/capitalize';
import { useMemo } from 'react';

import {
  CheckboxField,
  ErrorIcon,
  Flex,
  Image,
  Popover,
  SortUpIcon,
  Typography,
} from '@jane/shared/reefer';

import type { Filters, Product } from './productSelect.types';

export const SortableHeader = ({
  column,
  label,
}: {
  column: Column<Partial<Product>>;
  label: string;
}) => {
  const sortByAsc = column.getIsSorted() === 'asc';
  const isSorted = sortByAsc || column.getIsSorted() === 'desc';
  const toggleSort = () => column.toggleSorting(sortByAsc);

  return (
    <Flex
      alignItems="center"
      onClick={toggleSort}
      aria-label={`Sort by ${sortByAsc ? 'ascending' : 'descending'}`}
      width="100%"
    >
      <Typography variant="caps" truncateAt="100%">
        {label}
      </Typography>
      <div>
        <SortUpIcon
          color={isSorted ? 'primary-dark' : 'primary-light'}
          rotate={sortByAsc ? 'up' : 'down'}
        />
      </div>
    </Flex>
  );
};

export const useProductSelectColumns = ({
  filters,
  productCount,
}: {
  filters: Filters;
  productCount: number;
}): ColumnDef<Partial<Product>, string>[] => {
  const columnHelper = createColumnHelper<Partial<Product>>();

  return useMemo<ColumnDef<Partial<Product>, string>[]>(() => {
    const selectColumn = columnHelper.display({
      id: 'select',
      header: ({ table }) => {
        const isChecked = table.getIsAllRowsSelected();
        return (
          <CheckboxField
            checked={isChecked}
            indeterminate={table.getIsSomeRowsSelected()}
            onChange={() => {
              if (isChecked === false) {
                table.toggleAllRowsSelected(true);
              } else {
                table.toggleAllRowsSelected(false);
              }
            }}
            label="Select all products"
            name="Select all products"
            labelHidden
          />
        );
      },
      cell: ({ row }) => {
        return (
          <CheckboxField
            checked={row.getIsSelected()}
            disabled={!row.getCanSelect()}
            onChange={row.getToggleSelectedHandler()}
            label={`Select product ${row.original.name}`}
            name={`Select product ${row.original.name}`}
            labelHidden
          />
        );
      },
      size: 72,
    });

    const nameColumn = columnHelper.accessor('name', {
      header: (props) => (
        <SortableHeader
          label={`${productCount} products`}
          column={props.column}
        />
      ),
      enableColumnFilter: false,
      cell: ({ row }) => {
        return (
          <Flex alignItems="center" gap={16}>
            <Image
              height="48px"
              width="48px"
              border
              borderRadius="12px"
              sizes="thumbnail"
              responsive
              src={
                row.original.photos ? row.original.photos[0]?.urls.small : ''
              }
              altText={row.original.name ? row.original.name : ''}
            />

            <Typography truncateAt="275px">{row.original.name}</Typography>
          </Flex>
        );
      },
      size: 350,
    });

    const idColumn = columnHelper.accessor((row) => capitalize(row.id), {
      id: 'id',
      header: (props) => <SortableHeader label="Id" column={props.column} />,
      enableColumnFilter: false,
      size: 96,
    });

    const brandColumn = columnHelper.accessor((row) => capitalize(row.brand), {
      id: 'brand',
      header: (props) => <SortableHeader label="Brand" column={props.column} />,
      meta: {
        filterValues: filters.brand,
        filterLabel: 'Brand',
      },
    });

    const categoryColumn = columnHelper.accessor(
      (row) => capitalize(row.category),
      {
        id: 'category',
        header: (props) => (
          <SortableHeader label="Category" column={props.column} />
        ),
        meta: {
          filterValues: filters.category,
          filterLabel: 'Category',
        },
      }
    );

    const subcategoryColumn = columnHelper.accessor(
      (row) => capitalize(row.subcategory),
      {
        id: 'subcategory',
        header: (props) => (
          <SortableHeader label="Subcategory" column={props.column} />
        ),
        meta: {
          filterValues: filters.subcategory,
          filterLabel: 'Subcategory',
        },
      }
    );

    const weightColumn = columnHelper.accessor(
      (row) => row.weights && row.weights.map((weight) => weight).join(', '),
      {
        id: 'weight',
        header: (props) => (
          <SortableHeader label="Weight" column={props.column} />
        ),
        meta: {
          filterValues: filters.weight,
          filterLabel: 'weight',
        },
      }
    );

    const lineageColumn = columnHelper.accessor(
      (row) => capitalize(row.lineage),
      {
        id: 'lineage',
        header: (props) => (
          <SortableHeader label="lineage" column={props.column} />
        ),
        meta: {
          filterValues: filters.lineage,
          filterLabel: 'Lineage',
        },
      }
    );

    const outOfStock = columnHelper.display({
      id: 'outOfStock',
      cell: ({ row }) =>
        row.original.outOfStock ? (
          <Popover
            target={<ErrorIcon color="error" />}
            label="Out of stock"
            openOn="hover"
            alignment={{ horizontal: 'right' }}
          >
            <Popover.Content>
              <Typography>This product is out of stock</Typography>
            </Popover.Content>
          </Popover>
        ) : undefined,
      size: 72,
    });

    return [
      selectColumn,
      nameColumn,
      idColumn,
      brandColumn,
      categoryColumn,
      subcategoryColumn,
      weightColumn,
      lineageColumn,
      outOfStock,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnHelper]);
};
