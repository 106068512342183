import { useWatch } from 'react-hook-form';

import { Modal, Typography } from '@jane/shared/reefer';

import { AdBuilderSectionContainer } from '../AdBuilderSectionContainer';
import type { AdSubmissionFormState } from '../formState/types';
import { DisplayPlatforms } from './DisplayPlatforms';
import { MenuRowTitle } from './MenuRowTitle';
import { Placement } from './Placement';
import { Type } from './Type';

export const AdDetailsSection = () => {
  const adPlacement = useWatch<AdSubmissionFormState, 'adPlacement'>({
    name: 'adPlacement',
  });
  return (
    <AdBuilderSectionContainer>
      <Typography variant="header-bold" branded mb={24}>
        Details
      </Typography>
      <Typography variant="body-bold" mb={12}>
        Ad type
      </Typography>
      <Type />
      <Modal.ContentDivider color="grays-light" />
      <Placement />
      <Modal.ContentDivider color="grays-light" />
      {/*react-use-form gets confused when conditionally rendering inputs. better to hide them*/}
      <div hidden={!adPlacement.includes('Menu row')}>
        <MenuRowTitle />
        <Modal.ContentDivider color="grays-light" />
      </div>
      <DisplayPlatforms />
    </AdBuilderSectionContainer>
  );
};
