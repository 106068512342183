import styled from '@emotion/styled';
import escapeRegExp from 'lodash/escapeRegExp';
import { useMemo, useState } from 'react';

import { Loading } from '@jane/brands/components';
import type { PopoverProps } from '@jane/shared/reefer';
import {
  Box,
  Button,
  CheckboxField,
  ChevronDownIcon,
  Link,
  List,
  Popover,
  SearchField,
  Tag,
  Typography,
} from '@jane/shared/reefer';

import { toKebabCase } from '../utils/toKebabCase';

export interface MultiselectOption {
  label: string;
  value: string;
}

export interface MultiselectProps {
  /** Array of values that are selected */
  currentValue: string[];
  /** Callback that is called when filter values change, receives the updatedValue array which reflects the filter state after the change action has completed. */
  handleChange: (updatedValue: string[]) => void;
  /** Text displayed on the filter */
  label: PopoverProps['label'];
  /** List of options to be displayed. Each option should have a 'label' and 'value' */
  options: MultiselectOption[];
  /** Maximum number of options to render in the multiselect. Defaults to 100 */
  optionsLimit?: number;
  /** If options need to be fetched, set to true while options are loaded */
  optionsLoading?: boolean;
  /** Flag that controls wether or not the multiselect is searchable */
  searchable?: boolean;
}

const DEFAULT_OPTIONS_LIMIT = 100;

/**
 * Creates a popover multi-select filter dropdown. Behaves like a controlled
 * input, where it renders currentValue options as checked. When user clicks on
 * an option, handleChange callback is called with the updated value.
 */

export const Multiselect = ({
  label,
  options,
  optionsLimit,
  optionsLoading,
  currentValue,
  handleChange,
  searchable,
}: MultiselectProps) => {
  const currentValueSet = useMemo(() => new Set(currentValue), [currentValue]);
  const [searchTerm, setSearchTerm] = useState<string | undefined>();

  const filteredOptions = useMemo(() => {
    let filtered = options;
    const regex = new RegExp(escapeRegExp(searchTerm), 'i');

    if (searchable && searchTerm) {
      filtered = options.filter((option) => option.label.match(regex));
    }

    return filtered.slice(0, optionsLimit || DEFAULT_OPTIONS_LIMIT);
  }, [options, searchable, searchTerm, optionsLimit]);

  const selectedOptions = useMemo(() => {
    if (searchable && currentValueSet.size > 0) {
      return options.filter((options) => currentValueSet.has(options.value));
    }

    return [];
  }, [searchable, currentValueSet, options]);

  // Generate onChange function for each checkbox value
  const generateOnChange = (value: MultiselectOption['value']) => {
    return (checked: boolean) => {
      // only update the state if necessary, updates are idempotent
      if (checked === true && !currentValueSet.has(value)) {
        const updatedValues = new Set(currentValueSet);

        updatedValues.add(value);

        handleChange(Array.from(updatedValues));
      } else if (checked === false && currentValueSet.has(value)) {
        const updatedValues = new Set(currentValueSet);

        updatedValues.delete(value);

        handleChange(Array.from(updatedValues));
      }
    };
  };

  return (
    <Popover
      target={
        <Button
          label={label}
          variant={
            currentValueSet.size === 0 ? 'tertiary' : 'tertiary-selected'
          }
          endIcon={
            <>
              <ActiveTag valuesCount={currentValueSet.size} label={label} />
              <ChevronDownIcon color="inherit" size="sm" />
            </>
          }
        />
      }
      label={label}
    >
      <Popover.Content>
        {searchable && (
          <>
            <Box pb={12}>
              <SearchField
                label={`${label} Search`}
                name={toKebabCase(`${label}-option-search`)}
                onChange={(term) => setSearchTerm(term)}
                defaultValue={searchTerm}
              />
            </Box>
            {selectedOptions.length > 0 && (
              <>
                <Box pb={4}>
                  <Typography variant="body-bold">Selected</Typography>
                </Box>
                <List label={`${label}-selected-options`}>
                  {selectedOptions.map((option, index) => (
                    <List.Item key={`${option.value}-${index}`}>
                      <CheckboxField
                        label={option.label}
                        name={toKebabCase(`${label}-${option.value}-filter`)}
                        checked={true}
                        onChange={generateOnChange(option.value)}
                      />
                    </List.Item>
                  ))}
                  <List.Item key={'deselect-link'}>
                    <Link onClick={() => handleChange([])}>Deselect All</Link>
                  </List.Item>
                </List>
                <Popover.Divider />
              </>
            )}
          </>
        )}
        <OptionsLoading loading={optionsLoading || false}>
          <List label={`${label}-options`}>
            {filteredOptions?.map((option, index) => (
              <List.Item key={`${option.value}-${index}`}>
                <CheckboxField
                  label={option.label}
                  name={toKebabCase(`${label}-${option.value}-filter`)}
                  checked={currentValueSet.has(option.value)}
                  onChange={generateOnChange(option.value)}
                />
              </List.Item>
            ))}
            <List.Item key={'deselect-link'}>
              <Link onClick={() => handleChange([])}>Deselect All</Link>
            </List.Item>
          </List>
        </OptionsLoading>
      </Popover.Content>
    </Popover>
  );
};

const LoadingBox = styled(Box)({ height: 40 });

const OptionsLoading = ({
  loading,
  children,
}: {
  children: JSX.Element;
  loading: boolean;
}) => {
  if (loading) {
    return (
      <LoadingBox>
        <Loading
          background="transparent"
          color="purple"
          size="sm"
          data-testid="filter-options-loader"
        />
      </LoadingBox>
    );
  }
  return children;
};

const ActiveTag = ({
  valuesCount,
  label,
}: {
  label: string;
  valuesCount: number;
}) => {
  return valuesCount > 0 ? (
    <Box
      mr={4}
      role="status"
      ariaLabel={`${valuesCount} active ${label} filter${
        valuesCount > 1 ? 's' : ''
      }`}
      aria-live="polite"
    >
      <Tag
        label={valuesCount.toString()}
        aria-hidden={true}
        background="purple-light"
        color="purple-dark"
      />
    </Box>
  ) : null;
};
