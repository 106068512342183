import { useEffect, useState } from 'react';

import { useBrands, useInfiniteBrands } from '@jane/gold-manager/data-access';
import { useSpecialsFilters } from '@jane/gold-manager/hooks';
import type { Brand } from '@jane/gold-manager/types';
import { BrandSelectModal, SelectedBrands } from '@jane/shared-b2b/components';
import { Box, Button, Flex, Modal } from '@jane/shared/reefer';

export const BrandFilter = () => {
  const [open, setOpen] = useState<boolean>(false);
  const [query, setQuery] = useState<string>('');
  const [locallySelectedBrands, setLocallySelectedBrands] = useState<Brand[]>(
    []
  );

  const { filters, onFilterChange } = useSpecialsFilters();

  const handleOnSelectChange = (brands: Brand[]): void => {
    setLocallySelectedBrands(brands);
    onFilterChange(
      'brandId',
      brands.map((brand) => brand.id)
    );
  };

  const onClose = () => {
    setQuery('');
    setOpen(false);
  };

  const onViewAll = () => {
    handleOnSelectChange([]);
    onClose();
  };

  const { brands, fetchNextPage, isFetchingNextPage } = useInfiniteBrands({
    enabled: open,
    perPage: 100,
    query,
  });

  const { data: selectedBrands } = useBrands({
    productBrandIds: filters.brandId,
  });

  useEffect(() => {
    if (!filters.brandId && locallySelectedBrands.length) {
      setLocallySelectedBrands([]);
    }
  }, [filters.brandId, locallySelectedBrands]);

  return (
    <Box width="100%" p={24}>
      <SelectedBrands
        onClick={() => setOpen(true)}
        selectedBrands={selectedBrands || locallySelectedBrands}
      />
      <Modal appId="root" open={open} onRequestClose={onClose}>
        <BrandSelectModal
          brands={brands || []}
          headerActions={
            <Flex gap={16}>
              <Button
                variant="secondary"
                label="View all"
                onClick={onViewAll}
              />
              <Button label="Save" onClick={onClose} />
            </Flex>
          }
          isFetchingNextPage={isFetchingNextPage}
          onChange={handleOnSelectChange}
          onNextPage={fetchNextPage}
          onQuery={setQuery}
          selectedBrands={selectedBrands || locallySelectedBrands}
        />
      </Modal>
    </Box>
  );
};
