import { useState } from 'react';

import { Button, Card, Flex, Link, Typography } from '@jane/shared/reefer';
import { useFormContext } from '@jane/shared/reefer-hook-form';

import { SpecialFormBlock, StyledCardGroup } from '../specialForm.styles';
import { useSpecialForm } from '../specialFormProvider/specialFormProvider';
import { getExclusionRule, handleScroll } from '../utils/specialForm.util';
import type { SpecialFormData } from '../utils/useSpecialFormData';
import { SegmentsSelection } from './segmentsSelection';
import { StatesSelection } from './statesSelection';
import { StoresSelection } from './storesSelection';

export const TargetingSection = () => {
  const { rules, userSegments } = useSpecialForm();
  const {
    clearErrors,
    formState: { errors },
  } = useFormContext<SpecialFormData>();

  const hasStates =
    !!rules?.includes?.[0]?.states?.length ||
    !!getExclusionRule(rules, 'states').length;
  const hasStores =
    !!rules?.includes?.[0]?.store_ids?.length ||
    !!getExclusionRule(rules, 'store_ids').length;
  const hasSegments = !!userSegments?.length;

  const [isStatesFormOpen, setStatesFormOpen] = useState(hasStates);
  const [isStoresFormOpen, setStoresFormOpen] = useState(hasStores);
  const [isSegmentsFormOpen, setSegmentsFormOpen] = useState(hasSegments);

  return (
    <Card width="100%" style={{ overflow: 'visible' }}>
      <StyledCardGroup width="100%">
        <SpecialFormBlock first>
          <Typography branded variant="header-bold" mb={32}>
            Target states, stores and customers
          </Typography>
          {!isStatesFormOpen ? (
            <Flex flexDirection="row" width="100%" gap={12}>
              <Flex flexDirection="column">
                <Typography variant="body-bold">States to target</Typography>
                <Typography>All states will be targeted by default.</Typography>
              </Flex>
              <Button
                variant="tertiary"
                label="Customize states"
                ml="auto"
                onClick={() => setStatesFormOpen(true)}
              />
            </Flex>
          ) : (
            <StatesSelection />
          )}
        </SpecialFormBlock>

        <SpecialFormBlock>
          {!isStoresFormOpen ? (
            <Flex flexDirection="row" width="100%" gap={12}>
              <Flex flexDirection="column">
                <Typography variant="body-bold">Stores to target</Typography>
                <Typography>
                  All stores within states defined above will be targeted by
                  default. You can override this by adding or excluding
                  individual stores.
                </Typography>
              </Flex>
              <Button
                variant="tertiary"
                label="Customize stores"
                ml="auto"
                onClick={() => setStoresFormOpen(true)}
              />
            </Flex>
          ) : (
            <StoresSelection />
          )}
        </SpecialFormBlock>

        <SpecialFormBlock>
          {!isSegmentsFormOpen ? (
            <Flex flexDirection="row" width="100%" gap={12}>
              <Flex flexDirection="column">
                <Typography variant="body-bold">
                  Customer segments to target
                </Typography>
                <Typography>
                  Target customers by their purchasing behaviors over a set time
                  period.
                </Typography>
              </Flex>
              <Button
                variant="tertiary"
                label="Add customer segments"
                ml="auto"
                onClick={() => {
                  setSegmentsFormOpen(true);
                  clearErrors('userSegments');
                }}
              />
            </Flex>
          ) : (
            <SegmentsSelection />
          )}
          {errors?.userSegments && (
            <Typography color="error" mt={16}>
              {errors.userSegments.message}{' '}
              {errors.userSegments.message?.includes('Special type') && (
                <Link onClick={() => handleScroll('campaign-type')}>
                  Update type
                </Link>
              )}
            </Typography>
          )}
        </SpecialFormBlock>
      </StyledCardGroup>
    </Card>
  );
};
