import clsx from 'clsx';
import type { ReactElement, ReactNode } from 'react';
import { Children, cloneElement, forwardRef, isValidElement } from 'react';
import { Link } from 'react-router-dom';

import { getNodeText } from '../../internal/utils/getNodeText';
import { logger } from '../../internal/utils/logger';
import type { ButtonVariant } from '../../theme/themeComponents.types';
import { marginCSSVars } from '../../utils/spacing';
import { LoadingLabel } from '../internal/loadingLabel/loadingLabel';
import type { LoadingColor } from '../loading/loading';
import { Loading } from '../loading/loading';
import { useReeferTheme } from '../providers/reeferThemeProvider/reeferThemeProvider';
import { Typography } from '../typography/typography';
import styles from './button.module.css';
import type { ButtonLabelProps, ButtonProps } from './button.types';
import { IconButton } from './iconButton/iconButton';

export function iconWithProps(icon: ReactNode, label?: string) {
  return Children.map(icon, (child) => {
    if (isValidElement(child)) {
      const altText = child.props.altText || label;
      const color = child.props.color || 'inherit';
      return cloneElement(
        child as ReactElement<{ altText: string; color: string }, string>,
        {
          altText,
          color,
        }
      );
    }
    return child;
  });
}

export function loadingColor(variant: ButtonVariant) {
  let animationColor = 'white';

  if (variant === 'secondary' || variant === 'primary-inverse') {
    animationColor = 'purple';
  }

  if (variant === 'tertiary' || variant === 'tertiary-selected') {
    animationColor = 'black';
  }

  return animationColor as LoadingColor;
}

function ButtonLabel({
  alignment = 'center',
  branded,
  endIcon,
  id,
  label,
  loading,
  size = 'default',
  startIcon,
  subLabel,
  variant = 'primary',
}: ButtonLabelProps) {
  const isSmall = size === 'small';
  if (isSmall && subLabel) {
    logger.warn(
      'Button',
      'Inline button variants should not include a subLabel.'
    );
  }

  const theme = useReeferTheme();
  const stringifiedLabel = getNodeText(label);

  const accessibleStartIcon = iconWithProps(startIcon, stringifiedLabel);
  const accessibleEndIcon = iconWithProps(endIcon, stringifiedLabel);

  const buttonStyles = {
    ...theme.components.Button.shared,
    ...theme.components.Button.variants[variant],
    ...theme.components.Button.sizes[size],
  };

  return (
    <LoadingLabel
      data-testid="loadingLabel"
      isLoading={loading}
      subLabel={subLabel}
    >
      <div
        className={clsx([styles[`button_iconLabel__${alignment}`]], {
          [styles.button_iconLabel]: !!label,
        })}
      >
        {accessibleStartIcon}
        <div
          className={clsx({
            [styles.button_label]: !!label,
            [styles.button_label__start]: !!accessibleStartIcon,
            [styles.button_label__end]: !!accessibleEndIcon,
          })}
        >
          {label && (
            <Typography
              id={id}
              variant={buttonStyles.typographyVariant}
              branded={branded}
              textAlign={alignment}
              as="div"
              color="inherit"
            >
              {label}
            </Typography>
          )}
          {subLabel && (
            <Typography
              variant="mini"
              branded={branded}
              textAlign={alignment}
              as="div"
              mt={-4}
              color="inherit"
            >
              {subLabel}
            </Typography>
          )}
        </div>
        {accessibleEndIcon}
      </div>
    </LoadingLabel>
  );
}

const MainButton = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      alignment = 'center',
      'aria-label': ariaLabel,
      ariaLabel: ariaLabel_toBeDeprecated,
      branded = false,
      className: classNameProp,
      data,
      'data-testid': testId,
      disableRefDeprecationWarning = false,
      disabled = false,
      endIcon,
      full,
      form,
      size = 'default',
      href,
      id,
      label,
      loading = false,
      onClick,
      startIcon,
      style,
      subLabel,
      target,
      to,
      type = 'button',
      variant = 'primary',
      ...marginProps
    }: ButtonProps,
    ref
  ) => {
    const className = clsx(
      classNameProp,
      styles.button,
      styles.buttonText,
      styles[`button__${size}`],
      styles[`button__${variant}`],
      {
        [styles.button__full]: full,
        [styles.button__disabled]: disabled,
        [styles.button__loading]: loading,
        [styles[`button__${size}__withSubLabel`]]: !!subLabel,
      }
    );

    const fakeButton = !onClick && type === 'button';

    const getDataProps = (data: ButtonProps['data']) => {
      const dataProps = {} as Record<string, string | number | boolean>;

      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          dataProps[`data-${key}`] = data[key];
        }
      }

      return dataProps;
    };

    const sharedProps = {
      'aria-label': ariaLabel || ariaLabel_toBeDeprecated,
      'data-testid': testId,
      disabled,
      ...getDataProps(data),
    };

    const labelId = id ? `${id}-label` : undefined;

    const buttonLabel = (
      <ButtonLabel
        alignment={alignment}
        branded={branded}
        id={labelId}
        startIcon={startIcon}
        endIcon={endIcon}
        label={label}
        loading={loading}
        subLabel={subLabel}
        size={size}
        variant={variant}
      />
    );

    if (ref) {
      if (to || href) {
        throw Error(
          '[Reefer/Button] refs are not recommended and cannot be used in conjunction with "to" or "href"'
        );
      }
      if (!disableRefDeprecationWarning) {
        logger.warn(
          'Button',
          'refs are deprecated and will eventually be removed'
        );
      }
    }

    const inlineStyles = {
      ...marginCSSVars({
        ...marginProps,
      }),
      ...style,
    };

    if (to) {
      return (
        <Link
          className={className}
          id={id}
          style={inlineStyles}
          onClick={onClick}
          to={to}
          target={target || '_self'}
          {...sharedProps}
        >
          {loading && <Loading color={loadingColor(variant)} />}
          {buttonLabel}
        </Link>
      );
    }

    if (href) {
      return (
        <a
          className={className}
          href={href}
          id={id}
          onClick={onClick}
          target={target || '_blank'}
          rel="noopener noreferrer"
          style={inlineStyles}
          {...sharedProps}
        >
          {loading && <Loading color={loadingColor(variant)} />}
          <span className={styles.button_newTabLabel}>Opens in new window</span>
          {buttonLabel}
        </a>
      );
    }

    if (!href && !to && fakeButton) {
      return (
        <div
          className={className}
          id={id}
          style={inlineStyles}
          {...sharedProps}
        >
          {loading && <Loading color={loadingColor(variant)} />}
          {buttonLabel}
        </div>
      );
    }

    return (
      <button
        aria-labelledby={labelId}
        className={className}
        id={id}
        onClick={onClick}
        type={type}
        form={form}
        style={inlineStyles}
        {...sharedProps}
      >
        {loading && <Loading color={loadingColor(variant)} />}
        {buttonLabel}
      </button>
    );
  }
);

export const Button = Object.assign(MainButton, {
  Icon: IconButton,
});
