import styled from '@emotion/styled';
import { type ReactNode, useEffect, useMemo, useState } from 'react';

import { useBulkUpdateBrandSpecial } from '@jane/gold-manager/data-access';
import { useSpecialsFilters } from '@jane/gold-manager/hooks';
import { SPECIALS_TABLE_PER_PAGE } from '@jane/gold-manager/util';
import { ConfirmChangeModal } from '@jane/shared/components';
import { Button, Flex, Modal, useToast } from '@jane/shared/reefer';
import { spacing } from '@jane/shared/reefer-emotion';

import { ConfirmDiscardWrapper } from '../confirmDiscardWrapper';

const ModalHeaderWrapper = styled.div(({ theme }) => ({
  '> div': {
    backgroundColor: theme.colors.brand.purple.main,
    color: theme.colors.text.inverse,
    ...spacing({ px: 24 }),
    h2: {
      color: 'inherit',
    },
  },
}));

export const BulkEditModal = ({
  selectedSpecials,
  children,
  onClose,
}: {
  children: ReactNode;
  onClose: () => void;
  selectedSpecials: string[];
}) => {
  const toast = useToast();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [bulkAction, setBulkAction] = useState('');
  const [showError, setShowError] = useState(false);
  const { filters } = useSpecialsFilters();

  const {
    mutateAsync: bulkUpdateBrandSpecial,
    isSuccess: bulkUpdateSuccess,
    isLoading: bulkUpdateLoading,
  } = useBulkUpdateBrandSpecial({
    brandIds: filters.brandId,
    dateRange: filters.dateRange,
    discountType: filters.discountType,
    remainingBudgetRange: filters.remainingBudgetRange,
    status: filters.status,
    perPage: SPECIALS_TABLE_PER_PAGE,
  });

  const handleBulkAction = async (action: 'enable' | 'disable') => {
    const params = {
      brand_special_ids: selectedSpecials.map(Number),
      schedule_attributes: { enabled: action === 'enable' },
    };
    try {
      await bulkUpdateBrandSpecial(params);
    } catch (e: any) {
      setShowError(true);
      setBulkAction('');
    }
  };

  const numberOfSpecials = useMemo(
    () => selectedSpecials.length,
    [selectedSpecials]
  );

  const handleRequestClose = () => {
    numberOfSpecials > 0 ? setConfirmationModalOpen(true) : onClose();
  };

  useEffect(() => {
    if (bulkUpdateSuccess) {
      onClose();
      toast.add({
        label: `You have successfully ${bulkAction}d ${numberOfSpecials} special${
          numberOfSpecials > 1 ? 's' : ''
        }.`,
        variant: 'success',
      });
    }
  }, [bulkUpdateSuccess]);

  /** Using the same modal for confirming changes and showing errors because their skeletons are the
  same, so this allows for a smoother transition when an error pops up after confirming changes */
  const modalProps = showError
    ? {
        open: showError,
        title: 'Something went wrong',
        subtitle: 'Do you want to try again?',
        cancelLabel: 'Try again',
        onCancel: () => {
          setShowError(false);
        },
        confirmLabel: 'Cancel',
        onConfirm: () => onClose(),
      }
    : {
        open: !!bulkAction,
        title: `${
          bulkAction.charAt(0).toUpperCase() + bulkAction.slice(1)
        } Specials`,
        subtitle: `You are attempting to ${bulkAction} ${numberOfSpecials} special${
          numberOfSpecials > 1 ? 's' : ''
        }.`,
        onCancel: () => setBulkAction(''),
        onConfirm: () => {
          handleBulkAction(bulkAction as 'enable' | 'disable');
        },
      };

  return (
    <ConfirmDiscardWrapper
      open={confirmationModalOpen}
      onConfirm={onClose}
      onCancel={() => setConfirmationModalOpen(false)}
    >
      <Modal
        appId="root"
        onRequestClose={handleRequestClose}
        open
        variant="full-screen"
      >
        <ModalHeaderWrapper>
          <Modal.Header
            title="Select brand specials"
            actions={
              <Flex gap={16}>
                <Button
                  label={`Disable ${
                    numberOfSpecials ? `(${numberOfSpecials})` : ''
                  }`}
                  variant="secondary"
                  disabled={numberOfSpecials === 0 || bulkUpdateLoading}
                  onClick={() => setBulkAction('disable')}
                />
                <Button
                  label={`Enable ${
                    numberOfSpecials ? `(${numberOfSpecials})` : ''
                  }`}
                  variant="secondary"
                  disabled={numberOfSpecials === 0 || bulkUpdateLoading}
                  onClick={() => setBulkAction('enable')}
                />
              </Flex>
            }
          />
        </ModalHeaderWrapper>
        <Modal.Content padding={false}>{children}</Modal.Content>
      </Modal>
      <ConfirmChangeModal
        appId="parent"
        {...modalProps}
        confirmButtonVariant={showError ? 'destructive' : undefined}
      />
    </ConfirmDiscardWrapper>
  );
};
