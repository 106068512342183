import type { Weekday, WeekdayMin } from './schedulers.types';

export const ORDERED_DAYS = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
] as const;

export const ALL_DAY_SCHEDULE = { endTime: '11:59 PM', startTime: '12:00 AM' };

export const WEEKDAY_TO_WEEKDAY_MIN: Record<Weekday, WeekdayMin> = {
  Friday: 'FR',
  Monday: 'MO',
  Saturday: 'SA',
  Sunday: 'SU',
  Thursday: 'TH',
  Tuesday: 'TU',
  Wednesday: 'WE',
} as const;

export const TIME_SLOT_OPTIONS = Array.from({ length: 24 }, (_, index) => {
  const hour = index % 12 || 12;
  const ampm = index < 12 ? 'AM' : 'PM';
  const timeLabel =
    index === 23
      ? '11:59 PM'
      : `${hour.toString().padStart(2, '0')}:00 ${ampm}`;
  return { label: timeLabel, value: timeLabel };
});
