import type { Column } from '@tanstack/react-table';

import type { BrandSpecial } from '@jane/gold-manager/data-access';
import { Flex, SortUpIcon, Typography } from '@jane/shared/reefer';

export const SortableHeader = ({
  column,
  label,
}: {
  column: Column<BrandSpecial, unknown>;
  label: string;
}) => {
  const sortByAsc = column.getIsSorted() === 'asc';
  const isSorted = sortByAsc || column.getIsSorted() === 'desc';
  const toggleSort = () => column.toggleSorting(sortByAsc);

  return (
    <Flex
      alignItems="center"
      onClick={toggleSort}
      aria-label={`Sort by ${sortByAsc ? 'ascending' : 'descending'}`}
      width="100%"
    >
      <Typography variant="caps" truncateAt="100%">
        {label}
      </Typography>
      <div>
        <SortUpIcon
          color={isSorted ? 'primary-dark' : 'primary-light'}
          rotate={sortByAsc ? 'up' : 'down'}
        />
      </div>
    </Flex>
  );
};
