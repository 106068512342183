import type { BaseFormProps } from './lib/baseForm/baseForm';
import { BaseForm } from './lib/baseForm/baseForm';
import type { FormProps } from './lib/form/form';
import { Form as HookForm } from './lib/form/form';
import type { FormError } from './lib/form/formValidationError';
import type { FormCheckboxFieldProps } from './lib/formCheckboxField/formCheckboxField';
import { FormCheckboxField } from './lib/formCheckboxField/formCheckboxField';
import type { FormDateTimeFieldProps } from './lib/formDateTimeField/formDateTimeField';
import { FormDateTimeField } from './lib/formDateTimeField/formDateTimeField';
import { FormErrorBanner } from './lib/formErrorBanner/formErrorBanner';
import type { FormErrorBannerProps } from './lib/formErrorBanner/formErrorBanner';
import type { FormNumberFieldProps } from './lib/formNumberField/formNumberField';
import { FormNumberField } from './lib/formNumberField/formNumberField';
import { FormRadioFieldGroup } from './lib/formRadioFieldGroup/formRadioFieldGroup';
import type {
  FormRadioFieldGroupOption,
  FormRadioFieldGroupProps,
} from './lib/formRadioFieldGroup/formRadioFieldGroup';
import type { FormSearchFieldProps } from './lib/formSearchField/formSearchField';
import { FormSearchField } from './lib/formSearchField/formSearchField';
import type { FormSelectFieldProps } from './lib/formSelectField/formSelectField';
import { FormSelectField } from './lib/formSelectField/formSelectField';
import type { FormSliderFieldProps } from './lib/formSliderField/formSliderField';
import { FormSliderField } from './lib/formSliderField/formSliderField';
import { FormSubmitButton } from './lib/formSubmitButton/formSubmitButton';
import type { FormSubmitButtonProps } from './lib/formSubmitButton/formSubmitButton';
import { FormSwitchField } from './lib/formSwitchField/formSwitchField';
import type { FormSwitchFieldProps } from './lib/formSwitchField/formSwitchField';
import type { FormTextAreaFieldProps } from './lib/formTextAreaField/formTextAreaField';
import { FormTextAreaField } from './lib/formTextAreaField/formTextAreaField';
import type { FormTextFieldProps } from './lib/formTextField/formTextField';
import { FormTextField } from './lib/formTextField/formTextField';
import type { FormTypeAheadProps } from './lib/formTypeAhead/formTypeAhead';
import { FormTypeAhead } from './lib/formTypeAhead/formTypeAhead';

export type {
  BaseFormProps,
  FormCheckboxFieldProps,
  FormDateTimeFieldProps,
  FormError,
  FormErrorBannerProps,
  FormNumberFieldProps,
  FormProps,
  FormRadioFieldGroupOption,
  FormRadioFieldGroupProps,
  FormSearchFieldProps,
  FormSelectFieldProps,
  FormSliderFieldProps,
  FormSubmitButtonProps,
  FormSwitchFieldProps,
  FormTextAreaFieldProps,
  FormTextFieldProps,
  FormTypeAheadProps,
};

export const Form = Object.assign(HookForm, {
  BaseForm: BaseForm,
  CheckboxField: FormCheckboxField,
  DateTimeField: FormDateTimeField,
  ErrorBanner: FormErrorBanner,
  NumberField: FormNumberField,
  RadioFieldGroup: FormRadioFieldGroup,
  SearchField: FormSearchField,
  SelectField: FormSelectField,
  SliderField: FormSliderField,
  SwitchField: FormSwitchField,
  SubmitButton: FormSubmitButton,
  TextAreaField: FormTextAreaField,
  TextField: FormTextField,
  TypeAhead: FormTypeAhead,
});

export { FormFieldWrapper } from './lib/formFieldWrapper/formFieldWrapper';
export { FormValidationError } from './lib/form/formValidationError';
export {
  useController,
  useFieldArray,
  useFormContext,
  useWatch,
} from 'react-hook-form';
export { useForm } from './lib/form/formContext';
