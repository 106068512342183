import { Outlet } from 'react-router-dom';

import { Box, Flex } from '@jane/shared/reefer';

import { BrandPortalNavBar } from './BrandPortalNavBar/BrandPortalNavBar';

export const BrandPortalLayout = () => {
  return (
    <Flex minHeight="100vh" flexDirection="column" background="grays-white">
      <Box as="header">
        <BrandPortalNavBar />
      </Box>
      <Flex as="main" grow={1} flexDirection="column">
        <Outlet />
      </Flex>
    </Flex>
  );
};
