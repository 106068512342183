import clsx from 'clsx';

import { Box } from '../../box/box';
import { Flex } from '../../flex/flex';
import { DismissIcon } from '../../icon/icons/dynamic/DismissIcon';
import { Typography } from '../../typography/typography';
import { useDrawerContext } from '../drawer.context';
import type { DrawerHeaderProps } from '../drawer.types';
import styles from './drawerHeader.module.css';

export const DrawerHeader = ({
  background = 'grays-white',
  centerContent,
  children,
  'data-testid': testId,
  divider = false,
  rightContent,
  style,
  subtitle,
  title,
}: DrawerHeaderProps) => {
  const { onRequestClose } = useDrawerContext();

  return (
    <Flex
      alignItems="center"
      background={background}
      className={clsx(styles.drawerHeader, {
        [styles.drawerHeader_withDivider]: divider,
      })}
      data-testid={testId}
      flexDirection="column"
      flexWrap="wrap"
      justifyContent="space-between"
      minHeight="80px"
      style={style}
      width="100%"
    >
      <Flex alignItems="start" width="100%" minHeight="48px">
        <Box
          position="absolute"
          left="var(--drawer-mobile-padding-horiz)"
          zIndex={1}
        >
          <Flex alignSelf="center" alignItems="center">
            <DismissIcon
              altText="Close Drawer"
              color="inherit"
              onClick={onRequestClose}
              data-testid="drawer-header-close-icon"
            />
          </Flex>
        </Box>

        <Flex justifySelf="center">
          <Box
            className={styles.drawerHeader_title}
            position="absolute"
            width="100%"
            top={0}
            left={0}
          >
            {centerContent ? (
              centerContent
            ) : (
              <>
                {title && (
                  <Typography branded variant="header-bold">
                    {title}
                  </Typography>
                )}
                {subtitle && (
                  <Typography color="text-light">{subtitle}</Typography>
                )}
              </>
            )}
          </Box>
        </Flex>

        {rightContent && (
          <Box position="absolute" right="var(--drawer-mobile-padding-horiz)">
            <Flex alignSelf="center" alignItems="center">
              {rightContent}
            </Flex>
          </Box>
        )}
      </Flex>

      {children && (
        <Flex width="100%" justifyContent="center">
          {children}
        </Flex>
      )}
    </Flex>
  );
};
