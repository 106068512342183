import { getRuntimeConfig } from '@jane/shared/runtime-config';

const addBaseUrl = (relativePath: string) => {
  let baseUrl = process.env.NX_DEPLOY_PUBLIC_PATH ?? '';
  if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1);
  return `${baseUrl}${relativePath}`;
};

const addFramelessEmbedUrl = (relativePath: string) => {
  let baseUrl = process.env.NX_FRAMELESS_EMBED_API_URL ?? '';
  if (baseUrl.endsWith('/')) baseUrl = baseUrl.slice(0, -1);

  let relativeNoApiPath = relativePath;
  if (relativeNoApiPath.startsWith('/api'))
    relativeNoApiPath = relativeNoApiPath.slice(4);

  return `${baseUrl}${relativeNoApiPath}`;
};

export const getApiPath = (relativePath = '') => {
  const { partnerHostedStoreId, isFramelessEmbedMode } = getRuntimeConfig();
  const needsBaseUrl = partnerHostedStoreId && !isFramelessEmbedMode;

  if (isFramelessEmbedMode) return addFramelessEmbedUrl(relativePath);

  return needsBaseUrl ? addBaseUrl(relativePath) : relativePath;
};
