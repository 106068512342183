import { useController, useForm, useWatch } from 'react-hook-form';

import { Flex, Modal, Typography } from '@jane/shared/reefer';

import { AdBuilderSectionContainer } from '../AdBuilderSectionContainer';
import type { AdSubmissionFormState } from '../formState/types';
import { CategorySelector } from './CategorySelector/CategorySelector';
import { ProductSelector } from './ProductSelector/ProductSelector';
import { ProductTargetingModeSelector } from './ProductTargetingModeSelector';

export type SelectionModeOptions = 'automatic' | 'category' | 'manual';

export const AdProductsSection = () => {
  const { getValues } = useForm();
  const {
    field: { onChange: onChangeProductCategories },
  } = useController<AdSubmissionFormState, 'productCategories'>({
    name: 'productCategories',
  });
  const {
    field: { onChange: onChangeProductIds },
  } = useController<AdSubmissionFormState, 'productIds'>({
    name: 'productIds',
  });

  const selectionMode = useWatch<
    AdSubmissionFormState,
    '_productTargetingOption'
  >({
    name: '_productTargetingOption',
    defaultValue: getValues('_productTargetingOption'),
  });

  const onSetSelectionMode = (value: SelectionModeOptions) => {
    switch (value) {
      case 'automatic':
        onChangeProductCategories([]);
        onChangeProductIds([]);
        break;
      case 'category':
        onChangeProductIds([]);
        break;
      case 'manual':
        onChangeProductCategories([]);
        break;
    }
  };

  return (
    <AdBuilderSectionContainer data-testid="product-targeting-section">
      <Flex flexDirection="column">
        <Typography variant="header-bold" branded mb={24}>
          Products
        </Typography>
        <ProductTargetingModeSelector
          selectionMode={selectionMode}
          setSelectionMode={onSetSelectionMode}
        />
        {selectionMode !== 'automatic' && (
          <Modal.ContentDivider color="grays-light" />
        )}
        {selectionMode === 'category' && <CategorySelector />}
        {selectionMode === 'manual' && <ProductSelector />}
      </Flex>
    </AdBuilderSectionContainer>
  );
};
