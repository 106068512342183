/**
 * Helper functions for generating client side paths in the brand portal.
 */
export const brandsPaths = {
  root() {
    return '/';
  },
  login() {
    return '/login';
  },
  products() {
    return '/products';
  },
  analytics() {
    return '/analytics';
  },
  analyticsBoard(liveboardId: string) {
    return this.analytics() + '/' + liveboardId;
  },
  editProduct(uuid: string) {
    return brandsPaths.products() + '/' + uuid;
  },
  editLocalization(productId: string, localizationId: string) {
    return (
      brandsPaths.editProduct(productId) + '/localizations/' + localizationId
    );
  },
  createLocalization(productId: string) {
    return brandsPaths.editProduct(productId) + '/localizations/new';
  },
  forgotPassword() {
    return '/forgot-password';
  },
  productNotice(uuid: string) {
    return brandsPaths.editProduct(uuid) + '/report';
  },
  // Ad manager paths
  brandThemes() {
    return '/brand-themes';
  },
  editBrandTheme(id: number | string) {
    return `/brand-theme-editor/${id}`;
  },
  campaigns() {
    return '/digital-merchandising/campaigns';
  },
  campaignFlightList(campaignId: number | string) {
    return `/digital-merchandising/campaign/${campaignId}`;
  },
  digitalMerchandising() {
    return '/digital-merchandising';
  },
  flightDetails(id: number | string) {
    return `${brandsPaths.digitalMerchandising()}/flight/${id}`;
  },
  createFlight() {
    return `${brandsPaths.digitalMerchandising()}/flight/new`;
  },
  // Gold manager paths
  duplicateSpecial(id: string) {
    return `/jane-gold/duplicate/${id}`;
  },
  editSpecial(id: string, hash?: string) {
    return `/jane-gold/${id}/edit${hash ? '#' + hash : ''}`;
  },
  newSpecial(hash?: string) {
    return `/jane-gold/new${hash ? '#' + hash : ''}`;
  },
  specialDetail(id: string, hash?: string) {
    return `/jane-gold/${id}${hash ? '#' + hash : ''}`;
  },
  specials() {
    return '/jane-gold';
  },
};
