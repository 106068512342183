import { z } from 'zod';

export const zProductKindEnum = z.enum([
  'edible',
  'extract',
  'flower',
  'gear',
  'grow',
  'merch',
  'pre-roll',
  'tincture',
  'topical',
  'vape',
]);

export const zProductLineageEnum = z.enum([
  'cbd',
  'hybrid',
  'indica',
  'sativa',
]);

export const zPhoto = z.object({
  id: z.string(),
  position: z.number(),
  urls: z.object({
    extraLarge: z.string(),
    medium: z.string(),
    original: z.string(),
    small: z.string(),
  }),
});

export type BrandProductPhoto = z.infer<typeof zPhoto>;

export const BrandProductSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    brand: z.string(),
    category: z.string().nullable(),
    lineage: z.string().nullable(),
    subcategory: z.string().nullable(),
    photos: zPhoto.array().nullable(),
  })
  .transform((data) => {
    return {
      ...data,
      id: z.coerce.string().parse(data.id),
    };
  });

export const BrandProductsResponseSchema = z.object({
  products: z.array(BrandProductSchema),
});

export interface FetchedBrandProduct {
  brand: string;
  category: string | null;
  id: number;
  lineage: string | null;
  name: string;
  photos: BrandProductPhoto[] | null;
  subcategory: number | null;
}

export type BrandProduct = z.infer<typeof BrandProductSchema>;

export const BrandProductFilters = z
  .object({
    categories: z.array(z.string()),
    lineages: z.array(z.string()),
    subcategories: z.array(z.string()),
  })
  .transform((data) => {
    return {
      category: data.categories,
      lineage: data.lineages,
      subcategory: data.subcategories,
    };
  });

export const BrandProductFiltersResponseSchema = z.object({
  filters: BrandProductFilters,
});

export type BrandProductFilters = z.infer<typeof BrandProductFilters>;

export interface FetchBrandProductFiltersParams {
  brandId: string;
  category?: string[];
}

export interface FetchBrandProductsParams {
  brandId: string;
  category?: string[];
  enabled?: boolean;
  ids?: number[];
  limit?: number;
  lineage?: string[];
  offset?: number;
  query?: string;
  subcategory?: string[];
}

export interface UseInfiniteBrandProductsParams
  extends FetchBrandProductsParams {
  enabled: boolean;
  perPage: number;
}

const BrandCategoriesSchema = z.object({
  category: z.string(),
  subcategory: z.string().optional().nullable(),
});

export const BrandCategoriesResponse = z.object({
  brand_categories: z.array(BrandCategoriesSchema),
});

export type BrandCategory = z.infer<typeof BrandCategoriesSchema>;
