import { Component } from 'react';
import type { BasicDoc, HighlightResult } from 'react-instantsearch-core';
import type { RefinementItem } from 'react-instantsearch-dom';

import type {
  DeepReadonly,
  Product,
  ProductKind,
  ProductLineage,
} from '@jane/shared/models';

export type ProductSearchResultType = HitType<Product> & {
  __position: number;
  product_id?: number;
};

export interface KindFacet {
  count: number;
  kind: KindOrType;
  name?: string;
}

export const MagicRowKind = 'magic_row';
export const SpecialsKind = 'specials';

export type KindOrType =
  | ProductLineage
  | ProductKind
  | typeof MagicRowKind
  | typeof SpecialsKind
  | 'featured'
  | 'sale'
  | 'best_selling'
  | 'discountableBundleProducts';

export type InitialQueryParams =
  | 'category'
  | 'brand'
  | 'root_types'
  | 'store_type'
  | 'query'
  | 'brand_subtype'
  | 'store_id';

export type InitialQuery = DeepReadonly<
  {
    [key in InitialQueryParams]?: {
      hard: boolean;
      value: string[];
    };
  } & { canUseSearchState: boolean }
>;

export interface SearchStateWithInitialQuery {
  filters: {
    [InitialQueryParams: string]: string[];
  };
  searchText?: string;
}

export type HitType<TDoc = BasicDoc> = TDoc & {
  __position: number; // TODO: (Evan) position is undocumented and should not be used. Can use Hit from react-instantsearch-core after __position is no longer used by Jane code
  _highlightResult: HighlightResult<TDoc>;
  objectID: string;
};

export interface JaneRefinementItem extends RefinementItem<string[]> {
  count: number;
  customLabel?: string | null;
  isRefined: boolean;
}

export interface ConfigureProps {
  aroundLatLng?: string;
  aroundPrecision?: number;
  aroundRadius?: number;
  attributesToHighlight?: string[];
  attributesToRetrieve?: string[];
  filters?: string;
  hitsPerPage?: number;
  optionalFilters?: string;
  query?: string;
  userToken?: string;
}

export class Configure extends Component<ConfigureProps> {}
